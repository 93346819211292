import { useAuth0 } from "@auth0/auth0-react";
import { ReactNode, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { RootError } from "./RootError";

interface Props {
  cta?: string;
}

export const NotFoundError = ({ cta }: Props) => {
  const { loginWithRedirect, logout } = useAuth0();
  const navigate = useNavigate();

  const handleErrorLogin = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userInfo");
    logout({ logoutParams: { returnTo: window.location.origin } });
    // loginWithRedirect();
  };

  return (
    <RootError>
      <p style={{ marginBottom: "20px" }}>
        Whoops! This page could not be found.
      </p>
      <button
        onClick={() => navigate("/")}
        type="button"
        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2  focus:outline-none "
      >
        Back to home
      </button>
      <button
        onClick={handleErrorLogin}
        type="button"
        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2  focus:outline-none "
      >
        Logout
      </button>
    </RootError>
  );
};

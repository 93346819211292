import React, { useEffect, useRef, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { useUser } from "../context/user";
import { useThemeContext } from "../context/theme/context";
import MultiColumnCenterBox from "../layouts/MultiColumnCenterBox";
import { BoxColumn } from "../components/layout/BoxColumn";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Input,
  LinearProgress,
  Skeleton,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { AdminModule, AdminNote } from "../types/types";
import ModulePicker from "../components/Admin/pickers/ModulePicker";
import NotePicker from "../components/Admin/pickers/NotePicker";
import { NoteSectionDisplayBox } from "../components/Note/NoteSectionDisplayBox";
import APIService from "../services/APIService";
import { Send } from "@mui/icons-material";
import { textToParagraph } from "../utils/utils";
import { NoteLoader } from "./NoteView";
import { AnimatedCheckmark, AnimatedCrossmark } from "../components/SVGIcon";
import { keyframes } from "@mui/system";

export interface ModuleCustomizationViewProps {
  note_id?: string;
  module_id?: string;
  initialComparisonText?: string;
  section_name?: string;
  initialInstructions?: string; 
  onClose?: () => void;
  onAccept?: (generation_output: string) => void;
}

interface ModuleCustomizationSessionInfo {
  moduleCustomizationSessionId: string;
  moduleCustomizationSessionStep: number;
  status: string;
}

export const ModuleCustomizationView: React.FC<
  ModuleCustomizationViewProps
> = ({
  module_id,
  note_id,
  initialComparisonText,
  section_name,
  initialInstructions,
  onClose,
  onAccept,
}) => {
  const theme = useTheme();
  const { updateOnboardingStep, userState, getAccessToken } = useUser();
  const { brandLanguage } = useThemeContext();

  const [selectedModuleId, setSelectedModuleId] = useState<string | undefined>(
    module_id
  );
  const [selectedNoteId, setSelectedNoteId] = useState<string | undefined>(
    note_id
  );
  const [comparisonText, setComparisonText] = useState<string | undefined>(
    initialComparisonText
  );
  const [promptFeedback, setPromptFeedback] = useState<string>(initialInstructions || '');
  // const [promptFeedback, setPromptFeedback] = useState<string>();
  const [aiLearnInstructions, setAILearnInstructions] = useState<string | null>("");
  const [module_customization_session_id_from_ai_instruction, setModuleCustomizationSessionIdFromAIInstruction] = useState<string | null>(""); //willreceivefromaiinstruction
  const [module_customization_session_step_from_ai_instruction, setModuleCustomizationSessionStepFromAIInstruction] = useState<number >(-1); //willreceivefromaiinstruction
  const [visiblePlaceholder, setVisiblePlaceholder] = useState<string>("");
  const placeholders = [
    "Format section with bullet points.",
    "This is too long, make it more concise.",
    "Don't use medical abbreviations.",
    "Include a subsection called 'Key Points'.",
    "Don't put distant past medical history in here, only recent history.",
    "I need this section to always be in Spanish.",
    "Break into smaller, shorter paragraphs.",
    "Put an appropriate emoji next to each diagnosis.",
    "Pay extra attention to ADHD factors.",
  ];
  let typingTimeout: NodeJS.Timeout | null = null;

  const [generationResponse, setGenerationResponse] = useState<string>();
  const [improvementDescriptionResponse, setImprovementDescriptionResponse] =
    useState<string>();
  const [sessionStatus, setSessionStatus] = useState<string>("initial");

  const [preliminaryAccept, setPreliminaryAccept] = useState<boolean>(false);
  const [tooManySteps, setTooManySteps] = useState<boolean>(false);

  const pollingIntervalId = useRef<number | null>(null);
  const moduleCustomizationSessionInfoRef =
    useRef<ModuleCustomizationSessionInfo>();
  const savedModuleRef = useRef<boolean>(false);

  const handleSubmit = () => {
    let feedback = promptFeedback;

    if (aiLearnInstructions && aiLearnInstructions?.length) {
      feedback = aiLearnInstructions;
      setAILearnInstructions("");
    }    
    if (sessionStatus === "initial") {
      startModuleCustomizationSession(feedback);
    } else if (sessionStatus === "rejected" || sessionStatus === "completed") {
      userActionOnCustomizationStep("reject", feedback);
      setGenerationResponse(undefined);
      setImprovementDescriptionResponse(undefined);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSubmit();
    }
  };

  const startModuleCustomizationSession = async (custom_feedback?: string) => {
    const accessToken = await getAccessToken();
    

    if (!accessToken) {
      return;
    }

    let feedback = promptFeedback;

    if (custom_feedback && custom_feedback?.length) {
      feedback = custom_feedback;
    }
    const url = "/customization/startModuleCustomizationSession";
    const body = {
      note_id: selectedNoteId,
      module_id: selectedModuleId,
      prompt_feedback: feedback,
    };

    try {
        if (module_customization_session_id_from_ai_instruction && module_customization_session_id_from_ai_instruction?.length  && module_customization_session_step_from_ai_instruction===0) {
          setSessionStatus("writing");
          setModuleCustomizationSessionIdFromAIInstruction("");
          setModuleCustomizationSessionStepFromAIInstruction(-1);
          moduleCustomizationSessionInfoRef.current = {
            moduleCustomizationSessionId: module_customization_session_id_from_ai_instruction,
            moduleCustomizationSessionStep: module_customization_session_step_from_ai_instruction,
            status: "writing",
          };
        }
        else{          
          const response = await APIService.makeAPIPostRequest({
            requestString: url,
            accessToken: accessToken,
            body: body,
          });
    
          if (response.ok) {
            setSessionStatus("writing");

          moduleCustomizationSessionInfoRef.current = {
          moduleCustomizationSessionId:
            response.value.module_customization_session_id,
          moduleCustomizationSessionStep:
            response.value.module_customization_session_step,
          status: "writing",
        };
      }
      }
      pollingIntervalId.current = setInterval(() => {
        checkModuleCustomizationStepStatus();
      }, 3000) as unknown as number;

    } catch (error) {
      console.error("Failed to start a ModuleCustomizationSession:", error);
    }
  };

  const checkModuleCustomizationStepStatus = async () => {
    const accessToken = await getAccessToken();

    if (!accessToken) {
      return;
    }

    if (!moduleCustomizationSessionInfoRef.current) {
      return;
    }

    try {
      const response = await APIService.makeAPIGetRequest({
        requestString: `/customization/getModuleCustomizationStepStatus?module_customization_session_id=${moduleCustomizationSessionInfoRef.current.moduleCustomizationSessionId}&module_customization_session_step=${moduleCustomizationSessionInfoRef.current.moduleCustomizationSessionStep}`,
        accessToken: accessToken,
      });

      if (response.ok) {
        console.log(response.value);
        moduleCustomizationSessionInfoRef.current.status =
          response.value.status;
        setSessionStatus(response.value.status);

        if (response.value.step.description_of_improvements) {
          setImprovementDescriptionResponse(
            response.value.step.description_of_improvements
          );
        }

        if (response.value.step.section_output) {
          setGenerationResponse(response.value.step.section_output);
        }

        if (response.value.status === "completed") {
          if (pollingIntervalId.current) {
            clearInterval(pollingIntervalId.current);
          }
        }

        if (response.value.status === "errored") {
          if (pollingIntervalId.current) {
            clearInterval(pollingIntervalId.current);
          }
        }
      }
    } catch (error) {
      console.error("Failed to check ModuleCustomizationStepStatus:", error);
      setSessionStatus("errored");
      if (pollingIntervalId.current) {
        clearInterval(pollingIntervalId.current);
      }
    }
  };

  const userActionOnCustomizationStep = async (userAction: string, custom_feedback?: string) => {
    const accessToken = await getAccessToken();

    if (!accessToken) {
      return;
    }

    let feedback = promptFeedback;

    if (custom_feedback && custom_feedback?.length) {
      feedback = custom_feedback;
    }
    

    const url = "/customization/userActionOnCustomizationStep";
    const body = {
      module_customization_session_id:
        moduleCustomizationSessionInfoRef.current?.moduleCustomizationSessionId,
      module_customization_session_step:
        moduleCustomizationSessionInfoRef.current
          ?.moduleCustomizationSessionStep,
      note_id: selectedNoteId,
      user_action: userAction,
      prompt_feedback: feedback,
    };

    try {
      const response = await APIService.makeAPIPostRequest({
        requestString: url,
        accessToken: accessToken,
        body: body,
      });

      if (response.ok) {
        if (userAction === "reject") {
          setSessionStatus("writing");

          moduleCustomizationSessionInfoRef.current = {
            moduleCustomizationSessionId:
              response.value.module_customization_session_id,
            moduleCustomizationSessionStep:
              response.value.module_customization_session_step,
            status: "writing",
          };

          pollingIntervalId.current = setInterval(() => {
            checkModuleCustomizationStepStatus();
          }, 3000) as unknown as number;
        }

        if (userAction === "accept" && response.value.user_module_id) {
          savedModuleRef.current = true;
          if (onAccept) {
            onAccept(generationResponse || "");
          }
        }
      }
    } catch (error) {
      console.error(
        "Failed to submit user action ModuleCustomizationSession:",
        error
      );
    }
  };

  const handleAccept = () => {
    userActionOnCustomizationStep("accept");
  };

  const handlePreliminaryAccept = () => {
    setPreliminaryAccept(true);
  };

  const handleReject = () => {
    setSessionStatus("rejected");

    if (
      moduleCustomizationSessionInfoRef.current &&
      moduleCustomizationSessionInfoRef.current.moduleCustomizationSessionStep >
        3
    ) {
      setTooManySteps(true);
    }
  };

  const handleClose = async () => {
    if (onClose) {
      onClose();
    }
  };

  const convertTextToParagraphs = (text: string) => {
    const paragraphs = text.split("\n").map((line, i) => {
      if (line) {
        return textToParagraph({ text: line, className: "py-2" });
      }
    });
    return paragraphs;
  };

  const fetchInitialComparisonFromNote = async () => {
    if (!selectedNoteId || !selectedModuleId) {
      return;
    }

    const note = await NoteLoader({ params: { noteId: selectedNoteId } });

    const matchingSection = note?.content.sections.find(
      (section) => section.module_id === selectedModuleId
    );

    const text = matchingSection?.edited_text || matchingSection?.text;

    setComparisonText(text);
  };

  const handleAbortSession = async () => {
    const accessToken = await getAccessToken();

    if (!accessToken) {
      return;
    }
    const response = await APIService.makeAPIPostRequest({
      requestString: "/customization/abortModuleCustomizationSession",
      accessToken: accessToken,
      body: {
        module_customization_session_id:
          moduleCustomizationSessionInfoRef.current
            ?.moduleCustomizationSessionId,
      },
    });
  };

  useEffect(() => {
    if (initialInstructions ) {
      // Automatically start the customization process with the initial instructions
      startModuleCustomizationSession();
    }
  }, [initialInstructions]);

  useEffect(() => {
    if (aiLearnInstructions && aiLearnInstructions?.length || module_customization_session_id_from_ai_instruction && module_customization_session_id_from_ai_instruction?.length) {

      // startModuleCustomizationSession(aiLearnInstructions)
      handleSubmit()
      // setPromptFeedback(aiLearnInstructions)
    }
  }, [
    aiLearnInstructions,module_customization_session_id_from_ai_instruction
  ])

  useEffect(() => {
    if (
      sessionStorage.getItem("edit_instructions") !== null
      && sessionStorage.getItem("edit_instructions")?.length
      || sessionStorage.getItem("selected_ai_option") === 'ai_learn'
    ) {
      setAILearnInstructions(sessionStorage.getItem("edit_instructions"))
      sessionStorage.removeItem("edit_instructions");
      sessionStorage.removeItem("selected_ai_option");

    }
  }, [
    sessionStorage.getItem("edit_instructions"),
    sessionStorage.getItem("selected_ai_option"),
  ])

  useEffect(() => {
    const step = sessionStorage.getItem("module_customization_session_step");
    if (
        sessionStorage.getItem("module_customization_session_id") !== null
        && sessionStorage.getItem("module_customization_session_id")?.length
        && step !== null
    ) {
        setModuleCustomizationSessionIdFromAIInstruction(sessionStorage.getItem("module_customization_session_id"));
        setModuleCustomizationSessionStepFromAIInstruction(parseInt(step));
        sessionStorage.removeItem("module_customization_session_id");
        sessionStorage.removeItem("module_customization_session_step");
    }
}, [
    sessionStorage.getItem("module_customization_session_id"),
    sessionStorage.getItem("module_customization_session_step"),
]);

  useEffect(() => {
    if (selectedModuleId && selectedNoteId && !comparisonText) {
      fetchInitialComparisonFromNote();
    }
  }, [selectedModuleId, selectedNoteId]);

  useEffect(() => {
    return () => {
      if (
        !savedModuleRef.current &&
        moduleCustomizationSessionInfoRef.current
      ) {
        handleAbortSession();
      }
    };
  }, []);

  useEffect(() => {
    let currentPlaceholderIndex = 0;

    const typeText = (text: string, index: number) => {
      if (index <= text.length) {
        setVisiblePlaceholder(text.substring(0, index));
        typingTimeout = setTimeout(() => typeText(text, index + 1), 20);
      } else {
        setTimeout(() => deleteText(text), 4000);
      }
    };

    const deleteText = (text: string) => {
      if (text.length > 0) {
        setVisiblePlaceholder(text.substring(0, text.length - 1));
        typingTimeout = setTimeout(
          () => deleteText(text.substring(0, text.length - 1)),
          20
        );
      } else {
        currentPlaceholderIndex =
          (currentPlaceholderIndex + 1) % placeholders.length;
        typeText(placeholders[currentPlaceholderIndex], 0);
      }
    };

    typeText(placeholders[currentPlaceholderIndex], 0);

    return () => {
      if (typingTimeout) clearTimeout(typingTimeout);
    };
  }, []);

  return (
    <>
      {!selectedModuleId || !selectedNoteId ? (
        <Box sx={{ width: "50%" }}>
          <ModulePicker
            onModuleSelect={(selectedModuleId) => {
              setSelectedModuleId(selectedModuleId);
            }}
          />
          <NotePicker
            onNoteSelect={(selectedNoteId) => {
              setSelectedNoteId(selectedNoteId);
            }}
          />
        </Box>
      ) : (
        <MultiColumnCenterBox
          headingText={`Customize your ${section_name || ""} section`}
        >
          <BoxColumn>
            {savedModuleRef.current ||
            tooManySteps ||
            sessionStatus === "errored" ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                {savedModuleRef.current && (
                  <Box sx={{ width: "60vw", textAlign: "center" }}>
                    <Typography fontSize={32}>
                      Your custom {section_name || ""} section has been saved!
                    </Typography>
                    <AnimatedCheckmark />
                    <Typography fontSize={20}>
                      We've remembered your instructions and preferences and
                      will keep those in mind next time we generate this
                      section.
                    </Typography>
                    <Button
                      variant="contained"
                      onClick={handleClose}
                      sx={{ mt: 2 }}
                    >
                      Back to Note
                    </Button>
                  </Box>
                )}
                {tooManySteps ||
                  (sessionStatus === "errored" && (
                    <Box sx={{ width: "60vw", textAlign: "center" }}>
                      <Typography fontSize={32}>
                        Looks like we can't customize your {section_name || ""}{" "}
                        section right now.
                      </Typography>
                      <AnimatedCrossmark />
                      <Typography fontSize={20}>
                        Please try again later, or get in touch with us to help
                        you out.
                      </Typography>
                      <Typography fontSize={20}>
                        {brandLanguage.brandContact}
                      </Typography>
                      <Button
                        variant="contained"
                        onClick={handleClose}
                        sx={{ mt: 2 }}
                      >
                        Back to Note
                      </Button>
                    </Box>
                  ))}
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flex: 1,
                  justifyContent: "center",
                }}
              >
                <Box
                  id="box-container"
                  sx={{
                    minWidth: "60vw",
                    maxWidth: "80vw",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Box
                    id="top-box"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "80%",
                    }}
                  >
                    <Box sx={{ width: "100%" }}>
                      <Typography fontSize={22} sx={{ my: 2 }}>
                        How can we improve this section for you?
                      </Typography>
                      <TextField
                        onDoubleClick={(event) => {
                          event.stopPropagation();
                        }}
                        fullWidth
                        variant="outlined"
                        placeholder={`E.g. ${visiblePlaceholder}`}
                        value={promptFeedback}
                        onChange={(event) => {
                          setPromptFeedback(event.target.value);
                        }}
                        multiline
                        minRows={2}
                        InputProps={{
                          endAdornment: (
                            <IconButton
                              onClick={handleSubmit}
                              disabled={
                                sessionStatus === "writing" ||
                                sessionStatus === "generating" ||
                                sessionStatus === "completed"
                              }
                            >
                              {false ? (
                                <CircularProgress size={24} />
                              ) : (
                                <Send />
                              )}
                            </IconButton>
                          ),
                        }}
                        disabled={
                          sessionStatus === "writing" ||
                          sessionStatus === "generating" ||
                          sessionStatus === "completed"
                        }
                        onKeyDown={handleKeyDown}
                      />
                      <Typography fontSize={12} sx={{ mt: 2, opacity: 0.5 }}>
                        Tell your scribe how it could improve this section.
                        We'll implement the changes and show you the new output
                        below. If you like what you see, your scribe will
                        remember every time you use this same template!
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    id="bottom-box"
                    sx={{
                      my: 4,
                      display: "flex",
                      flexDirection: "column",
                      height: "100%",
                      width: "80%",
                    }}
                  >
                    {sessionStatus !== "initial" && (
                      <Box
                        sx={{
                          backgroundColor:
                            theme.palette.backgroundColors?.primary,
                          border: 1,
                          borderColor: theme.palette.borderColors?.brand,
                          borderRadius: "0.75rem",
                          padding: "1rem 1.5rem",
                          width: "90%",
                          alignSelf: "flex-start",
                          marginBottom: "-20px",
                          marginLeft: "-20px",
                          zIndex: 5,
                        }}
                      >
                        <span style={{ marginRight: 8 }}>
                          {sessionStatus === "writing" && (
                            <>
                              <CircularProgress size={14} />
                            </>
                          )}
                          {sessionStatus === "generating" && (
                            <>
                              <CircularProgress size={14} />
                            </>
                          )}
                          {sessionStatus === "completed" && <>✅</>}
                          {sessionStatus === "errored" && <>❌</>}
                        </span>
                        {improvementDescriptionResponse
                          ? improvementDescriptionResponse
                          : "One second while we implement your changes..."}
                      </Box>
                    )}
                    <NoteSectionDisplayBox>
                      {generationResponse ? (
                        convertTextToParagraphs(generationResponse)
                      ) : (
                        <Box>
                          {sessionStatus === "writing" ||
                          sessionStatus === "generating" ? (
                            <Box sx={{ display: "flex" }}>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  flexDirection: "column",
                                  width: "100%",
                                }}
                              >
                                <Skeleton
                                  animation="wave"
                                  height={30}
                                  width="70%"
                                  style={{ marginBottom: 6 }}
                                />
                                <Skeleton
                                  animation="wave"
                                  height={30}
                                  width="90%"
                                  style={{ marginBottom: 6 }}
                                />
                                <Skeleton
                                  animation="wave"
                                  height={30}
                                  width="80%"
                                  style={{ marginBottom: 6 }}
                                />
                                <Skeleton
                                  animation="wave"
                                  height={30}
                                  width="85%"
                                  style={{ marginBottom: 6 }}
                                />
                              </Box>
                            </Box>
                          ) : (
                            convertTextToParagraphs(comparisonText || "")
                          )}
                        </Box>
                      )}
                    </NoteSectionDisplayBox>
                    {sessionStatus === "completed" && (
                      <Box
                        sx={{
                          backgroundColor:
                            theme.palette.backgroundColors?.primary,
                          border: 1,
                          borderColor: theme.palette.borderColors?.brand,
                          borderRadius: "0.75rem",
                          padding: "1rem 1.5rem",
                          width: "90%",
                          alignSelf: "flex-end",
                          marginTop: "-20px",
                          marginRight: "-20px",
                          zIndex: 5,
                        }}
                      >
                        <Box>
                          {preliminaryAccept ? (
                            <Box>
                              {" "}
                              <Box>
                                <Box>
                                  Great! Do you want us to save this section and
                                  use it for you from now on?
                                </Box>
                                <Box sx={{ display: "flex", mt: 1 }} gap={2}>
                                  <Button
                                    variant="outlined"
                                    color="success"
                                    onClick={handleAccept}
                                  >
                                    Save Section
                                  </Button>
                                  <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={handleReject}
                                  >
                                    Not now
                                  </Button>
                                </Box>
                              </Box>
                            </Box>
                          ) : (
                            <Box>
                              <Box>
                                Does this new section look how you want it to?
                              </Box>
                              <Box sx={{ display: "flex", mt: 1 }} gap={2}>
                                <Button
                                  variant="outlined"
                                  color="success"
                                  onClick={handlePreliminaryAccept}
                                >
                                  Yes
                                </Button>
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  onClick={handleReject}
                                >
                                  Not Yet
                                </Button>
                              </Box>
                            </Box>
                          )}
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
            )}
          </BoxColumn>
        </MultiColumnCenterBox>
      )}
    </>
  );
};
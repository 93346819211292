import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUIState } from "../../context/uiState";
import CustomButton from "../../styles/CustomButtons";
import { JotPalWordmarkLogo, JotPsychWordmarkLogo } from "../Icons/Logo";
import { useThemeContext } from "../../context/theme/context";
import { AlertBanner } from "../AlertBanner";
import { useUser } from "../../context/user";

export const DemoNav = ({}: {}) => {
  const { state: UIState, setState, showAlertBanner } = useUIState();
  const { brandLanguage } = useThemeContext();
  const { loginWithRedirect } = useAuth0();
  const navigate = useNavigate();

  const handleToggleSidebar = () => {
    setState({ ...UIState, sidebar: { isHidden: !UIState.sidebar.isHidden } });
  };

  useEffect(() => {
    // Set a timeout to delay the alert banner display
    if (!localStorage.getItem("accessToken")) {
      const timerId = setTimeout(() => {
        showAlertBanner(
          "Want to record your own sessions and save time on documentation? Click here to start your free 10-day trial!",
          "info",
          false,
          () => {
            navigate("/signup");
          }
        );
      }, 5000); // Delay set to 20 seconds

      return () => clearTimeout(timerId);
    }
  }, []);

  return (
    <nav className="fixed top-0 z-50 w-full bg-white border-b border-gray-200">
      <div className="px-3 py-3 lg:px-5 lg:pl-3">
        <div className="flex items-center justify-between">
          <div className="flex items-center justify-start">
            <button
              data-drawer-target="logo-sidebar"
              data-drawer-toggle="logo-sidebar"
              aria-controls="logo-sidebar"
              type="button"
              className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
              onClick={handleToggleSidebar}
            >
              <span className="sr-only">Open sidebar</span>
              <svg
                className="w-6 h-6"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  clipRule="evenodd"
                  fillRule="evenodd"
                  d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
                ></path>
              </svg>
            </button>
            <CustomButton
              onClick={() => {
                navigate("/");
              }}
              size="large"
              aria-label="logo-home-button"
            >
              {brandLanguage.brandName === "JotPsych" && (
                <JotPsychWordmarkLogo size={100} />
              )}
              {brandLanguage.brandName === "JotPal" && (
                <JotPalWordmarkLogo size={100} />
              )}
            </CustomButton>
          </div>
        </div>
        {UIState.navbar.showCopyNotification && (
          <div
            className={`tooltip-animation absolute z-40 top-0 left-0 inline-block p-4 mt-12 w-full font-medium text-black duration-300 transition-opacity bg-green-400 shadow-sm tooltip`}
          >
            Copied
          </div>
        )}
      </div>
      <AlertBanner />
    </nav>
  );
};

import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useCallback,
} from "react";
import { DeleteModal, DeleteModalProps } from "../../components/DeleteModal";

interface DeleteModalContextType {
  showDeleteModal: (props: DeleteModalProps) => void;
  hideDeleteModal: () => void;
}

const DeleteModalContext = createContext<DeleteModalContextType | undefined>(
  undefined
);

export const DeleteModalProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [modalProps, setModalProps] = useState<DeleteModalProps | null>(null);

  const showDeleteModal = useCallback((props: DeleteModalProps) => {
    setModalProps(props);
  }, []);

  const hideDeleteModal = useCallback(() => {
    setModalProps(null);
  }, []);

  return (
    <DeleteModalContext.Provider value={{ showDeleteModal, hideDeleteModal }}>
      {children}
      {modalProps && <DeleteModal {...modalProps} />}
    </DeleteModalContext.Provider>
  );
};

export const useDeleteModal = () => {
  const context = useContext(DeleteModalContext);
  if (!context) {
    throw new Error("useModal must be used within a DeleteModalProvider");
  }
  return context;
};

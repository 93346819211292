import { useEffect, useState } from "react";
import { BorderBox } from "./layout/BorderBox";
import { UserNote } from "./UserNote";
import { NoteCounts, UserNoteTypeII } from "../types/types";
import {
  Box,
  Typography,
  Link,
  useTheme,
  Select,
  MenuItem,
  SelectChangeEvent,
  Button,
  TextField,
  CircularProgress,
  IconButton,
} from "@mui/material";
import CustomButton, { RoundedButton } from "../styles/CustomButtons";
import Pagination from "./Pagination";
import { FilterDropdown } from "./FilterDropDown";
import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
import React from "react";
import { Height, Search } from "@mui/icons-material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { EmptyNoteList } from "../components/EmptyNoteList";
import {
  ArchivedNotesLoader,
  ForReviewNotesLoader,
  NoteCountsLoader,
} from "../loaders/DashboardLoader";
import { useUser } from "../context/user";
import { DeleteModal } from "./DeleteModal";
import APIService from "../services/APIService";

export const UserNotesList = ({}: {}) => {
  const { getAccessToken } = useUser();
  const theme = useTheme();

  const [sortedNotes, setSortedNotes] = useState<UserNoteTypeII[]>([]);
  const [notesToDisplay, setNotesToDisplay] = useState("ready_for_review");
  const [itemsPerPage, setItemsPerPage] = useState<number>(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [checkedNoteIds, setCheckedNoteIds] = useState<string[]>([]);
  const [displayNotes, setDisplayNotes] = useState<UserNoteTypeII[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { userState } = useUser();
  const [sortOrder, setSortOrder] = useState<number>(-1);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const [forReviewNotes, setForReviewNotes] = useState<
    UserNoteTypeII[] | undefined | null
  >();
  const [archivedNotes, setArchivedNotes] = useState<
    UserNoteTypeII[] | undefined | null
  >();
  const [noteCounts, setNoteCounts] = useState<NoteCounts>({
    for_review_notes_count: 0,
    archived_notes_count: 0,
  });

  const [loadError, setLoadError] = useState(false);

  const fetchForReviewNotes = async (
    limit: number = 20,
    skip: number = 0,
    sort_order: number = -1,
    search: string
  ) => {
    try {
      setIsLoading(true);
      const notes = await ForReviewNotesLoader(limit, skip, sort_order, search);
      setIsLoading(false);
      setForReviewNotes(notes);
    } catch {
      console.log("setting load error");
      setIsLoading(false);
      setLoadError(true);
    }
  };

  const fetchArchivedReviewNotes = async (
    limit: number = 40,
    skip: number = 0,
    sort_order: number = -1,
    search: string
  ) => {
    try {
      setIsLoading(true);
      const notes = await ArchivedNotesLoader(limit, skip, sort_order, search);
      setIsLoading(false);
      setArchivedNotes(notes);
    } catch {
      console.log("setting load error");
      setIsLoading(false);
      setLoadError(true);
    }
  };

  const fetchNoteCounts = async () => {
    try {
      const noteCounts = await NoteCountsLoader();
      console.log(noteCounts);
      setNoteCounts(noteCounts);
    } catch {
      setLoadError(true);
    }
  };

  const filterItems = ["a-z", "Z-A", "recent", "oldest"];

  const sortNotes = (notes: UserNoteTypeII[]) => {
    const sorted = notes.sort((a, b) =>
      sortOrder === -1
        ? b.time_uploaded - a.time_uploaded
        : a.time_uploaded - b.time_uploaded
    );
    return sorted;
  };

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleDeleteNotes = () => {
    if (checkedNoteIds.length > 0) {
      checkedNoteIds.forEach((noteId) => {
        const deleteNote = async () => {
          const accessToken = await getAccessToken();
          const response = await APIService.makeAPIPostRequest({
            requestString: "/notes/delete",
            accessToken: accessToken,
            body: { note_id: noteId },
          });

          if (response.ok) {
            const data = response.value;
            if (data.status === 200) {
              const win: Window = window;
              win.location = `/notes`;
            }
          }
        };

        deleteNote();
      });
      setShowDeleteModal(false);
    }
  };

  const handleArchiveNotesClick = () => {
    if (checkedNoteIds.length > 0) {
      checkedNoteIds.forEach((noteId) => {
        const archiveNote = async () => {
          const accessToken = await getAccessToken();
          const response = await APIService.makeAPIPostRequest({
            requestString: "/notes/updateNoteReviewStatus",
            accessToken: accessToken,
            body: {
              note_id: noteId,
              status: "archived",
            },
          });

          if (response.ok) {
            const data = response.value;
            if (data.status === 200) {
              const win: Window = window;
              win.location = `/notes`;
            }
          }
        };

        archiveNote();
      });
    }
  };

  const handleSearch = async () => {
    if (notesToDisplay === "ready_for_review") {
      fetchForReviewNotes(
        itemsPerPage,
        (currentPage - 1) * itemsPerPage,
        sortOrder,
        searchTerm
      );
    } else if (notesToDisplay === "archived") {
      fetchArchivedReviewNotes(
        itemsPerPage,
        (currentPage - 1) * itemsPerPage,
        sortOrder,
        searchTerm
      );
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSearch();
    }
  };

  const handleDeleteNotesClick = () => {
    setShowDeleteModal(true);
  };

  const handleItemsPerPageChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setCurrentPage(1);
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleSortOrderChange = (event: SelectChangeEvent) => {
    setSortOrder(event.target.value as unknown as number);
  };

  useEffect(() => {
    // clear checked notes
    setCheckedNoteIds([]);

    if (notesToDisplay === "ready_for_review") {
      if (forReviewNotes && forReviewNotes.length > 0) {
        const sorted = sortNotes(forReviewNotes);
        setIsLoading(false);
        setSortedNotes(sorted);
        setDisplayNotes(sorted);
      } else if (forReviewNotes === null) {
        // no notes exist
        setIsLoading(false);
        setSortedNotes([]);
        setDisplayNotes([]);
      } else {
        // undefined
      }
    } else if (notesToDisplay === "archived") {
      if (archivedNotes && archivedNotes.length > 0) {
        const sorted = sortNotes(archivedNotes);
        setIsLoading(false);
        setSortedNotes(sorted);
        setDisplayNotes(sorted);
      } else if (archivedNotes == null) {
        // no notes exist
        setIsLoading(false);
        setSortedNotes([]);
        setDisplayNotes([]);
      } else {
        // undefined
      }
    }
  }, [notesToDisplay, forReviewNotes, archivedNotes]);

  useEffect(() => {
    handleSearch();
  }, [currentPage, itemsPerPage, notesToDisplay, sortOrder]);

  useEffect(() => {
    fetchNoteCounts();
  }, []);

  // return (
  //   <Box className="NotesList-Container" sx={{ width: "100vw" }}>
  //     <DeleteModal
  //       isOpen={showDeleteModal}
  //       continueText="Delete"
  //       cancelText="Cancel"
  //       onCancel={() => {
  //         setShowDeleteModal(false);
  //       }}
  //       onContinue={handleDeleteNotes}
  //     >
  //       Are you sure you want to delete this note? This action cannot be undone.
  //     </DeleteModal>
  //     <Box
  //       className="NotesListNav-Container"
  //       sx={{
  //         display: "flex",
  //         flexDirection: "column",
  //         alignItems: "flex-start",
  //         width: "100%",
  //         paddingLeft: "4rem",
  //         paddingRight: "4rem",
  //         paddingTop: 2,
  //         paddingBottom: 2,
  //       }}
  //     >
  //       <Box
  //         sx={{ display: "flex", alignItems: "center", width: "100%", mb: 2 }}
  //       >
  //         <Select
  //           value={notesToDisplay}
  //           onChange={(event) =>
  //             setNotesToDisplay(event.target.value as string)
  //           }
  //           sx={{ minWidth: 120, mr: 2 }}
  //         >
  //           <MenuItem value="ready_for_review">Awaiting Review</MenuItem>
  //           <MenuItem value="archived">Archived Notes</MenuItem>
  //         </Select>
  //         <Select
  //           value={sortOrder as unknown as string}
  //           onChange={handleSortOrderChange}
  //           sx={{ minWidth: 120, mr: 2 }}
  //         >
  //           <MenuItem value={-1}>Newest First</MenuItem>
  //           <MenuItem value={1}>Oldest First</MenuItem>
  //         </Select>
  //         <Select
  //           value={itemsPerPage}
  //           onChange={(event) =>
  //             setItemsPerPage(parseInt(event.target.value as string))
  //           }
  //           sx={{ minWidth: 120, mr: 2 }}
  //         >
  //           <MenuItem value={20}>20</MenuItem>
  //           <MenuItem value={50}>50</MenuItem>
  //           <MenuItem value={100}>100</MenuItem>
  //         </Select>
  //         <TextField
  //           placeholder="Search notes..."
  //           variant="outlined"
  //           size="medium"
  //           sx={{ flexGrow: 1 }}
  //           value={searchTerm}
  //           InputProps={{
  //             endAdornment: (
  //               <IconButton onClick={handleSearch} disabled={isLoading}>
  //                 {isLoading ? <CircularProgress size={24} /> : <Search />}
  //               </IconButton>
  //             ),
  //           }}
  //           onKeyDown={handleKeyDown}
  //           onChange={(e) => {
  //             setSearchTerm(e.target.value);
  //           }}
  //         />
  //       </Box>
  //       <Box sx={{ display: "flex", alignItems: "center" }}>
  //         <Button
  //           variant="outlined"
  //           onClick={() =>
  //             checkedNoteIds.length === sortedNotes.length
  //               ? setCheckedNoteIds([])
  //               : setCheckedNoteIds(sortedNotes.map((note) => note.note_id))
  //           }
  //           sx={{ mr: 2 }}
  //         >
  //           {checkedNoteIds.length === sortedNotes.length
  //             ? "Deselect All"
  //             : "Select All"}
  //         </Button>
  //         <Button
  //           variant="outlined"
  //           onClick={handleArchiveNotesClick}
  //           startIcon={<CheckIcon />}
  //           disabled={
  //             checkedNoteIds.length === 0 || notesToDisplay === "archived"
  //           }
  //           sx={{ mr: 1 }}
  //         >
  //           Archive
  //         </Button>
  //         <Button
  //           variant="outlined"
  //           onClick={handleDeleteNotesClick}
  //           startIcon={<DeleteIcon />}
  //           color="error"
  //           disabled={checkedNoteIds.length === 0}
  //         >
  //           Delete
  //         </Button>
  //       </Box>
  //     </Box>
  //     <Box
  //       className="notesListMain-container"
  //       sx={{ padding: "4.5rem", paddingTop: "1rem" }}
  //     >
  //       <Box className="notes-container" sx={{ paddingTop: "1rem" }}>
  //         {isLoading && (
  //           <Box
  //             sx={{
  //               display: "flex",
  //               justifyContent: "center",
  //               alignItems: "center",
  //               alignContent: "center",
  //               height: "25vh",
  //             }}
  //           >
  //             <Typography variant="h4" color="textColors.lightHeader">
  //               Loading Notes...
  //             </Typography>
  //           </Box>
  //         )}
  //         {!isLoading && !userState?.onboardingSteps?.generated_first_note && (
  //           <Box
  //             sx={{
  //               border: 1,
  //               borderColor: "borderColors.primary",
  //               borderRadius: "0.75rem",
  //               padding: "2rem",
  //               textAlign: "center",
  //             }}
  //           >
  //             <EmptyNoteList />
  //           </Box>
  //         )}
  //         {!isLoading &&
  //           sortedNotes.map((note: UserNoteTypeII, index: number) => {
  //             return (
  //               <UserNote
  //                 key={note.note_id}
  //                 index={index}
  //                 note_id={note.note_id}
  //                 note_title={note.note_title}
  //                 status={note.status}
  //                 review_status={note.review_status}
  //                 time={note.time_uploaded}
  //                 summary={note.summary}
  //                 link={
  //                   note.status === "completed"
  //                     ? `/note/${note.note_id}`
  //                     : `/status/${note.note_id}`
  //                 }
  //                 checkedNoteIds={checkedNoteIds}
  //                 setCheckedNoteIds={setCheckedNoteIds}
  //                 notesToDisplay={notesToDisplay}
  //                 origin="user"
  //               />
  //             );
  //           })}
  //         <Pagination
  //           currentPage={currentPage}
  //           itemsPerPage={itemsPerPage}
  //           totalItems={
  //             notesToDisplay === "ready_for_review"
  //               ? noteCounts?.for_review_notes_count
  //               : noteCounts?.archived_notes_count
  //           }
  //           onPageChange={handlePageChange}
  //         />
  //       </Box>
  //     </Box>
  //   </Box>
  // );

  return (
    <Box
      className="NotesList-Container"
      sx={{
        width: "100%",
        maxWidth: "100vw",
        overflow: "hidden",
      }}
    >
      <DeleteModal
        isOpen={showDeleteModal}
        continueText="Delete"
        cancelText="Cancel"
        onCancel={() => {
          setShowDeleteModal(false);
        }}
        onContinue={handleDeleteNotes}
      >
        Are you sure you want to delete this note? This action cannot be undone.
      </DeleteModal>
      <Box
        className="NotesListNav-Container"
        sx={{
          display: "flex",
          flexDirection: { xs: "column" },
          alignItems: { xs: "stretch", md: "flex-start" },
          width: "100%",
          padding: { xs: "1rem", md: "2rem 4rem" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            alignItems: "center",
            width: "100%",
            mb: 2,
            gap: 2,
          }}
        >
          <Select
            value={notesToDisplay}
            onChange={(event) =>
              setNotesToDisplay(event.target.value as string)
            }
            sx={{ minWidth: 120, width: { xs: "100%", sm: "auto" } }}
          >
            <MenuItem value="ready_for_review">Awaiting Review</MenuItem>
            <MenuItem value="archived">Archived Notes</MenuItem>
          </Select>
          <Select
            value={sortOrder as unknown as string}
            onChange={handleSortOrderChange}
            sx={{ minWidth: 120, width: { xs: "100%", sm: "auto" } }}
          >
            <MenuItem value={-1}>Newest First</MenuItem>
            <MenuItem value={1}>Oldest First</MenuItem>
          </Select>
          <Select
            value={itemsPerPage}
            onChange={(event) =>
              setItemsPerPage(parseInt(event.target.value as string))
            }
            sx={{ minWidth: 120, width: { xs: "100%", sm: "auto" } }}
          >
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
          <TextField
            placeholder="Search notes..."
            variant="outlined"
            size="medium"
            sx={{ flexGrow: 1, width: { xs: "100%", sm: "auto" } }}
            value={searchTerm}
            InputProps={{
              endAdornment: (
                <IconButton onClick={handleSearch} disabled={isLoading}>
                  {isLoading ? <CircularProgress size={24} /> : <Search />}
                </IconButton>
              ),
            }}
            onKeyDown={handleKeyDown}
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            alignItems: "stretch",
            gap: 2,
            width: { xs: "100%", sm: "auto" },
          }}
        >
          <Button
            variant="outlined"
            onClick={() =>
              checkedNoteIds.length === sortedNotes.length
                ? setCheckedNoteIds([])
                : setCheckedNoteIds(sortedNotes.map((note) => note.note_id))
            }
            sx={{ width: { xs: "100%", sm: "auto" } }}
          >
            {checkedNoteIds.length === sortedNotes.length
              ? "Deselect All"
              : "Select All"}
          </Button>
          <Button
            variant="outlined"
            onClick={handleArchiveNotesClick}
            startIcon={<CheckIcon />}
            disabled={
              checkedNoteIds.length === 0 || notesToDisplay === "archived"
            }
            sx={{ width: { xs: "100%", sm: "auto" } }}
          >
            Archive
          </Button>
          <Button
            variant="outlined"
            onClick={handleDeleteNotesClick}
            startIcon={<DeleteIcon />}
            color="error"
            disabled={checkedNoteIds.length === 0}
            sx={{ width: { xs: "100%", sm: "auto" } }}
          >
            Delete
          </Button>
        </Box>
      </Box>
      <Box
        className="notesListMain-container"
        sx={{
          padding: { xs: "1rem", sm: "2rem", md: "4.5rem" },
          paddingTop: "1rem",
        }}
      >
        <Box className="notes-container" sx={{ paddingTop: "1rem" }}>
          {isLoading && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                alignContent: "center",
                height: "25vh",
              }}
            >
              <Typography
                variant="h4"
                color="textColors.lightHeader"
                sx={{
                  fontSize: {
                    xs: "1.5rem",
                    sm: "1.75rem",
                    md: "2rem",
                    lg: "2.25rem",
                  },
                  textAlign: "center",
                }}
              >
                Loading Notes...
              </Typography>
            </Box>
          )}
          {!isLoading && !userState?.onboardingSteps?.generated_first_note && (
            <Box
              sx={{
                border: 1,
                borderColor: "borderColors.primary",
                borderRadius: "0.75rem",
                padding: { xs: "1rem", sm: "2rem" },
                textAlign: "center",
                margin: { xs: "1rem", sm: "0" },
              }}
            >
              <EmptyNoteList />
            </Box>
          )}
          {!isLoading &&
            sortedNotes.map((note: UserNoteTypeII, index: number) => {
              return (
                <UserNote
                  key={note.note_id}
                  index={index}
                  note_id={note.note_id}
                  note_title={note.note_title}
                  status={note.status}
                  review_status={note.review_status}
                  time={note.time_uploaded}
                  summary={note.summary}
                  link={
                    note.status === "completed"
                      ? `/note/${note.note_id}`
                      : `/status/${note.note_id}`
                  }
                  checkedNoteIds={checkedNoteIds}
                  setCheckedNoteIds={setCheckedNoteIds}
                  notesToDisplay={notesToDisplay}
                  origin="user"
                  error_message={note.error_message}
                />
              );
            })}

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
              gap: 1,
              marginTop: 2,
            }}
          >
            <Pagination
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              totalItems={
                notesToDisplay === "ready_for_review"
                  ? noteCounts?.for_review_notes_count
                  : noteCounts?.archived_notes_count
              }
              onPageChange={handlePageChange}
            />
          </Box>
          {/* <Pagination
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            totalItems={
              notesToDisplay === "ready_for_review"
                ? noteCounts?.for_review_notes_count
                : noteCounts?.archived_notes_count
            }
            onPageChange={handlePageChange}
            sx={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
              gap: 1,
              marginTop: 2
            }}
          /> */}
        </Box>
      </Box>
    </Box>
  );
};

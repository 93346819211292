import React, { useState } from "react";
import { Patient } from "../types/types";
import { PatientsListItem } from "./PatientsListItem";

type PatientsListProps = {
  patientsList: Patient[];
};

export const PatientsList = ({ patientsList }: PatientsListProps) => {
  return (
    <div className="patient-list-container">
      {patientsList.length === 0 ? (
        <p>No patients available.</p>
      ) : (
        <div>
          {patientsList.map((patient) => (
            <PatientsListItem key={patient.patient_id} patient={patient} />
          ))}
        </div>
      )}
    </div>
  );
};

export default PatientsList;

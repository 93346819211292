import { useSelectedUser } from "../../context/selectedUserContext.tsx";
import { AdminUser } from "../../types/types.js";
import { UniversalCustomPromptSection } from "./CustomPromptSections.js";

export const UserCustomPrompts = () => {
  const { selectedUser } = useSelectedUser();

  if (!selectedUser) {
    return <></>;
  }

  return (
    <div className="m-4 p-4 bg-slate-100 rounded-md">
      <h2 className="text-2xl font-bold mb-2">User Custom Prompts</h2>
      <UniversalCustomPromptSection user_id={selectedUser} />
    </div>
  );
};

// PatientView.tsx

import React from "react";
import { useLoaderData } from "react-router-dom";
import { Patient, PatientInfo, PatientNoteType } from "../types/types";
import { PatientLoader } from "../loaders/PatientsLoader";
import { formatDate } from "../utils/utils";
import { UserNote } from "../components/UserNote";
import { PatientNote } from "../components/PatientNote";

type Awaited<T> = T extends PromiseLike<infer U> ? Awaited<U> : T;

export const PatientView = () => {
  const patient = useLoaderData() as Awaited<ReturnType<typeof PatientLoader>>;
  const { most_recent_note, patient_id, patient_info, last_visit, notes } =
    patient;

  return (
    <div className="bg-gray-100 h-screen flex-1">
      <div className="text-center py-4 text-2xl font-bold bg-blue-500 text-white">
        Patient Dashboard
      </div>
      <div className="p-4 flex flex-wrap justify-around">
        <div className="bg-white p-4 rounded-md shadow-md flex-none w-full md:w-1/3">
          <h3 className="text-xl font-bold mb-2">Patient Details</h3>
          <div className="text-base">
            <div>Name: {patient_info.name}</div>
            {patient_info.dob && <div>DOB: {patient_info.dob}</div>}
            {last_visit && <div>Last Visit: {formatDate(last_visit)}</div>}
            <div>Patient ID: {patient_id}</div>
          </div>
        </div>

        {most_recent_note && (
          <div className="bg-white p-4 rounded-md shadow-md flex-none w-full md:w-1/3">
            <h3 className="text-xl font-bold mb-2">Most Recent Note</h3>
            <UserNote
              note_id={most_recent_note.note_id}
              note_title={most_recent_note.note_title}
              status={most_recent_note.status}
              time={most_recent_note.time_uploaded}
              summary={most_recent_note.content.sections[0].text}
              link={`/note/${most_recent_note.note_id}`}
            />
          </div>
        )}

        {notes.length > 0 && (
          <div className="bg-white p-4 rounded-md shadow-md flex-none w-full md:w-1/3 overflow-y-auto max-h-[400px]">
            <h3 className="text-xl font-bold mb-2">Patient Notes</h3>
            <div className="space-y-2">
              {notes.map((note: PatientNoteType, index: number) => (
                <PatientNote
                  note_id={note.note_id}
                  timestamp={note.timestamp}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PatientView;

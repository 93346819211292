import { useAuth0 } from "@auth0/auth0-react";
import { PropsWithChildren } from "react";
import { PrimaryButton } from "./Buttons";
import { useUIState } from "../../context/uiState";
import { useNavigate } from "react-router-dom";

export const LoginButton = () => {
  const { loginWithRedirect, loginWithPopup, handleRedirectCallback } =
    useAuth0();
  const { state } = useUIState();
  const { loadedInChromeExtension } = state;

  const handleLogin = async () => {
    if (loadedInChromeExtension) {
      await loginWithPopup();
      window.location.href = "/callback"; // Redirect to your callback route
    } else {
      loginWithRedirect();
    }
  };

  return <PrimaryButton handleClick={handleLogin}>Login</PrimaryButton>;
};

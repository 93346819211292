import { useAuth0 } from "@auth0/auth0-react";
import { ReactNode, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { RootError } from "./RootError";

interface Props {
  message: string;
  cta?: string;
}

export const UnauthorizedError = ({ message, cta }: Props) => {
  const { loginWithRedirect, logout } = useAuth0();
  const [countdown, setCountdown] = useState(3);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    // Check if the page was already refreshed
    if (searchParams.get("refreshed")) {
      return; // Do not start the countdown if the page was refreshed
    }

    // Set up the interval
    const intervalId = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    // Clear the interval on unmount
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (countdown === 0) {
      // Refresh the page when countdown reaches 0
      setSearchParams({ refreshed: "true" });
      setTimeout(() => {
        window.location.reload();
      }, 50);
    }
  }, [countdown, searchParams]);

  const handleErrorLogin = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userInfo");
    logout({ logoutParams: { returnTo: window.location.origin } });
    // loginWithRedirect();
  };

  return (
    <RootError>
      <p style={{ marginBottom: "20px" }}>{message}</p>
      {!searchParams.get("refreshed") && (
        <p style={{ marginBottom: "20px" }}>
          Or wait while we try again in {countdown} seconds.
        </p>
      )}
      <button
        onClick={handleErrorLogin}
        type="button"
        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2  focus:outline-none "
      >
        Login
      </button>
    </RootError>
  );
};

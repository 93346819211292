import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { json, Params } from "react-router-dom";
import { PrimaryButton } from "../../components/Buttons/Buttons";
import { NoteSection } from "../../components/NoteSection";
import APIService from "../../services/APIService";
import { useUser } from "../../context/user";

export const ExtractionTestsView = () => {
  const { getAccessToken } = useUser();
  const [transcriptsList, setTranscriptsList] = useState<any[]>([]);
  const [selectedTranscript, setSelectedTranscript] = useState<any>();
  const [query, setQuery] = useState("");
  const [maxTokens, setMaxTokens] = useState("1500");
  const [similarityThreshold, setSimilarityThreshold] = useState("0.45");
  const [results, setResults] = useState<any>();
  const [spinning, setSpinning] = useState<boolean>(false);

  const handleRunTest = async () => {
    setSpinning(true);
    const response = await APIService.makeAPIPostRequest({
      requestString: "/testsuite/compareExtractionsForQueryTwoSystems",
      accessToken: await getAccessToken(),
      body: {
        note_id_for_transcript: selectedTranscript,
        query: query,
        num_of_tokens: maxTokens,
        similarity_threshold: similarityThreshold,
      },
    });

    if (response.ok) {
      const data = response.value;
      console.log(data);
      setResults(data);
      setSpinning(false);
    } else {
      setSpinning(false);
    }

    console.log(response);
  };

  const fetchTranscripts = async () => {
    const accessToken = await getAccessToken();

    const response = await APIService.makeAPIGetRequest({
      requestString: "/testsuite/getAllNotes",
      accessToken: accessToken,
    });

    if (response.ok) {
      const data = response.value;
      console.log(data.notes);
      setTranscriptsList(data.notes);
    }
  };

  const handleTranscriptSelection = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedTranscript(e.target.value);
  };

  useEffect(() => {
    fetchTranscripts();
  }, []);

  return (
    <div>
      <div className="mb-4">
        <label
          htmlFor="transcriptSelect"
          className="block text-sm font-medium text-gray-700"
        >
          Select a config to edit
        </label>
        <select
          id="transcriptSelect"
          value={selectedTranscript}
          onChange={handleTranscriptSelection}
          className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
        >
          <option value="">Select a transcript to compare</option>
          {transcriptsList &&
            transcriptsList.map((obj, index) => (
              <option key={index} value={obj.note_id}>
                {obj.note_title}
              </option>
            ))}
        </select>
      </div>
      <div className="mb-4">
        <label
          htmlFor="queryInput"
          className="block text-sm font-medium text-gray-700"
        >
          Query
        </label>
        <textarea
          id="queryInput"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
        />
      </div>
      <div className="mb-4">
        <label
          htmlFor="maxTokensInput"
          className="block text-sm font-medium text-gray-700"
        >
          Max Tokens
        </label>
        <input
          id="maxTokensInput"
          type="number"
          defaultValue={1000}
          value={maxTokens}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setMaxTokens(e.target.value)
          }
          className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
        />
      </div>
      <div className="mb-4">
        <label
          htmlFor="similarity"
          className="block text-sm font-medium text-gray-700"
        >
          Similarity Threshold
        </label>
        <input
          id="similarity"
          type="number"
          defaultValue={0.45}
          value={similarityThreshold}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setSimilarityThreshold(e.target.value)
          }
          className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
        />
      </div>
      {selectedTranscript && (
        <p className="mb-4">Selected config: {selectedTranscript}</p>
      )}
      {spinning && <Spinner />}
      {!spinning && (
        <div className="mb-4">
          <PrimaryButton handleClick={() => handleRunTest()}>
            Send
          </PrimaryButton>
        </div>
      )}
      {results && (
        <div className="flex justify-between">
          <div className="w-1/2 p-2">
            <h2 className="font-bold mb-2">New Engine</h2>
            {results.results_new.map((chunk: any[], chunkIndex: number) => (
              <div key={chunkIndex} className="mb-4">
                <h3 className="text-sm text-gray-500 mb-2">
                  Chunk {chunkIndex + 1}
                </h3>
                {chunk.map((line: string, lineIndex: number) => (
                  <p key={lineIndex} className="text-sm mb-1">
                    {line}
                  </p>
                ))}
              </div>
            ))}
          </div>
          <div className="w-1/2 p-2">
            <h2 className="font-bold mb-2">Old Engine</h2>
            {results.results_old.map((chunk: any[], chunkIndex: number) => (
              <div key={chunkIndex} className="mb-4">
                <h3 className="text-sm text-gray-500 mb-2">
                  Chunk {chunkIndex + 1}
                </h3>
                {chunk.map((line: string, lineIndex: number) => (
                  <p key={lineIndex} className="text-sm mb-1">
                    {line}
                  </p>
                ))}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

function Spinner() {
  return (
    <div className="flex justify-center items-center">
      <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-gray-900"></div>
    </div>
  );
}

export default ExtractionTestsView;

import { useUser } from "../context/user";
import { useState } from "react";
import APIService from "../services/APIService";

interface CreateGroupFormProps {
  onGroupCreated: (groupId: string) => void;
}

export const CreateGroupForm: React.FC<CreateGroupFormProps> = ({
  onGroupCreated,
}) => {
  const { getAccessToken } = useUser();

  const { userState } = useUser();
  const [groupName, setGroupName] = useState("");

  const addGroup = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!groupName) {
      alert("Please fill in the group name.");
      return;
    }

    const newGroup = {
      group_info: {
        name: groupName,
      },
      billing_admin: userState?.user_id,
    };

    try {
      const accessToken = await getAccessToken();
      const response = await APIService.makeAPIPostRequest({
        requestString: "/group/createGroup",
        accessToken: accessToken,
        body: newGroup,
      });

      if (!response.ok) {
        const message = `An error has occurred.`;
        throw new Error(message);
      }

      const result = response.value;
      onGroupCreated(result.group_id);
    } catch (error) {
      console.error("Failed to create group:", error);
    }

    setGroupName("");
  };

  return (
    <div className="p-4 shadow-lg rounded-lg bg-white mt-4 mx-auto max-w-lg">
      <h2 className="text-lg font-semibold text-center mb-4">
        Create New Group
      </h2>
      <form className="flex items-center" onSubmit={addGroup}>
        <div className="flex-1 mr-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="group-name"
          >
            Group Name
          </label>
          <input
            className="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-300 rounded-md py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
            id="group-name"
            type="text"
            placeholder="Enter group name"
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
            required
          />
        </div>
        <div className="mt-7">
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out"
          >
            Add Group
          </button>
        </div>
      </form>
    </div>
  );
};

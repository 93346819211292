import { ReactNode, useEffect, useState } from "react";
import DashboardNote from "../components/Dashboard/DashboardNote";
import {
  json,
  Params,
  useLoaderData,
  useNavigate,
  useParams,
} from "react-router-dom";
import {
  NoteCounts,
  UserNoteType,
  UserNoteTypeII,
  GamificationType,
  UserMetricsType,
  ReferralInfoType,
} from "../types/types";
import { Box, Typography, Link, Skeleton, Button } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { Margin } from "@mui/icons-material";
import React from "react";
import { UserInfoLocalStorageType } from "../utils/LocalStorageUtils";
import DashboardNums from "../components/Dashboard/DashboardNums";
import { useUIState } from "../context/uiState";

import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Grid from "@mui/material/Grid"; // Grid version 1
import RefreshIcon from "@mui/icons-material/Refresh";
import MultiColumnCenterBox from "../layouts/MultiColumnCenterBox";
import { BoxColumn } from "../components/layout/BoxColumn";
import { UserState, useUser } from "../context/user/context";
import ExtensionIcon from "@mui/icons-material/Extension";
import { EmptyNoteList } from "../components/EmptyNoteList";
import { DashboardOnboardingModal } from "../components/Modals/DashboardOnboardingModal";
import {
  ForReviewNotesLoader,
  NoteCountsLoader,
} from "../loaders/DashboardLoader";
import { useThemeContext } from "../context/theme/context";
import { TermsOfServiceModal } from "../components/Modals/ToSModal";
import { TestNoteRecordingView } from "./TestNoteRecordingView";
import { NewFeatureModal } from "../components/Modals/NewFeatureModal";
import AddToHomeScreen from "../components/AddToHomeScreen";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: "100%",
  borderRadius: 2,
}));

const Dashboard = () => {
  const theme = useTheme();
  const {
    showAlertBanner,
    state: uiState,
    setState: setUIState,
  } = useUIState();
  const { platform } = uiState;

  const [notesForReview, setNotesForReview] = useState<
    UserNoteTypeII[] | undefined | null
  >();
  const [noteCounts, setNoteCounts] = useState<NoteCounts>();
  const [loading, setLoading] = useState(true);
  const [loadError, setLoadError] = useState(false);
  const [notesLoading, setNotesLoading] = useState(true);

  const [gamificationInfo, setGamificationInfo] = useState<
    GamificationType | undefined
  >();
  const [userMetrics, setUserMetrics] = useState<UserMetricsType | undefined>();
  const [referralInfo, setReferralInfo] = useState<
    ReferralInfoType | undefined
  >();

  const { updateOnboardingStep, userState, newFeature, setNewFeature } =
    useUser();
  const { brandLanguage } = useThemeContext();

  const [showToSModal, setShowToSModal] = useState<boolean>(false);
  const [showAddToHomeScreen, setShowAddToHomeScreen] =
    useState<boolean>(false);

  const [showNewFeatureModal, setShowNewFeatureModal] =
    useState<boolean>(false);

  const navigate = useNavigate();

  const fetchForReviewNotes = async () => {
    try {
      const notes = await ForReviewNotesLoader();
      setNotesForReview(notes);
    } catch (e) {
      console.log("setting load error", e);
      setLoadError(true);
    }
  };

  const fetchNoteCounts = async () => {
    try {
      const counts = await NoteCountsLoader();
      setNoteCounts(counts);
    } catch {
      console.log("setting load error");
      setLoadError(true);
    }
  };

  const handleCopyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(() => {
      setUIState({ ...uiState, navbar: { showCopyNotification: true } });
    });
  };

  const findCorrespondingUserMetric = (metric: string) => {
    if (gamificationInfo && userMetrics) {
      const user_metric = userMetrics[metric];
      if (user_metric) {
        return user_metric;
      }
    }
    return 0;
  };

  const constructGamificationText = (info: GamificationType) => {
    if (!info.game_completed) {
      return `Level ${info.current_level}: ${info.activity_verb} ${info.target} ${info.activity_unit}.`;
    }
  };

  const constructMetricText = (info: GamificationType) => {
    return `${
      findCorrespondingUserMetric(info.current_metric) -
      info.starting_metric_value
    } of ${info.target} ${info.activity_unit}`;
  };

  const getGamificationProgress = (info: GamificationType) => {
    if (info && userMetrics) {
      const progress =
        ((findCorrespondingUserMetric(info.current_metric) -
          info.starting_metric_value) /
          info.target) *
        100;

      if (progress >= 100) {
        return 100;
      } else if (progress <= 0) {
        return 0;
      } else {
        return progress;
      }
    }
    return 0;
  };

  // const handleCloseOnboardingModal = () => {
  //   updateOnboardingStep("dashboard_onboarding_completed", true);
  //   setShowOnboardingModal(false);
  // };

  useEffect(() => {
    if (noteCounts && notesForReview) {
      setNotesLoading(false);
    }
  }, [noteCounts, notesForReview]);

  useEffect(() => {
    if (userState) {
      try {
        fetchForReviewNotes();
        fetchNoteCounts();
        if (userState.gamification) {
          setGamificationInfo(userState.gamification);
        }
        if (userState.userMetrics) {
          setUserMetrics(userState.userMetrics);
        }
        if (userState.referralInfo) {
          setReferralInfo(userState.referralInfo);
        }
        if (userState.onboardingSteps) {
          if (userState.onboardingSteps.accepted_tos_0524 !== true) {
            setShowToSModal(true);
          }
        }
      } catch (e) {
        console.log("error in retrieval", e);
      }
    }
  }, [userState]);

  useEffect(() => {
    if (newFeature) {
      setShowNewFeatureModal(true);
    }

    // only show add to home screen after ToS dialog has been accepted
    // (to not crowd with too many pop ups)
    if (userState) {
      if (
        platform !== "desktop" &&
        !newFeature &&
        userState.onboardingSteps?.accepted_tos_0524 === true
      ) {
        setShowAddToHomeScreen(true);
      }
    }
  }, [userState]);

  const RefreshComponent = () => {
    return (
      <Box
        onClick={() => {
          window.location.reload();
        }}
        sx={{
          marginRight: "2.5rem",
          "&:hover": {
            cursor: "pointer",
            borderRadius: "0.25rem",
            backgroundColor: "backgroundColors.secondary",
          },
        }}
      >
        <RefreshIcon style={{ fontSize: 30 }} />
      </Box>
    );
  };

  return (
    <>
      <MultiColumnCenterBox
        headingText="Dashboard"
        headingComponent={<RefreshComponent />}
      >
        {!userState?.onboardingSteps?.generated_first_note && (
          <BoxColumn>
            <Box
              //box for Review Completed and Time
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                padding: "1.5rem 0rem",
                height: "100%",
              }}
            >
              <EmptyNoteList />
            </Box>
          </BoxColumn>
        )}
        {userState?.onboardingSteps?.generated_first_note && (
          <BoxColumn>
            <Box
              //box for Review Completed and Time
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                padding: "1.5rem 1.5rem",
                height: "100%",
              }}
            >
              {/* Note Challenge Box */}
              {gamificationInfo ? (
                <Box
                  sx={{
                    bgcolor: "backgroundColors.secondary",
                    border: 1,
                    borderColor: "borderColors.primary",
                    borderRadius: "0.75rem",
                    padding: "1rem 1.5rem",
                    width: "100%",
                    marginBottom: "0.5rem",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    variant="body1"
                    fontSize="1.0625rem"
                    fontStyle="normal"
                    fontWeight="700"
                    lineHeight="1.59375rem"
                    color="textColors.lightHeader"
                  >
                    Your {brandLanguage.brandName} Challenge
                  </Typography>

                  {!userState?.gamification?.game_completed ? (
                    <>
                      <Typography
                        variant="body1"
                        fontSize="0.75rem"
                        fontStyle="normal"
                        fontWeight="500"
                        lineHeight="1.59375rem"
                        color="textColors.lightHeader"
                      >
                        {gamificationInfo.reward.text}&nbsp;
                        <Link
                          href={`https://${brandLanguage.brandSite}/rewardsandreferrals`}
                          target="_blank"
                          underline="hover"
                        >
                          {"Click here to learn more."}
                        </Link>
                      </Typography>
                      <Box
                        sx={{
                          bgcolor: "backgroundColors.secondary",
                          display: "flex",
                          flexDirection: "column",
                          paddingY: "px",
                        }}
                      >
                        <Typography
                          variant="body1"
                          fontSize="1.0rem"
                          fontStyle="normal"
                          fontWeight="500"
                          color="textColors.lightHeader"
                        >
                          {constructGamificationText(gamificationInfo)}
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          bgcolor: "backgroundColors.secondary",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                          height: "30%",
                        }}
                      >
                        <Typography
                          variant="body1"
                          fontSize="0.75rem"
                          fontStyle="normal"
                          fontWeight="500"
                          lineHeight="1.59375rem"
                          color="textColors.lightHeader"
                          sx={{
                            marginBottom: "10px",
                          }}
                        >
                          {constructMetricText(gamificationInfo)}
                        </Typography>

                        <Box
                          sx={{
                            width: "100%",
                            height: "25px",
                            minHeight: "5px",
                          }}
                        >
                          <BorderLinearProgress
                            variant="determinate"
                            value={getGamificationProgress(gamificationInfo)}
                          />
                        </Box>
                      </Box>
                    </>
                  ) : (
                    <Box>
                      {/* Content or component to render when game_completed is true */}
                      <Box sx={{ paddingY: "10px" }}>
                        <Typography
                          variant="h2"
                          fontSize="1.5rem"
                          fontStyle="normal"
                          fontWeight="700"
                          color="textColors.lightHeader"
                        >
                          Nice work! You've completed your challenge.{" "}
                          {String.fromCodePoint(0x1f389)}
                        </Typography>
                      </Box>
                      <Box sx={{ paddingY: "10px" }}>
                        <Typography
                          variant="h2"
                          fontSize="1rem"
                          fontStyle="normal"
                          fontWeight="500"
                          color="textColors.lightHeader"
                        >
                          Keep an eye out for your reward and for future
                          challenges!
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </Box>
              ) : (
                <>
                  {!notesLoading && noteCounts && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: { xs: "row", sm: "row" },
                        width: "100%",
                        justifyContent: "space-between",
                        marginBottom: "0.5rem",
                        height: "100%",
                      }}
                    >
                      <Box sx={{ width: { xs: "48%", sm: "48%" }, mb: { xs: 0, sm: 0 } }}>
                        <DashboardNums
                          num={noteCounts.for_review_notes_count}
                          text="Notes to Review"
                          link="/notes"
                        />
                      </Box>
                      <Box sx={{ width: { xs: "48%", sm: "48%" } }}>
                        <DashboardNums
                          num={noteCounts.archived_notes_count}
                          text="Archived Notes"
                        />
                      </Box>
                    </Box>
                  )}
                </>
              )}
              {/* Referral Program Box */}
              {referralInfo ? (
                <Box
                  sx={{
                    bgcolor: "backgroundColors.secondary",
                    border: 1,
                    borderColor: "borderColors.primary",
                    borderRadius: "0.75rem",
                    padding: "1rem 1.5rem",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                  }}
                >
                  <Typography
                    variant="body1"
                    fontSize="1.0625rem"
                    fontStyle="normal"
                    fontWeight="700"
                    lineHeight="1.59375rem"
                    color="textColors.lightHeader"
                  >
                    Referral Program
                  </Typography>

                  <Box
                    sx={{
                      bgcolor: "backgroundColors.secondary",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      paddingY: "1rem",
                    }}
                  >
                    <Typography
                      variant="body1"
                      fontSize="0.9rem"
                      fontStyle="normal"
                      fontWeight="500"
                      color="textColors.lightHeader"
                    >
                      Send your referral code to colleagues and we’ll give them
                      5% off their first month, and give you 25% your next
                      month.&nbsp;
                      <Link
                        href={`https://${brandLanguage.brandSite}/rewardsandreferrals`}
                        target="_blank"
                        underline="hover"
                      >
                        {"Click here to learn more."}
                      </Link>
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "flex-start",
                    }}
                  >
                    {/* Box for Promo Code */}
                    <Box
                      sx={{
                        bgcolor: "backgroundColors.primary",
                        border: 1,
                        borderColor: "borderColors.primary",
                        borderRadius: "0.95rem",
                        display: "flex",
                        flex: "auto",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "50%",
                        marginRight: "1%",
                        padding: "0.5rem",
                      }}
                    >
                      <Typography
                        variant="body1"
                        fontStyle="normal"
                        fontWeight="600"
                        color="textColors.lightHeader"
                        align="center"
                        fontSize="1.75vh"
                      >
                        Your Referral Code
                      </Typography>

                      <Box
                        sx={{
                          bgcolor: "backgroundColors.secondary",
                          border: 1,
                          borderColor: "borderColors.primary",
                          width: "50%",
                          marginTop: "10px",
                        }}
                      >
                        <Typography
                          variant="body1"
                          fontStyle="bold"
                          fontWeight="700"
                          color="textColors.lightHeader"
                          align="center"
                          sx={{ padding: "0.5rem", cursor: "pointer" }}
                          onClick={() =>
                            handleCopyToClipboard(referralInfo.referral_code)
                          }
                        >
                          {referralInfo.referral_code}
                        </Typography>
                      </Box>
                      <Typography
                        variant="body1"
                        fontStyle="normal"
                        fontWeight="500"
                        color="textColors.lightHeader"
                        align="center"
                        sx={{ padding: "0.5rem", cursor: "pointer" }}
                        onClick={() =>
                          handleCopyToClipboard(referralInfo.referral_code)
                        }
                      >
                        Click to copy
                      </Typography>
                    </Box>

                    {/* Box for Referral Earnings */}
                    <Box
                      sx={{
                        bgcolor: "backgroundColors.primary",
                        border: 1,
                        borderColor: "borderColors.primary",
                        borderRadius: "0.95rem",
                        display: "flex",
                        flex: "auto",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "50%",
                        marginRight: "1%",
                        padding: "0.5rem",
                        height: "100%",
                      }}
                    >
                      <Typography
                        fontStyle="normal"
                        fontWeight="600"
                        color="textColors.lightHeader"
                        align="center"
                        fontSize="1.75vh"
                      >
                        Your Referral Earnings
                      </Typography>

                      <Box
                        sx={{
                          display: "flex",
                          flex: "0",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <Typography
                          variant="h3"
                          fontStyle="bold"
                          fontWeight="700"
                          color="textColors.lightHeader"
                        >
                          {referralInfo.referral_earnings}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              ) : (
                <Box
                  sx={{
                    width: "100%",
                    flex: "1",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                  }}
                >
                  {/* display empty box if no referral_info on user */}
                </Box>
              )}
            </Box>
          </BoxColumn>
        )}

        <BoxColumn>
          <Box
            id="review-box"
            sx={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              border: 1,
              borderColor: "borderColors.primary",
              bgcolor: "backgroundColors.primary",
              borderRadius: "0.75rem",
              margin: "1em",
            }}
          >
            <Box
              sx={{
                bgcolor: "backgroundColors.secondary",
                border: 1,
                borderColor: "borderColors.primary",
                borderTopLeftRadius: "0.75rem",
                borderTopRightRadius: "0.75rem",
                display: "flex",
                padding: "1rem 1.5rem",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                fontSize="1.0625rem"
                fontStyle="normal"
                fontWeight="700"
                lineHeight="1.59375rem"
                color="textColors.lightHeader"
              >
                Notes to Review
              </Typography>
              <Box
                sx={{
                  borderRadius: "100%",
                  backgroundColor: theme.palette.backgroundColors?.primary,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "2rem",
                  height: "2rem",
                }}
              >
                <Typography
                  fontSize="1.0625rem"
                  fontStyle="normal"
                  fontWeight="600"
                  lineHeight="1.59375rem"
                  color={theme.palette.primary.light}
                >
                  {noteCounts ? (
                    noteCounts.for_review_notes_count
                  ) : (
                    <Skeleton variant="circular" width={"80%"} height={"80%"} />
                  )}
                </Typography>
              </Box>
            </Box>
            <Box
              id="note-content"
              sx={{
                display: "flex",
                flexDirection: "column",
                bgcolor: "backgroundColors.primary",
                borderRadius: "0.75rem",
                overflowY: "scroll",
                height: "100%",
                maxHeight: "50vh",
              }}
            >
              {!notesForReview && (
                <Box height="100%">
                  {notesForReview === undefined && (
                    <>
                      <Box py={2}>
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                      </Box>
                      <Box py={2}>
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                      </Box>
                      <Box py={2}>
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                      </Box>
                    </>
                  )}
                  {notesForReview === null && (
                    <Box sx={{ height: "100%" }}>
                      <DashboardNote
                        index={0}
                        key={0}
                        status={"completed"}
                        review_status={"ready_for_review"}
                        note_id={"xyz"}
                        note_title={"Demo Intake Note"}
                        time={Date.now() / 1000}
                        summary={`Want to see what a ${brandLanguage.brandName} note looks like? Click here to view an example note of a Psychiatric Intake note.`}
                        link={`/demo/psychiatric-intake-demo`}
                        tabLink={true}
                      />
                      <hr />
                      <Box>
                        <Box
                          sx={{
                            p: 4,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Typography sx={{ mb: 2 }}>
                            You haven't recorded any notes yet. Either view our
                            sample note above, or click the button below to
                            record your own example note:
                          </Typography>
                          <Button
                            variant="contained"
                            onClick={() => {
                              navigate("/exampleRecord");
                            }}
                          >
                            Record an Example Note
                          </Button>
                        </Box>
                      </Box>
                      {/* <EmptyNoteList /> */}
                    </Box>
                  )}
                </Box>
              )}
              {notesForReview &&
                notesForReview.map((note: UserNoteTypeII, index: number) => {
                  return (
                    <DashboardNote
                      index={index}
                      key={note.note_id}
                      status={note.status}
                      review_status={note.review_status}
                      note_id={note.note_id}
                      note_title={note.note_title}
                      time={note.time_uploaded}
                      summary={note.summary}
                      link={
                        note.status === "completed"
                          ? `/note/${note.note_id}`
                          : `/status/${note.note_id}`
                      }
                      error_message={note.error_message}
                    />
                  );
                })}
              {notesForReview && (
                <Box
                  sx={{
                    display: "flex",
                    flex: "1 1 0%",
                    bgcolor: "backgroundColors.primary",
                    border: 0,
                    borderTop: 1,
                    borderBottomLeftRadius: "0.75rem",
                    borderBottomRightRadius: "0.75rem",
                    borderColor: "borderColors.primary",
                    height: "min-content",
                    maxHeight: "100%",
                    padding: "1.5rem",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "0.5rem",
                    "&:hover": {
                      cursor: "pointer",
                      bgcolor: "backgroundColors.secondary",
                    },
                  }}
                  onClick={() => {
                    navigate("/notes");
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Typography
                      fontSize="0.9375rem"
                      fontStyle="normal"
                      fontWeight="700"
                      lineHeight="1.40625rem"
                      color="textColors.lightHeader"
                    >
                      View all Notes
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </BoxColumn>
      </MultiColumnCenterBox>
      <TermsOfServiceModal
        isOpen={showToSModal}
        onClose={() => setShowToSModal(false)}
      />
      <NewFeatureModal
        isOpen={showNewFeatureModal}
        onClose={() => {
          setShowNewFeatureModal(false);
          setNewFeature(false);
        }}
      />
      {/* {showAddToHomeScreen && <AddToHomeScreen />} */}
    </>
  );
};

export default Dashboard;

const clarityInit = (id: string): void => {
  ((c: any, l: any, a: string, r: string, i: string) => {
    c[a] =
      c[a] ||
      function () {
        (c[a].q = c[a].q || []).push(arguments);
      };
    let t = l.createElement(r);
    t.async = true;
    t.src = "https://www.clarity.ms/tag/" + i;
    let y = l.getElementsByTagName(r)[0];
    y.parentNode.insertBefore(t, y);
  })(window, document, "clarity", "script", id);
};

// Extend the Window interface to include clarity
interface Window {
  clarity: (...args: any[]) => void;
}

declare const window: Window;

const clarityWrapper = (...params: any[]): void => {
  if (!window.clarity) {
    throw new Error("Clarity is not initialized");
  }

  window.clarity(...params);
};

const init = (id: string): void => {
  clarityInit(id);
};

const hasStarted = (): boolean => {
  return typeof window.clarity === "function";
};

const identify = (
  userId: string,
  properties: Record<string, unknown>
): void => {
  clarityWrapper("identify", userId, null, null, userId);
};

const consent = (): void => {
  clarityWrapper("consent");
};

const setTag = (key: string, value: string): void => {
  clarityWrapper("set", key, value);
};

const event = (key: string): void => {
  clarityWrapper("event", key);
};

const upgrade = (reason: string): void => {
  clarityWrapper("upgrade", reason);
};

export const clarity = {
  init,
  hasStarted,
  identify,
  consent,
  setTag,
  event,
  upgrade,
};

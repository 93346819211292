import { Params, json } from "react-router-dom";
import {
  AdminBaseTemplate,
  AdminPublicTemplate,
  AdminTemplate,
  AdminUser,
  AdminUserTemplate,
  UserTemplateItem,
} from "../types/types";
import { API_BASE_URL } from "../services/APIService";

export const TemplatesLoader = async (): Promise<AdminTemplate[]> => {
  const accessToken = localStorage.getItem("accessToken");
  const templatesList = await fetch(
    `${API_BASE_URL}/template/getAllTemplates`,
    {
      method: "get",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      }),
    }
  )
    .then((res) => {
      if (res.status === 401) {
        throw json(
          {
            message: "You are not authorized to access this resource.",
            cta: "Login",
          },
          { status: 401 }
        );
      }

      return res.json();
    })
    .then((data: { templates: AdminTemplate[] }) => {
      return data.templates;
    })
    .catch(() => {
      throw json(
        {
          message: "There has been an error. Please login again.",
          cta: "Login",
        },
        { status: 500 }
      );
    });

  return templatesList;
};

export const BaseTemplatesLoader = async (): Promise<AdminBaseTemplate[]> => {
  const accessToken = localStorage.getItem("accessToken");
  const baseTemplatesList = await fetch(
    `${API_BASE_URL}/template/getAllBaseTemplates`,
    {
      method: "get",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      }),
    }
  )
    .then((res) => {
      if (res.status === 401) {
        throw json(
          {
            message: "You are not authorized to access this resource.",
            cta: "Login",
          },
          { status: 401 }
        );
      }

      return res.json();
    })
    .then((data: { base_templates: AdminBaseTemplate[] }) => {
      return data.base_templates;
    })
    .catch(() => {
      throw json(
        {
          message: "There has been an error. Please login again.",
          cta: "Login",
        },
        { status: 500 }
      );
    });

  return baseTemplatesList;
};

export const PublicTemplatesLoader = async (): Promise<
  AdminPublicTemplate[]
> => {
  const accessToken = localStorage.getItem("accessToken");
  const templatesList = await fetch(
    `${API_BASE_URL}/template/getAllPublicTemplates`,
    {
      method: "get",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      }),
    }
  )
    .then((res) => {
      if (res.status === 401) {
        throw json(
          {
            message: "You are not authorized to access this resource.",
            cta: "Login",
          },
          { status: 401 }
        );
      }

      return res.json();
    })
    .then((data: { templates: AdminPublicTemplate[] }) => {
      return data.templates;
    })
    .catch(() => {
      throw json(
        {
          message: "There has been an error. Please login again.",
          cta: "Login",
        },
        { status: 500 }
      );
    });

  return templatesList;
};

export const UserTemplatesLoader = async (): Promise<AdminUserTemplate[]> => {
  const accessToken = localStorage.getItem("accessToken");
  const templatesList = await fetch(
    `${API_BASE_URL}/template/getAllUserTemplates`,
    {
      method: "get",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      }),
    }
  )
    .then((res) => {
      if (res.status === 401) {
        throw json(
          {
            message: "You are not authorized to access this resource.",
            cta: "Login",
          },
          { status: 401 }
        );
      }

      return res.json();
    })
    .then((data: { templates: AdminUserTemplate[] }) => {
      return data.templates;
    })
    .catch(() => {
      throw json(
        {
          message: "There has been an error. Please login again.",
          cta: "Login",
        },
        { status: 500 }
      );
    });

  return templatesList;
};

export const TemplateLoader = async ({
  params,
}: {
  params: Params;
}): Promise<AdminTemplate> => {
  const accessToken = localStorage.getItem("accessToken");
  const template = await fetch(
    `${API_BASE_URL}/template/getTemplate?template_id=${params.templateId}`,
    {
      method: "get",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      }),
    }
  )
    .then((res) => {
      if (res.status === 401) {
        throw json(
          {
            message: "You are not authorized to access this resource.",
            cta: "Login",
          },
          { status: 401 }
        );
      }

      return res.json();
    })
    .then((data: { template: AdminTemplate }) => {
      return data.template;
    })
    .catch(() => {
      throw json(
        {
          message: "There has been an error. Please login again.",
          cta: "Login",
        },
        { status: 500 }
      );
    });

  return template;
};

export const BaseTemplateLoader = async ({
  params,
}: {
  params: Params;
}): Promise<AdminBaseTemplate> => {
  const accessToken = localStorage.getItem("accessToken");
  const template = await fetch(
    `${API_BASE_URL}/template/getBaseTemplate?base_template_id=${params.baseTemplateId}`,
    {
      method: "get",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      }),
    }
  )
    .then((res) => {
      if (res.status === 401) {
        throw json(
          {
            message: "You are not authorized to access this resource.",
            cta: "Login",
          },
          { status: 401 }
        );
      }

      return res.json();
    })
    .then((data: { base_template: AdminBaseTemplate }) => {
      return data.base_template;
    })
    .catch(() => {
      throw json(
        {
          message: "There has been an error. Please login again.",
          cta: "Login",
        },
        { status: 500 }
      );
    });

  return template;
};

export const PublicTemplateLoader = async ({
  params,
}: {
  params: Params;
}): Promise<AdminPublicTemplate> => {
  const accessToken = localStorage.getItem("accessToken");
  const template = await fetch(
    `${API_BASE_URL}/template/getPublicTemplate?template_id=${params.templateId}`,
    {
      method: "get",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      }),
    }
  )
    .then((res) => {
      if (res.status === 401) {
        throw json(
          {
            message: "You are not authorized to access this resource.",
            cta: "Login",
          },
          { status: 401 }
        );
      }

      return res.json();
    })
    .then((data: { template: AdminPublicTemplate }) => {
      return data.template;
    })
    .catch(() => {
      throw json(
        {
          message: "There has been an error. Please login again.",
          cta: "Login",
        },
        { status: 500 }
      );
    });

  return template;
};

export const UserTemplateLoader = async ({
  params,
}: {
  params: Params;
}): Promise<AdminUserTemplate> => {
  const accessToken = localStorage.getItem("accessToken");
  const template = await fetch(
    `${API_BASE_URL}/template/getUserTemplate?template_id=${params.templateId}`,
    {
      method: "get",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      }),
    }
  )
    .then((res) => {
      if (res.status === 401) {
        throw json(
          {
            message: "You are not authorized to access this resource.",
            cta: "Login",
          },
          { status: 401 }
        );
      }

      return res.json();
    })
    .then((data: { template: AdminUserTemplate }) => {
      return data.template;
    })
    .catch(() => {
      throw json(
        {
          message: "There has been an error. Please login again.",
          cta: "Login",
        },
        { status: 500 }
      );
    });

  return template;
};

export const TemplatesOnUserLoader = async ({
  params,
}: {
  params: Params;
}): Promise<UserTemplateItem[]> => {
  const accessToken = localStorage.getItem("accessToken");
  const templates = await fetch(
    `${API_BASE_URL}/user/getTemplatesForUser?target_user_id=${params.user_id}`,
    {
      method: "get",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      }),
    }
  )
    .then((res) => {
      if (res.status === 401) {
        throw json(
          {
            message: "You are not authorized to access this resource.",
            cta: "Login",
          },
          { status: 401 }
        );
      }

      return res.json();
    })
    .then((data: { templates: UserTemplateItem[] }) => {
      return data.templates;
    })
    .catch(() => {
      throw json(
        {
          message: "There has been an error. Please login again.",
          cta: "Login",
        },
        { status: 500 }
      );
    });

  return templates;
};

import { useContext, useEffect, useState } from "react";
import { useLoaderData } from "react-router-dom";
import { DebugContext } from "../../main";
import { AdminFeedbackEvent, AdminNote, AdminTemplate } from "../../types/types";
import NoteFeedbackDisplayBox from "../../components/Admin/NoteFeedbackDisplayBox";
import SectionFeedbackDisplayBox from "../../components/Admin/SectionFeedbackDisplayBox";
import { AdminFeedbackSessionLoader } from "../../loaders/AdminFeedbackSessionLoader";
import { json } from "react-router-dom";
import { NoteLoader } from "../../loaders/NoteLoader"

function AdminFeedbackSessionView() {
  const debug = useContext(DebugContext);
  const feedbackSession = useLoaderData() as Awaited<
    ReturnType<typeof AdminFeedbackSessionLoader>
  >;
  const [noteFeedback, setNoteFeedback] = useState<AdminFeedbackEvent[]>();
  const [sectionFeedbackMap, setSectionFeedbackMap] = useState<{
    [sectionId: string]: AdminFeedbackEvent[];
  }>();
  const [noteData, setNoteData] = useState<AdminNote>();

  useEffect(() => {

    const fetchNoteData = async () => {
      if (feedbackSession) {
        const note = NoteLoader(feedbackSession.note_id)
        setNoteData(await note)
      }
    };

    fetchNoteData();

  }, [feedbackSession]);

  // This function separates the overall note feedback and section feedback
  const processFeedbackEvents = (feedbackEvents: AdminFeedbackEvent[]) => {
    const noteFeedback: AdminFeedbackEvent[] = [];
    const sectionFeedbackMap: { [sectionId: string]: AdminFeedbackEvent[] } =
      {};

    feedbackEvents.forEach((event) => {
      if (event.feedback_type === "note") {
        noteFeedback.push(event);
      } else if (event.feedback_type === "section" && event.section_id) {
        // If section feedback exists for the given section_id, append it, else create a new array
        sectionFeedbackMap[event.section_id] =
          sectionFeedbackMap[event.section_id] || [];
        sectionFeedbackMap[event.section_id].push(event);
      }
    });

    setNoteFeedback(noteFeedback);
    setSectionFeedbackMap(sectionFeedbackMap);
  };

  useEffect(() => {
    if (feedbackSession) {
      processFeedbackEvents(feedbackSession.feedback_events);
    }
  }, [feedbackSession]);

  return (
    <div>
      <div>user_id: {feedbackSession?.user_id}</div>
      <div>note_id: {feedbackSession?.note_id}</div>
      <div>template_name: {noteData?.template?.template_name}</div>
      {noteFeedback && <NoteFeedbackDisplayBox feedbackEvents={noteFeedback} />}
      {sectionFeedbackMap &&
        Object.keys(sectionFeedbackMap).map((sectionId) => (
          <div className="ml-32">
            <SectionFeedbackDisplayBox
              feedbackEvents={sectionFeedbackMap[sectionId]}
              showContent={true}
            />
          </div>
        ))}
    </div>
  );
}

export default AdminFeedbackSessionView;

export type UserInfoAPIType = {
  name?: string;
  email?: string;
  institution?: string;
  notes?: any;
};

export type UserInfoLocalStorageType = {
  name?: string;
  email?: string;
  institution?: string;
  img?: string;
  featureSet?: string;
  featureFlags?: any;
  payment_status?: "overdue" | "freetrial_expired";
  user_metrics?: any;
  gamification?: any;
  referral_info?: any;
  user_id?: string;
  email_verified?: any;
  group_ids?: string[];
  group_user_roles?: string[];
};

export const getUserInfoFromLocalStorage = ():
  | UserInfoLocalStorageType
  | undefined => {
  const userInfo = localStorage.getItem("userInfo");
  if (userInfo) {
    return JSON.parse(userInfo) as UserInfoLocalStorageType;
  } else {
    return undefined;
  }
};

export const saveUserInfoToLocalStorage = (
  userInfo: UserInfoLocalStorageType,
  user: any
) => {
  localStorage.setItem(
    "userInfo",
    JSON.stringify({ ...userInfo, img: user?.picture || "" })
  );
};

export const updateUserInfoToLocalStorage = (
  userInfo: UserInfoLocalStorageType
) => {
  const current = getUserInfoFromLocalStorage();
  const newUserInfo = {
    ...current,
    email: userInfo?.email,
    name: userInfo?.name,
    institution: userInfo?.institution,
  };
  localStorage.setItem("userInfo", JSON.stringify(newUserInfo));
};

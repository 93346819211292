import React from "react";

const Logo = ({ size, className }: { size?: number; className?: string }) => {
  const style = { height: size || "100%", width: "auto" };
  return (
    <img
      src={`/logo-outline-128.png`}
      alt="Logo"
      style={style}
      className={className}
    />
  );
};

export const JotPsychWordmarkLogo = ({
  size,
  className,
}: {
  size?: number;
  className?: string;
}) => {
  const style = { width: size || "100%", height: "auto" };
  return (
    <img
      src={`/jotpsych-wordmark-256.png`}
      alt="JotPsych"
      style={style}
      className={className}
    />
  );
};

export const JotPalWordmarkLogo = ({
  size,
  className,
}: {
  size?: number;
  className?: string;
}) => {
  const style = { width: size || "100%", height: "auto" };
  return (
    <img
      src={`/jotpal-wordmark-256.png`}
      alt="JotPal"
      style={style}
      className={className}
    />
  );
};

export default Logo;

import { Params, json } from "react-router-dom";
import { AdminNote } from "../types/types";
import { API_BASE_URL } from "../services/APIService";

export const NoteLoader = async (
  note_id: string
): Promise<AdminNote | undefined> => {
  const accessToken = localStorage.getItem("accessToken");
  const response = await fetch(`${API_BASE_URL}/notes/${note_id}`, {
    method: "get",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    }),
  })
    .then((res) => {
      if (res.status === 401) {
        throw json(
          {
            message: "You are not authorized to access this resource.",
            cta: "Login",
          },
          { status: 401 }
        );
      }
      return res.json();
    })
    .then((note: AdminNote) => {
      return note;
    })
    .catch(() => {
      throw json(
        {
          message: "There has been an error. Please login again.",
          cta: "Login",
        },
        { status: 500 }
      );
    });
  return response;
};

import React, { useEffect, useRef, useState } from "react";
import { Box, LinearProgress, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { formatDate, truncateText } from "../../utils/utils";
import { useUIState } from "../../context/uiState";
import { UIStateType } from "../../context/uiState/context";
import FullScreenLoader from "../FullScreenLoader";
import { getUserInfoFromLocalStorage } from "../../utils/LocalStorageUtils";
import { getErrorMessage } from "../../utils/errorUtils";

export type DashboardNoteType = {
  note_id: string;
  index: number;
  status?: string;
  review_status?: string;
  note_title: string;
  time: number;
  link: string;
  tabLink?: boolean;
  summary: string;
  error_message?: string;
};

const DashboardNote = ({
  note_id,
  index,
  status,
  review_status,
  note_title,
  time,
  summary,
  link,
  tabLink = false,
  error_message = "",
}: DashboardNoteType) => {
  const { state, setState } = useUIState();
  const currentPage = state.navigation.currentPage;
  const navigate = useNavigate();
  const truncateRef = useRef<HTMLElement>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const handleClick = () => {
    if (tabLink) {
      window.open(link, "_blank");
    } else {
      if (status !== "errored") {
        setLoading(true);
        setState((prevState: UIStateType) => ({
          ...prevState,
          navigation: { currentPage: "note" },
        }));
        navigate(link);
      }
    }
  };

  let haveTop = 1;
  if (index === 0) {
    haveTop = 0;
  }

  useEffect(() => {
    if (truncateRef.current) {
      truncateText(truncateRef.current, 2);
    }
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flex: "1 1 0%",
        bgcolor: "backgroundColors.primary",
        border: 0,
        borderTop: haveTop,
        borderBottomLeftRadius: "0.75rem",
        borderBottomRightRadius: "0.75rem",
        borderColor: "borderColors.primary",
        height: "min-content",
        maxHeight: "100%",
        padding: "1.5rem",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "0.5rem",
        "&:hover": {
          cursor: "pointer",
          bgcolor: "backgroundColors.secondary",
        },
      }}
      onClick={handleClick}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Typography
          fontSize="0.9375rem"
          fontStyle="normal"
          fontWeight="700"
          lineHeight="1.40625rem"
          color="textColors.lightHeader"
        >
          {note_title}
        </Typography>
        <Box>
          <Typography
            fontSize="0.75rem"
            fontStyle="normal"
            fontWeight="600"
            lineHeight="1.125rem"
            letterSpacing="0.125rem"
            textTransform="uppercase"
            color="textColors.accent"
          >
            {formatDate(time)}
          </Typography>
          {status === "completed" && review_status === "archived" && (
            <Box>
              <LinearProgress variant="determinate" value={100} color="info" />
            </Box>
          )}
          {status === "completed" && review_status === "ready_for_review" && (
            <Box>
              <LinearProgress
                variant="determinate"
                value={100}
                color="success"
              />
            </Box>
          )}
          {status === "processing" && (
            <Box>
              <LinearProgress value={100} color={"warning"} />
            </Box>
          )}
          {status === "errored" && (
            <Box>
              <LinearProgress
                value={100}
                variant="determinate"
                color={"error"}
              />
            </Box>
          )}
        </Box>
      </Box>
      <Typography
        ref={truncateRef}
        fontSize="0.9375rem"
        fontStyle="normal"
        fontWeight="400"
        lineHeight="1.40625rem"
        color="textColors.primaryText"
      >
        {status === "errored" && (
          <div className="text-gray-400">
            There was an error generating this note.
          </div>
        )}
        {summary}
      </Typography>
      {loading && <FullScreenLoader />}
      {status === "errored" && error_message && getErrorMessage(error_message, note_id)}
    </Box>
  );
};

export default DashboardNote;

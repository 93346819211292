import React, { createContext, useContext, useState, ReactNode } from "react";

interface AudioDataContextType {
  audioData: number;
  setAudioData: React.Dispatch<React.SetStateAction<number>>;
}

const AudioDataContext = createContext<AudioDataContextType | undefined>(
  undefined
);

export const useAudioDataContext = (): AudioDataContextType => {
  const context = useContext(AudioDataContext);
  if (!context) {
    throw new Error(
      "useAudioDataContext must be used within an AudioDataProvider"
    );
  }
  return context;
};

interface AudioDataProviderProps {
  children: ReactNode;
}

export const AudioDataProvider: React.FC<AudioDataProviderProps> = ({
  children,
}) => {
  const [audioData, setAudioData] = useState<number>(0);

  return (
    <AudioDataContext.Provider value={{ audioData, setAudioData }}>
      {children}
    </AudioDataContext.Provider>
  );
};

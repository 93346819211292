import React, { useState, useEffect } from 'react';
import { TextField, IconButton, Chip, Box, InputAdornment, Button, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import LinkIcon from '@mui/icons-material/Link';
import CloseIcon from '@mui/icons-material/Close';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { API_BASE_URL } from '../../services/APIService';
import { useUser } from "../../context/user";
import APIService from "../../services/APIService";

interface TemplateSharingProps {
  setToastOpen: (message: boolean) => void; 
  tempTitle: string;
  copyLink: string;
  templateId: string;
  setToastMessage: (message: string) => void; 
  setShowShareModal: (close :boolean) => void;
}

const TemplateSharing: React.FC<TemplateSharingProps> = ({ setToastOpen, tempTitle, copyLink, templateId, setToastMessage, setShowShareModal}) => {
  const [emailInput, setEmailInput] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const [emailList, setEmailList] = useState<string[]>([]);
  const { userState, getAccessToken } = useUser();
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmailInput(e.target.value);
    setEmailError('');
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (emailInput && !emailRegex.test(emailInput)) {
        setEmailError('Invalid email format');
      } else {
        setEmailError('');
      }
    }, 5000);

    return () => clearTimeout(timer);
  }, [emailInput]);

  const handleAddEmail = () => {
    if (emailRegex.test(emailInput)) {
      if (emailList.includes(emailInput)) {
        setEmailError('Email already added');
      } else {
        setEmailList([...emailList, emailInput]);
        setEmailInput('');
      }
    } else {
      setEmailError('Please enter a valid email address');
    }
  };

  const handleDeleteEmail = (emailToDelete: string) => {
    setEmailList(emailList.filter(email => email !== emailToDelete));
  };

  const handleShare = async () => {
    try {
        if (emailList.length<1) {
            setEmailError('Please add the email');
            return;
          } else {
            setEmailError('');
          }
        const accessToken = await getAccessToken();
        
        const shareTemplateResponse = await APIService.makeAPIPostRequest({
            requestString: "/user/shareTemplate",
            accessToken: accessToken,
            body: {
                emailList: emailList,
                templateId: templateId,
            },
          });
    
        if (shareTemplateResponse.ok) {
            const data = await shareTemplateResponse.value;
            setToastMessage(data.message);
            setToastOpen(true);
            setEmailList([]);
        } else {
            setToastMessage('Failed to share template, error:'+ shareTemplateResponse.error);
            setToastOpen(true);
        }
    } catch (error) {
        setToastMessage('Failed to share template, error: '+ error);
        setToastOpen(true);
    }
  };
  
  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '0.5px solid #000',
    boxShadow: 50,
    p: 4,
  };
  const handleCopy = () => {
    setToastMessage("Link Copied");
    setToastOpen(true);
  }
  return (
    <Box sx={style}>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        Share "{tempTitle}"
      </Typography>
      <IconButton
      onClick={() => setShowShareModal(false)}
      sx={{ position: 'absolute', top: 8, right: 8 }}
    >
      <CloseIcon />
    </IconButton>
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        <Box sx={{ width: "100%", margin: '0 auto', padding: '10px', position: 'relative' }}>
          <TextField
            label="Enter email"
            variant="outlined"
            fullWidth
            value={emailInput}
            onChange={handleEmailChange}
            error={Boolean(emailError)}
            helperText={emailError || ' '}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton 
                    onClick={handleAddEmail} 
                    disabled={!emailInput || Boolean(emailError)}
                  >
                    <AddIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <Box sx={{ marginTop: 2 }}>
            {emailList.map((email, index) => (
              <Chip 
                key={index} 
                label={email} 
                onDelete={() => handleDeleteEmail(email)} 
                sx={{ marginBottom: 1, marginRight: 1 }} 
              />
            ))}
          </Box>

          <Box sx={{ marginTop: 3, display: 'flex', justifyContent: 'flex-start' }}>
            <CopyToClipboard text={copyLink} onCopy={handleCopy}>
              <Button
                variant="outlined"
                startIcon={<LinkIcon />}
              >
                Copy Link
              </Button>
            </CopyToClipboard>
          </Box>

          <Button
            variant="contained"
            color="primary"
            onClick={handleShare}
            sx={{
              position: 'absolute',
              bottom: 10,
              right: 10,
            }}
          >
            SHARE
          </Button>
        </Box>
      </Typography>
    </Box>
    
  );
};

export default TemplateSharing;

import React, { useEffect, useState } from "react";
import {
  AdminModule,
  AdminSection,
  AdminSystemPrompt,
  AdminTemplate,
  AdminUser,
} from "../../types/types";
import { AdminNotesLoader } from "../../loaders/AdminNotesLoader";
import { NoteSection } from "../NoteSection";
import { TemplatesLoader } from "../../loaders/TemplatesLoader";
import { formatDate } from "../../utils/utils";
import { UsersLoader } from "../../loaders/UsersLoader";
import APIService from "../../services/APIService";
import { useUser } from "../../context/user";

interface GenerationTestSectionProps {
  module: AdminModule;
  system_prompt?: string;
}

export const GenerationTestSection: React.FC<GenerationTestSectionProps> = ({
  module,
  system_prompt,
}) => {
  const { getAccessToken } = useUser();
  const [systemPrompt, setSystemPrompt] = useState<string>(system_prompt || "");
  const [generationLoading, setGenerationLoading] = useState(false);

  const [multipleGenerationLoading, setMultipleGenerationLoading] =
    useState(false);
  const [generationMultiple, setGenerationMultiple] = useState(1);
  const [results, setResults] = useState<any>();
  const [templateSystemPrompts, setTemplateSystemPrompts] =
    useState<AdminTemplate[]>();
  const [selectedTemplateId, setSelectedTemplateId] = useState<string>();
  const [selectedNote, setSelectedNote] = useState<string>();
  const [notes, setNotes] = useState<any>();
  const [noteSearchString, setNoteSearchString] = useState<string>("");
  const [filteredNotes, setFilteredNotes] = useState<any>([]);
  const [usersList, setUsersList] = useState<AdminUser[]>();
  const [selectedUserId, setSelectedUserId] = useState<string>();
  const [multipleResults, setMultipleResults] = useState<any>();
  const [awaitingGeneration, setAwaitingGeneration] = useState<boolean>(false);
  const [testGenerationSessionId, setTestGenerationSessionId] =
    useState<string>();

  const fetchSystemPrompts = async () => {
    const response = await TemplatesLoader();
    if (response) {
      setTemplateSystemPrompts(response);
      setSystemPrompt(response[0].system_prompt); // set first one
    }
  };

  const fetchNotes = async () => {
    const response = await AdminNotesLoader(0, 20);
    if (response) {
      setNotes(response);
    }
  };

  const fetchUsers = async () => {
    const response = await UsersLoader();
    if (response) {
      setUsersList(response);
    }
  };

  const handleUserSelection = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const userId = e.target.value;
    if (userId) {
      setSelectedUserId(userId);
    }
  };

  // Function that will perform the polling
  const fetchGenerationStatus = async () => {
    const accessToken = await getAccessToken();
    const response = await APIService.makeAPIGetRequest({
      requestString: `/testsuite/genstatus?testGenerationSessionId=${testGenerationSessionId}`,
      accessToken: accessToken,
    });

    if (response.ok) {
      const data = response.value;
      console.log(data);
      if (data.tasks_list) {
        setMultipleResults(data.tasks_list);
      }
      if (data.status === "completed") {
        setAwaitingGeneration(false);
      }
    }
  };

  const handleGenerate = async () => {
    setGenerationLoading(true);
    const accessToken = await getAccessToken();
    const response = await APIService.makeAPIPostRequest({
      requestString: "/testsuite/generateModuleSection",
      accessToken: accessToken,
      body: {
        note_id_for_transcript: selectedNote,
        module: module,
        system_prompt: systemPrompt,
        multiple: generationMultiple,
        target_user_id: selectedUserId,
      },
    });

    if (!response.ok) {
      console.error("there was a problem with the network request");
    } else {
      const data = response.value;
      setAwaitingGeneration(true);
      setTestGenerationSessionId(data.test_generation_session_id);
      setMultipleResults(data.tasks_list);
      console.log(data);
    }

    setGenerationLoading(false);
  };

  const handleNoteSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value.toLowerCase();
    setNoteSearchString(searchValue);
    const filtered = notes.filter((note: any) =>
      note.internal_title?.toLowerCase().includes(searchValue)
    );
    setFilteredNotes(filtered);
  };

  const handleSystemPromptSelect = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedTemplateId(event.target.value);
    const templateObj = templateSystemPrompts?.find(
      (obj) => obj.template_id === event.target.value
    );
    if (templateObj) {
      setSystemPrompt(templateObj.system_prompt);
    }
  };

  const handleNoteSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedNote(event.target.value);
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSystemPrompt(event.target.value);
  };

  useEffect(() => {
    if (!systemPrompt) {
      fetchSystemPrompts();
    }
    fetchNotes();
    fetchUsers();
  }, []);

  // Initialize filteredNotes with all notes
  useEffect(() => {
    setFilteredNotes(notes);
  }, [notes]);

  useEffect(() => {
    let intervalId: number;

    // Start polling only if awaitingGeneration is true
    if (awaitingGeneration) {
      intervalId = setInterval(() => {
        fetchGenerationStatus();
      }, 5000) as unknown as number; // Polls every 5 seconds
    }

    // Clear interval when component unmounts or when awaitingGeneration is false
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [awaitingGeneration, testGenerationSessionId]); // Dependency array

  return (
    <div className="my-4 bg-white p-2 rounded-md shadow">
      <h3>Generation Test</h3>
      <div className="mb-2">
        <div className="flex justify-between">
          <label
            className="block text-sm text-gray-500"
            htmlFor="system_prompt"
          >
            System Prompt
          </label>
          {!system_prompt && (
            <select
              value={selectedTemplateId}
              onChange={handleSystemPromptSelect}
            >
              {templateSystemPrompts?.map((template, index) => (
                <option key={template.template_id} value={template.template_id}>
                  {template.template_name}
                </option>
              ))}
            </select>
          )}
        </div>
        <textarea
          name="system_prompt"
          id="system_prompt"
          value={systemPrompt}
          onChange={handleInputChange}
          className="block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        />
      </div>
      <div className="flex justify-between">
        <div>
          <label className="block text-sm text-gray-500" htmlFor="note_select">
            Note Transcript
          </label>
          <input
            onChange={handleNoteSearchChange}
            value={noteSearchString}
            className="mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
          <select
            name="note_select"
            id="note_select"
            value={selectedNote}
            onChange={handleNoteSelect}
            className="block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          >
            {filteredNotes?.map((note: any, index: number) => (
              <option key={index} value={note.note_id}>
                {note.internal_title} - {formatDate(note.time_uploaded)}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-4">
          <select
            value={selectedUserId}
            onChange={handleUserSelection}
            className="max-w-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          >
            <option value="">Select a user to test</option>
            {usersList?.map((user, index) => (
              <option key={index} value={user._id}>
                {user.user_info?.name ? user.user_info?.name : user._id}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label
            className="block text-sm text-gray-500"
            htmlFor="multiple_generation"
          >
            Number of Generations
          </label>
          <input
            type="number"
            id="multiple_generation"
            min="1"
            max="20"
            value={generationMultiple}
            onChange={(e) => setGenerationMultiple(parseInt(e.target.value))}
            className="block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
          <button
            onClick={handleGenerate}
            className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
          >
            {generationLoading ? "Loading..." : "Generate"}
          </button>
        </div>
        {/* <div>
          <button
            onClick={handleGenerateMultiple}
            className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
          >
            {multipleGenerationLoading ? "Loading..." : "Generate Multiple"}
          </button>
        </div> */}
      </div>
      <div className="">
        {multipleResults &&
          multipleResults.map((task: any, index: number) => (
            <NoteSection
              key={task.task_id}
              text={task.result ? task.result : "Loading..."}
              isEdited={false}
              isRated={false}
              heading={task.task_id}
              isAdded={false}
              handleAdd={() => {}}
              forceDebug={true}
              handleSaveEdits={() => {}}
              handleRevertEdits={() => {}}
              isSaving={false}
            />
          ))}
      </div>
    </div>
  );
};

import { useSelectedUser } from "../../context/selectedUserContext.tsx";
import { AdminUser } from "../../types/types";

interface UserRowProps {
  user: any;
}

export const UserRow = ({ user }: UserRowProps) => {
  const { selectUser, selectedUser } = useSelectedUser();

  const isSelected = selectedUser && user._id === selectedUser;

  return (
    <tr
      className={`hover:bg-blue-100 cursor-pointer ${
        isSelected ? "bg-blue-300" : ""
      }`}
      onClick={() => selectUser(user._id)}
    >
      <td className="px-4 py-2 border-2 border-gray-400">{user._id}</td>
      <td className="px-4 py-2 border-2 border-gray-400">
        {user.user_info?.name}
      </td>
      <td className="px-4 py-2 border-2 border-gray-400">{user.logins}</td>
      <td className="px-4 py-2 border-2 border-gray-400">
        {user.user_metrics?.notes_created}
      </td>
      <td className="px-4 py-2 border-2 border-gray-400">
        {user.templates?.length}
      </td>
      <td className="px-4 py-2 border-2 border-gray-400">
        {user.feature_flags?.next === true ? "true" : ""}
      </td>
      <td className="px-4 py-2 border-2 border-gray-400">
        {JSON.stringify(user.feature_flags)}
      </td>
    </tr>
  );
};

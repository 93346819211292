import {
  AdminBaseTemplate,
  AdminPublicTemplate,
  AdminTemplate,
} from "../../types/types";
import React, { useEffect, useState } from "react";
import {
  BaseTemplatesLoader,
  PublicTemplatesLoader,
  TemplatesLoader,
} from "../../loaders/TemplatesLoader";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../utils/utils";
import { useUser } from "../../context/user";
import APIService from "../../services/APIService";

interface TemplatesOverviewProps {}

export const PublicTemplatesOverview: React.FC<TemplatesOverviewProps> = () => {
  const { getAccessToken } = useUser();
  const [isLoading, setIsLoading] = useState(true);
  const [templates, setTemplates] = useState<AdminPublicTemplate[] | null>();
  const navigate = useNavigate();

  const fetchTemplatesData = async () => {
    setIsLoading(true);
    const response = await PublicTemplatesLoader();
    console.log(response);
    if (response) {
      setTemplates(response);
      setIsLoading(false);
    }
  };

  const handleDuplicateTemplate = async (template_id: string) => {
    const accessToken = await getAccessToken();

    const response = await APIService.makeAPIPostRequest({
      requestString: "/template/duplicatePublicTemplate",
      accessToken: accessToken,
      body: {
        template_id,
      },
    });

    if (!response.ok) {
      console.error("Problem duplicating public template");
    } else {
      onUpdateSuccess();
    }
  };

  const handleDeleteTemplate = async (template_id: string) => {
    const accessToken = await getAccessToken();

    const response = await APIService.makeAPIPostRequest({
      requestString: "/template/deletePublicTemplate",
      accessToken: accessToken,
      body: {
        template_id,
      },
    });

    if (!response.ok) {
      console.error("Problem deleting public template");
    } else {
      onUpdateSuccess();
    }
  };

  const onUpdateSuccess = async () => {
    fetchTemplatesData();
  };

  useEffect(() => {
    fetchTemplatesData();
  }, []);

  if (isLoading || !templates) return <div>Loading...</div>;

  return (
    <div className="flex">
      <div className="h-screen">
        <table className="table-auto w-full border-collapse border-2 border-gray-500">
          <thead className="sticky top-0">
            <tr className="bg-blue-200">
              <th className="px-4 py-2 border-2 border-gray-400">id</th>
              <th className="px-4 py-2 border-2 border-gray-400">Name</th>
              <th className="px-4 py-2 border-2 border-gray-400"># Sections</th>
              <th className="px-4 py-2 border-2 border-gray-400">Created On</th>
              <th className="px-4 py-2 border-2 border-gray-400">
                Last Modified
              </th>
              <th className="px-4 py-2 border-2 border-gray-400">Edit</th>
              <th className="px-4 py-2 border-2 border-gray-400">Duplicate</th>
              <th className="px-4 py-2 border-2 border-gray-400">Delete</th>
            </tr>
          </thead>
          <tbody>
            {templates.map((template, index) => (
              <tr className={`hover:bg-blue-100 cursor-pointer`} key={index}>
                <td className="px-4 py-2 border-2 border-gray-400">
                  {template.template_id}
                </td>
                <td className="px-4 py-2 border-2 border-gray-400">
                  {template.template_name}
                </td>

                <td className="px-4 py-2 border-2 border-gray-400">
                  {template.sections.length}
                </td>
                <td className="px-4 py-2 border-2 border-gray-400">
                  {formatDate(template.created_on)}
                </td>
                <td className="px-4 py-2 border-2 border-gray-400">
                  {formatDate(template.modified_on)}
                </td>
                <td className="px-4 py-2 border-2 border-gray-400">
                  <button
                    className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
                    onClick={() => {
                      navigate(`/admin/publictemplate/${template.template_id}`);
                    }}
                  >
                    Edit Template
                  </button>
                </td>
                <td className="px-4 py-2 border-2 border-gray-400">
                  <button
                    className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-700"
                    onClick={() =>
                      handleDuplicateTemplate(template.template_id)
                    }
                  >
                    Duplicate
                  </button>
                </td>
                <td className="px-4 py-2 border-2 border-gray-400">
                  <button
                    className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-700"
                    onClick={() => handleDeleteTemplate(template.template_id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {/* <div className="flex justify-center mt-4">
          <button
            onClick={createNewPublicTemplate}
            className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
          >
            Create New Public Template
          </button>
        </div> */}
      </div>
      {/* <div className="w-1/2 fixed right-0 border-2 border-gray-400">
        <UserInfo usersList={users!} />
        <UserTemplates
          usersList={users!}
          removeTemplateFromUser={handleRemoveTemplateFromUser}
          addTemplateToUser={() => {
            setTemplatesModalVisible(true);
          }}
        />
        <UserGroups
          usersList={users!}
          addUserToGroup={() => {
            setGroupsModalVisible(true);
          }}
          removeUserFromGroup={handleRemoveUserFromGroup}
        />
      </div> */}
    </div>
  );
};

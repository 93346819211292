import React, { useState } from 'react';
import { GroupMemberInfo } from "../../types/types";

interface UserGroupDetailsRowProps {
  member: GroupMemberInfo;
  isSelected: boolean;
  onUserSelect: (userId: string) => void;
  onChangeUserRole: (userId: string, newRole: string) => void;
}

export const UserGroupDetailsRow: React.FC<UserGroupDetailsRowProps> = ({ member, isSelected, onUserSelect, onChangeUserRole }) => {
  const [role, setRole] = useState(member.role);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onUserSelect(member.user_id);
  };

  const handleRoleChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newRole = event.target.value;
    setRole(newRole);
    onChangeUserRole(member.user_id, newRole);
  };

  const getDisplayNameForRole = (role: string) => {
    return role === "member" ? "Provider" : "View Only";
  };

  return (
    <tr style={{ backgroundColor: isSelected ? '#f0f0f0' : 'transparent' }}>
      <td style={{ padding: '10px 15px', border: 'none' }}>
        <input
          type="checkbox"
          onChange={handleCheckboxChange}
          checked={isSelected}
          readOnly
        />
      </td>
      <td style={{ padding: '10px 15px', border: 'none' }}>{member.user_info.name}</td>
      <td style={{ padding: '10px 15px', border: 'none' }}>{member.user_info.email}</td>
      <td style={{ padding: '10px 15px', border: 'none' }}>{member.user_metrics?.notes_created ?? 'N/A'}</td>
      <td style={{ padding: '10px 15px', border: 'none' }}>
      <select value={getDisplayNameForRole(role)} onChange={handleRoleChange}>
          <option value="Provider">Provider</option>
          <option value="View Only">View Only</option>
        </select>
      </td>
    </tr>
  );
};
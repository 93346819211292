import React, { useEffect, useState } from "react";
import {
  AdminModule,
  AdminSection,
  AdminSystemPrompt,
  AdminTemplate,
  AdminUser,
  CustomPrompts,
} from "../../types/types";
import { AdminNotesLoader } from "../../loaders/AdminNotesLoader";
import { formatDate } from "../../utils/utils";
import { UsersLoader } from "../../loaders/UsersLoader";
import { CustomPromptsForUserLoader } from "../../loaders/CustomPromptsLoader";
import { useUser } from "../../context/user";
import APIService from "../../services/APIService";

interface UniversalCustomPromptSectionProps {
  user_id: string;
}

export const UniversalCustomPromptSection: React.FC<
  UniversalCustomPromptSectionProps
> = ({ user_id }) => {
  const { getAccessToken } = useUser();
  const [customPrompts, setCustomPrompts] = useState<CustomPrompts>();
  const [universalPrompts, setUniversalPrompts] = useState<any>();
  const [spinning, setSpinning] = useState<boolean>(false);

  const updateUniversalPrompt = (
    oldKey: string,
    newKey: string,
    newValue: string
  ) => {
    if (universalPrompts) {
      // Remove the old key-value pair
      const { [oldKey]: _, ...rest } = universalPrompts;
      // Add the new key-value pair
      setUniversalPrompts({ ...rest, [newKey]: newValue });
    }
  };

  const removeUniversalPrompt = (key: string) => {
    if (universalPrompts) {
      const { [key]: _, ...rest } = universalPrompts;
      setUniversalPrompts(rest);
    }
  };

  const handleSave = async () => {
    const newPrompts = { ...customPrompts };

    if (!newPrompts.universal) {
      newPrompts.universal = {};
    }

    newPrompts.universal = universalPrompts;

    console.log("Saving the current state of customPrompts:", newPrompts);
    setSpinning(true);
    const accessToken = await getAccessToken();

    const response = await APIService.makeAPIPostRequest({
      requestString: "/user/updateCustomPromptsForUser",
      accessToken: accessToken,
      body: {
        target_user_id: user_id,
        custom_prompts: newPrompts,
      },
    });

    if (response.ok) {
      const data = response.value;
      console.log(data);
      setSpinning(false);
    } else {
      setSpinning(false);
    }

    console.log(response);
  };

  const filterUniversalPrompts = () => {
    console.log("filtering");
    const filteredUniversalPrompts = customPrompts?.universal || null;
    console.log(filteredUniversalPrompts);
    setUniversalPrompts(filteredUniversalPrompts);
  };

  const fetchCustomPromptsForUser = async (userId: string) => {
    if (userId) {
      const customPrompts = await CustomPromptsForUserLoader(userId);
      setCustomPrompts(customPrompts);
    }
  };

  const addNewPrompt = () => {
    if (universalPrompts) {
      let newKey = "newKey";
      let counter = 1;

      // Ensure the key is unique
      while (universalPrompts.hasOwnProperty(newKey)) {
        newKey = `newKey_${counter}`;
        counter++;
      }

      setUniversalPrompts({ ...universalPrompts, [newKey]: "" });
    } else {
      // Initialize universalPrompts if it's null or undefined
      setUniversalPrompts({ newKey: "" });
    }
  };

  // Initialize filteredNotes with all notes
  useEffect(() => {
    fetchCustomPromptsForUser(user_id);
  }, []);

  useEffect(() => {
    filterUniversalPrompts();
  }, [customPrompts]);

  return (
    <div className="my-4 bg-white p-2 rounded-md shadow">
      <h3>User Custom Prompts</h3>
      {user_id && (
        <>
          {universalPrompts && (
            <div className="mb-2">
              <label
                className="block text-sm text-gray-500"
                htmlFor="second_query"
              >
                Custom prompts for universal
              </label>
              {Object.keys(universalPrompts).map((key: string) => (
                <UserPrompt
                  key={key}
                  initialKey={key}
                  initialValue={universalPrompts[key]}
                  onModify={updateUniversalPrompt}
                  onRemove={removeUniversalPrompt}
                />
              ))}
            </div>
          )}
          <div className="py-4">
            {" "}
            <button onClick={addNewPrompt}>Add Prompt</button>
          </div>
          {!spinning && <button onClick={handleSave}>Save</button>}
          {spinning && <Spinner />}
        </>
      )}
    </div>
  );
};

interface TemplateCustomPromptSectionProps {
  template: AdminTemplate;
}

export const TemplateCustomPromptSection: React.FC<
  TemplateCustomPromptSectionProps
> = ({ template }) => {
  const { getAccessToken } = useUser();
  const [usersList, setUsersList] = useState<AdminUser[]>();
  const [selectedUserId, setSelectedUserId] = useState<string>();
  const [customPrompts, setCustomPrompts] = useState<CustomPrompts>();
  const [templatePrompts, setTemplatePrompts] = useState<any>();
  const [spinning, setSpinning] = useState<boolean>(false);

  const updateTemplatePrompt = (
    oldKey: string,
    newKey: string,
    newValue: string
  ) => {
    if (templatePrompts) {
      // Remove the old key-value pair
      const { [oldKey]: _, ...rest } = templatePrompts;
      // Add the new key-value pair
      setTemplatePrompts({ ...rest, [newKey]: newValue });
    }
  };

  const removeTemplatePrompt = (key: string) => {
    if (templatePrompts) {
      const { [key]: _, ...rest } = templatePrompts;
      setTemplatePrompts(rest);
    }
  };

  const handleSave = async () => {
    const newPrompts = { ...customPrompts };

    if (!newPrompts.template) {
      newPrompts.template = {};
    }

    newPrompts.template[template.template_id] = templatePrompts;

    console.log("Saving the current state of customPrompts:", newPrompts);
    setSpinning(true);
    const accessToken = await getAccessToken();

    const response = await APIService.makeAPIPostRequest({
      requestString: "/user/updateCustomPromptsForUser",
      accessToken: accessToken,
      body: {
        target_user_id: selectedUserId,
        custom_prompts: newPrompts,
      },
    });

    if (response.ok) {
      const data = response.value;
      console.log(data);
      setSpinning(false);
    } else {
      setSpinning(false);
    }

    console.log(response);
  };

  const filterTemplatePrompts = () => {
    console.log("filtering");
    const filteredTemplatePrompts =
      customPrompts?.template?.[template.template_id] || null;
    console.log(filteredTemplatePrompts);
    setTemplatePrompts(filteredTemplatePrompts);
  };

  const handleUserSelection = async (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setTemplatePrompts({});
    const userId = e.target.value;
    if (userId) {
      setSelectedUserId(userId);
      fetchCustomPromptsForUser(userId);
    }
  };

  const fetchCustomPromptsForUser = async (userId: string) => {
    if (userId) {
      const customPrompts = await CustomPromptsForUserLoader(userId);
      setCustomPrompts(customPrompts);
    }
  };

  const fetchUsers = async () => {
    const response = await UsersLoader();
    if (response) {
      setUsersList(response);
    }
  };

  const addNewPrompt = () => {
    if (templatePrompts) {
      let newKey = "newKey";
      let counter = 1;

      // Ensure the key is unique
      while (templatePrompts.hasOwnProperty(newKey)) {
        newKey = `newKey_${counter}`;
        counter++;
      }

      setTemplatePrompts({ ...templatePrompts, [newKey]: "" });
    } else {
      // Initialize templatePrompts if it's null or undefined
      setTemplatePrompts({ newKey: "" });
    }
  };

  // Initialize filteredNotes with all notes
  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    filterTemplatePrompts();
  }, [customPrompts, selectedUserId]);

  return (
    <div className="my-4 bg-white p-2 rounded-md shadow">
      <h3>User Custom Prompts</h3>
      <div className="mb-2">
        <label className="block text-sm text-gray-500" htmlFor="second_query">
          Select a User
        </label>
        <div className="mb-4">
          <select
            value={selectedUserId}
            onChange={handleUserSelection}
            className="max-w-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          >
            <option value="">Select a user to edit custom prompts</option>
            {usersList?.map((user, index) => (
              <option key={index} value={user._id}>
                {user.user_info?.name ? user.user_info?.name : user._id}
              </option>
            ))}
          </select>
        </div>
      </div>
      {selectedUserId && (
        <>
          {templatePrompts && (
            <div className="mb-2">
              <label
                className="block text-sm text-gray-500"
                htmlFor="second_query"
              >
                Custom prompts for template: {template.template_name}
              </label>
              {Object.keys(templatePrompts).map((key: string) => (
                <UserPrompt
                  key={key}
                  initialKey={key}
                  initialValue={templatePrompts[key]}
                  onModify={updateTemplatePrompt}
                  onRemove={removeTemplatePrompt}
                />
              ))}
            </div>
          )}
          <div className="py-4">
            {" "}
            <button onClick={addNewPrompt}>Add Prompt</button>
          </div>
          {!spinning && <button onClick={handleSave}>Save</button>}
          {spinning && <Spinner />}
        </>
      )}
    </div>
  );
};

interface ModuleCustomPromptSectionProps {
  module: AdminModule;
}

export const ModuleCustomPromptSection: React.FC<
  ModuleCustomPromptSectionProps
> = ({ module }) => {
  const { getAccessToken } = useUser();
  const [usersList, setUsersList] = useState<AdminUser[]>();
  const [selectedUserId, setSelectedUserId] = useState<string>();
  const [customPrompts, setCustomPrompts] = useState<CustomPrompts>();
  const [modulePrompts, setModulePrompts] = useState<any>();
  const [spinning, setSpinning] = useState<boolean>(false);

  const updateModulePrompt = (
    oldKey: string,
    newKey: string,
    newValue: string
  ) => {
    if (modulePrompts) {
      // Remove the old key-value pair
      const { [oldKey]: _, ...rest } = modulePrompts;
      // Add the new key-value pair
      setModulePrompts({ ...rest, [newKey]: newValue });
    }
  };

  const removeModulePrompt = (key: string) => {
    if (modulePrompts) {
      const { [key]: _, ...rest } = modulePrompts;
      setModulePrompts(rest);
    }
  };

  const handleSave = async () => {
    const newPrompts = { ...customPrompts };

    if (!newPrompts.module) {
      newPrompts.module = {};
    }

    newPrompts.module[module.module_id] = modulePrompts;

    console.log("Saving the current state of customPrompts:", newPrompts);
    setSpinning(true);
    const accessToken = await getAccessToken();

    const response = await APIService.makeAPIPostRequest({
      requestString: "/user/updateCustomPromptsForUser",
      accessToken: accessToken,
      body: {
        target_user_id: selectedUserId,
        custom_prompts: newPrompts,
      },
    });

    if (response.ok) {
      const data = response.value;
      console.log(data);
      setSpinning(false);
    } else {
      setSpinning(false);
    }

    console.log(response);
  };

  const filterModulePrompts = () => {
    console.log("filtering");
    const filteredModulePrompts =
      customPrompts?.module?.[module.module_id] || null;
    console.log(filteredModulePrompts);
    setModulePrompts(filteredModulePrompts);
  };

  const handleUserSelection = async (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setModulePrompts({});
    const userId = e.target.value;
    if (userId) {
      setSelectedUserId(userId);
      fetchCustomPromptsForUser(userId);
    }
  };

  const fetchCustomPromptsForUser = async (userId: string) => {
    if (userId) {
      const customPrompts = await CustomPromptsForUserLoader(userId);
      setCustomPrompts(customPrompts);
    }
  };

  const fetchUsers = async () => {
    const response = await UsersLoader();
    if (response) {
      setUsersList(response);
    }
  };

  const addNewPrompt = () => {
    if (modulePrompts) {
      let newKey = "newKey";
      let counter = 1;

      // Ensure the key is unique
      while (modulePrompts.hasOwnProperty(newKey)) {
        newKey = `newKey_${counter}`;
        counter++;
      }

      setModulePrompts({ ...modulePrompts, [newKey]: "" });
    } else {
      // Initialize modulePrompts if it's null or undefined
      setModulePrompts({ newKey: "" });
    }
  };

  // Initialize filteredNotes with all notes
  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    filterModulePrompts();
  }, [customPrompts, selectedUserId]);

  return (
    <div className="my-4 bg-white p-2 rounded-md shadow">
      <h3>User Custom Prompts</h3>
      <div className="mb-2">
        <label className="block text-sm text-gray-500" htmlFor="second_query">
          Select a User
        </label>
        <div className="mb-4">
          <select
            value={selectedUserId}
            onChange={handleUserSelection}
            className="max-w-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          >
            <option value="">Select a user to edit custom prompts</option>
            {usersList?.map((user, index) => (
              <option key={index} value={user._id}>
                {user.user_info?.name ? user.user_info?.name : user._id}
              </option>
            ))}
          </select>
        </div>
      </div>
      {selectedUserId && (
        <>
          {modulePrompts && (
            <div className="mb-2">
              <label
                className="block text-sm text-gray-500"
                htmlFor="second_query"
              >
                Custom prompts for module: {module.module_name}
              </label>
              {Object.keys(modulePrompts).map((key: string) => (
                <UserPrompt
                  key={key}
                  initialKey={key}
                  initialValue={modulePrompts[key]}
                  onModify={updateModulePrompt}
                  onRemove={removeModulePrompt}
                />
              ))}
            </div>
          )}
          <div className="py-4">
            {" "}
            <button onClick={addNewPrompt}>Add Prompt</button>
          </div>
          {!spinning && <button onClick={handleSave}>Save</button>}
          {spinning && <Spinner />}
        </>
      )}
    </div>
  );
};

interface UserPromptProps {
  initialKey: string;
  initialValue: string;
  onModify?: (oldKey: string, newKey: string, newValue: string) => void;
  onRemove?: (key: string) => void;
}

const UserPrompt: React.FC<UserPromptProps> = ({
  initialKey,
  initialValue,
  onModify,
  onRemove,
}) => {
  const [key, setKey] = useState<string>(initialKey);
  const [value, setValue] = useState<string>(initialValue);

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (event.target.name === "key") {
      setKey(event.target.value);
    } else if (event.target.name === "value") {
      setValue(event.target.value);
    }
  };

  const handleInputBlur = () => {
    if (onModify) {
      onModify(initialKey, key, value);
    }
  };

  const handleRemove = () => {
    if (onRemove) {
      onRemove(initialKey);
    }
  };

  return (
    <div className="mb-4 flex">
      <div className="w-1/5">
        <label className="block text-sm text-gray-500" htmlFor="key">
          Key
        </label>
        <input
          type="text"
          name="key"
          id="key"
          value={key}
          onChange={handleInputChange}
          className="mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          onBlur={handleInputBlur}
        />
      </div>
      <div className="w-4/5">
        <label className="block text-sm text-gray-500" htmlFor="value">
          Value
        </label>
        <textarea
          name="value"
          id="value"
          value={value}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          className="mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        />
      </div>
      <button onClick={handleRemove}>Remove</button>
    </div>
  );
};

function Spinner() {
  return (
    <div className="flex justify-center items-center">
      <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-gray-900"></div>
    </div>
  );
}

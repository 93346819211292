import { Box, Card, CardContent, Icon, Typography, Button } from "@mui/material";
import {
  AdminModule,
  AdminPublicTemplate,
  AdminSection,
  AdminUser,
  AdminUserTemplate,
  AvailableModule,
  ListTemplate,
  ModuleMappings,
} from "../../types/types";
import { AddCircleOutline } from "@mui/icons-material";
import { useState } from "react";
import NotePreviewModal from "./PreviewTemplate";


interface NewTemplateBlockProps {
  template: AdminPublicTemplate;
  handleClick?: () => void;
  handleCopy?: (template: AdminPublicTemplate) => void;
  availableModules?: AvailableModule[];
}

const NewTemplateBlock = ({ template, handleClick, handleCopy,availableModules }: NewTemplateBlockProps) => {
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);

  const onPreview = () => {
    setIsPreviewOpen(true);
  };

  const handleClosePreview = () => {
    setIsPreviewOpen(false);
  };
  
  const onCopy = () => {
    if (handleCopy) {
      handleCopy(template);
    }
  };

  return (
    <Card
      sx={{
        margin: 1,
        borderColor: "borderColors.primary",
        border: "1px solid",
        // "&:hover": {
        //   boxShadow: 3, 
        //   bgcolor: "backgroundColors.secondary",
        // },
      }}
      
    >
      <CardContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: {xs: "column", md: "row"},
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Typography
              fontSize="0.9375rem"
              fontStyle="normal"
              fontWeight="700"
              lineHeight="1.40625rem"
              color="textColors.lightHeader"
            >
              {template.public_name}
            </Typography>
            <Typography sx={{fontSize:"1.1vh", paddingY: '0.5vh'}}>
              Description:&nbsp;
              {template.public_description}
            </Typography>
            <Typography fontSize="1.1vh">
              Contains:&nbsp;
            {template.sections.map((section, index) => (
              <span key={index}>
                {section.display_name}
                {index < template.sections.length - 1 ? ", " : ""}
              </span>
            ))}
          </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: "1vh" , padding: '0.5vh 1vh' }}>
            <Button 
              variant="contained" 
              color="primary" 
              onClick={handleClick}
              sx={{ borderRadius: '1vh', padding: '0.5vh 1vh' }}
            >
              Add
            </Button>
            <Button 
              variant="outlined" 
              color="primary" 
              onClick={onCopy}
              sx={{ borderRadius: '1vh', padding: '0.5vh 1vh' }}
            >
              Customize
            </Button>
            <Button 
              variant="outlined" 
              color="primary" 
              onClick={() => 
                {onPreview();
                }
              }
              sx={{ borderRadius: '1vh', padding: '0.5vh 1vh' }}
            >
              Preview
            </Button>
            
          </Box>
          {isPreviewOpen && (
            <NotePreviewModal
            open={isPreviewOpen}
            onClose={handleClosePreview}
            templateSections={template.sections}
            availableModules={availableModules} // Pass the availableModules to the modal
          />
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default NewTemplateBlock;

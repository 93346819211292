
import {
  Box,
  Badge,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  Popover,
  Divider
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useUIState } from "../../context/uiState";
import { useUser } from "../../context/user";
import NotificationsIcon from '@mui/icons-material/Notifications';
import ClearIcon from '@mui/icons-material/Clear';
import { formatDistanceToNow } from 'date-fns';
import { useEffect, useState } from "react";
import React from "react";
import APIService from "../../services/APIService";
import { NotificationState } from "../../context/user/context";


export const ShowNotifications = ({}: {}) => {
    const navigate = useNavigate();
    const { showAlertBanner } = useUIState();
    const { userState, getAccessToken } = useUser();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [notifications, setNotifications] = useState<NotificationState[]>([]);
    const [unopenedNotifications, setUnopenedNotifications] = useState<number>(0);
    useEffect(() => {
        if(userState?.notifications && userState?.notifications?.length >= 1){
          setNotifications(userState?.notifications)
          const unopenedNotifications = userState?.notifications.filter(n => n.isOpened === 0);
          setUnopenedNotifications(unopenedNotifications.length || 0);
        }
        if(localStorage.getItem("sharedTemplateAdded") && localStorage.getItem("sharedTemplateAdded") != ""){
          showAlertBanner(
            "A new template has been added",
            "success",
            true,
            () => {
              navigate('/templates')
            }
          );
          localStorage.removeItem("sharedTemplateAdded")
        }
      }, [userState, localStorage.getItem("sharedTemplateAdded")]);
    useEffect(() => {
        if (userState?.notifications && userState?.notifications?.length >= 1) {
        const sortedNotifications = userState.notifications.sort((a, b) => 
            (b.createdAt || 0) - (a.createdAt || 0)
        );
        setNotifications(sortedNotifications);
        
        const unopenedNotifications = sortedNotifications.filter(n => n.isOpened === 0);
        setUnopenedNotifications(unopenedNotifications.length || 0);
        }
    }, [userState]);
    
    const handleMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleMouseLeave = () => {
        setAnchorEl(null);
    };
    
    const open = Boolean(anchorEl);
    const id = open ? 'notification-popover' : undefined;
    
    const handleNotificationClick = async (notification: NotificationState) => {
        try {
        const accessToken = await getAccessToken();
        await APIService.makeAPIPostRequest({
            requestString: "/user/notificationViewed",
            accessToken,
            body: {
            notificationId: notification.notification_id,
            },
        });
        } catch (error) {
        console.error("Failed to mark notification as opened", error);
        } finally {
        if (notification.external_url) window.location.href = notification.external_url;
        }
    };
    
    const handleRemoveNotification = async (notification: NotificationState) => {
        try {
        const accessToken = await getAccessToken();
        await APIService.makeAPIPostRequest({
            requestString: "/user/removeNotification",
            accessToken,
            body: {
            notificationId: notification.notification_id,
            },
        });
    
        // Remove the notification from the state
        setNotifications(prevNotifications => prevNotifications.filter(n => n.notification_id !== notification.notification_id));
        if(!notification.isOpened)
            setUnopenedNotifications(unopenedNotifications-1);
        } catch (error) {
        console.error("Failed to remove notification", error);
        }
    };
    
    return (
        <Box sx={{ mx: 1, display: 'inline-block' }}>
        <Badge
            badgeContent={unopenedNotifications}
            max={9}
            color="secondary"
            onMouseEnter={handleMouseEnter}
        >
            <NotificationsIcon color="action" />
        </Badge>
    
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
            }}
            transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
            }}
            onClose={handleMouseLeave}
            PaperProps={{
            onMouseLeave: handleMouseLeave,
            sx: {
                mt: 1,
                maxHeight: 300,
                overflow: 'auto',
                width: 450,
                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                borderRadius: 2,
            },
            }}
            disableRestoreFocus
        >
            <Box sx={{ px: 2, py: 1 }}>
            <Typography variant="h6" component="h6">
                Notifications
            </Typography>
            </Box>
            <Divider />
            <List>
            {notifications.length >= 1 ? 
                notifications.map((notification, index) => (
                <React.Fragment key={index}>
                    <ListItem 
                        disablePadding 
                        sx={{ 
                            backgroundColor: notification.isOpened === 0 ? 'white' : 'rgba(0, 0, 0, 0.1)',  // Light gray for viewed, white for unviewed
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            position: 'relative' // Required to position the "x" button
                        }}
                    >
                        {notification.type === "template_sharing" && (
                            <Typography variant="caption" color="primary" sx={{ pl: 2, pt: 1, pb: 0 }}>
                                A template has been shared with you
                            </Typography>
                        )}
                        <IconButton
                            sx={{
                                position: 'absolute',
                                top: 0,
                                right: 0,
                                zIndex: 2, // Ensure the button is on top of everything
                                padding: '4px', // Optional: Adjust padding to make the button smaller
                            }}
                            onClick={(e) => {
                                e.stopPropagation(); // Prevent the ListItemButton click event
                                handleRemoveNotification(notification);
                            }}
                            size="large"
                        >
                            <ClearIcon sx={{ fontSize: 'large' }} />
                        </IconButton>
                        <ListItemButton
                            sx={{ flexGrow: 1, width: '100%', pl: 2, pr: 4 }} // Adjust padding to make space for the "x" button
                            onClick={notification.external_url ? () => handleNotificationClick(notification) : undefined}
                        >
                            <ListItemText
                                primary={notification.title}
                                secondary={`From: ${notification.requester_name}`}
                                sx={{width: '60%'}}
                            />
                            <Typography variant="caption" color="text.secondary" sx={{ alignSelf: 'flex-end', pr: 0, pb: 1 }}>
                                {notification.createdAt ? `${formatDistanceToNow(new Date(notification.createdAt * 1000))} ago` : ''}
                            </Typography>
                        </ListItemButton>

                    </ListItem>
                    {index < notifications.length - 1 && <Divider component="li" />}
                </React.Fragment>
                )):
                <Typography variant="body2" sx={{ p: 2 }}>
                No Notifications
                </Typography>
            }
            </List>
        </Popover>
        </Box>
    );
}
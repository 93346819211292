import { AdminGroup, GroupMemberInfo } from "../../types/types";
import { GroupsLoader } from "../../loaders/GroupsLoader";
import { SetStateAction, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { GroupDetailsRow } from "../../components/Admin/GroupDetailsRow";
import { GroupDetailsLoader } from "../../loaders/GroupDetailsLoader";
import APIService from "../../services/APIService";
import { useUser } from "../../context/user";

export const AdminGroupDetails: React.FC = () => {
  const { getAccessToken } = useUser();
  const [isLoading, setIsLoading] = useState(true);
  const { groupId } = useParams();
  const [groupDetails, setGroupDetails] = useState<AdminGroup | null>(null);
  const [error, setError] = useState<string | null>(null);
  const params = useParams(); // This hook gives you access to the params of the current route
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const [selectedGroupId, setSelectedGroupId] = useState<string | null>(null);

  const loadGroupDetails = async () => {
    try {
      const details = await GroupDetailsLoader({ params });
      setGroupDetails(details[0]);
      setIsLoading(false);
    } catch (e: any) {
      setError(e.message);
    }
  };

  useEffect(() => {
    loadGroupDetails();
  }, [groupId]);

  if (isLoading) return <div>Loading...</div>;

  const validMembers =
    groupDetails?.members?.filter((member) => member.user_id !== null) || [];
  const validAdmins =
    groupDetails?.admins?.filter((admin) => admin.user_id !== null) || [];
  const validBillingAdmin =
    groupDetails?.billing_admin?.filter(
      (billing_admin) => billing_admin.user_id !== null
    ) || [];

  const handleUserSelect = (userId: string) => {
    setSelectedUserId(userId);
  };

  const removeUserFromGroup = async () => {
    if (!selectedUserId || !groupId) return;

    try {
      const accessToken = await getAccessToken();
      const response = await APIService.makeAPIPostRequest({
        requestString: "/group/removeUserFromGroup",
        accessToken: accessToken,
        body: {
          user_id: selectedUserId,
          group_id: groupId,
        },
      });

      if (!response.ok) throw new Error("Failed to remove user from group");

      // After successfully removing a user, re-fetch the group details
      await loadGroupDetails(); // Call the function that loads the group details
      setSelectedUserId(null); // Reset selection
    } catch (error) {
      console.error("Failed to remove user:", error);
      setError("Failed to remove user from group");
    }
  };

  return (
    <>
      <div className="flex flex-col">
        {/* Group Information Section */}
        <div className="admin-group-details mb-8">
          {" "}
          <section className="group-info">
            <h2 className="text-xl font-bold mb-4">Group Information</h2>
            <div className="flex flex-wrap justify-between">
              {" "}
              <p>
                <strong>Name:</strong> {groupDetails?.group_info?.name}
              </p>
              <p>
                <strong>Institution:</strong>{" "}
                {groupDetails?.group_info?.institution}
              </p>
              <p>
                <strong>Total Members:</strong> {groupDetails?.members?.length}
              </p>
              <p>
                <strong>Total Admins:</strong> {groupDetails?.admins?.length}
              </p>
              <p>
                <strong>Total Stripe Subscription Quantity:</strong>{" "}
                {groupDetails?.subscription_quantity}
              </p>
            </div>
          </section>
        </div>

        {/* Users Table Section */}
        <div className="flex flex-col">
          <table className="table-auto w-full border-collapse border-2 border-gray-500">
            <thead className="sticky top-0 bg-blue-200">
              <tr>
                <th className="px-4 py-2 border-2 border-gray-400"></th>
                <th className="px-4 py-2 border-2 border-gray-400">User ID</th>
                <th className="px-4 py-2 border-2 border-gray-400">Name</th>
                <th className="px-4 py-2 border-2 border-gray-400">Email</th>
                <th className="px-4 py-2 border-2 border-gray-400">
                  Notes Created
                </th>
                <th className="px-4 py-2 border-2 border-gray-400">Role</th>
                <th className="px-4 py-2 border-2 border-gray-400">Status</th>
              </tr>
            </thead>
            <tbody>
              {validBillingAdmin.map((billing_admin: GroupMemberInfo) => (
                <GroupDetailsRow
                  key={billing_admin.user_id}
                  member={billing_admin}
                  isSelected={billing_admin.user_id === selectedUserId}
                  onUserSelect={handleUserSelect}
                />
              ))}
              {validMembers.map((member: GroupMemberInfo) => (
                <GroupDetailsRow
                  key={member.user_id}
                  member={member}
                  isSelected={member.user_id === selectedUserId}
                  onUserSelect={handleUserSelect}
                />
              ))}
              {validAdmins.map((admin: GroupMemberInfo) => (
                <GroupDetailsRow
                  key={admin.user_id}
                  member={admin}
                  isSelected={admin.user_id === selectedUserId}
                  onUserSelect={handleUserSelect}
                />
              ))}
            </tbody>
          </table>
        </div>

        {/* Remove User Button */}
        <div className="mt-4 flex justify-center">
          {" "}
          <button
            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
            onClick={removeUserFromGroup}
            disabled={!selectedUserId} // Disable button if no user is selected
          >
            Remove User from Group
          </button>
        </div>
      </div>
    </>
  );
};

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Box,
  Button,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import { Report } from "@mui/icons-material";
import { useRecordingContext } from "../context/RecordingContext";
import { useUser } from "../context/user";
import { useUIState } from "../context/uiState";
import AudioVisualizer from "../components/AudioVisualizer";

interface SimpleRecorderProps {
  onSuccessfulSubmit?: (noteId: string) => void;
  onStopRecording?: () => void;
  onReceiveAudioPermission?: () => void;
  onDeleteRecording?: () => void;
  showAudioStream: boolean;
  showAdditionalInfo: boolean;
  showNoteTitle: boolean;
}

export const SimpleRecorder: React.FC<SimpleRecorderProps> = ({
  onSuccessfulSubmit,
  onStopRecording,
  onReceiveAudioPermission,
  onDeleteRecording,
  showAudioStream = false,
  showAdditionalInfo = false,
  showNoteTitle = false,
}) => {
  const {
    recordingStatus,
    noteInformation,
    setNoteInformation,
    microphonePermissionGranted,
    checkMicrophonePermission,
    selectedMicrophone,
    setNoAudioStatus,
    noAudioStatus,
    initiateRecording,
    initiateEndRecording,
    sendRecording,
    pauseRecording,
    resumeRecording,
    setSelectedMicrophone,
    getAvailableMicrophones,
    availableMicrophones,
    deleteRecordingHelper,
    elapsedTime,
    cleanUpSession,
    releaseMicrophone,
    initiateReupload,
    lowDataMode,
  } = useRecordingContext();

  const { userState, templatesList } = useUser();
  const { state, showAlertBanner } = useUIState();
  const { platform } = state;

  const [noteTitleError, setNoteTitleError] = useState(false);
  const [timerDisplay, setTimerDisplay] = useState("0:00");
  const [spinnerError, setSpinnerError] = useState(false);
  const [maxRecordingTime] = useState(10 * 60); // Ten minutes

  const isRecording = useRef<boolean>(false);

  const SPINNER_TIMEOUT = 30000; // 30 seconds

  useEffect(() => {
    localStorage.setItem("elapsedTime", elapsedTime.toString());
    const minutes = Math.floor(elapsedTime / 60);
    const seconds = elapsedTime % 60;
    const formattedTime = `${minutes}:${String(seconds).padStart(2, "0")}`;
    setTimerDisplay(formattedTime);
  }, [elapsedTime]);

  useEffect(() => {
    isRecording.current = recordingStatus.isRecording;
  }, [recordingStatus.isRecording]);

  useEffect(() => {
    if (microphonePermissionGranted === undefined) {
      getAvailableMicrophones();
    }

    // This will only run when the component unmounts
    return () => {
      if (!isRecording) {
        releaseMicrophone();
      }
    };
  }, []);

  useEffect(() => {
    const minutes = Math.floor(elapsedTime / 60);
    const seconds = elapsedTime % 60;
    const formattedTime = `${minutes}:${String(seconds).padStart(2, "0")}`;
    setTimerDisplay(formattedTime);

    if (elapsedTime >= maxRecordingTime && recordingStatus.isRecording) {
      stopRecording();
    }
  }, [elapsedTime]);

  useEffect(() => {
    if (microphonePermissionGranted) {
      if (onReceiveAudioPermission) onReceiveAudioPermission();
    }
  }, [microphonePermissionGranted]);

  useEffect(() => {
    let spinnerTimeout: NodeJS.Timeout;
    if (
      recordingStatus.isReuploading ||
      recordingStatus.isStarting ||
      recordingStatus.isStopping
    ) {
      spinnerTimeout = setTimeout(() => {
        setSpinnerError(true);
        showAlertBanner(
          "An error occurred. Please refresh the page and try again.",
          "error"
        );
      }, SPINNER_TIMEOUT);
    }

    return () => {
      if (spinnerTimeout) {
        clearTimeout(spinnerTimeout);
      }
    };
  }, [
    recordingStatus.isReuploading,
    recordingStatus.isStarting,
    recordingStatus.isStopping,
  ]);

  const handleNoteTitleChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newNoteTitle = event.target.value;
    setNoteInformation((prevInfo) => ({
      ...prevInfo,
      noteTitle: newNoteTitle,
    }));
    setNoteTitleError(newNoteTitle.trim() === "");
  };

  const handleAdditionalInfoChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newAdditionalInfo = event.target.value;
    setNoteInformation((prevInfo) => ({
      ...prevInfo,
      additionalInfo: newAdditionalInfo,
    }));
  };

  const startRecording = async () => {
    const startRecordingStatus = await initiateRecording();
    if (startRecordingStatus) {
    } else {
      showAlertBanner("Failed to start recording.", "error");
    }
  };

  const stopRecording = async () => {
    const stopRecordingStatus = await initiateEndRecording();

    if (stopRecordingStatus) {
      if (onStopRecording) onStopRecording();
    } else {
      showAlertBanner("Failed to stop recording.", "error");
    }
  };

  const deleteRecording = async () => {
    const deleteRecordingResponse = await deleteRecordingHelper();
    if (deleteRecordingResponse) {
      showAlertBanner("Recording deleted successfully.", "success");
      if (onDeleteRecording) onDeleteRecording();
    } else {
      showAlertBanner("Failed to delete recording.", "error");
    }
  };

  const submitRecording = async () => {
    if (elapsedTime < 20) {
      showAlertBanner("Recording must be at least 20 seconds long.", "error");
      return;
    }

    if (!noteInformation.noteTitle) {
      setNoteTitleError(true);
      return;
    }

    setNoteTitleError(false);
    try {
      const submitRecordingResponse = await sendRecording();
      if (submitRecordingResponse) {
        if (onSuccessfulSubmit) onSuccessfulSubmit(submitRecordingResponse);
      } else {
        showAlertBanner("Failed to submit recording.", "error");
      }
    } catch (error) {
      showAlertBanner("Error occurred while submitting recording.", "error");
    }
  };

  const RecordingControls = useMemo(() => {
    if (
      recordingStatus.isReuploading ||
      recordingStatus.isStarting ||
      recordingStatus.isStopping
    ) {
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 100,
          }}
        >
          {spinnerError ? (
            <>
              <Typography color="error">
                An error occurred. Please refresh the page.
              </Typography>
              <Button
                variant="contained"
                onClick={() => {
                  localStorage.removeItem("recordingStatus");
                  window.location.reload();
                }}
              >
                Reset Recorder
              </Button>
            </>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <CircularProgress />
              {lowDataMode && (
                <Typography sx={{ mt: 2, color: "lightgrey" }}>
                  Slow network detected, improve your network connection if this
                  takes too long.
                </Typography>
              )}
            </Box>
          )}
        </Box>
      );
    }

    if (recordingStatus.readyToRecord) {
      switch (microphonePermissionGranted) {
        case true:
          return (
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              {platform === "desktop" && (
                <FormControl fullWidth>
                  <InputLabel id="microphone-select-label">
                    Microphone
                  </InputLabel>
                  <Select
                    labelId="microphone-select-label"
                    value={selectedMicrophone?.deviceId || ""}
                    label="Microphone"
                    onChange={(event) => {
                      const selectedMic = availableMicrophones?.find(
                        (mic) => mic.deviceId === event.target.value
                      );
                      if (selectedMic) {
                        setSelectedMicrophone(selectedMic);
                      }
                    }}
                  >
                    {availableMicrophones?.map((mic) => (
                      <MenuItem key={mic.deviceId} value={mic.deviceId}>
                        {mic.label || `Microphone ${mic.deviceId}`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              <Button
                variant="contained"
                color="primary"
                onClick={startRecording}
                fullWidth
              >
                Start Recording
              </Button>
            </Box>
          );
        case false:
          return (
            <Typography>
              Microphone access is denied. You'll need to enable it to use the
              recorder.
            </Typography>
          );
        case undefined:
          return (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                alignItems: "center",
              }}
            >
              <Typography>
                The recorder needs microphone access to record.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={checkMicrophonePermission}
              >
                Request Microphone Access
              </Button>
            </Box>
          );
      }
    }

    if (recordingStatus.isRecording) {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 2,
            width: "100%",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            {noAudioStatus ? (
              <Tooltip
                title="No audio is being recorded. Please check your microphone selection."
                arrow
              >
                <Report
                  sx={{ color: "#FFC107", animation: "pulse 1s infinite" }}
                />
              </Tooltip>
            ) : (
              !recordingStatus.recordingPaused && (
                <Box
                  sx={{
                    width: 20,
                    height: 20,
                    borderRadius: "50%",
                    backgroundColor: "red",
                    animation: "pulse 1s infinite",
                  }}
                />
              )
            )}
            <Typography variant="body2">{timerDisplay}</Typography>
          </Box>
          {showAudioStream && (
            <Box sx={{ width: "100%" }}>
              <AudioVisualizer />
            </Box>
          )}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              width: "100%",
            }}
          >
            <Button
              sx={{ width: "180px" }}
              variant="contained"
              color="primary"
              onClick={stopRecording}
            >
              Stop Recording
            </Button>
            <Button
              sx={{ flex: 1 }}
              variant="contained"
              color="inherit"
              onClick={
                recordingStatus.recordingPaused
                  ? resumeRecording
                  : pauseRecording
              }
            >
              {recordingStatus.recordingPaused ? "Resume" : "Pause"}
            </Button>
          </Box>
        </Box>
      );
    }

    if (recordingStatus.chunksToBeUploaded) {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 2,
            width: "100%",
          }}
        >
          <Typography variant="body1" sx={{ textAlign: "center" }}>
            Timer: {timerDisplay}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "100%",
              gap: 2,
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={initiateReupload}
              sx={{ flex: 3 }}
            >
              Retry Upload
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={deleteRecording}
              sx={{ flex: 1 }}
            >
              Delete
            </Button>
          </Box>
        </Box>
      );
    }

    if (recordingStatus.readyToSubmit) {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 2,
            width: "100%",
          }}
        >
          <Typography variant="body1" sx={{ textAlign: "center" }}>
            Timer: {timerDisplay}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "100%",
              gap: 2,
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={submitRecording}
              sx={{ flex: 3 }}
            >
              Submit Recording
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={deleteRecording}
              sx={{ flex: 1 }}
            >
              Delete
            </Button>
          </Box>
        </Box>
      );
    }

    return null;
  }, [
    recordingStatus,
    microphonePermissionGranted,
    spinnerError,
    timerDisplay,
    noAudioStatus,
  ]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: { xs: "95%", md: "80%" },
        margin: "auto",
        padding: 2,
        maxWidth: "500px",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        {showNoteTitle && (
          <TextField
            label="Note Title"
            value={noteInformation.noteTitle || ""}
            onChange={handleNoteTitleChange}
            required
            error={noteTitleError}
            helperText={noteTitleError ? "Please enter a note title" : ""}
          />
        )}
        {showAdditionalInfo && (
          <TextField
            label="Additional Info"
            multiline
            rows={3}
            value={noteInformation.additionalInfo || ""}
            onChange={handleAdditionalInfoChange}
          />
        )}
        {showAudioStream && (
          <Box sx={{ minHeight: "100px", height: "10vh", maxHeight: "300px" }}>
            <AudioVisualizer />
          </Box>
        )}
        {RecordingControls}
      </Box>
    </Box>
  );
};

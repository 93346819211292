import {
  Box,
  Card,
  CardContent,
  LinearProgress,
  Typography,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { formatDate, truncateText } from "../../utils/utils";
import { useEffect, useRef } from "react";
import { useUIState } from "../../context/uiState";
import { UIStateType } from "../../context/uiState/context";
import {
  AdminSection,
  AdminTemplate,
  AvailableModule,
  ListTemplate,
} from "../../types/types";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { HoverContent } from "./SectionBlock";

interface NewModuleBlockProps {
  module: AvailableModule;
  handleClick?: () => void;
}

const NewModuleBlock = ({ module, handleClick }: NewModuleBlockProps) => {
  return (
    <Card
      sx={{
        margin: 1,
        borderColor: "borderColors.primary",
        border: "1px solid",
        "&:hover": {
          boxShadow: 3, // Adjust shadow on hover
        },
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "center",
        }}
      >
        {/* Module details on the left side */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            flexGrow: 1,
          }}
        >
          <Box>
            <Typography
              fontStyle="normal"
              fontWeight="700"
              sx={{
                cursor: "pointer",
                "&:hover + .hover-content": {
                  cursor: "pointer",
                  display: "block",
                  opacity: 1,
                  visibility: "visible",
                  // transform: "translateY(0%)",
                },
              }}
            >
              {module.public_name}
            </Typography>
            <HoverContent
              title={module.public_name ?? ""}
              content={
                module?.public_example ||
                "The patient is alert and oriented, cooperative, and able to provide a clear medical history. They appear to be in no acute distress and are able to communicate effectively. Their mental status is normal, with no evidence of confusion or disorientation."
              }
            />
          </Box>
          <Box>
            <Typography fontSize={".7rem"}>
              {module.public_description}
            </Typography>
          </Box>
        </Box>

        <Button
          variant="contained"
          color="primary"
          onClick={handleClick}
          sx={{
            borderRadius: "1vh",
            padding: "0.5vh 1.5vh",
            ml: 2,
            alignSelf: "flex-end",
          }}
        >
          Add
        </Button>
      </CardContent>
    </Card>
  );
};
export default NewModuleBlock;

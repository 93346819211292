import { useContext, useEffect, useRef, useState } from "react";
import {
  json,
  Params,
  redirect,
  useLoaderData,
  useParams,
  useRouteError,
} from "react-router-dom";
import { NoteSection } from "../components/NoteSection";
import { Sidebar } from "../components/layout/Sidebar";
import { SVGIcon } from "../components/SVGIcon";
import { MainContainer } from "../components/layout/MainContainer";
import { stripTags, textToParagraph } from "../utils/utils";
import { DebugContext } from "../main";
import { Transcript, TranscriptType } from "../components/Transcript";
import { useAuth0 } from "@auth0/auth0-react";
import { useUIState } from "../context/uiState";
import { DeleteModal } from "../components/DeleteModal";
import { DemoTranscript } from "../components/DemoTranscript";
import { API_BASE_URL } from "../services/APIService";

export type DemoNoteDataType = {
  note_id: string;
  title: string;
  status: "done" | "processing";
  trans_status: string;
  embed_status: string;
  generation_status: string;
  transcript: TranscriptType;
  note: {
    summary: string;
    sections: { name: string; text: string; context?: string }[];
  };
  time: string;
  config?: any;
};

interface Section {
  id: string;
}

export const DemoNoteLoader = async ({
  params,
}: {
  params: Params;
}): Promise<DemoNoteDataType | null> => {
  const note = await fetch(`${API_BASE_URL}/demoNote/${params.noteId}`, {
    method: "get",
    headers: new Headers({
      "Content-Type": "application/json",
    }),
  })
    .then((res) => {
      if (res.status === 401) {
        throw json(
          {
            message: "You are not authorized to access this resource.",
            cta: "Login",
          },
          { status: 401 }
        );
      }

      return res.json();
    })
    .then((data: DemoNoteDataType) => {
      console.log(data);
      return data;
    })
    .catch(() => {
      throw json(
        {
          message: "There has been an error. Please login again.",
          cta: "Login",
        },
        { status: 500 }
      );
    });

  return note;
};

function DemoNoteView() {
  const debug = useContext(DebugContext);
  const { noteId } = useParams();
  const note = useLoaderData() as Awaited<ReturnType<typeof DemoNoteLoader>>;
  const [noteData, setNoteData] = useState(note as DemoNoteDataType);
  const [isLoading, setIsLoading] = useState({
    loading: true,
    processing: "trans",
  });
  const [added, setAdded] = useState<number[]>([]);
  const [viewMode, setViewMode] = useState<{ mode: "note" | "transcript" }>({
    mode: "note",
  });
  const { state: uiState, setState: setUIState } = useUIState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [activeSection, setActiveSection] = useState<Section>();
  const scrollRef = useRef<HTMLDivElement>(null);
  const [selectedSections, setSelectedSections] = useState<number[]>([]);

  const handleAdd = (sectionIndex: number) => {
    console.log(`adding ${sectionIndex}`);
    if (added.includes(sectionIndex)) {
      setAdded((current) =>
        current.filter((section) => section !== sectionIndex)
      );
    } else {
      setAdded([...added, sectionIndex]);
    }
  };

  const handleSectionClick = (sectionIndex: number) => {
    setActiveSection({ id: String(sectionIndex) });
  };

  const titleCase = (sentence: string) => {
    const words = sentence.split(" ");

    for (let i = 0; i < words.length; i++) {
      words[i] = words[i][0].toUpperCase() + words[i].substr(1);
    }

    const newSentence = words.join(" ");

    return newSentence;
  };

  const handleCopyAll = () => {
    let copyString = "";
    console.log("copy all");

    for (const sectionID of added) {
      copyString += `===\n${titleCase(
        noteData?.note?.sections[sectionID].name as string
      )}\n===\n\n`;
      copyString += `${noteData?.note?.sections[sectionID].text}\n\n`;
    }

    copyString = stripTags(copyString);

    navigator.clipboard.writeText(copyString);
    setUIState({ ...uiState, navbar: { showCopyNotification: true } });
  };

  useEffect(() => {
    if (scrollRef.current && activeSection) {
      // Find the associated content section in the main container
      const contentSection = scrollRef.current.querySelector(
        `#section-${activeSection.id}`
      );

      // Scroll the main container to the content section
      contentSection?.scrollIntoView({ behavior: "smooth", inline: "start" });
    }
  }, [activeSection]);

  return (
    <div>
      {noteData.note && (
        <div>
          <Sidebar
            selectedSections={selectedSections}
            setSelectedSections={setSelectedSections}
          >
            {noteData.note &&
              noteData.note.sections.map((section, sectionIndex) => {
                return (
                  <li key={sectionIndex}>
                    <div
                      onClick={() => handleSectionClick(sectionIndex)}
                      className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg  hover:bg-gray-100 "
                    >
                      <SVGIcon
                        name={
                          added.includes(sectionIndex)
                            ? "check-filled"
                            : "check-unfilled"
                        }
                      />
                      <span className="ml-3 capitalize">{section.name}</span>
                    </div>
                  </li>
                );
              })}
            <div className="pt-4 mt-4 space-y-2 border-t border-gray-200 text-center">
              <div className="w-[85%] m-auto">
                <button
                  type="button"
                  className="text-white w-full bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2  focus:outline-none "
                  onClick={handleCopyAll}
                >
                  Copy Selected Sections
                </button>
              </div>
            </div>
          </Sidebar>
          <MainContainer scrollRef={scrollRef}>
            <div className="mb-4 flex">
              <div className="w-12 flex-shrink-0 lg:w-20"></div>
              <div className="flex-auto mb-8">
                <div className="flex justify-between items-center">
                  <div className="text-4xl font-bold py-2">
                    {noteData.title}
                  </div>
                  <div className="py-2">
                    <span className="font-bold px-2">Note</span>
                    <label className="relative inline-block w-16 h-6">
                      <input
                        type="checkbox"
                        className="peer opacity-0 w-0 h-0"
                        onChange={(e) => {
                          if (e.target.checked) {
                            setViewMode({ mode: "transcript" });
                          } else {
                            setViewMode({ mode: "note" });
                          }
                        }}
                      />
                      <span
                        className="absolute cursor-pointer top-0 left-0 right-0 bottom-0 bg-blue-500 duration-300 before:content-[''] before:absolute before:w-7 before:h-4 before:bottom-1 before:left-1
                before:bg-white before:duration-300 peer-checked:before:translate-x-7 peer-checked:bg-purple-500"
                      ></span>
                    </label>
                    <span className="font-bold px-2">Transcript</span>
                  </div>
                </div>
                {noteData.note.summary && (
                  <div>{textToParagraph({ text: noteData.note.summary })}</div>
                )}
              </div>
            </div>
            {/* {viewMode.mode === "note" && (
              <div>
                {noteData.note &&
                  noteData.note.sections.map((section, sectionIndex) => {
                    return (
                      <div
                        key={sectionIndex}
                        id={`section-${sectionIndex}`}
                        style={{ scrollMarginTop: "4em" }}
                      >
                        <NoteSection
                          heading={section.name}
                          text={section.text}
                          isAdded={added.includes(sectionIndex)}
                          isEdited={false}
                          isRated={false}
                          handleAdd={() => handleAdd(sectionIndex)}
                          context={section.context}
                          handleSaveEdits={() => {}}
                          handleRevertEdits={() => {}}
                          isSaving={false}
                        />
                      </div>
                    );
                  })}
              </div>
            )} */}
            {viewMode.mode === "transcript" && noteData.transcript && (
              <DemoTranscript transcript={noteData.transcript} />
            )}
            {debug && noteData.config && (
              <pre className="text-black/40">
                {JSON.stringify(noteData.config, null, 4)}
              </pre>
            )}
          </MainContainer>
        </div>
      )}
    </div>
  );
}

export default DemoNoteView;

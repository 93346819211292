import React, { useEffect, useState } from "react";
import {
  AdminTemplate,
  AdminSection,
  AdminModule,
  AdminPublicTemplate,
  AdminUser,
} from "../../types/types";
import { SectionEditor } from "../../components/Admin/SectionEditor";
import { ModulesLoader } from "../../loaders/ModulesLoader";
import { useLoaderData } from "react-router-dom";
import {
  PublicTemplateLoader,
  TemplateLoader,
} from "../../loaders/TemplatesLoader";
import { TemplateCustomPromptSection } from "../../components/Admin/CustomPromptSections";
import { UsersLoader } from "../../loaders/UsersLoader";
import { TemplateToUserModal } from "../../components/Admin/TemplateToUserModal";
import { useUser } from "../../context/user";
import APIService from "../../services/APIService";

interface TemplateEditorProps {}

export const PublicTemplateEditor: React.FC<TemplateEditorProps> = () => {
  const { getAccessToken } = useUser();
  const template = useLoaderData() as Awaited<
    ReturnType<typeof PublicTemplateLoader>
  >;
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [sections, setSections] = useState<AdminSection[]>(template.sections);
  const [modules, setModules] = useState<AdminModule[]>();
  const [showUsersModal, setShowUsersModal] = useState<boolean>(false);
  const [modifiedTemplate, setModifiedTemplate] =
    useState<AdminPublicTemplate>(template);

  const fetchModulesData = async () => {
    const response = await ModulesLoader();
    if (response) {
      setModules(response);
    }
  };

  const onTemplateChange = () => {
    console.log(modifiedTemplate);
  };

  const saveTemplate = async () => {
    setIsSaving(true);

    const accessToken = await getAccessToken();

    const response = await APIService.makeAPIPostRequest({
      requestString: "/template/updatePublicTemplate",
      accessToken: accessToken,
      body: {
        template_id: template.template_id,
        updated_fields: modifiedTemplate,
      },
    });

    if (!response.ok) {
      console.error("there was a problem with the network request");
    } else {
      console.log("Successfull saved!");
    }

    setIsSaving(false);
  };

  const calculateSummarySections = (sections: AdminSection[]) => {
    let summaryModuleIds: string[] = [];
    sections.forEach((section: AdminSection) => {
      if (section.is_summary_section) {
        summaryModuleIds.push(section.module_id);
      }
    });
    return summaryModuleIds;
  };

  const handleSectionChange = (index: number, updatedSection: AdminSection) => {
    const updatedSections = [...sections];
    updatedSections[index] = updatedSection;
    setSections(updatedSections);
    setModifiedTemplate({
      ...modifiedTemplate,
      sections: updatedSections,
      summary_modules: calculateSummarySections(updatedSections),
    });
    onTemplateChange();
  };

  const addSection = () => {
    setSections([
      ...sections,
      {
        // The new section structure can be updated as per your requirement
        module_id: "",
        is_summary_section: false,
        updatable: false,
        display_name: "",
        placeholder_dict: {},
        model: "",
        temperature: 0,
        max_tokens: 0,
      },
    ]);
  };

  const removeSection = (index: number) => {
    const updatedSections = sections.filter(
      (_, sectionIndex) => sectionIndex !== index
    );
    setSections(updatedSections);
    setModifiedTemplate({ ...modifiedTemplate, sections: updatedSections });
  };

  const moveUp = (index: number) => {
    if (index === 0) return; // If it's the first item, it can't be moved up
    const updatedSections = [...sections];
    const temp = updatedSections[index];
    updatedSections[index] = updatedSections[index - 1];
    updatedSections[index - 1] = temp;
    setSections(updatedSections);
    setModifiedTemplate({ ...modifiedTemplate, sections: updatedSections });
  };

  const moveDown = (index: number) => {
    if (index === sections.length - 1) return; // If it's the last item, it can't be moved down
    const updatedSections = [...sections];
    const temp = updatedSections[index];
    updatedSections[index] = updatedSections[index + 1];
    updatedSections[index + 1] = temp;
    setSections(updatedSections);
    setModifiedTemplate({ ...modifiedTemplate, sections: updatedSections });
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value =
      event.target.name === "max_tokens"
        ? Number(event.target.value)
        : event.target.value;

    setModifiedTemplate({
      ...modifiedTemplate,
      [event.target.name]: value,
    });
    onTemplateChange();
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setModifiedTemplate({
      ...modifiedTemplate,
      [event.target.name]: event.target.checked,
    });
  };

  const setCheckedStatusForSections = () => {
    const newSections = sections.map((section: AdminSection) => {
      const isSummarySection = template.summary_modules.includes(
        section.module_id
      );

      return {
        ...section,
        is_summary_section: isSummarySection,
      };
    });

    setSections(newSections);
  };

  useEffect(() => {
    setCheckedStatusForSections();
    fetchModulesData();
    setIsLoading(false);
  }, []);

  if (isLoading || !modules) return <div>Loading...</div>;

  return (
    <div className="m-4 p-4 bg-slate-100 rounded-md">
      <div className="flex mt-4 flex-col">
        <button
          onClick={() => {
            setShowUsersModal(true);
          }}
          className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
        >
          Assign to User
        </button>
        <button
          onClick={saveTemplate}
          className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
        >
          Save Template
        </button>
      </div>
      <div className="flex justify-between mb-4">
        <h1 className="text-2xl font-bold">{modifiedTemplate.template_name}</h1>
        <div className="text-sm text-gray-500">{template.template_id}</div>
      </div>
      <div className="mb-2 bg-white p-2 rounded-md shadow">
        <div className="mb-2">
          <label
            className="block text-sm text-gray-500"
            htmlFor="template_name"
          >
            Template Name
          </label>
          <input
            type="text"
            name="template_name"
            id="template_name"
            value={modifiedTemplate.template_name}
            onChange={handleInputChange}
            className="mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
        <div className="mb-2">
          <label className="block text-sm text-gray-500" htmlFor="public_name">
            Public Display Name
          </label>
          <input
            type="text"
            name="public_name"
            id="public_name"
            value={modifiedTemplate.public_name}
            onChange={handleInputChange}
            className="mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
          <label
            className="block text-sm text-gray-500"
            htmlFor="public_description"
          >
            Public Description
          </label>
          <input
            type="text"
            name="public_description"
            id="public_description"
            value={modifiedTemplate.public_description}
            onChange={handleInputChange}
            className="mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
          <label
            className="block text-sm text-gray-500"
            htmlFor="public_categories"
          >
            Public Categories (lowercase, comma-separated)
          </label>
          <input
            type="text"
            name="public_categories"
            id="public_categories"
            value={modifiedTemplate.public_categories}
            onChange={handleInputChange}
            className="mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>

        {sections.map((section, index) => (
          <SectionEditor
            key={index}
            section={section}
            onChange={(updatedSection) =>
              handleSectionChange(index, updatedSection)
            }
            modules={modules}
            onMoveUp={() => moveUp(index)}
            onMoveDown={() => moveDown(index)}
            onRemoveSection={() => removeSection(index)}
          />
        ))}
        <div className="flex justify-center mt-4">
          <button
            onClick={addSection}
            className="mt-4 px-4 py-2 bg-slate-500 text-white rounded hover:bg-blue-700"
          >
            Add New Section
          </button>
        </div>
      </div>
      <div className="flex justify-center mt-4">
        <button
          onClick={saveTemplate}
          className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
        >
          Save Template
        </button>
      </div>
      {isSaving && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="p-6 bg-white rounded shadow-xl text-xl font-bold">
            Saving...
          </div>
        </div>
      )}
      <TemplateToUserModal
        isOpen={showUsersModal}
        onClose={() => {
          setShowUsersModal(false);
        }}
        template={template}
        type="public"
      />
    </div>
  );
};

import { useNavigate } from "react-router-dom";
import { formatDate } from "../utils/utils";
import { PatientNoteType } from "../types/types";

export const PatientNote = ({ note_id, timestamp }: PatientNoteType) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/note/${note_id}`);
  };

  return (
    <div
      className="relative p-4 m-4 border-2 border-gray-200 rounded-lg  hover:border-purple-400 hover:cursor-pointer transition-colors"
      onClick={handleClick}
    >
      <div className="flex justify-between">
        <div className="text-4xl font-bold">{formatDate(timestamp)}</div>
        <div className="text-black/20">{note_id}</div>
      </div>
    </div>
  );
};

import React from 'react';

// Define a type for the error object
interface ErrorObject {
  error: string;
}

// Define props for the ErrorPage component
interface ErrorPageProps {
  errors?: ErrorObject[];
  error_message?: string;
}

export const ErrorPage: React.FC<ErrorPageProps> = ({ errors = [], error_message = "" }) => {
  return (
    <div className="p-8 rounded-lg flex flex-col items-center justify-center h-full w-full">
      <p className="text-gray-800 font-bold text-2xl">Oops! Something went wrong.</p>
      <p className="text-gray-600 mt-4 text-center">
        {error_message}
      </p>
      <p className="text-gray-600 mt-4 text-center">
        If the problem persists, please contact <a href="mailto:support@jotpsych.com" className="text-blue-600 underline">support@jotpsych.com</a> for further assistance.
      </p>
      {errors.length > 0 && (
        <div className="mt-4 w-full flex flex-col items-center">
          <p className="text-gray-800 font-bold text-lg">Error Details:</p>
          <div className="mt-2 p-4 w-full bg-red-100 border border-red-400 rounded-lg">
            <ul className="list-disc list-inside text-red-700 space-y-2">
              {errors.map((error: ErrorObject, index: number) => (
                <li key={index} className="mt-1 break-words">
                  {error.error}
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

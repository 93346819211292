import { Alarm, Assignment } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { formatDate, formatDuration } from "../utils/utils";
import React, { ReactNode } from "react";
import { NoteDataType } from "../Views/NoteView";

interface NoteMetadataProps {
  noteData: NoteDataType;
  setOpenTemplateRegenerateModal: (boolean: boolean) => void;
}

export const NoteMetadata = ({
  noteData,
  setOpenTemplateRegenerateModal,
}: NoteMetadataProps) => {
  const Divider = () => (
    <Box sx={{ width: "2px", height: "24px", bgcolor: "divider", mx: 1 }} />
  );

  const MetadataItem: React.FC<{ children: React.ReactNode }> = ({
    children,
  }) => (
    <Box sx={{ display: "flex", alignItems: "center", my: 0.5, mr: 1 }}>
      {children}
    </Box>
  );

  return (
    <Box
      sx={{ display: "flex", flexWrap: "wrap", alignItems: "center", gap: 1 }}
    >
      <MetadataItem>
        <Typography variant="subtitle2">
          {noteData.issuing_user_name}
        </Typography>
      </MetadataItem>

      <Divider />

      <MetadataItem>
        <Typography variant="subtitle2">
          {formatDate(noteData.time_uploaded, "datelong")}
        </Typography>
      </MetadataItem>

      <Divider />

      <MetadataItem>
        <Typography variant="subtitle2">
          {noteData.transcription_start_timestamp
            ? `${formatDate(
                noteData.transcription_start_timestamp,
                "time"
              )} – ${formatDate(noteData.transcription_end_timestamp, "time")}`
            : formatDate(noteData.time_uploaded, "time")}
        </Typography>
      </MetadataItem>

      {noteData.transcription_length_seconds && (
        <>
          <Divider />
          <MetadataItem>
            <Alarm sx={{ mr: 0.5 }} fontSize="small" />
            <Typography variant="subtitle2">
              {formatDuration(noteData.transcription_length_seconds)}
            </Typography>
          </MetadataItem>
        </>
      )}

      {noteData.template && (
        <>
          <Divider />
          <MetadataItem>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                "&:hover": { opacity: 0.8 },
              }}
              onClick={() => setOpenTemplateRegenerateModal(true)}
            >
              <Assignment sx={{ mr: 0.5 }} fontSize="small" />
              <Typography
                variant="subtitle2"
                sx={{ textDecoration: "underline" }}
              >
                {noteData.template.template_name}
              </Typography>
            </Box>
          </MetadataItem>
        </>
      )}
    </Box>
  );
};

import React from "react";
import { AdminFeedbackEvent } from "../../types/types";
import ThumbUpIconFilled from "@mui/icons-material/ThumbUp";
import ThumbDownIconFilled from "@mui/icons-material/ThumbDown";
import { formatDate } from "../../utils/utils";
import { NoteSection } from "../NoteSection";

interface SectionFeedbackDisplayBoxProps {
  feedbackEvents: AdminFeedbackEvent[];
  showContent?: boolean;
}

const SectionFeedbackDisplayBox: React.FC<SectionFeedbackDisplayBoxProps> = ({
  feedbackEvents,
  showContent = false,
}) => {
  const getIconForRating = (rating: number) => {
    switch (rating) {
      case 0:
        return <ThumbDownIconFilled />;
      case 1:
        return <ThumbUpIconFilled />;
      default:
        return null;
    }
  };

  return (
    <div className="bg-white shadow-lg rounded-lg p-6 mt-4 outline-dashed outline-slate-200">
      <div className="flex justify-between items-start mb-4">
        <h3 className="text-xl font-bold">Section Feedback</h3>
        <h4 className="text-xs">{feedbackEvents[0].section_id}</h4>
        {feedbackEvents[0]?.timestamp && (
          <span className="text-gray-400 text-sm">
            {formatDate(feedbackEvents[0].timestamp)}
          </span>
        )}
      </div>
      {feedbackEvents.map((feedback, index) => (
        <div
          key={index}
          className="border-b pb-2 mb-2 last:border-0 last:pb-0 last:mb-0"
        >
          {showContent && feedback.section_rating !== (undefined || null) && (
            <NoteSection
              heading={feedback.section_content.name}
              text={feedback.section_content.text}
              isEdited={false}
              isAdded={false}
              handleAdd={() => {}}
              isSaving={false}
            />
          )}
          {feedback.section_rating !== (undefined || null) && (
            <div className="flex items-center">
              <span className="text-gray-600">Rating:</span>
              <span className="text-lg font-semibold ml-2">
                {getIconForRating(feedback.section_rating!)}
              </span>
            </div>
          )}
          {feedback.section_comment && (
            <div>
              <span className="text-gray-600">Comment:</span>
              <div className="mt-1 text-gray-700">
                {feedback.section_comment}
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default SectionFeedbackDisplayBox;

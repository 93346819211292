import React, { useEffect, useState } from "react";
import { AdminModule, AdminTemplate, AdminUser } from "../../types/types";
import { UsersLoader } from "../../loaders/UsersLoader";
import { TemplatesLoader } from "../../loaders/TemplatesLoader";
import { ModulesLoader } from "../../loaders/ModulesLoader";
import { CustomPromptsForUserLoader } from "../../loaders/CustomPromptsLoader";
import APIService from "../../services/APIService";
import { useUser } from "../../context/user";

export const CustomPromptEditor: React.FC = () => {
  const { getAccessToken } = useUser();
  const [selectedUserId, setSelectedUserId] = useState<string>("");
  const [customPrompts, setCustomPrompts] = useState<any>();
  const [usersList, setUsersList] = useState<AdminUser[]>();
  const [templatesList, setTemplatesList] = useState<AdminTemplate[]>();
  const [modulesList, setModulesList] = useState<AdminModule[]>();
  const [spinning, setSpinning] = useState<boolean>(false);

  const handleSave = async () => {
    console.log("Saving the current state of customPrompts:", customPrompts);
    setSpinning(true);
    const accessToken = await getAccessToken();

    const response = await APIService.makeAPIPostRequest({
      requestString: "/user/updateCustomPromptsForUser",
      accessToken: accessToken,
      body: {
        target_user_id: selectedUserId,
        custom_prompts: customPrompts,
      },
    });

    if (response.ok) {
      const data = response.value;
      console.log(data);
      setSpinning(false);
    } else {
      setSpinning(false);
    }

    console.log(response);
  };

  const handleUserSelection = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const userId = e.target.value;
    if (userId) {
      setSelectedUserId(userId);
      fetchCustomPromptsForUser(userId);
    }
  };

  const fetchCustomPromptsForUser = async (userId: string) => {
    if (userId) {
      const customPrompts = await CustomPromptsForUserLoader(userId);
      console.log("custom prompts", customPrompts);
      setCustomPrompts(customPrompts);
    }
  };

  const fetchUsers = async () => {
    const usersList = await UsersLoader();
    setUsersList(usersList);
  };

  const fetchTemplates = async () => {
    const templatesList = await TemplatesLoader();
    setTemplatesList(templatesList);
  };

  const fetchModules = async () => {
    const modulesList = await ModulesLoader();
    setModulesList(modulesList);
  };

  const addPrompt = (
    category: string,
    key: string,
    value?: string,
    subKey?: string
  ) => {
    const newPrompts = { ...customPrompts };

    console.log(newPrompts);

    if (!newPrompts[category]) {
      newPrompts[category] = {};
    }

    if (!newPrompts[category][key]) {
      newPrompts[category][key] = {};
    }

    if (subKey !== undefined && value !== undefined) {
      newPrompts[category][key][subKey] = value;
    } else if (value !== undefined) {
      newPrompts[category][key] = value;
    }

    console.log(newPrompts);

    setCustomPrompts(newPrompts);
  };

  const removePrompt = (category: string, key: string, subKey?: string) => {
    const newPrompts = { ...customPrompts };

    console.log(category, key, subKey);
    console.log(newPrompts);

    if (subKey !== undefined) {
      console.log("is sub key");
      delete newPrompts[category][key][subKey];
      if (Object.keys(newPrompts[category][key]).length === 0) {
        delete newPrompts[category][key]; // Optionally remove the key if no subKeys are left
      }
    } else {
      console.log("deleting universal");
      delete newPrompts[category][key];
    }

    console.log(newPrompts);

    setCustomPrompts(newPrompts);
  };

  useEffect(() => {
    fetchUsers();
    fetchTemplates();
    fetchModules();
  }, []);

  return (
    <div>
      <h1>Custom Prompts Editor</h1>

      <div className="mb-4">
        <select
          value={selectedUserId}
          onChange={handleUserSelection}
          className="max-w-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
        >
          <option value="">Select a user to edit custom prompts</option>
          {usersList?.map((user, index) => (
            <option key={index} value={user._id}>
              {user.user_info?.name ? user.user_info?.name : user._id}
            </option>
          ))}
        </select>
      </div>

      {selectedUserId && templatesList && modulesList && (
        <>
          {/* The rest of your component appears only when a user is selected */}
          <div>
            <UniversalEditor
              title="universal"
              prompts={customPrompts?.["universal"] ?? {}}
              handleAddPrompt={addPrompt}
              handleModifyPrompt={addPrompt}
              handleRemovePrompt={removePrompt}
            />
          </div>

          <div>
            <SubKeyEditor
              title="template"
              itemsList={templatesList} // Universal Prompts might not have an items list
              prompts={customPrompts?.["template"] ?? {}}
              handleAddPrompt={addPrompt}
              handleModifyPrompt={addPrompt}
              handleRemovePrompt={removePrompt}
            />
          </div>

          <div>
            <SubKeyEditor
              title="module"
              itemsList={modulesList} // Universal Prompts might not have an items list
              prompts={customPrompts?.["module"] ?? {}}
              handleAddPrompt={addPrompt}
              handleModifyPrompt={addPrompt}
              handleRemovePrompt={removePrompt}
            />
          </div>

          <button onClick={handleSave}>Save</button>
          {spinning && <Spinner />}
        </>
      )}
    </div>
  );
};

interface SubKeyEditorProps {
  title: string;
  itemsList: any[]; // Can be a list of AdminTemplate, AdminModule, or AdminUser
  prompts: { [key: string]: { [subKey: string]: string } };
  handleAddPrompt: (
    category: string,
    key: string,
    value: string,
    subKey: string
  ) => void;
  handleModifyPrompt: (
    category: string,
    key: string,
    value: string,
    subKey: string
  ) => void;
  handleRemovePrompt: (category: string, key: string, subKey: string) => void;
}

export const SubKeyEditor: React.FC<SubKeyEditorProps> = ({
  title,
  itemsList,
  prompts,
  handleAddPrompt,
  handleModifyPrompt,
  handleRemovePrompt,
}) => {
  const [selectedItem, setSelectedItem] = useState<string>("");
  const [key, setKey] = useState<string>("");
  const [subKey, setSubKey] = useState<string>("");
  const [value, setValue] = useState<string>("");

  return (
    <div>
      <h2>{title}</h2>
      {itemsList && itemsList.length > 0 && (
        <div className="mb-4">
          <select
            value={selectedItem}
            onChange={(e) => setSelectedItem(e.target.value)}
            className="max-w-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          >
            <option value="">
              Select a {title.toLowerCase()} to edit prompts for
            </option>
            {itemsList.map((item, index) => {
              const itemId = item[`${title}_id`];
              const star = prompts
                ? Object.keys(prompts).includes(itemId)
                : false;
              return (
                <option key={index} value={itemId}>
                  {item[`${title}_name`]} {star && "★"}
                </option>
              );
            })}
          </select>
        </div>
      )}

      {selectedItem && (
        <div>
          {/* Existing Prompts */}
          <div>
            {Object.keys(prompts)
              .filter((k) => k === selectedItem)
              .map((key) => (
                <div key={key}>
                  {key}:
                  {Object.keys(prompts[key]).map((subKey) => (
                    <div key={subKey}>
                      {subKey} - {prompts[key][subKey]}
                      <button
                        onClick={() => handleRemovePrompt(title, key, subKey)}
                      >
                        Remove
                      </button>
                    </div>
                  ))}
                </div>
              ))}
          </div>

          {/* Add / Modify Prompts */}
          <div>
            <input
              type="text"
              placeholder="Sub-Key"
              value={subKey}
              onChange={(e) => setSubKey(e.target.value)}
            />
            <input
              type="text"
              placeholder="Value"
              value={value}
              onChange={(e) => setValue(e.target.value)}
            />
            <button
              onClick={() =>
                handleAddPrompt(title, selectedItem, value, subKey)
              }
            >
              Add
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

interface UniversalEditorProps {
  title: string;
  prompts: { [key: string]: string };
  handleAddPrompt: (category: string, key: string, value: string) => void;
  handleModifyPrompt: (category: string, key: string, value: string) => void;
  handleRemovePrompt: (category: string, key: string) => void;
}

export const UniversalEditor: React.FC<UniversalEditorProps> = ({
  title,
  prompts,
  handleAddPrompt,
  handleModifyPrompt,
  handleRemovePrompt,
}) => {
  const [key, setKey] = useState<string>("");
  const [value, setValue] = useState<string>("");

  return (
    <div>
      <h2>{title}</h2>

      {/* Existing Prompts */}
      <div>
        {Object.keys(prompts).map((key) => (
          <div key={key}>
            {key} - {prompts[key]}
            <button onClick={() => handleRemovePrompt(title, key)}>
              Remove
            </button>
          </div>
        ))}
      </div>

      {/* Add / Modify Prompts */}
      <div>
        <input
          type="text"
          placeholder="Key"
          value={key}
          onChange={(e) => setKey(e.target.value)}
        />
        <input
          type="text"
          placeholder="Value"
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
        <button onClick={() => handleAddPrompt(title, key, value)}>Add</button>
      </div>
    </div>
  );
};

function Spinner() {
  return (
    <div className="flex justify-center items-center">
      <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-gray-900"></div>
    </div>
  );
}

import { RefObject } from "react";
import { useUIState } from "../../context/uiState";
import { Box } from "@mui/material";

export const MainContainer = ({
  children,
  scrollRef,
  ...props
}: {
  children: any;
  scrollRef?: RefObject<HTMLDivElement>;
}) => {
  const { state: UIState, setState } = useUIState();

  return (
    <Box
      component="main"
      className="p-4 md:p-6 lg:p-8 lg:ml-64"
      sx={{
        '@media (min-width: 900px)': {
          padding: '1.5rem',
          marginLeft: '10rem'
        }
      }}
      ref={scrollRef}
      {...props}
    >
      {!UIState.sidebar.isHidden && (
        <div className="fixed w-screen h-screen top-0 left-0 bg-black/40"></div>
      )}
      {children}
    </Box>
  );
};

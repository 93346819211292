import React, { useState, useEffect } from "react";
import { Box, Typography, Button, IconButton } from "@mui/material";
import UploadModal from "../components/CustomTemplateUpload"; // Make sure this import path is correct
import { Close } from "@mui/icons-material";

interface TemplateHeaderBoxProps {
  headerText: string;
  description: string;
  contact?: boolean;
  children?: React.ReactNode;
}

const TemplateHeaderBox: React.FC<TemplateHeaderBoxProps> = ({
  headerText,
  description,
  contact,
  children,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isBannerVisible, setIsBannerVisible] = useState(true);

  useEffect(() => {
    const bannerDismissed = sessionStorage.getItem("bannerDismissed");
    if (bannerDismissed === "true") {
      setIsBannerVisible(false);
    }
  }, []);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const handleDismissBanner = () => {
    setIsBannerVisible(false);
    sessionStorage.setItem("bannerDismissed", "true");
  };

  return (
    <Box
      className="container"
      sx={{
        display: "flex",
        flexDirection: "column",
        bgcolor: "backgroundColors.primary",
        border: 1,
        borderColor: "borderColors.primary",
        borderRadius: "0.75rem",
        marginY: { xs: "2rem", sm: "2rem" },
        marginX: { xs: ".5rem", sm: "2rem" },
      }}
    >
      {isBannerVisible && (
        <Box
          className="header-container"
          sx={{
            backgroundColor: "backgroundColors.primary",
            borderBottom: 1,
            borderColor: "borderColors.primary",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            padding: "1rem",
            marginBottom: "1rem",
            position: "relative",
          }}
        >
          <IconButton
            onClick={handleDismissBanner}
            sx={{
              position: "absolute",
              right: "0.5rem",
              top: "0.5rem",
            }}
          >
            <Close />
          </IconButton>
          <Typography
            variant="h5"
            sx={{
              fontWeight: "bold",
              mb: 1,
            }}
          >
            {headerText}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
              width: "100%",
            }}
          >
            <Typography
              variant="body1"
              sx={{ color: "textColors.secondary", width: "100%" }}
            >
              {description}
              {contact && (
                <span>
                  <a
                    href={`mailto:${"support@jotpsych.com"}?subject=${encodeURIComponent(
                      "SmartScribe: New Section Request"
                    )}&body=${encodeURIComponent(
                      `Hi! I'd like to request a new section.
                      \nName:
                      \nDescription of desired section:
                      `
                    )}`}
                    style={{ color: "blue", textDecoration: "underline" }}
                  >
                    Contact us
                  </a>
                  {" to request a new section."}
                </span>
              )}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenModal}
              sx={{ ml: 2 }}
            >
              Upload Your Own Template
            </Button>
          </Box>
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          height: {
            xs: "auto",
          },
          flexDirection: { xs: "column-reverse", md: "row" },
        }}
      >
        {children}
      </Box>
      <UploadModal open={isModalOpen} onClose={handleCloseModal} />
    </Box>
  );
};

export default TemplateHeaderBox;

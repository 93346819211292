import { Params, json } from "react-router-dom";
import { Patient } from "../types/types";
import { API_BASE_URL } from "../services/APIService";

export const PatientsLoader = async (): Promise<Patient[]> => {
  const accessToken = localStorage.getItem("accessToken");
  const patientsList = await fetch(`${API_BASE_URL}/user/getPatients`, {
    method: "get",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    }),
  })
    .then((res) => {
      if (res.status === 401) {
        throw json(
          {
            message: "You are not authorized to access this resource.",
            cta: "Login",
          },
          { status: 401 }
        );
      }

      return res.json();
    })
    .then((data: { patients: Patient[] }) => {
      console.log(data.patients);
      return data.patients;
    })
    .catch(() => {
      throw json(
        {
          message: "There has been an error. Please login again.",
          cta: "Login",
        },
        { status: 500 }
      );
    });

  return patientsList;
};

export const PatientLoader = async ({
  params,
}: {
  params: Params;
}): Promise<Patient> => {
  const accessToken = localStorage.getItem("accessToken");
  const patient = await fetch(
    `${API_BASE_URL}/patient/getPatient?patient_id=${params.patientId}`,
    {
      method: "get",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      }),
    }
  )
    .then((res) => {
      if (res.status === 401) {
        throw json(
          {
            message: "You are not authorized to access this resource.",
            cta: "Login",
          },
          { status: 401 }
        );
      }

      return res.json();
    })
    .then((data: { patient: Patient; most_recent_note: any }) => {
      const returnData = {
        ...data.patient,
        most_recent_note: data.most_recent_note,
      };
      console.log(returnData);
      return returnData;
    })
    .catch(() => {
      throw json(
        {
          message: "There has been an error. Please login again.",
          cta: "Login",
        },
        { status: 500 }
      );
    });

  return patient;
};

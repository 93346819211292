import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

export const SVGIcon = ({ name, ...rest }: { name: string }) => {
  const icons = {
    help: <HelpOutlineIcon color="primary" fontSize="large" />,
    delete: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="#F67777"
      >
        <path d="M 10 2 L 9 3 L 5 3 C 4.4 3 4 3.4 4 4 C 4 4.6 4.4 5 5 5 L 7 5 L 17 5 L 19 5 C 19.6 5 20 4.6 20 4 C 20 3.4 19.6 3 19 3 L 15 3 L 14 2 L 10 2 z M 5 7 L 5 20 C 5 21.1 5.9 22 7 22 L 17 22 C 18.1 22 19 21.1 19 20 L 19 7 L 5 7 z M 9 9 C 9.6 9 10 9.4 10 10 L 10 19 C 10 19.6 9.6 20 9 20 C 8.4 20 8 19.6 8 19 L 8 10 C 8 9.4 8.4 9 9 9 z M 15 9 C 15.6 9 16 9.4 16 10 L 16 19 C 16 19.6 15.6 20 15 20 C 14.4 20 14 19.6 14 19 L 14 10 C 14 9.4 14.4 9 15 9 z"></path>
      </svg>
    ),
    add: (
      <svg
        viewBox="0 0 24 24"
        width="24px"
        height="24px"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
        <g
          id="SVGRepo_tracerCarrier"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></g>
        <g id="SVGRepo_iconCarrier">
          {" "}
          <path
            d="M9 12H15"
            stroke="#36229E"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>{" "}
          <path
            d="M12 9L12 15"
            stroke="#36229E"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>{" "}
          <path
            d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
            stroke="#36229E"
            strokeWidth="2"
          ></path>{" "}
        </g>
      </svg>
    ),
    "check-filled": (
      <svg
        fill="#000000"
        viewBox="0 0 24 24"
        width="24px"
        height="24px"
        id="check-circle"
        data-name="Flat Color"
        xmlns="http://www.w3.org/2000/svg"
        className="icon flat-color"
      >
        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
        <g
          id="SVGRepo_tracerCarrier"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></g>
        <g id="SVGRepo_iconCarrier">
          <circle id="primary" cx="12" cy="12" r="10" fill="#36229E"></circle>
          <path
            id="secondary"
            d="M11,16a1,1,0,0,1-.71-.29l-3-3a1,1,0,1,1,1.42-1.42L11,13.59l4.29-4.3a1,1,0,0,1,1.42,1.42l-5,5A1,1,0,0,1,11,16Z"
            fill="#ffffff"
          ></path>
        </g>
      </svg>
    ),
    copy: (
      <svg
        width="24px"
        height="24px"
        viewBox="0 0 64 64"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="3"
        stroke="#000000"
        fill="none"
      >
        <rect x="11.13" y="17.72" width="33.92" height="36.85" rx="2.5" />
        <path d="M19.35,14.23V13.09a3.51,3.51,0,0,1,3.33-3.66H49.54a3.51,3.51,0,0,1,3.33,3.66V42.62a3.51,3.51,0,0,1-3.33,3.66H48.39" />
      </svg>
    ),
    "check-unfilled": (
      <svg
        viewBox="0 0 24 24"
        width="24px"
        height="24px"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
        <g
          id="SVGRepo_tracerCarrier"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></g>
        <g id="SVGRepo_iconCarrier">
          {" "}
          <path
            d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
            stroke="#36229E"
            strokeWidth="2"
          ></path>{" "}
          <path
            d="M9 12L10.6828 13.6828V13.6828C10.858 13.858 11.142 13.858 11.3172 13.6828V13.6828L15 10"
            stroke="#36229E"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>{" "}
        </g>
      </svg>
    ),
  } as { [key: string]: JSX.Element };

  return <div {...rest}>{icons[name]}</div>;
};

export const IOSQRCode = () => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 100 100"
      enableBackground="new 0 0 100 100"
      xmlSpace="preserve"
      style={{ width: "100%", height: "100%" }} // Make SVG responsive
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        version="1.1"
        width="100"
        height="100"
        viewBox="0 0 2000 2000"
        x="0"
        y="0"
        shape-rendering="crispEdges"
      >
        <defs />
        <rect x="0" y="0" width="2000" height="2000" fill="#ffffff" />
        <svg
          viewBox="0 0 4 4"
          x="712"
          y="200"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M2,4H4V2A2,2,0,0,1,2,4Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="776"
          y="200"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M4,4V2A2,2,0,0,0,0,2H0V4Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1032"
          y="200"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M2,4H4V2A2,2,0,0,1,2,4Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1096"
          y="200"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M4,4V2A2,2,0,0,0,0,2H0V4Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1224"
          y="200"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path
            fill="#36229e"
            d="M0,2A2,2,0,0,0,2,4H2A2,2,0,0,0,2,0H2A2,2,0,0,0,0,2Z"
          />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="712"
          y="264"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M4,0H2A2,2,0,0,0,2,4H4Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="776"
          y="264"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="840"
          y="264"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M2,4H4V2A2,2,0,0,1,2,4Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="840"
          y="264"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,2V4H2A2,2,0,0,1,0,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="904"
          y="264"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,2V4H4V0H2A2,2,0,0,0,0,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="968"
          y="264"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1032"
          y="264"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1096"
          y="264"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M2,4H0V0H4V2A2,2,0,0,1,2,4Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="712"
          y="328"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M4,2V0H2A2,2,0,0,1,4,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="712"
          y="328"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M2,4H4V2A2,2,0,0,1,2,4Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="776"
          y="328"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="840"
          y="328"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="904"
          y="328"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="968"
          y="328"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,2V0H2A2,2,0,0,0,0,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="712"
          y="392"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M4,0H2A2,2,0,0,0,2,4H4Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="776"
          y="392"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M2,4H0V0H4V2A2,2,0,0,1,2,4Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="840"
          y="392"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,2V0H2A2,2,0,0,0,0,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="840"
          y="392"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M4,2V0H2A2,2,0,0,1,4,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="904"
          y="392"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,0V2A2,2,0,0,0,4,2H4V0Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1032"
          y="392"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path
            fill="#36229e"
            d="M0,2A2,2,0,0,0,2,4H2A2,2,0,0,0,2,0H2A2,2,0,0,0,0,2Z"
          />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1160"
          y="392"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,2V4H4V0H2A2,2,0,0,0,0,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1224"
          y="392"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M4,2V4H0V0H2A2,2,0,0,1,4,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="840"
          y="456"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M4,4V2A2,2,0,0,0,0,2H0V4Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="968"
          y="456"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path
            fill="#36229e"
            d="M0,2A2,2,0,0,0,2,4H2A2,2,0,0,0,2,0H2A2,2,0,0,0,0,2Z"
          />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1096"
          y="456"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M2,4H4V2A2,2,0,0,1,2,4Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1160"
          y="456"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1224"
          y="456"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M2,4H0V0H4V2A2,2,0,0,1,2,4Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="712"
          y="520"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M4,4V2A2,2,0,0,0,0,2H0V4Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="840"
          y="520"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1032"
          y="520"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M4,0H2A2,2,0,0,0,2,4H4Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1096"
          y="520"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1160"
          y="520"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M2,4H0V0H4V2A2,2,0,0,1,2,4Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1224"
          y="520"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,2V0H2A2,2,0,0,0,0,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="712"
          y="584"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,0V2A2,2,0,0,0,4,2H4V0Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="840"
          y="584"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,0V2A2,2,0,0,0,4,2H4V0Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="968"
          y="584"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M4,4V2A2,2,0,0,0,0,2H0V4Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1032"
          y="584"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M4,2V0H2A2,2,0,0,1,4,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1096"
          y="584"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,0V2A2,2,0,0,0,4,2H4V0Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1160"
          y="584"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,2V0H2A2,2,0,0,0,0,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1224"
          y="584"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M4,4V2A2,2,0,0,0,0,2H0V4Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="968"
          y="648"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1224"
          y="648"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,0V2A2,2,0,0,0,4,2H4V0Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="200"
          y="712"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,2V4H4V0H2A2,2,0,0,0,0,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="264"
          y="712"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="328"
          y="712"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="392"
          y="712"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="456"
          y="712"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,4H2A2,2,0,0,0,2,0H0Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="584"
          y="712"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M4,0H2A2,2,0,0,0,2,4H4Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="648"
          y="712"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="712"
          y="712"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="776"
          y="712"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="840"
          y="712"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,4H2A2,2,0,0,0,2,0H0Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="968"
          y="712"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,0V2A2,2,0,0,0,4,2H4V0Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1096"
          y="712"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,2V4H4V0H2A2,2,0,0,0,0,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1160"
          y="712"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M4,2V4H0V0H2A2,2,0,0,1,4,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1224"
          y="712"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M2,4H4V2A2,2,0,0,1,2,4Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1224"
          y="712"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,2V4H2A2,2,0,0,1,0,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1288"
          y="712"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M4,4V2A2,2,0,0,0,0,2H0V4Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1416"
          y="712"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M4,4V2A2,2,0,0,0,0,2H0V4Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1480"
          y="712"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,2V4H2A2,2,0,0,1,0,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1544"
          y="712"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path
            fill="#36229e"
            d="M0,2A2,2,0,0,0,2,4H2A2,2,0,0,0,2,0H2A2,2,0,0,0,0,2Z"
          />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1672"
          y="712"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path
            fill="#36229e"
            d="M0,2A2,2,0,0,0,2,4H2A2,2,0,0,0,2,0H2A2,2,0,0,0,0,2Z"
          />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="200"
          y="776"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="264"
          y="776"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="328"
          y="776"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,2V0H2A2,2,0,0,0,0,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="328"
          y="776"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M4,2V0H2A2,2,0,0,1,4,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="328"
          y="776"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,2V4H2A2,2,0,0,1,0,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="392"
          y="776"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,0V2A2,2,0,0,0,4,2H4V0Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="456"
          y="776"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,2V0H2A2,2,0,0,0,0,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="520"
          y="776"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path
            fill="#36229e"
            d="M0,2A2,2,0,0,0,2,4H2A2,2,0,0,0,2,0H2A2,2,0,0,0,0,2Z"
          />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="584"
          y="776"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M4,2V0H2A2,2,0,0,1,4,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="648"
          y="776"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,0V2A2,2,0,0,0,4,2H4V0Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="712"
          y="776"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,2V0H2A2,2,0,0,0,0,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="712"
          y="776"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M4,2V0H2A2,2,0,0,1,4,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="712"
          y="776"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M2,4H4V2A2,2,0,0,1,2,4Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="776"
          y="776"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="840"
          y="776"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,2V0H2A2,2,0,0,0,0,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1032"
          y="776"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M2,4H4V2A2,2,0,0,1,2,4Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1096"
          y="776"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1160"
          y="776"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1224"
          y="776"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1288"
          y="776"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1352"
          y="776"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,2V4H2A2,2,0,0,1,0,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1416"
          y="776"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M2,4H4V0H0V2A2,2,0,0,0,2,4Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1480"
          y="776"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M4,2V4H0V0H2A2,2,0,0,1,4,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1736"
          y="776"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path
            fill="#36229e"
            d="M0,2A2,2,0,0,0,2,4H2A2,2,0,0,0,2,0H2A2,2,0,0,0,0,2Z"
          />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="200"
          y="840"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="264"
          y="840"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="328"
          y="840"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M4,2V4H0V0H2A2,2,0,0,1,4,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="584"
          y="840"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path
            fill="#36229e"
            d="M0,2A2,2,0,0,0,2,4H2A2,2,0,0,0,2,0H2A2,2,0,0,0,0,2Z"
          />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="712"
          y="840"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,2V4H4V0H2A2,2,0,0,0,0,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="776"
          y="840"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="840"
          y="840"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M2,4H4V2A2,2,0,0,1,2,4Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="840"
          y="840"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,2V4H2A2,2,0,0,1,0,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="904"
          y="840"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,2V4H4V0H2A2,2,0,0,0,0,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="968"
          y="840"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1032"
          y="840"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1096"
          y="840"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1160"
          y="840"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M2,4H0V0H4V2A2,2,0,0,1,2,4Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1224"
          y="840"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,2V0H2A2,2,0,0,0,0,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1224"
          y="840"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M4,2V0H2A2,2,0,0,1,4,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1224"
          y="840"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M2,4H4V2A2,2,0,0,1,2,4Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1288"
          y="840"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1352"
          y="840"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,4H2A2,2,0,0,0,2,0H0Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1416"
          y="840"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M4,2V0H2A2,2,0,0,1,4,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1480"
          y="840"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,0V2A2,2,0,0,0,4,2H4V0Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1608"
          y="840"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path
            fill="#36229e"
            d="M0,2A2,2,0,0,0,2,4H2A2,2,0,0,0,2,0H2A2,2,0,0,0,0,2Z"
          />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="200"
          y="904"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="264"
          y="904"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="328"
          y="904"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M2,4H0V0H4V2A2,2,0,0,1,2,4Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="456"
          y="904"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,2V4H4V0H2A2,2,0,0,0,0,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="520"
          y="904"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,4H2A2,2,0,0,0,2,0H0Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="712"
          y="904"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M2,4H4V0H0V2A2,2,0,0,0,2,4Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="776"
          y="904"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="840"
          y="904"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="904"
          y="904"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="968"
          y="904"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,2V0H2A2,2,0,0,0,0,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1224"
          y="904"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M4,0H2A2,2,0,0,0,2,4H4Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1288"
          y="904"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M2,4H0V0H4V2A2,2,0,0,1,2,4Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1352"
          y="904"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,2V0H2A2,2,0,0,0,0,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="200"
          y="968"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="264"
          y="968"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M2,4H0V0H4V2A2,2,0,0,1,2,4Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="328"
          y="968"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,2V0H2A2,2,0,0,0,0,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="456"
          y="968"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,0V2A2,2,0,0,0,4,2H4V0Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="520"
          y="968"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,2V0H2A2,2,0,0,0,0,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="584"
          y="968"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path
            fill="#36229e"
            d="M0,2A2,2,0,0,0,2,4H2A2,2,0,0,0,2,0H2A2,2,0,0,0,0,2Z"
          />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="712"
          y="968"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M4,2V0H2A2,2,0,0,1,4,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="712"
          y="968"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M2,4H4V2A2,2,0,0,1,2,4Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="776"
          y="968"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="840"
          y="968"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,2V0H2A2,2,0,0,0,0,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="840"
          y="968"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M4,2V0H2A2,2,0,0,1,4,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="840"
          y="968"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,2V4H2A2,2,0,0,1,0,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="904"
          y="968"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,0V2A2,2,0,0,0,4,2H4V0Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1160"
          y="968"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path
            fill="#36229e"
            d="M0,2A2,2,0,0,0,2,4H2A2,2,0,0,0,2,0H2A2,2,0,0,0,0,2Z"
          />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1288"
          y="968"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M2,4H4V2A2,2,0,0,1,2,4Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1352"
          y="968"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,2V4H4V0H2A2,2,0,0,0,0,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1416"
          y="968"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1480"
          y="968"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1544"
          y="968"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,4H2A2,2,0,0,0,2,0H0Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1672"
          y="968"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M4,0H2A2,2,0,0,0,2,4H4Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1736"
          y="968"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,4H2A2,2,0,0,0,2,0H0Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="200"
          y="1032"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="264"
          y="1032"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,2V0H2A2,2,0,0,0,0,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="328"
          y="1032"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,2V4H4V0H2A2,2,0,0,0,0,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="392"
          y="1032"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,4H2A2,2,0,0,0,2,0H0Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="456"
          y="1032"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M2,4H4V2A2,2,0,0,1,2,4Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="520"
          y="1032"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M4,4V2A2,2,0,0,0,0,2H0V4Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="584"
          y="1032"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,2V4H2A2,2,0,0,1,0,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="648"
          y="1032"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M4,0H2A2,2,0,0,0,2,4H4Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="712"
          y="1032"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="776"
          y="1032"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="840"
          y="1032"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,4H2A2,2,0,0,0,2,0H0Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="968"
          y="1032"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path
            fill="#36229e"
            d="M0,2A2,2,0,0,0,2,4H2A2,2,0,0,0,2,0H2A2,2,0,0,0,0,2Z"
          />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1096"
          y="1032"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M4,4V2A2,2,0,0,0,0,2H0V4Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1224"
          y="1032"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M4,0H2A2,2,0,0,0,2,4H4Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1288"
          y="1032"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1352"
          y="1032"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1416"
          y="1032"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1480"
          y="1032"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1544"
          y="1032"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,2V0H2A2,2,0,0,0,0,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="200"
          y="1096"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="328"
          y="1096"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="392"
          y="1096"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,2V0H2A2,2,0,0,0,0,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="456"
          y="1096"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,2V4H4V0H2A2,2,0,0,0,0,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="520"
          y="1096"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="584"
          y="1096"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,4H2A2,2,0,0,0,2,0H0Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="648"
          y="1096"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M4,2V0H2A2,2,0,0,1,4,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="648"
          y="1096"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M2,4H4V2A2,2,0,0,1,2,4Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="712"
          y="1096"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="776"
          y="1096"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="840"
          y="1096"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,2V0H2A2,2,0,0,0,0,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="840"
          y="1096"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,2V4H2A2,2,0,0,1,0,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1096"
          y="1096"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,0V2A2,2,0,0,0,4,2H4V0Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1224"
          y="1096"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M4,2V0H2A2,2,0,0,1,4,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1288"
          y="1096"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1352"
          y="1096"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M2,4H0V0H4V2A2,2,0,0,1,2,4Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1416"
          y="1096"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,2V0H2A2,2,0,0,0,0,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1416"
          y="1096"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M4,2V0H2A2,2,0,0,1,4,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1480"
          y="1096"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,0V2A2,2,0,0,0,4,2H4V0Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1608"
          y="1096"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M4,0H2A2,2,0,0,0,2,4H4Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1672"
          y="1096"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1736"
          y="1096"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M4,2V4H0V0H2A2,2,0,0,1,4,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="200"
          y="1160"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="328"
          y="1160"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="392"
          y="1160"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M2,4H4V2A2,2,0,0,1,2,4Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="392"
          y="1160"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,2V4H2A2,2,0,0,1,0,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="456"
          y="1160"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="520"
          y="1160"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,2V0H2A2,2,0,0,0,0,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="648"
          y="1160"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M4,0H2A2,2,0,0,0,2,4H4Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="712"
          y="1160"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="776"
          y="1160"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="840"
          y="1160"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M4,2V4H0V0H2A2,2,0,0,1,4,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="968"
          y="1160"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M4,4V2A2,2,0,0,0,0,2H0V4Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1032"
          y="1160"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,2V4H2A2,2,0,0,1,0,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1224"
          y="1160"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M2,4H4V2A2,2,0,0,1,2,4Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1288"
          y="1160"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1352"
          y="1160"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,2V0H2A2,2,0,0,0,0,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1352"
          y="1160"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,2V4H2A2,2,0,0,1,0,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1608"
          y="1160"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M4,2V0H2A2,2,0,0,1,4,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1672"
          y="1160"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M2,4H4V0H0V2A2,2,0,0,0,2,4Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1736"
          y="1160"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="200"
          y="1224"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,0V2A2,2,0,0,0,4,2H4V0Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="328"
          y="1224"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M2,4H4V0H0V2A2,2,0,0,0,2,4Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="392"
          y="1224"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="456"
          y="1224"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M2,4H0V0H4V2A2,2,0,0,1,2,4Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="584"
          y="1224"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path
            fill="#36229e"
            d="M0,2A2,2,0,0,0,2,4H2A2,2,0,0,0,2,0H2A2,2,0,0,0,0,2Z"
          />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="648"
          y="1224"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M4,2V0H2A2,2,0,0,1,4,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="712"
          y="1224"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="776"
          y="1224"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="840"
          y="1224"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M2,4H0V0H4V2A2,2,0,0,1,2,4Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="968"
          y="1224"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M2,4H4V0H0V2A2,2,0,0,0,2,4Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1032"
          y="1224"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1096"
          y="1224"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1160"
          y="1224"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1224"
          y="1224"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1288"
          y="1224"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1352"
          y="1224"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1416"
          y="1224"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1480"
          y="1224"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1544"
          y="1224"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,4H2A2,2,0,0,0,2,0H0Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1672"
          y="1224"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M4,2V0H2A2,2,0,0,1,4,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1736"
          y="1224"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="712"
          y="1288"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="776"
          y="1288"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,2V0H2A2,2,0,0,0,0,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="776"
          y="1288"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,2V4H2A2,2,0,0,1,0,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1032"
          y="1288"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M4,2V0H2A2,2,0,0,1,4,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1096"
          y="1288"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,0V2A2,2,0,0,0,4,2H4V0Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1160"
          y="1288"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,2V0H2A2,2,0,0,0,0,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1160"
          y="1288"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M4,2V0H2A2,2,0,0,1,4,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1224"
          y="1288"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1288"
          y="1288"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,2V0H2A2,2,0,0,0,0,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1416"
          y="1288"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M4,2V0H2A2,2,0,0,1,4,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1480"
          y="1288"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1544"
          y="1288"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,2V0H2A2,2,0,0,0,0,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1672"
          y="1288"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M2,4H4V2A2,2,0,0,1,2,4Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1736"
          y="1288"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="712"
          y="1352"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M2,4H4V0H0V2A2,2,0,0,0,2,4Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="776"
          y="1352"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M4,2V4H0V0H2A2,2,0,0,1,4,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="904"
          y="1352"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,2V4H4V0H2A2,2,0,0,0,0,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="968"
          y="1352"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1032"
          y="1352"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,4H2A2,2,0,0,0,2,0H0Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1224"
          y="1352"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1352"
          y="1352"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path
            fill="#36229e"
            d="M0,2A2,2,0,0,0,2,4H2A2,2,0,0,0,2,0H2A2,2,0,0,0,0,2Z"
          />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1480"
          y="1352"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1544"
          y="1352"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,2V4H2A2,2,0,0,1,0,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1608"
          y="1352"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M4,0H2A2,2,0,0,0,2,4H4Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1672"
          y="1352"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1736"
          y="1352"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M2,4H0V0H4V2A2,2,0,0,1,2,4Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="712"
          y="1416"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M4,2V0H2A2,2,0,0,1,4,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="776"
          y="1416"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,0V2A2,2,0,0,0,4,2H4V0Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="904"
          y="1416"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="968"
          y="1416"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,2V0H2A2,2,0,0,0,0,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="968"
          y="1416"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,2V4H2A2,2,0,0,1,0,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1032"
          y="1416"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M2,4H4V2A2,2,0,0,1,2,4Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1096"
          y="1416"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M4,4V2A2,2,0,0,0,0,2H0V4Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1224"
          y="1416"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1288"
          y="1416"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,2V4H2A2,2,0,0,1,0,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1416"
          y="1416"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M2,4H4V2A2,2,0,0,1,2,4Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1480"
          y="1416"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1544"
          y="1416"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M4,2V4H0V0H2A2,2,0,0,1,4,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="712"
          y="1480"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M4,4V2A2,2,0,0,0,0,2H0V4Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="904"
          y="1480"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M2,4H4V0H0V2A2,2,0,0,0,2,4Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="968"
          y="1480"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1032"
          y="1480"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1096"
          y="1480"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M2,4H0V0H4V2A2,2,0,0,1,2,4Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1160"
          y="1480"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M2,4H4V2A2,2,0,0,1,2,4Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1224"
          y="1480"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1288"
          y="1480"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1352"
          y="1480"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1416"
          y="1480"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1480"
          y="1480"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1544"
          y="1480"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M2,4H0V0H4V2A2,2,0,0,1,2,4Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="712"
          y="1544"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="904"
          y="1544"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M4,2V0H2A2,2,0,0,1,4,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="968"
          y="1544"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1032"
          y="1544"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,2V0H2A2,2,0,0,0,0,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1032"
          y="1544"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,2V4H2A2,2,0,0,1,0,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1160"
          y="1544"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M4,0H2A2,2,0,0,0,2,4H4Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1224"
          y="1544"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M2,4H0V0H4V2A2,2,0,0,1,2,4Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1288"
          y="1544"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,2V0H2A2,2,0,0,0,0,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1288"
          y="1544"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M4,2V0H2A2,2,0,0,1,4,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1352"
          y="1544"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,0V2A2,2,0,0,0,4,2H4V0Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1416"
          y="1544"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,2V0H2A2,2,0,0,0,0,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1416"
          y="1544"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M4,2V0H2A2,2,0,0,1,4,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1480"
          y="1544"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1544"
          y="1544"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,2V0H2A2,2,0,0,0,0,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1672"
          y="1544"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M4,0H2A2,2,0,0,0,2,4H4Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1736"
          y="1544"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M4,2V4H0V0H2A2,2,0,0,1,4,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="712"
          y="1608"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="776"
          y="1608"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M2,4H4V2A2,2,0,0,1,2,4Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="776"
          y="1608"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,2V4H2A2,2,0,0,1,0,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="840"
          y="1608"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M4,4V2A2,2,0,0,0,0,2H0V4Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="968"
          y="1608"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1032"
          y="1608"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1096"
          y="1608"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,4H2A2,2,0,0,0,2,0H0Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1416"
          y="1608"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M2,4H4V2A2,2,0,0,1,2,4Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1480"
          y="1608"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1544"
          y="1608"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,2V4H2A2,2,0,0,1,0,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1608"
          y="1608"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path
            fill="#36229e"
            d="M0,2A2,2,0,0,0,2,4H2A2,2,0,0,0,2,0H2A2,2,0,0,0,0,2Z"
          />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1672"
          y="1608"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M4,2V0H2A2,2,0,0,1,4,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1736"
          y="1608"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,0V2A2,2,0,0,0,4,2H4V0Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="712"
          y="1672"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="776"
          y="1672"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="840"
          y="1672"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="968"
          y="1672"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M2,4H4V0H0V2A2,2,0,0,0,2,4Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1032"
          y="1672"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M2,4H0V0H4V2A2,2,0,0,1,2,4Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1096"
          y="1672"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,2V0H2A2,2,0,0,0,0,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1224"
          y="1672"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M4,0H2A2,2,0,0,0,2,4H4Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1288"
          y="1672"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1352"
          y="1672"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1416"
          y="1672"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1480"
          y="1672"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1544"
          y="1672"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,4H2A2,2,0,0,0,2,0H0Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1608"
          y="1672"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M2,4H4V2A2,2,0,0,1,2,4Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1672"
          y="1672"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M4,4V2A2,2,0,0,0,0,2H0V4Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1736"
          y="1672"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,2V4H2A2,2,0,0,1,0,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="712"
          y="1736"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,0V2A2,2,0,0,0,4,2H4V0Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="776"
          y="1736"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,2V0H2A2,2,0,0,0,0,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="776"
          y="1736"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M4,2V0H2A2,2,0,0,1,4,2Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="840"
          y="1736"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,0V2A2,2,0,0,0,4,2H4V0Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1160"
          y="1736"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path
            fill="#36229e"
            d="M0,2A2,2,0,0,0,2,4H2A2,2,0,0,0,2,0H2A2,2,0,0,0,0,2Z"
          />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1608"
          y="1736"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M4,0H2A2,2,0,0,0,2,4H4Z" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1672"
          y="1736"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <rect fill="#36229e" width="4" height="4" />
        </svg>
        <svg
          viewBox="0 0 4 4"
          x="1736"
          y="1736"
          width="64"
          height="64"
          shape-rendering="auto"
        >
          <path fill="#36229e" d="M0,4H2A2,2,0,0,0,2,0H0Z" />
        </svg>
        <svg
          version="1.1"
          id="Ebene_1"
          x="200"
          y="200"
          width="448"
          height="448"
          viewBox="0 0 699.988 699.988"
          enableBackground="new 0 0 699.988 699.988"
          xmlSpace="preserve"
          shape-rendering="auto"
        >
          <path
            fill="#19183a"
            d="M488.367,0H211.621C94.746,0,0,94.746,0,211.621v276.745c0,116.876,94.746,211.622,211.621,211.622h276.746  c116.875,0,211.621-94.746,211.621-211.622V211.621C699.988,94.746,605.242,0,488.367,0z M599.99,448.832  c0,83.482-67.676,151.158-151.158,151.158H251.156c-83.482,0-151.158-67.676-151.158-151.158V251.156  c0-83.482,67.676-151.158,151.158-151.158h197.676c83.482,0,151.158,67.676,151.158,151.158V448.832z"
          />
        </svg>
        <svg
          version="1.1"
          id="Ebene_1"
          x="200"
          y="200"
          width="448"
          height="448"
          viewBox="0 0 699.988 699.988"
          enableBackground="new 0 0 699.988 699.988"
          xmlSpace="preserve"
          shape-rendering="auto"
        >
          <path
            fill="#d4687d"
            d="M499.991,409.296c0,50.091-40.604,90.694-90.693,90.694H290.691c-50.089,0-90.694-40.604-90.694-90.694  V290.692c0-50.09,40.605-90.694,90.694-90.694h118.605c50.089,0,90.693,40.604,90.693,90.694L499.991,409.296L499.991,409.296z"
          />
        </svg>
        <svg
          version="1.1"
          id="Ebene_1"
          x="1352"
          y="200"
          width="448"
          height="448"
          viewBox="0 0 699.988 699.988"
          enableBackground="new 0 0 699.988 699.988"
          xmlSpace="preserve"
          shape-rendering="auto"
        >
          <path
            fill="#19183a"
            d="M488.367,0H211.621C94.746,0,0,94.746,0,211.621v276.745c0,116.876,94.746,211.622,211.621,211.622h276.746  c116.875,0,211.621-94.746,211.621-211.622V211.621C699.988,94.746,605.242,0,488.367,0z M599.99,448.832  c0,83.482-67.676,151.158-151.158,151.158H251.156c-83.482,0-151.158-67.676-151.158-151.158V251.156  c0-83.482,67.676-151.158,151.158-151.158h197.676c83.482,0,151.158,67.676,151.158,151.158V448.832z"
          />
        </svg>
        <svg
          version="1.1"
          id="Ebene_1"
          x="1352"
          y="200"
          width="448"
          height="448"
          viewBox="0 0 699.988 699.988"
          enableBackground="new 0 0 699.988 699.988"
          xmlSpace="preserve"
          shape-rendering="auto"
        >
          <path
            fill="#d4687d"
            d="M499.991,409.296c0,50.091-40.604,90.694-90.693,90.694H290.691c-50.089,0-90.694-40.604-90.694-90.694  V290.692c0-50.09,40.605-90.694,90.694-90.694h118.605c50.089,0,90.693,40.604,90.693,90.694L499.991,409.296L499.991,409.296z"
          />
        </svg>
        <svg
          version="1.1"
          id="Ebene_1"
          x="200"
          y="1352"
          width="448"
          height="448"
          viewBox="0 0 699.988 699.988"
          enableBackground="new 0 0 699.988 699.988"
          xmlSpace="preserve"
          shape-rendering="auto"
        >
          <path
            fill="#19183a"
            d="M488.367,0H211.621C94.746,0,0,94.746,0,211.621v276.745c0,116.876,94.746,211.622,211.621,211.622h276.746  c116.875,0,211.621-94.746,211.621-211.622V211.621C699.988,94.746,605.242,0,488.367,0z M599.99,448.832  c0,83.482-67.676,151.158-151.158,151.158H251.156c-83.482,0-151.158-67.676-151.158-151.158V251.156  c0-83.482,67.676-151.158,151.158-151.158h197.676c83.482,0,151.158,67.676,151.158,151.158V448.832z"
          />
        </svg>
        <svg
          version="1.1"
          id="Ebene_1"
          x="200"
          y="1352"
          width="448"
          height="448"
          viewBox="0 0 699.988 699.988"
          enableBackground="new 0 0 699.988 699.988"
          xmlSpace="preserve"
          shape-rendering="auto"
        >
          <path
            fill="#d4687d"
            d="M499.991,409.296c0,50.091-40.604,90.694-90.693,90.694H290.691c-50.089,0-90.694-40.604-90.694-90.694  V290.692c0-50.09,40.605-90.694,90.694-90.694h118.605c50.089,0,90.693,40.604,90.693,90.694L499.991,409.296L499.991,409.296z"
          />
        </svg>
      </svg>
    </svg>
  );
};

export const AnimatedCheckmark = () => {
  return (
    <>
      <style>
        {`.checkmark__circle{stroke-dasharray: 166;stroke-dashoffset: 166;stroke-width: 2;stroke-miterlimit: 10;stroke: #7ac142;fill: none;animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards}.checkmark{width: 56px;height: 56px;border-radius: 50%;display: block;stroke-width: 2;stroke: #fff;stroke-miterlimit: 10;margin: 10% auto;box-shadow: inset 0px 0px 0px #7ac142;animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both}.checkmark__check{transform-origin: 50% 50%;stroke-dasharray: 48;stroke-dashoffset: 48;animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards}@keyframes stroke{100%{stroke-dashoffset: 0}}@keyframes scale{0%, 100%{transform: none}50%{transform: scale3d(1.1, 1.1, 1)}}@keyframes fill{100%{box-shadow: inset 0px 0px 0px 30px #7ac142}}`}
      </style>
      <div className="wrapper">
        {" "}
        <svg
          className="checkmark"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 52 52"
        >
          {" "}
          <circle
            className="checkmark__circle"
            cx="26"
            cy="26"
            r="25"
            fill="none"
          />{" "}
          <path
            className="checkmark__check"
            fill="none"
            d="M14.1 27.2l7.1 7.2 16.7-16.8"
          />
        </svg>
      </div>
    </>
  );
};

export const AnimatedCrossmark = () => {
  return (
    <>
      <style>
        {`.crossmark__circle {
          stroke-dasharray: 166;
          stroke-dashoffset: 166;
          stroke-width: 2;
          stroke-miterlimit: 10;
          stroke: #ff0000; /* Red circle */
          fill: none;
          animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
        }
        .crossmark {
          width: 56px;
          height: 56px;
          border-radius: 50%;
          display: block;
          stroke-width: 2;
          stroke: #fff;
          stroke-miterlimit: 10;
          margin: 10% auto;
          box-shadow: inset 0px 0px 0px #ff0000; /* Red shadow */
          animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
        }
        .crossmark__x {
          transform-origin: 50% 50%;
          stroke-dasharray: 50; /* Adjusted for the X */
          stroke-dashoffset: 50; /* Adjusted for the X */
          animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
        }
        @keyframes stroke {
          100% {
            stroke-dashoffset: 0;
          }
        }
        @keyframes scale {
          0%, 100% {
            transform: none;
          }
          50% {
            transform: scale3d(1.1, 1.1, 1);
          }
        }
        @keyframes fill {
          100% {
            box-shadow: inset 0px 0px 0px 30px #ff0000; /* Red fill */
          }
        }`}
      </style>
      <div className="wrapper">
        <svg
          className="crossmark"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 52 52"
        >
          <circle
            className="crossmark__circle"
            cx="26"
            cy="26"
            r="25"
            fill="none"
          />
          {/* Path for the X */}
          <path
            className="crossmark__x"
            fill="none"
            d="M16 16 L36 36 M36 16 L16 36"
          />
        </svg>
      </div>
    </>
  );
};

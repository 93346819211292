import {
  AutoAwesome,
  AutoFixHigh,
  Edit,
  School,
  Send,
} from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Paper,
  Tooltip,
  Divider,
  InputBase,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useUser } from "../../context/user";
import React, {
  useRef,
  useState,
  useEffect,
  FormEvent,
  ReactNode,
  useMemo,
} from "react";
import { SelectChangeEvent } from "@mui/material";
import { clarity } from "../../services/ClarityService";
import APIService from "../../services/APIService";
import { keyframes } from "@mui/system";
import { ConditionalTooltip } from "../ConditionalTooltip";
import { useUIState } from "../../context/uiState";

interface EditWithAIProps {
  loading: boolean;
  editInstructions: string;
  setEditInstructions: React.Dispatch<React.SetStateAction<string>>;
  onEditInstructionsSubmit: (editInstructions: string) => void; // New callback for submit action
  handleStartModuleCustomizationSession?: (edit_instructions?: string) => void;
  sectionContent?: string | null;
  moduleId?: string | null;
  // note_Id?: string | null;
  pollEditTaskStatusfromAIInstructions?: (taskId: string) => void;
}

const EditWithAI: React.FC<EditWithAIProps> = ({
  loading,
  editInstructions,
  setEditInstructions,
  onEditInstructionsSubmit,
  handleStartModuleCustomizationSession = () => {},
  sectionContent,
  moduleId,
  // note_Id
  pollEditTaskStatusfromAIInstructions = () => {},
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(loading);
  const [selectedOption, setSelectedOption] = useState("smart"); // Define the selectedOption state
  const [AIInstructionTaskId, setAIInstructionTaskId] = useState<string | null>(
    null
  );
  const { noteId } = useParams();
  const { userState, getAccessToken } = useUser();
  const { showAlertBanner } = useUIState();
  const userId = userState?.user_id;

  const maxChars = 500; // Maximum number of characters

  let typingTimeout: NodeJS.Timeout | null = null;

  const initiateAIInstruction = async () => {
    localStorage.setItem("hasUsedAiInstruction", "true");
    setIsLoading(true);
    try {
      const response = await APIService.makeAPIPostRequest({
        requestString: "/notes/createAIInstruction",
        accessToken: await getAccessToken(),
        body: {
          user_id: userId,
          section_content: sectionContent,
          edit_instructions: editInstructions,
          module_id: moduleId,
          note_id: noteId,
        },
      });

      if (response.ok && response.value && response.value.task_id) {
        setAIInstructionTaskId(response.value.task_id);
        pollAIInstructionStatus(response.value.task_id);
      } else {
        setIsLoading(false);
        throw new Error("Failed to initiate AI instruction");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error initiating AI instruction:", error);
    }
  };

  const pollAIInstructionStatus = async (taskId: string) => {
    let attempts = 0;
    const maxAttempts = 10;

    const pollInterval = setInterval(async () => {
      if (attempts >= maxAttempts) {
        clearInterval(pollInterval);
        showAlertBanner(
          "Error sending AI instructions. Please try again.",
          "error"
        );
        setEditInstructions("");
        setIsLoading(false);
      }

      attempts++;

      try {
        const response = await APIService.makeAPIGetRequest({
          requestString: `/notes/getAIInstructionStatus?task_id=${taskId}`,
          accessToken: await getAccessToken(),
        });

        if (response.ok && response.value) {
          const {
            classification,
            status,
            edit_task_id,
            module_customization_session_id,
            module_customization_session_step,
          } = response.value;
          if (status === 202) {
            clearInterval(pollInterval);
            if (classification === "AI_EDIT") {
              pollEditTaskStatusfromAIInstructions(edit_task_id);
            } else if (classification === "MODULE_CUSTOMIZATION") {
              sessionStorage.setItem(
                "module_customization_session_id",
                module_customization_session_id
              );
              sessionStorage.setItem(
                "module_customization_session_step",
                module_customization_session_step
              );
              handleStartModuleCustomizationSession();
              setIsLoading(false);
            }
          } else {
            throw new Error(`Unexpected status: ${status}`);
          }
        }
      } catch (error) {
        console.error("Error checking AI instruction status:", error);
        clearInterval(pollInterval);
        showAlertBanner(
          "Error sending AI instructions. Please try again.",
          "error"
        );
        setEditInstructions("");
        setIsLoading(false);
      }
    }, 3000);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newEditInstructions = event.target.value.slice(0, maxChars); // Enforce max characters
    setEditInstructions(newEditInstructions);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (selectedOption === "edit") {
      onEditInstructionsSubmit(editInstructions);
    } else if (selectedOption === "teach") {
      sessionStorage.setItem("edit_instructions", editInstructions);
      sessionStorage.setItem("selected_ai_option", selectedOption);
      clarity.setTag("sessionType", "moduleCustomizationSession");
      handleStartModuleCustomizationSession();
    } else {
      initiateAIInstruction();
    }
    setEditInstructions(""); // Clear the TextField value
  };

  // const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
  //   if (event.key === "Enter") {
  //     handleSubmit(event);
  //   }
  // };

  const handleDropdownChange = (event: SelectChangeEvent<string>) => {
    setSelectedOption(event.target.value);
  };

  // Helper functions
  const getIcon = (option: string) => {
    switch (option) {
      case "smart":
        return <AutoAwesome />;
      case "edit":
        return <Edit />;
      case "teach":
        return <School />;
      default:
        return <AutoAwesome />;
    }
  };

  const getTooltipText = (option: string) => {
    switch (option) {
      case "smart":
        return "Tell the AI how this section could be improved";
      case "edit":
        return "Edit this specific note section";
      case "teach":
        return "Teach the AI how you want this section in the future";
      default:
        return "";
    }
  };

  const getStaticPlaceholder = (option: string) => {
    switch (option) {
      case "smart":
        return "Tell the AI what you want";
      case "edit":
        return "Edit this specific note section";
      case "teach":
        return "Teach the AI how you want this section in the future";
      default:
        return "";
    }
  };

  // Custom component to wrap MenuItem with Tooltip
  const TooltippedMenuItem = React.forwardRef<HTMLLIElement, any>(
    (props, ref) => {
      const { tooltip, children, ...other } = props;
      return (
        <Tooltip title={tooltip} placement="right" arrow>
          <MenuItem ref={ref} {...other}>
            {children}
          </MenuItem>
        </Tooltip>
      );
    }
  );

  const [visiblePlaceholder, setVisiblePlaceholder] = useState<string>("");
  const placeholders = [
    "Always format section with bullet points.",
    "Make it more concise.",
    "Remove mentions of pet dog.",
    "Don't use medical abbreviations.",
    "Include a subsection called 'Key Points'.",
    "Remove info about patient's family.",
    "I need this section to always be in Spanish.",
    "Break into smaller, shorter paragraphs.",
  ];

  useEffect(() => {
    let currentPlaceholderIndex = 0;
    let typingTimeout: NodeJS.Timeout | null = null;

    const typeText = (text: string, index: number) => {
      if (index <= text.length) {
        setVisiblePlaceholder(text.substring(0, index));
        typingTimeout = setTimeout(() => typeText(text, index + 1), 20);
      } else {
        typingTimeout = setTimeout(() => deleteText(text), 3000);
      }
    };

    const deleteText = (text: string) => {
      if (text.length > 0) {
        setVisiblePlaceholder(text.substring(0, text.length - 1));
        typingTimeout = setTimeout(
          () => deleteText(text.substring(0, text.length - 1)),
          15
        );
      } else {
        currentPlaceholderIndex =
          (currentPlaceholderIndex + 1) % placeholders.length;
        typeText(placeholders[currentPlaceholderIndex], 0);
      }
    };

    const startAnimation = () => {
      if (selectedOption === "smart" && !editInstructions && !isLoading) {
        typeText(placeholders[currentPlaceholderIndex], 0);
      } else {
        setVisiblePlaceholder(getStaticPlaceholder(selectedOption));
      }
    };

    startAnimation();

    return () => {
      if (typingTimeout) clearTimeout(typingTimeout);
    };
  }, [selectedOption, editInstructions, isLoading]);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  const memoizedInputBase = useMemo(
    () => (
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder={visiblePlaceholder}
        value={editInstructions}
        onChange={handleChange}
        inputProps={{ "aria-label": "edit with AI input" }}
      />
    ),
    [visiblePlaceholder, editInstructions, handleChange]
  );

  const firstTime = localStorage.getItem("hasUsedAiInstruction") !== "true";

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        display: "flex",
        alignItems: "center",
        width: "100%",
      }}
    >
      <ConditionalTooltip
        condition={firstTime}
        title="Edit this note section or train your scribe for future notes by entering instructions into the AI Instruction box."
        arrow
        placement="bottom"
      >
        <Paper
          variant="outlined"
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            borderRadius: 1,
            position: "relative",
            overflow: "hidden",
            "&::before": {
              content: '""',
              position: "absolute",
              top: -2,
              left: -2,
              right: -2,
              bottom: -2,
              background:
                "linear-gradient(90deg, transparent, #7b1fa2, #2196f3, #e0f542, transparent)",
              backgroundSize: "200% 100%",
              animation: "gradientMove 1s ease-out forwards",
            },
            "&::after": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background: "white",
              zIndex: -1,
            },
          }}
        >
          <FormControl sx={{ minWidth: 50 }}>
            <Select
              value={selectedOption}
              onChange={handleDropdownChange}
              displayEmpty
              variant="standard"
              sx={{
                marginLeft: 2,
                "&:before": { borderBottom: "none" },
                "&:after": { borderBottom: "none" },
                ":hover&:before": { borderBottom: "none" },
              }}
              renderValue={(selected) => getIcon(selected)}
            >
              <TooltippedMenuItem
                value="smart"
                tooltip={getTooltipText("smart")}
              >
                <AutoAwesome sx={{ mr: 1 }} /> Smart
              </TooltippedMenuItem>
              <TooltippedMenuItem value="edit" tooltip={getTooltipText("edit")}>
                <Edit sx={{ mr: 1 }} /> Edit
              </TooltippedMenuItem>
              <TooltippedMenuItem
                value="teach"
                tooltip={getTooltipText("teach")}
              >
                <School sx={{ mr: 1 }} /> Teach
              </TooltippedMenuItem>
            </Select>
          </FormControl>
          <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
          {memoizedInputBase}
          <IconButton
            type="submit"
            sx={{ p: "10px" }}
            aria-label="send"
            disabled={editInstructions.trim().length === 0 || isLoading}
          >
            {isLoading ? <CircularProgress size={24} /> : <Send />}
          </IconButton>
        </Paper>
      </ConditionalTooltip>
    </Box>
  );
};

export default EditWithAI;

import React, { MouseEvent, useState, ChangeEvent } from "react";
import {
  Box,
  Typography,
  useTheme,
  IconButton,
  Stack,
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import CustomButton from "../../styles/CustomButtons";
import { useThemeContext } from "../../context/theme/context";
import APIService from "../../services/APIService";
import { useUser } from "../../context/user";

export interface PatientMatchModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const PatientMatchModal: React.FC<PatientMatchModalProps> = ({
  isOpen,
  onClose,
}) => {
  const { getAccessToken, userState } = useUser();
  const theme = useTheme();
  const { brandLanguage } = useThemeContext();
  const [infoText, setInfoText] = useState("");
  const [acknowledged, setAcknowledged] = useState(false);

  if (!isOpen) {
    return null;
  }

  const handleOverlayClick = (event: MouseEvent) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  const handleInfoChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setInfoText(event.target.value);
  };

  const handleAcknowledgementChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setAcknowledged(event.target.checked);
  };

  const handleSubmit = async () => {
    // Handle form submission here

    const accessToken = await getAccessToken();

    if (!accessToken) {
      return;
    }

    const respone = await APIService.makeAPIPostRequest({
      requestString: "/user/updatePatientMatchEnrollment",
      accessToken,
      body: {
        patient_match_enrollment: acknowledged,
        enrollment_info: infoText,
      },
    });

    if (respone.ok) {
      console.log("Form submitted");
      window.location.reload();
      onClose();
    }
  };

  const isSubmitDisabled = infoText.length < 30 || !acknowledged;

  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 50,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgba(0, 0, 0, 0.4)",
      }}
      onClick={handleOverlayClick}
    >
      <Box
        sx={{
          bgcolor: "backgroundColors.primary",
          borderRadius: "12px",
          p: 4,
          width: { xs: "90%", md: "50%" },
          fontWeight: "400",
          color: "textColors.darkText",
          maxWidth: "90vw",
          maxHeight: "80vh",
          position: "relative",
          overflow: "auto",
        }}
      >
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            color: "textColors.darkText",
          }}
        >
          <Close />
        </IconButton>
        {userState?.patientMatchEnrolled ? (
          <Stack spacing={3}>
            <Typography variant="body1">
              MindFind (Beta) seeks to bridge the chasm between providers and
              searching clients/patients. We do that through analyzing and
              identifying the subtle cues that erupt during real interactions
              with your clients/patients. Through this data profile of you, the
              provider, we are able to match searching clients/patients directly
              to your expertise.
            </Typography>
            <Typography variant="body1">
              <span style={{ fontWeight: "bold" }}>
                Are you sure you'd like to withdraw your enrollment in MindFind?
              </span>
            </Typography>
            <Button variant="contained" color="error" onClick={handleSubmit}>
              Withdraw
            </Button>
          </Stack>
        ) : (
          <Stack spacing={3}>
            <Typography variant="body1">
              MindFind (Beta) seeks to bridge the chasm between providers and
              searching patients. We do that through analyzing and identifying
              the subtle cues that erupt during real interactions with your
              clients/patients. Through this data profile of you, the provider,
              we are able to match searching clients/patients directly to your
              expertise.
            </Typography>
            <Typography variant="body1">
              MindFind is a chatbot that client/patients interact with to figure
              out the best provider for them, and the best client/patients for
              you.{" "}
              <span style={{ fontWeight: "bold" }}>
                If you would like to opt-in to the beta, please fill out the
                info form below.
              </span>
            </Typography>
            <Box>
              <Typography variant="subtitle1" gutterBottom>
                What state are you licensed in? Do you provide in-person and/or
                telehealth care? What insurance do you take? Are there any
                clients/patients you don't want to see?
              </Typography>
              <TextField
                multiline
                rows={4}
                fullWidth
                variant="outlined"
                value={infoText}
                onChange={handleInfoChange}
              />
            </Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={acknowledged}
                  onChange={handleAcknowledgementChange}
                />
              }
              label={
                <Typography variant="body2" align="center">
                  <Typography color={"red"}>
                    We respect your data privacy, and will never access your
                    information without consent.
                  </Typography>
                  I acknowledge and consent to SmartScribe analyzing my clinical
                  data on JotPsych (notes, anonymized transcripts) as well as
                  available public data to match me with potential
                  clients/patients.
                </Typography>
              }
            />
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                variant="contained"
                onClick={handleSubmit}
                disabled={isSubmitDisabled}
              >
                Accept
              </Button>
            </Box>
          </Stack>
        )}
      </Box>
    </Box>
  );
};

import React from 'react';
import { GroupMemberInfo } from "../../types/types";

interface GroupDetailsRowProps {
  member: GroupMemberInfo;
  isSelected: boolean;
  onUserSelect: (userId: string) => void;
}

export const GroupDetailsRow: React.FC<GroupDetailsRowProps> = ({ member, isSelected, onUserSelect }) => {

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onUserSelect(member.user_id);
  };

  return (
    <tr>
      {/* Checkbox for selecting the user */}
      <td className="px-4 py-2 border-2 border-gray-400">
        <input
          type="checkbox"
          onChange={handleCheckboxChange}
          checked={isSelected}
          readOnly // To prevent warning about the input being controlled
        />
      </td>
      <td className="px-4 py-2 border-2 border-gray-400">{member.user_id}</td>
      <td className="px-4 py-2 border-2 border-gray-400">{member.user_info.name}</td>
      <td className="px-4 py-2 border-2 border-gray-400">{member.user_info.email}</td>
      <td className="px-4 py-2 border-2 border-gray-400">{member.user_metrics?.notes_created ?? 'N/A'}</td>
      <td className="px-4 py-2 border-2 border-gray-400">{member.role}</td>
      <td className="px-4 py-2 border-2 border-gray-400">{member.payment_status}</td>
    </tr>
  );
};

import React, { useState, useEffect } from "react";
import { Modal, Box, Typography, TextField, Button, Divider } from "@mui/material";
import CloseButton from "../Buttons/CloseButton";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { TroubleWordsDict } from "../../context/user/context";
import { useUIState } from "../../context/uiState";

interface SpellModalProps {
  open: boolean;
  onClose: () => void;
  matchedWords: string[];
  misspelledWords: string[];
  selectedWord: string | null;
  onSubmit: (updates: { original: string, corrected: string }[]) => void;
  onNewWordsSubmit: (correctWord: string, misspellings: string[]) => void;
  troubleWords: TroubleWordsDict[] | undefined;
 addTroubleWords: (newTroubleWords: { correct_spelling: string; misspelling: string }[]) => Promise<boolean>;

}


const SpellModal: React.FC<SpellModalProps> = ({
  open,
  onClose,
  matchedWords,
  misspelledWords,
  selectedWord,
  onSubmit,
  onNewWordsSubmit,
  troubleWords,
  addTroubleWords,
}) => {
  const [editedWords, setEditedWords] = useState<string[]>([]);
  const [remainingWords, setRemainingWords] = useState<string[]>([]);
  const [newCorrectWord, setNewCorrectWord] = useState("");
  const [newMisspelling, setNewMisspelling] = useState("");
  const { showAlertBanner, state: uiState } = useUIState();


  useEffect(() => {
    if (selectedWord) {
      const correctWord = findCorrectSpelling(selectedWord);
      if(correctWord){
        setEditedWords([correctWord]);
        setRemainingWords([selectedWord]);
      }
      
    } else {
      const correctedWords = misspelledWords.map(findCorrectSpelling);
      const filteredArray  = correctedWords.filter(element => element !== undefined) as string[];
      setEditedWords(filteredArray);
      setRemainingWords(misspelledWords);
    }
  }, [selectedWord, misspelledWords, troubleWords]);
 
  const findCorrectSpelling = (word: string): string | undefined => {
    if (!troubleWords) {
      console.warn('troubleWords is not defined');
      return word;  // Return the original word if troubleWords is undefined
    }
    const lowerCaseWord = word.toLowerCase();
    const matchingEntry = troubleWords.find(troubleWord => 
      troubleWord.correct_spelling.toLowerCase() === lowerCaseWord ||
      troubleWord.misspellings.some(misspelling => misspelling.toLowerCase() === lowerCaseWord)
    );
    return matchingEntry?.correct_spelling || word;  // Return the correct spelling if found, otherwise return the original word
  };

  const handleChange = (index: number, value: string) => {
    const newEditedWords = [...editedWords];
    newEditedWords[index] = value;
    setEditedWords(newEditedWords);
  };

  const handleUpdate = (index: number) => {
    const updates = [{ original: remainingWords[index], corrected: editedWords[index] }];
    onSubmit(updates);
    const newRemainingWords = remainingWords.filter((_, i) => i !== index);
    const newEditedWords = editedWords.filter((_, i) => i !== index);
    setRemainingWords(newRemainingWords);
    setEditedWords(newEditedWords);
    showAlertBanner("Updated successfully!", "success");
  };

  const handleUpdateAll = () => {
    const updates = remainingWords.map((word, index) => ({
      original: word,
      corrected: editedWords[index]
    }));
    onSubmit(updates);
    onClose();
    showAlertBanner("Updated All successfully!", "success");
  };

  

  const handleAddNewEntry = async () => {
    if (newCorrectWord.trim() !== "" && newMisspelling.trim() !== "") {
      const newWord = [{
        correct_spelling: newCorrectWord.trim(),
        misspelling: newMisspelling.trim()
      }];
  
      const success = await addTroubleWords(newWord);
  
      if (success) {
        setNewCorrectWord("");
        setNewMisspelling("");
        showAlertBanner("Added new word successfully!", "success");
      } else {
        console.log("Failed to add new trouble word. Please try again.");
      }
    } else {
      alert("Please enter both the correct spelling and the misspelling.");
    }
  };

  const renderWordEditor = (word: string, index: number) => {
    return (
      <Box key={index} sx={{ display: "flex", alignItems: "center", mb: 2 }}>
        <Typography sx={{ flexBasis: "30%", mr: 2 }}>{word}</Typography>
        <ArrowRightAltIcon sx={{ mr: 2 }} />
        <TextField
          value={editedWords[index]}
          onChange={(e) => handleChange(index, e.target.value)}
          variant="outlined"
          size="small"
          sx={{ flexGrow: 1, mr: 2 }}
        />
        <Button
          variant="contained"
          onClick={() => handleUpdate(index)}
          sx={{
            bgcolor: "#1976d2",
            color: "white",
            "&:hover": {
              bgcolor: "#1565c0",
            },
          }}
        >
          Update
        </Button>
      </Box>
    );
  };

  const renderFullModal = () => (
    <>
      {remainingWords.length > 0 && (
        <>
          <Typography variant="h6" sx={{ mb: 3 }}>
            Do you want to make these changes?
          </Typography>
          {remainingWords.map((word, index) => renderWordEditor(word, index))}
          {remainingWords.length > 1 && (
            <Button
              variant="contained"
              onClick={handleUpdateAll}
              sx={{ mt: 2, mb: 3 }}
            >
              Update All
            </Button>
          )}
          <Divider sx={{ my: 3 }} />
        </>
      )}

      <Typography variant="subtitle1" sx={{ mb: 2 }}>
        Anything we missed?
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
        <TextField
          value={newCorrectWord}
          onChange={(e) => setNewCorrectWord(e.target.value)}
          variant="outlined"
          size="small"
          placeholder="Correct spelling"
          sx={{ flexGrow: 1, mr: 2 }}
        />
        <TextField
          value={newMisspelling}
          onChange={(e) => setNewMisspelling(e.target.value)}
          variant="outlined"
          size="small"
          placeholder="Misspelling"
          sx={{ flexGrow: 1, mr: 2 }}
        />
        <Button
          variant="contained"
          onClick={handleAddNewEntry}
          sx={{
            bgcolor: "#4B0082",
            color: "white",
            "&:hover": {
              bgcolor: "#3D007A",
            },
          }}
        >
          ADD
        </Button>
      </Box>
      <Typography variant="body2">
        Add any correct spellings and their associated misspellings that we missed.
      </Typography>
    </>
  );

  const renderSingleWordModal = () => (
    <>
      <Typography variant="h6" sx={{ mb: 3 }}>
        Do you want to make this change?
      </Typography>
      {renderWordEditor(remainingWords[0], 0)}
    </>
  );

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "#f5f5f5",
          boxShadow: 24,
          p: 4,
          outline: "none",
          borderRadius: 4,
          width: "80%",
          maxWidth: 500,
          maxHeight: "90vh",
          overflow: "auto",
        }}
      >
        <CloseButton onClick={onClose} />
        {selectedWord ? renderSingleWordModal() : renderFullModal()}
      </Box>
    </Modal>
  );
};

export default SpellModal;
import { Params, json } from "react-router-dom";
import { AdminModule, AdminUser } from "../types/types";
import { API_BASE_URL } from "../services/APIService";

export const ModulesLoader = async (): Promise<AdminModule[]> => {
  const accessToken = localStorage.getItem("accessToken");
  const modulesList = await fetch(`${API_BASE_URL}/template/getAllModules`, {
    method: "get",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    }),
  })
    .then((res) => {
      if (res.status === 401) {
        throw json(
          {
            message: "You are not authorized to access this resource.",
            cta: "Login",
          },
          { status: 401 }
        );
      }

      return res.json();
    })
    .then((data: { modules: AdminModule[] }) => {
      return data.modules;
    })
    .catch(() => {
      throw json(
        {
          message: "There has been an error. Please login again.",
          cta: "Login",
        },
        { status: 500 }
      );
    });

  return modulesList;
};

export const ModuleLoader = async ({
  params,
}: {
  params: Params;
}): Promise<AdminModule> => {
  const accessToken = localStorage.getItem("accessToken");
  const module = await fetch(
    `${API_BASE_URL}/template/getModule?module_id=${params.moduleId}`,
    {
      method: "get",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      }),
    }
  )
    .then((res) => {
      if (res.status === 401) {
        throw json(
          {
            message: "You are not authorized to access this resource.",
            cta: "Login",
          },
          { status: 401 }
        );
      }

      return res.json();
    })
    .then((data: { module: AdminModule }) => {
      return data.module;
    })
    .catch(() => {
      throw json(
        {
          message: "There has been an error. Please login again.",
          cta: "Login",
        },
        { status: 500 }
      );
    });

  return module;
};

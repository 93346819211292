import { Box } from "@mui/material";
import { ReactNode } from "react";

interface Props {
  children?: ReactNode;
}

export const RootError = ({ children }: Props) => {
  return (
    <div className="flex flex-col w-full h-full justify-center items-center max-w-lg mt-8">
      <Box
        sx={{
          minWidth: "350px",
          bgcolor: "backgroundColors.secondary",
          border: 1,
          borderColor: "borderColors.primary",
          borderRadius: "0.75rem",

          alignItems: "flex-start",
          padding: "1rem 1.5rem",
          margin: "0.5rem",
          width: "100%",
        }}
      >
        {children}
      </Box>
    </div>
  );
};

// src/components/TermsOfServiceModal.tsx
import React, { MouseEvent } from "react";
import {
  Box,
  Typography,
  useTheme,
  IconButton,
  Button,
  Stack,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { useUser } from "../../context/user";

interface TermsOfServiceModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const TermsOfServiceModal: React.FC<TermsOfServiceModalProps> = ({
  isOpen,
  onClose,
}) => {
  const theme = useTheme();
  const { updateOnboardingStep } = useUser();

  if (!isOpen) {
    return null;
  }

  const onAccept = async () => {
    updateOnboardingStep("accepted_tos_0524", true);
    onClose();
  };

  const handleOverlayClick = (event: MouseEvent) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 50,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgba(0, 0, 0, 0.4)",
      }}
      onClick={handleOverlayClick}
    >
      <Box
        sx={{
          bgcolor: "backgroundColors.primary",
          borderRadius: "12px",
          p: 4,
          width: { xs: "90%", md: "50%" },
          fontWeight: "400",
          color: "textColors.darkText",
          maxWidth: "90vw",
          height: "100%",
          maxHeight: "70vh",
          position: "relative",
        }}
      >
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            color: "textColors.darkText",
          }}
        >
          <Close />
        </IconButton>
        <Typography variant="h4" sx={{ mb: 2 }}>
          Terms of Use
        </Typography>
        <Box
          sx={{
            height: "70%",
            overflow: "scroll",
            mb: 2,
          }}
        >
          <Stack gap={2}>
            <Typography variant="body1">
              Link to Current Terms: https://jotpsych.com/terms-of-use
            </Typography>
            <Typography variant="body1">
              Version last updated on January 15, 2024
            </Typography>
            <Typography variant="body1">
              The Terms and Conditions of Sale and Service, together with the
              Privacy Policy, form a contract ("Contract") between SmartScribe
              Corp, a Delaware corporation ("SmartScribe", "we", "us", or "our")
              and you (referred to as "you", "your", or "Client") for any use of
              our Services.
            </Typography>
            <Typography variant="body1">
              SmartScribe has developed a digital technology solution to process
              health data made available on affiliated websites, applications,
              and web extensions owned and operated by SmartScribe, hereinafter
              referred to as the "Services."
            </Typography>
            <Typography variant="body1">
              The Client is aware that the use of the Services must be carefully
              monitored by the Client regarding the legal and technical
              conditions for processing personal health data.
            </Typography>
            <Typography variant="body1">
              Please read the Contracts carefully and keep a copy of them for
              your reference. For information about our data practices, please
              see our Privacy Policy. By using our Services, you agree to these
              Terms and Conditions and to the Privacy Policy.
            </Typography>
            <Typography variant="body1">
              We reserve the right to modify these Terms and Conditions at any
              time and at our sole discretion. We invite you to check this page
              regularly for updates to these Terms and Conditions.
            </Typography>
            <Typography variant="h6">Definitions</Typography>
            <Typography variant="body1" component="div">
              For the purposes of this Contract:
              <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
                <li>
                  Client (referred to as "you", "your", or "Client") means the
                  individual accessing or using the Service, or the company, or
                  other legal entity on behalf of which such individual is
                  accessing or using the Service, as applicable.
                </li>
                <li>
                  Company (referred to as either "the Company", "we", "us",
                  "SmartScribe", or "our" in this Agreement) refers to
                  SmartScribe Corp, Delaware, USA.
                </li>
                <li>
                  Services refer to websites, applications, and web extensions
                  owned and operated by SmartScribe.
                </li>
                <li>
                  ‍Service Provider means any natural or legal person who
                  processes the data on behalf of the Company. It refers to
                  third-party companies or individuals employed by the Company
                  to facilitate the Service, to provide the Service on behalf of
                  the Company, to perform services related to the Service, or to
                  assist the Company in analyzing how the Service is used.
                </li>
                <li>
                  User Account means a unique account created for you to access
                  our Services or parts of our Services.
                </li>
              </ul>
            </Typography>
            <Typography variant="h6">Your User Account</Typography>
            <Typography variant="body1">
              In order to use SmartScribe Services, you must create a User
              Account. In creating a User Account, you must provide SmartScribe
              with accurate information, in good faith, and you agree to keep
              your information up to date if changes occur. You must not provide
              false account registration information.
            </Typography>
            <Typography variant="body1">
              In order to create a User Account, you must be at least 18 years
              old or older, or of the age of majority in your local
              jurisdiction, whichever is higher, and legally capable of forming
              a binding contract with SmartScribe. SmartScribe does not
              knowingly collect, either online or offline, personal information
              from persons under the age of thirteen.
            </Typography>
            <Typography variant="body1">
              If you use SmartScribe Services, you are responsible for
              maintaining the confidentiality of your account and password and
              for restricting access to your computer, and you agree to accept
              responsibility for all activities that occur under your User
              Account.
            </Typography>
            <Typography variant="body1">
              You may not assign or otherwise transfer your User Account to any
              other person or entity, whether free of charge or for
              consideration, without the prior written consent of SmartScribe.
            </Typography>
            <Typography variant="body1">
              You acknowledge that SmartScribe is not responsible for
              third-party access to your User Account that results from theft or
              misappropriation of your User Account.
            </Typography>
            <Typography variant="body1">
              Please contact SmartScribe immediately if you believe your User
              Account has been compromised.
            </Typography>
            <Typography variant="body1">
              SmartScribe and its associates reserve the right to refuse or
              cancel Services, terminate User Accounts, or remove or edit
              content in our sole discretion.
            </Typography>
            <Typography variant="body1">
              You shall acquire no rights to the Services, intellectual property
              or otherwise, other than those outlined by the Contract.
            </Typography>
            <Typography variant="h6">Privacy </Typography>
            <Typography variant="body1">
              Your use of SmartScribe’s Services is subject to SmartScribe's
              Privacy Policy. Please review our Privacy Policy, which also
              governs the Services and informs users of our data collection
              practices.
            </Typography>
            <Typography variant="h6">Electronic Communications</Typography>
            <Typography variant="body1">
              Using SmartScribe Services or sending emails to SmartScribe
              constitutes electronic communications. You consent to receive
              electronic communications and you agree that all agreements,
              notices, disclosures, and other communications that we provide to
              you electronically, via email, and via our Services satisfy any
              legal requirement that such communications be in writing.
            </Typography>
            <Typography variant="h6">
              Links to Third-Party Sites/Third-Party Services
            </Typography>
            <Typography variant="body1">
              SmartScribe Services may contain links to other websites ("Linked
              Sites"). The Linked Sites are not under the control of SmartScribe
              and SmartScribe is not responsible for the contents of any Linked
              Site, including without limitation any link contained in a Linked
              Site, or any changes or updates to a Linked Site. SmartScribe is
              providing these links to you only as a convenience, and the
              inclusion of any link does not imply endorsement by SmartScribe of
              the site or any association with its operators.
            </Typography>
            <Typography variant="body1">
              Certain Services may be delivered by a Service Provider. By using
              any SmartScribe Service you hereby acknowledge and consent that
              SmartScribe may share information and data with any third-party
              with whom SmartScribe has a contractual relationship to provide
              the requested product, service or functionality on behalf of
              SmartScribe users and customers.
            </Typography>
            <Typography variant="h6">
              No Unlawful or Prohibited Use/Intellectual Property
            </Typography>
            <Typography variant="body1">
              You are granted a non-exclusive, non-transferable, revocable
              license to access and use SmartScribe Services strictly in
              accordance with these Terms and Conditions.
            </Typography>
            <Typography variant="body1">
              As a condition of your use of the Services, you warrant to
              SmartScribe that you will not use the Services for any purpose
              that is unlawful or prohibited by these Terms and Conditions.
            </Typography>
            <Typography variant="body1">
              You may not use the Services in any manner that could damage,
              disable, overburden, or impair the Services or interfere with any
              other party's use and enjoyment of the Services. You may not
              obtain or attempt to obtain any materials or information through
              any means not intentionally made available or provided for through
              the Services.
            </Typography>
            <Typography variant="body1">
              As a Client, you are responsible for all activity on your account.
              If you violate our Terms and Conditions, we may terminate the
              Client account.
            </Typography>
            <Typography variant="body1">
              SmartScribe remains the owner of the intellectual property rights
              relating to the Services.
            </Typography>
            <Typography variant="body1">
              All content included as part of the Services, such as software,
              code, text, graphics, logos, images, as well as the compilation
              thereof, is the property of SmartScribe or its suppliers and
              protected by copyright and other laws that protect intellectual
              property and proprietary rights. You agree to observe and abide by
              all copyright and other proprietary notices, legends or other
              restrictions contained in any such content and will not make any
              changes thereto.
            </Typography>
            <Typography variant="body1">
              You will not modify, publish, transmit, reverse engineer,
              participate in the transfer or sale, create derivative works, or
              in any way exploit any of the content, in whole or in part, made
              available by the Services. SmartScribe content is not for resale.
            </Typography>
            <Typography variant="body1">
              Your use of the Services does not entitle you to make any
              unauthorized use of any protected content, and in particular, you
              will not delete or alter any proprietary rights or attribution
              notices in any content. You will use the protected content
              exclusively for professional purposes and for input into an
              electronic health record system, and will make no other use of the
              content without the express written permission of SmartScribe and
              the copyright owner..
            </Typography>
            <Typography variant="body1">
              You agree that you do not acquire any ownership rights to any
              protected content. We do not grant you any licenses, express or
              implied, to the intellectual property of SmartScribe or our
              licensors except as expressly authorized by these Terms and
              Conditions.
            </Typography>
            <Typography variant="h6">International Users</Typography>
            <Typography variant="body1">
              The Services are controlled, operated and administered by
              SmartScribe from our offices within the USA. If you access the
              Service from a location outside the USA, you are responsible for
              compliance with all local laws. You agree that you will not use
              the SmartScribe Content accessed through our Services in any
              country or in any manner prohibited by any applicable laws,
              restrictions or regulations.
            </Typography>
            <Typography variant="h6">Indemnification</Typography>
            <Typography variant="body1">
              You agree to indemnify, defend, and hold harmless SmartScribe, its
              officers, directors, employees, agents, and third parties, for any
              losses, costs, liabilities, and expenses (including legal fees)
              relating to or arising out of your use of or inability to use the
              Services, any notes made by your User Account, your violation of
              any Terms and Conditions of this Contract, your violation of any
              rights of a third party, or your violation of any applicable laws,
              rules, or regulations. This indemnification also specifically
              includes, but is not limited to, any claims of malpractice brought
              against you, and any penalties or disciplinary action imposed by
              your employer due to poor job performance, violation of workplace
              rules, or other misconduct related to your use of the Services.
            </Typography>
            <Typography variant="body1">
              SmartScribe reserves the right, at its own cost, to assume the
              exclusive defense and control of any matter otherwise subject to
              indemnification by you, in which event you will fully cooperate
              with SmartScribe in asserting any available defenses.
            </Typography>
            <Typography variant="h6">Arbitration</Typography>
            <Typography variant="body1">
              In the event the parties are not able to resolve any dispute
              between them arising out of or concerning these Terms and
              Conditions, or any provisions hereof, whether in contract, tort,
              or otherwise at law or in equity for damages or any other relief,
              then such dispute shall be resolved only by final and binding
              arbitration pursuant to the Federal Arbitration Act, conducted by
              a single neutral arbitrator and administered by the American
              Arbitration Association, or a similar arbitration service selected
              by the parties, in a location mutually agreed upon by the parties.
            </Typography>
            <Typography variant="body1">
              The arbitrator's award shall be final, and judgment may be entered
              upon it in any court having jurisdiction. In the event that any
              legal or equitable action, proceeding or arbitration arises out of
              or concerns these Terms and Conditions, the prevailing party shall
              be entitled to recover its costs and reasonable attorney's fees.
              The parties agree to arbitrate all disputes and claims in regards
              to these Terms and Conditions or any disputes arising as a result
              of these Terms and Conditions, whether directly or indirectly,
              including Tort claims that are a result of these Terms and
              Conditions. The parties agree that the Federal Arbitration Act
              governs the interpretation and enforcement of this provision. The
              entire dispute, including the scope and enforceability of this
              arbitration provision, shall be determined by the Arbitrator. This
              arbitration provision shall survive the termination of these Terms
              and Conditions.
            </Typography>
            <Typography variant="h6">Class Action Waiver</Typography>
            <Typography variant="body1">
              Any arbitration under these Terms and Conditions will take place
              on an individual basis; class arbitrations and
              class/representative/collective actions are not permitted. THE
              PARTIES AGREE THAT A PARTY MAY BRING CLAIMS AGAINST THE OTHER ONLY
              IN THEIR INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS
              MEMBER IN ANY PUTATIVE CLASS, COLLECTIVE AND/ OR REPRESENTATIVE
              PROCEEDING, SUCH AS IN THE FORM OF A PRIVATE ATTORNEY GENERAL
              ACTION AGAINST THE OTHER. Further, unless both you and SmartScribe
              agree otherwise, the arbitrator may not consolidate more than one
              person's claims, and may not otherwise preside over any form of a
              representative or class proceeding.
            </Typography>
            <Typography variant="h6">Liability Disclaimer</Typography>
            <Typography variant="body1">
              THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR
              AVAILABLE THROUGH THE SERVICES MAY INCLUDE INACCURACIES OR
              TYPOGRAPHICAL ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE
              INFORMATION HEREIN. SMARTSCRIBE CORP AND/OR ITS SUPPLIERS MAY MAKE
              IMPROVEMENTS AND/OR CHANGES IN THE SERVICES AT ANY TIME.
            </Typography>
            <Typography variant="body1">
              SMARTSCRIBE CORP AND/OR ITS SUPPLIERS PROVIDE INFORMATION,
              SOFTWARE, PRODUCTS, SERVICES, AND RELATED GRAPHICS PRIMARILY BASED
              ON ANECDOTAL EVIDENCE AND EXPERIENCES, WHICH SHOULD NOT BE
              INTERPRETED AS A GUARANTEE OR PREDICTIVE REPRESENTATION OF THE
              PERFORMANCE OR SUITABILITY FOR EACH USER. WHILE EFFORTS ARE MADE
              TO SHARE RELEVANT AND ACCURATE INFORMATION, THE RELIABILITY,
              AVAILABILITY, TIMELINESS, AND ACCURACY OF THE INFORMATION,
              SOFTWARE, PRODUCTS, SERVICES, AND RELATED GRAPHICS CONTAINED ON
              THE SERVICES ARE NOT GUARANTEED OR WARRANTED. TO THE MAXIMUM
              EXTENT PERMITTED BY APPLICABLE LAW, ALL SUCH INFORMATION,
              SOFTWARE, PRODUCTS, SERVICES, AND RELATED GRAPHICS ARE PROVIDED
              "AS IS" WITHOUT WARRANTY OR CONDITION OF ANY KIND. SMARTSCRIBE
              CORP AND/OR ITS SUPPLIERS HEREBY DISCLAIM ALL WARRANTIES AND
              CONDITIONS WITH REGARD TO THIS INFORMATION, SOFTWARE, PRODUCTS,
              SERVICES, AND RELATED GRAPHICS, INCLUDING ALL IMPLIED WARRANTIES
              OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
              PURPOSE, TITLE, AND NON-INFRINGEMENT."
            </Typography>
            <Typography variant="body1">
              TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT
              SHALL SMARTSCRIBE CORP AND/OR ITS SUPPLIERS BE LIABLE FOR ANY
              DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL
              DAMAGES OR ANY DAMAGES WHATSOEVER INCLUDING, WITHOUT LIMITATION,
              DAMAGES FOR LOSS OF USE, DATA OR PROFITS, ARISING OUT OF OR IN ANY
              WAY CONNECTED WITH THE USE OR PERFORMANCE OF THE SERVICES, WITH
              THE DELAY OR INABILITY TO USE THE SERVICES OR RELATED SERVICES,
              THE PROVISION OF OR FAILURE TO PROVIDE SERVICES, OR FOR ANY
              INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS
              OBTAINED THROUGH THE SERVICES, OR OTHERWISE ARISING OUT OF THE USE
              OF THE SERVICES, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE,
              STRICT LIABILITY OR OTHERWISE, EVEN IF SMARTSCRIBE CORP OR ANY OF
              ITS SUPPLIERS HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES.
              BECAUSE SOME STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR
              LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES,
              THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IF YOU ARE DISSATISFIED
              WITH ANY PORTION OF THE SERVICES, OR WITH ANY OF THESE TERMS AND
              CONDITIONS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO
              DISCONTINUE USING THE SERVICES.
            </Typography>
            <Typography variant="h6">Termination/Access Restriction</Typography>
            <Typography variant="body1">
              SmartScribe reserves the right, at its sole discretion, to
              terminate your access to the Services and the related services or
              any portion thereof at any time, without notice. To the maximum
              extent permitted by law, this agreement is governed by the laws of
              the State of Delaware and you hereby consent to the exclusive
              jurisdiction and venue of courts in Delaware in all disputes
              arising out of or relating to the use of the Services. Use of the
              Services is unauthorized in any jurisdiction that does not give
              effect to all provisions of these Terms and Conditions, including,
              without limitation, this section.
            </Typography>
            <Typography variant="body1">
              You agree that no joint venture, partnership, employment, or
              agency relationship exists between you and SmartScribe as a result
              of this agreement or use of the Services.
            </Typography>
            <Typography variant="body1">
              SmartScribe's performance of this agreement is subject to existing
              laws and legal process, and nothing contained in this agreement is
              in derogation of SmartScribe's right to comply with governmental,
              court and law enforcement requests or requirements relating to
              your use of the Services or information provided to or gathered by
              SmartScribe with respect to such use. If any part of this
              agreement is determined to be invalid or unenforceable pursuant to
              applicable law including, but not limited to, the warranty
              disclaimers and liability limitations set forth above, then the
              invalid or unenforceable provision will be deemed superseded by a
              valid, enforceable provision that most closely matches the intent
              of the original provision and the remainder of the agreement shall
              continue in effect.
            </Typography>
            <Typography variant="body1">
              Unless otherwise specified herein, this agreement constitutes the
              entire agreement between the user and SmartScribe with respect to
              the Services and it supersedes all prior or contemporaneous
              communications and proposals, whether electronic, oral or written,
              between the user and SmartScribe with respect to the Services. A
              printed version of this agreement and of any notice given in
              electronic form shall be admissible in judicial or administrative
              proceedings based upon or relating to this agreement to the same
              extent and subject to the same conditions as other business
              documents and records originally generated and maintained in
              printed form. It is the express wish to the parties that this
              agreement and all related documents be written in English.
            </Typography>
            <Typography variant="h6">
              Changes to Terms and Conditions
            </Typography>
            <Typography variant="body1">
              SmartScribe reserves the right, in its sole discretion, to change
              the Terms and Conditions under which Services are offered. The
              most current version of the Terms and Conditions will supersede
              all previous versions. SmartScribe encourages you to periodically
              review the Terms and Conditions to stay informed of our updates.
            </Typography>
            <Typography variant="h6">Contact Us</Typography>
            <Typography variant="body1">
              SmartScribe welcomes your questions or comments regarding the
              Terms and Conditions: info@smartscribe.health
            </Typography>
          </Stack>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            onClick={onClose}
            sx={{ mr: 2 }}
            variant="outlined"
            color="primary"
          >
            Not Now
          </Button>
          <Button onClick={onAccept} variant="contained" color="primary">
            Accept
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

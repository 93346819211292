import { useUIState } from "../../context/uiState";
import { Button, Theme } from "@mui/material";
import { useMediaQuery } from "@mui/material";
export const Sidebar = ({
  children,
  selectedSections,
  setSelectedSections,
}: {
  children: any;
  selectedSections: number[];
  setSelectedSections: React.Dispatch<React.SetStateAction<number[]>>;
}) => {
  const { state: UIState } = useUIState();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  return (
    <aside
      id="logo-sidebar"
      style={{ transform: isMobile && UIState.sidebar.isHidden ? "translateX(-100%)" : "none" }}
      className={`fixed top-0 left-0 z-40 w-64 h-screen pt-20 transition-transform bg-white border-r border-gray-200 md:translate-x-0`}
      aria-label="Sidebar"
    >
      <div className="h-full flex flex-col px-3 pb-4 bg-white ">
        {children}
        <div className="mt-4 space-y-2 border-t border-gray-200 text-center flex-shrink-0"></div>
      </div>
    </aside>
  );
};

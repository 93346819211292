import React from "react";
import { Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

export const getErrorMessage = (error_message: string, note_id: string) => {
  const emailDefault = {
    supportEmail: "support@jotpsych.com",
    noteErrorSubject: "Assistance Needed for Note Processing Issue",
    chunkErrorSubject: "Assistance Needed for Audio Uploading Issue",
    bodyContent: `Please describe your issue here:\n\n\nNote ID: ${note_id}\n`,
  };

  switch (error_message) {
    case "NO_SPOKEN_WORDS":
      return (
        <div className="flex items-center text-red-600">
          <span className="text-sm mt-0 pt-0">
            No spoken words detected in audio.
          </span>
          <Tooltip title="Please check your microphone settings before your next recording">
            <InfoIcon className="ml-1" />
          </Tooltip>
        </div>
      );

    case "NOTE_PROCESSING_TIMEOUT":
      return (
        <div className="flex items-center text-red-600">
          <span className="text-sm mt-0 pt-0">
            Note took too long to process.{" "}
          </span>
          <Tooltip
            title={
              <>
                Please get in touch with support for assistance:{" "}
                <a
                  href={`mailto:${
                    emailDefault.supportEmail
                  }?subject=${encodeURIComponent(
                    emailDefault.noteErrorSubject
                  )}&body=${encodeURIComponent(emailDefault.bodyContent)}`}
                  style={{ textDecoration: "underline" }}
                >
                  {emailDefault.supportEmail}
                </a>
              </>
            }
          >
            <InfoIcon className="ml-1" />
          </Tooltip>
        </div>
      );

    case "NO_CHUNKS_UPLOADED":
      return (
        <div className="flex items-center text-red-600">
          <span className="text-sm mt-0 pt-0">Failed to upload audio.</span>
          <Tooltip
            title={
              <>
                Please get in touch with support for assistance:{" "}
                <a
                  href={`mailto:${
                    emailDefault.supportEmail
                  }?subject=${encodeURIComponent(
                    emailDefault.chunkErrorSubject
                  )}&body=${encodeURIComponent(emailDefault.bodyContent)}`}
                  style={{ textDecoration: "underline" }}
                >
                  {emailDefault.supportEmail}
                </a>
              </>
            }
          >
            <InfoIcon className="ml-1" />
          </Tooltip>
        </div>
      );

    default:
      return (
        <span className="text-sm text-red-600 mt-0 pt-0">
          Unexpected error: {error_message}
        </span>
      );
  }
};

import React from "react";
import { AdminFeedbackEvent } from "../../types/types";
import SentimentSatisfiedIcon from "@mui/icons-material/SentimentSatisfiedOutlined";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfiedOutlined";
import SentimentNeutralIcon from "@mui/icons-material/SentimentNeutralOutlined";
import { formatDate } from "../../utils/utils";

interface NoteFeedbackDisplayBoxProps {
  feedbackEvents: AdminFeedbackEvent[];
}

const NoteFeedbackDisplayBox: React.FC<NoteFeedbackDisplayBoxProps> = ({
  feedbackEvents,
}) => {
  const getIconForRating = (rating: number) => {
    switch (rating) {
      case 0:
        return <SentimentSatisfiedIcon />;
      case 1:
        return <SentimentDissatisfiedIcon />;
      case 2:
        return <SentimentNeutralIcon />;
      default:
        return null;
    }
  };

  return (
    <div className="bg-white shadow-lg rounded-lg p-6 mt-4 outline-dashed outline-slate-200">
      <div className="flex justify-between items-start mb-4">
        <h3 className="text-xl font-bold">Note Feedback</h3>
        {feedbackEvents[0]?.timestamp && (
          <span className="text-gray-400 text-sm">
            {formatDate(feedbackEvents[0].timestamp)}
          </span>
        )}
      </div>
      {feedbackEvents.map((feedback, index) => (
        <div
          key={index}
          className="border-b pb-2 mb-2 last:border-0 last:pb-0 last:mb-0"
        >
          {feedback.note_rating !== (undefined || null) && (
            <div className="flex items-center">
              <span className="text-gray-600">Rating:</span>
              <span className="text-lg font-semibold ml-2">
                {getIconForRating(feedback.note_rating!)}
              </span>
            </div>
          )}
          {feedback.note_comment && (
            <div>
              <span className="text-gray-600">Comment:</span>
              <div className="mt-1 text-gray-700">{feedback.note_comment}</div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default NoteFeedbackDisplayBox;

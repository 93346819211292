import React, { useState, useEffect } from "react";
import { TextField, FormControl, Box, Autocomplete } from "@mui/material";
import { AdminUser } from "../../../types/types";
import APIService from "../../../services/APIService";

interface UserPickerProps {
  onUserSelect: (user_id: string) => void;
}

const UserPicker: React.FC<UserPickerProps> = ({ onUserSelect }) => {
  const [availableUsers, setAvailableUsers] = useState<AdminUser[]>([]);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async (search: string = "") => {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) return;
    const response = await APIService.makeAPIGetRequest({
      requestString: `/user/getAllUsers?search=${search}`,
      accessToken: accessToken,
    });

    if (response.ok) {
      setAvailableUsers(response.value.users);
    }
  };

  const handleUserChange = (event: any, value: AdminUser | null) => {
    if (value) {
      onUserSelect(value._id);
    } else {
      onUserSelect("");
    }
  };

  return (
    <Box>
      <FormControl fullWidth>
        <Autocomplete
          options={availableUsers}
          getOptionLabel={(option) =>
            option.user_info?.name ? option.user_info.name : option._id
          }
          renderInput={(params) => (
            <TextField {...params} label="Choose a user" variant="outlined" />
          )}
          onChange={handleUserChange}
        />
      </FormControl>
    </Box>
  );
};

export default UserPicker;

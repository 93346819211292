import { useSelectedUser } from "../../context/selectedUserContext.tsx/index.js";
import { AdminGroup, AdminUser } from "../../types/types.js";
import { useNavigate } from "react-router-dom";

interface GroupRowProps {
  group: AdminGroup;
}

export const GroupRow = ({ group }: GroupRowProps) => {

  const navigate = useNavigate();

  return (
    <tr
      className={`hover:bg-blue-100 cursor-pointer`}
      onClick={() => {
        console.log(group.group_id)
        navigate(`/admin/groups/${group.group_id}`);
      }}
    >
      <td className="px-4 py-2 border-2 border-gray-400">{group.group_id}</td>
      <td className="px-4 py-2 border-2 border-gray-400">{group.group_info?.name}</td>
      <td className="px-4 py-2 border-2 border-gray-400">{group.group_info?.institution}</td>
      <td className="px-4 py-2 border-2 border-gray-400">{group.members?.length}</td>
      <td className="px-4 py-2 border-2 border-gray-400">{group.admins?.length}</td>
    </tr>
  );
};

import { Box, Button, SlideProps } from "@mui/material";

import MultiColumnCenterBox from "../layouts/MultiColumnCenterBox";
import { BoxColumn } from "../components/layout/BoxColumn";
import { useUser } from "../context/user";
import { UserInfoForm } from "../components/UserInfoForm";
import { useEffect, useState } from "react";
import { Slide } from "@mui/material";
import DownloadCompanionApp from "../components/DownloadCompanionApp";
import LanguageAndNotePreferencesForm from "../components/LanguageAndNotePreferencesForm";
import { useNavigate } from "react-router-dom";
import { clarity } from "../services/ClarityService";
import { LogoutButton } from "../components/Buttons/LogoutButton";
import { useThemeContext } from "../context/theme/context";
import { useUIState } from "../context/uiState";
import OnboardingCompleted from "../components/OnboardingCompleted";
import { TestNoteRecordingView } from "./TestNoteRecordingView";
import AdditionalFactorsForm from "../components/ModalitiesDiagnosesForm";
import ModalitiesDiagnosesForm from "../components/ModalitiesDiagnosesForm";

const OnboardingView = () => {
  const { getAccessToken, completeSignup, userState } = useUser();
  const { state } = useUIState();
  const { platform } = state;
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState<number>(
    userState?.userInfo ? 1 : 0
  );
  const [contentKey, setContentKey] = useState<number>(0);

  const { brandLanguage } = useThemeContext();

  const maxSteps = 4; // Adjust the number of steps as needed

  const win: Window = window;

  const [subjectLanguage, setSubjectLanguage] = useState<string>();

  const handleNext = (): void => {
    if (activeStep < maxSteps - 1) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setContentKey((prevKey) => prevKey + 1); // Trigger re-render with new key
    } else {
      onCompleteOnboarding();
    }
  };

  const handleSkip = (): void => {
    onCompleteOnboarding();
  };

  const onCompleteOnboarding = (): void => {
    // Implement your onComplete callback logic here
    completeSignup();
    navigate("dashboard");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const renderBreadcrumbs = (): JSX.Element => (
    <Box sx={{ display: "flex", justifyContent: "center", p: 2 }}>
      {Array.from({ length: maxSteps }, (_, index) => (
        <Box
          key={index}
          sx={{
            width: 8,
            height: 8,
            bgcolor: activeStep === index ? "primary.main" : "grey.400",
            borderRadius: "50%",
            margin: "0 4px",
            transition: "background-color 0.3s",
          }}
        />
      ))}
    </Box>
  );

  // Conditional rendering based on activeStep
  const getStepContent = (step: number): JSX.Element => {
    switch (step) {
      case 0:
        return (
          <UserInfoForm
            onSuccessfulFormSubmission={() => {
              // log google analytics event
              //@ts-ignore
              win.gtag("event", "WebApp_SignupConfirm", { platform: platform });
              //@ts-ignore
              win.gtag("event", "onboarding_user_info_screen_complete", {
                platform: platform,
              });
              //@ts-ignore
              win.gtag("event", "conversion", {
                send_to: "AW-11416945570/JtXkCLqC-boZEKKHg8Qq",
              });
              //@ts-ignore
              win.fbq("track", "userInfoFormCompleted", {
                eventName: "userInfoFormCompleted",
                platform: platform,
              });
              handleNext();
            }}
          />
        );
      case 1:
        return (
          <ModalitiesDiagnosesForm
            onSuccessfulFormSubmission={() => {
              handleNext();
            }}
            onNext={handleNext}
          />
        );
      case 2:
        return (
          <LanguageAndNotePreferencesForm
            onSuccessfulFormSubmission={(subjectLanguage: string) => {
              //@ts-ignore
              win.gtag(
                "event",
                "onboarding_language_preferences_screen_complete",
                { platform: platform }
              );
              setSubjectLanguage(subjectLanguage);
              handleNext();
            }}
          />
        );
      case 3:
        return (
          <TestNoteRecordingView
            onNext={handleNext}
            subjectLanguage={subjectLanguage}
          />
        );

      default:
        return <div>Unknown step</div>;
    }
  };

  useEffect(() => {
    if (clarity.hasStarted()) {
      clarity.upgrade("onboarding");
      clarity.setTag("sessionType", "onboarding");
    }
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <MultiColumnCenterBox
        headingText={`Welcome to ${brandLanguage.brandName}!`}
        showHeader={false}
      >
        <BoxColumn flex={1}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: "100%",
              alignItems: "center",
            }}
          >
            <Slide
              id="slide"
              direction="left"
              in={true}
              mountOnEnter
              unmountOnExit
              key={contentKey}
            >
              <Box
                sx={{
                  height: "100%",
                  width: { xs: "95%", sm: "80%" },
                }}
              >
                {getStepContent(activeStep)}
              </Box>
            </Slide>
            {renderBreadcrumbs()}
            {/* <Button onClick={handleNext}>Skip</Button> */}
          </Box>
        </BoxColumn>
      </MultiColumnCenterBox>
      <Box sx={{ ml: 4, mt: -2 }}>
        <LogoutButton />
      </Box>
    </Box>
  );
};

export default OnboardingView;

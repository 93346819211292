import {
  AdminPublicTemplate,
  AdminUserTemplate,
  AvailableModule,
  ModuleMappings,
  UserTemplateItem,
} from "../types/types";
import {
  Box,
  Typography,
  useTheme,
  Skeleton,
  Button,
  ButtonGroup,
  IconButton,
  Divider,
  Link,
  Paper,
  InputAdornment,
  InputBase,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import MultiColumnCenterBox from "../layouts/MultiColumnCenterBox";
import TemplateHeaderBox from "../layouts/TemplateHeaderBox";
import { BoxColumn } from "../components/layout/BoxColumn";
import { useUser } from "../context/user";
import { DraggableTemplateListItem } from "../components/Templates/TemplateListItem";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import {
  PublicTemplateLoader,
  UserTemplateLoader,
} from "../loaders/TemplatesLoader";
import TemplateEditor from "../components/Templates/TemplateEditor";
import APIService from "../services/APIService";
import NewTemplateBlock from "../components/Templates/NewTemplateBlock";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { ChevronLeft, Close } from "@mui/icons-material";
import { DeleteConfirmDialog } from "../components/DeleteConfirmDialog";

const TemplatesView = () => {
  const { templatesList, getAccessToken, refreshTemplatesList } = useUser();

  const [templateLoading, setTemplateLoading] = useState<boolean>(false);
  const [selectedTemplate, setSelectedTemplate] = useState<UserTemplateItem>();
  const [loadedTemplate, setLoadedTemplate] = useState<
    AdminUserTemplate | AdminPublicTemplate
  >();
  const [availableModules, setAvailableModules] = useState<AvailableModule[]>();
  const [publicTemplates, setPublicTemplates] =
    useState<AdminPublicTemplate[]>();
  const [addingTemplate, setAddingTemplate] = useState<boolean>(false);
  const [addingTemplateLoading, setAddingTemplateLoading] =
    useState<boolean>(false);
  const [modifiedTemplatesList, setModifiedTemplatesList] = useState<
    UserTemplateItem[] | undefined
  >(templatesList);
  const [userTemplateDeleteModalOpen, setUserTemplateDeleteModalOpen] =
    useState<boolean>(false);

  const [moduleMappings, setModuleMappings] = useState<ModuleMappings>();
  const [headerText, setHeaderText] = useState(
    "Welcome to the template builder!"
  );
  const [description, setDescription] = useState(
    "JotPsych can organize your note contents based on your preferences. Use the template builder to add, remove, or re-order sections within a template, or create new templates. Start with a pre-built template from our library or create your own custom template from scratch."
  );
  const [addContact, setAddContact] = useState(false);
  const [lastSaved, setLastSaved] = useState<Date | null>(null);
  const scrollRef = useRef<HTMLDivElement>(null);
  const scrollPositionRef = useRef(0);

  const StartFromScratch = async () => {
    const accessToken = await getAccessToken();

    if (!accessToken) {
      return;
    }

    setAddingTemplateLoading(true);
    setTemplateLoading(true);
    setHeaderText("Build a Custom Template");
    setDescription(
      "Browse JotPsych's wide variety of sections to create your own custom template. Not seeing a section you need? "
    );
    setAddContact(true);

    const createNewUserTemplateResponse = await APIService.makeAPIPostRequest({
      requestString: "/template/createUserTemplate",
      accessToken: accessToken,
      body: {
        template_name: "[Name Your Template]",
        summary_modules: [],
        sections: [],
      },
    });

    if (createNewUserTemplateResponse.ok) {
      setSelectedTemplate({
        template_id: createNewUserTemplateResponse.value.template_id,
        type: "user",
      });
      refreshTemplatesList();
      setAddingTemplateLoading(false);
    }
  };

  const copyUserTemplate = async (templateId: string) => {
    const accessToken = await getAccessToken();

    if (!accessToken) {
      return;
    }

    setAddingTemplateLoading(true);
    setTemplateLoading(true);

    const copyUserTemplateResponse = await APIService.makeAPIPostRequest({
      requestString: "/template/copyUserTemplate",
      accessToken: accessToken,
      body: {
        template_id: templateId,
      },
    });

    if (copyUserTemplateResponse.ok) {
      setSelectedTemplate({
        template_id: copyUserTemplateResponse.value.template_id,
        type: "user",
      });
      refreshTemplatesList();
      setAddingTemplateLoading(false);
    }
  };

  const copyPublicTemplate = async (templateId: string) => {
    const accessToken = await getAccessToken();

    if (!accessToken) {
      return;
    }

    setAddingTemplateLoading(true);
    setTemplateLoading(true);

    const duplicateNewUserTemplateResponse =
      await APIService.makeAPIPostRequest({
        requestString: "/template/copyPublicTemplateToUser",
        accessToken: accessToken,
        body: {
          template_id: templateId,
        },
      });

    if (duplicateNewUserTemplateResponse.ok) {
      setSelectedTemplate({
        template_id: duplicateNewUserTemplateResponse.value.template_id,
        type: "user",
      });
      refreshTemplatesList();
      setAddingTemplateLoading(false);
    }
  };

  const deleteUserTemplate = async (template_id: string) => {
    const accessToken = await getAccessToken();

    if (!accessToken) {
      return;
    }

    const deleteUserTemplate = await APIService.makeAPIPostRequest({
      requestString: "/template/deleteUserTemplate",
      accessToken: accessToken,
      body: {
        template_id,
      },
    });

    if (deleteUserTemplate.ok) {
      setSelectedTemplate(undefined);
      setLoadedTemplate(undefined);
      refreshTemplatesList();
    }
  };

  const removePublicTemplate = async (template_id: string) => {
    const accessToken = await getAccessToken();

    if (!accessToken) {
      return;
    }

    const removePublicTemplate = await APIService.makeAPIPostRequest({
      requestString: "/template/removePublicTemplate",
      accessToken: accessToken,
      body: {
        template_id,
      },
    });

    if (removePublicTemplate.ok) {
      setSelectedTemplate(undefined);
      setLoadedTemplate(undefined);
      refreshTemplatesList();
    }
  };

  const fetchModuleMappings = async () => {
    const accessToken = await getAccessToken();

    if (!accessToken) {
      return;
    }

    const moduleMappings = await APIService.makeAPIGetRequest({
      requestString: "/template/getModuleMappings",
      accessToken: accessToken,
    });

    if (moduleMappings.ok) {
      setModuleMappings(moduleMappings.value.module_mappings);
    }
  };

  const fetchAvailableModules = async () => {
    const accessToken = await getAccessToken();

    if (!accessToken) {
      return;
    }

    const availableModulesResponse = await APIService.makeAPIGetRequest({
      requestString: "/template/getAvailableModules",
      accessToken: accessToken,
    });

    if (availableModulesResponse.ok) {
      setAvailableModules(availableModulesResponse.value.modules);
    }
  };

  const fetchPublicTemplates = async () => {
    const accessToken = await getAccessToken();

    if (!accessToken) {
      return;
    }

    const publicTemplatesResponse = await APIService.makeAPIGetRequest({
      requestString: "/template/getAllPublicTemplates",
      accessToken: accessToken,
    });

    if (publicTemplatesResponse.ok) {
      setPublicTemplates(publicTemplatesResponse.value.templates);
    }
  };

  const fetchTemplate = async () => {
    setTemplateLoading(true);

    let template: AdminUserTemplate | AdminPublicTemplate;

    if (selectedTemplate?.type === "user") {
      template = await UserTemplateLoader({
        params: { templateId: selectedTemplate?.template_id },
      });
    } else if (selectedTemplate?.type === "public") {
      template = await PublicTemplateLoader({
        params: { templateId: selectedTemplate?.template_id },
      });
    } else if (selectedTemplate?.type === "group") {
      template = await UserTemplateLoader({
        params: { templateId: selectedTemplate?.template_id },
      });
    } else {
      return;
    }

    if (template) {
      setLoadedTemplate(template);
    }

    setTemplateLoading(false);
  };

  const updateUserTemplates = async (user_templates: UserTemplateItem[]) => {
    const accessToken = await getAccessToken();

    if (!accessToken) {
      return;
    }

    const updateUserTemplatesResponse = await APIService.makeAPIPostRequest({
      requestString: "/user/updateUserTemplates",
      accessToken: accessToken,
      body: { user_templates },
    });

    if (updateUserTemplatesResponse.ok) {
      refreshTemplatesList();
    }
  };

  const handleSave = async (modfiedTemplate: AdminUserTemplate) => {
    const accessToken = await getAccessToken();

    if (!accessToken) {
      return;
    }

    const updateUserTemplateResponse = await APIService.makeAPIPostRequest({
      requestString: "/template/updateUserTemplate",
      accessToken: accessToken,
      body: {
        template_id: modfiedTemplate.template_id,
        updated_fields: {
          template_name: modfiedTemplate.template_name,
          sections: modfiedTemplate.sections,
          summary_modules: modfiedTemplate.summary_modules,
        },
      },
    });

    setLoadedTemplate(modfiedTemplate);
    setLastSaved(new Date());
  };

  const handleTitleChange = (newTitle: string) => {
    const newTemplatesList = modifiedTemplatesList?.map((template) => {
      if (template.template_id === selectedTemplate?.template_id) {
        return { ...template, display_name: newTitle };
      }
      return template;
    });
    setModifiedTemplatesList(newTemplatesList);

    if (loadedTemplate) {
      setLoadedTemplate({ ...loadedTemplate, template_name: newTitle });
    }

    // refreshTemplatesList();
  };

  const handleDelete = async () => {
    if (selectedTemplate && loadedTemplate) {
      if (selectedTemplate.type === "user") {
        deleteUserTemplate(loadedTemplate.template_id);
      } else if (selectedTemplate.type === "public") {
        removePublicTemplate(loadedTemplate.template_id);
      }
    }
  };

  const handleAddTemplate = async (publicTemplateId: string) => {
    const accessToken = await getAccessToken();

    if (!accessToken) {
      return;
    }

    setAddingTemplateLoading(true);
    setTemplateLoading(true);

    const addPublicTemplateResponse = await APIService.makeAPIPostRequest({
      requestString: "/template/addPublicTemplateToUser",
      accessToken: accessToken,
      body: {
        public_template_id: publicTemplateId,
      },
    });

    if (addPublicTemplateResponse.ok) {
      setSelectedTemplate({
        template_id: addPublicTemplateResponse.value.template_id,
        type: "public",
      });
      refreshTemplatesList();
      setAddingTemplateLoading(false);
    }
  };

  const handleCopy = async (template?: AdminPublicTemplate) => {
    if (template) {
      await copyPublicTemplate(template.template_id);
    } else if (selectedTemplate?.type === "user") {
      await copyUserTemplate(selectedTemplate.template_id);
    } else if (selectedTemplate?.type === "public") {
      await copyPublicTemplate(selectedTemplate.template_id);
    }
  };

  const handleTemplateSelection = (template: UserTemplateItem) => {
    // Save the current scroll position
    if (scrollRef.current) {
      scrollPositionRef.current = scrollRef.current.scrollTop;
    }

    setSelectedTemplate(template);
    setHeaderText("Welcome to the template builder!");
    setDescription(
      "JotPsych can organize your note contents based on your preferences. Use the template builder to add, remove, or re-order sections within a template, or create new templates. Start with a pre-built template from our library or create your own custom template from scratch."
    );
    setAddingTemplate(false);
    setAddContact(false);
    setLastSaved(null);
  };

  useLayoutEffect(() => {
    // Restore the scroll position after render
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollPositionRef.current;
    }
  });

  useEffect(() => {
    if (templatesList) {
      setModifiedTemplatesList(templatesList);
      if (selectedTemplate === undefined && templatesList.length > 0) {
        setSelectedTemplate(templatesList[0]);
        setHeaderText("Welcome to the template builder!");
        setDescription(
          "JotPsych can organize your note contents based on your preferences. Use the template builder to add, remove, or re-order sections within a template, or create new templates. Start with a pre-built template from our library or create your own custom template from scratch."
        );
        setAddContact(false);
      }
    }
  }, [templatesList]);

  useEffect(() => {
    if (selectedTemplate) {
      fetchTemplate();
    }
  }, [selectedTemplate]);

  useEffect(() => {
    refreshTemplatesList();
    fetchAvailableModules();
    fetchPublicTemplates();
    fetchModuleMappings();
  }, []);

  const TemplateListView = () => {
    const loading = templatesList ? false : true;

    const reorder = (
      list: UserTemplateItem[],
      startIndex: number,
      endIndex: number
    ) => {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);

      return result;
    };

    const onDragEnd = async (result: any) => {
      // dropped outside the list
      if (!result.destination || !modifiedTemplatesList) {
        return;
      }

      const newTemplatesList = reorder(
        modifiedTemplatesList,
        result.source.index,
        result.destination.index
      );

      setModifiedTemplatesList(newTemplatesList);

      await updateUserTemplates(newTemplatesList);
    };

    return (
      <>
        <Box
          id="template-list"
          ref={scrollRef}
          sx={{
            flex: 1,
            overflow: "scroll",
            flexGrow: 1,
          }}
        >
          {loading && (
            <>
              <Skeleton variant="rounded" height={40} sx={{ m: 2 }} />
              <Skeleton variant="rounded" height={40} sx={{ m: 2 }} />
              <Skeleton variant="rounded" height={40} sx={{ m: 2 }} />
              <Skeleton variant="rounded" height={40} sx={{ m: 2 }} />
              <Skeleton variant="rounded" height={40} sx={{ m: 2 }} />
              <Skeleton variant="rounded" height={40} sx={{ m: 2 }} />
              <Skeleton variant="rounded" height={40} sx={{ m: 2 }} />
            </>
          )}
          {!loading &&
            modifiedTemplatesList &&
            modifiedTemplatesList.length > 0 && (
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <Box {...provided.droppableProps} ref={provided.innerRef}>
                      {modifiedTemplatesList.map((template, index) => (
                        <DraggableTemplateListItem
                          key={index}
                          index={index}
                          template={template}
                          canModify={template.type === "user" ? true : false}
                          handleClick={() => handleTemplateSelection(template)}
                          isSelected={
                            selectedTemplate?.template_id ===
                            template.template_id
                          }
                        />
                      ))}
                      {addingTemplateLoading && (
                        <Skeleton variant="rounded" height={50} sx={{ m: 2 }} />
                      )}
                      {provided.placeholder}
                    </Box>
                  )}
                </Droppable>
              </DragDropContext>
            )}
        </Box>
      </>
    );
  };

  const TemplatePickerView = () => {
    if (!templatesList || publicTemplates === undefined) {
      return (
        <Box>
          <Skeleton />
        </Box>
      );
    }

    const excludedTemplates = templatesList?.map(
      (template) => template.template_id
    );

    return (
      <Box
        sx={{ margin: "0 auto", height: "100%", width: "95%", display: "flex" }}
      >
        <TemplateSelector
          availableTemplates={publicTemplates}
          excludedTemplates={excludedTemplates}
          onAddTemplate={handleAddTemplate}
          handleCopy={handleCopy}
          onClose={() => {
            setHeaderText("Welcome to the template builder!");
            setDescription(
              "JotPsych can organize your note contents based on your preferences. Use the template builder to add, remove, or re-order sections within a template, or create new templates. Start with a pre-built template from our library or create your own custom template from scratch."
            );
            setAddingTemplate(false);
            setAddContact(false);
          }}
          availableModules={availableModules}
        />
      </Box>
    );
  };

  const TemplateEditorView = () => (
    <>
      {templateLoading ? (
        <Box sx={{ flex: 1 }}>
          <Skeleton variant="text" width={"60%"} height={40} sx={{ m: 2 }} />
          <Skeleton variant="text" width={"60%"} height={20} sx={{ m: 2 }} />
          <Box
            sx={{
              width: "80%",
              margin: "0 auto",
              marginTop: 2,
            }}
          >
            <Skeleton variant="rounded" height={75} sx={{ m: 2 }} />
            <Skeleton variant="rounded" height={50} sx={{ m: 2 }} />
            <Skeleton variant="rounded" height={75} sx={{ m: 2 }} />
            <Skeleton variant="rounded" height={60} sx={{ m: 2 }} />
            <Skeleton variant="rounded" height={50} sx={{ m: 2 }} />
          </Box>
        </Box>
      ) : (
        <>
          {loadedTemplate && (
            <Box sx={{ flex: 1 }}>
              <TemplateEditor
                template={loadedTemplate}
                canModify={selectedTemplate?.type === "user" ? true : false}
                canCopyAndDelete={
                  selectedTemplate?.type === "group" ? false : true
                }
                availableModules={availableModules}
                moduleMappings={moduleMappings}
                handleTitleChange={handleTitleChange}
                handleCopy={handleCopy}
                handleSave={handleSave}
                handleDelete={() => setUserTemplateDeleteModalOpen(true)}
                lastSaved={lastSaved}
              />
              <DeleteConfirmDialog
                open={userTemplateDeleteModalOpen}
                type="template"
                onClose={() => setUserTemplateDeleteModalOpen(false)}
                onConfirm={() => {
                  setUserTemplateDeleteModalOpen(false);
                  handleDelete();
                }}
              />
            </Box>
          )}
        </>
      )}
    </>
  );

  const TemplateExplanationView = () => null;

  return (
    <TemplateHeaderBox
      headerText={headerText}
      description={description}
      contact={addContact}
    >
      <BoxColumn flex={1}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "75vh",
            overflowY: "auto",
          }}
        >
          <Typography
            fontSize={"1.1rem"}
            fontStyle="normal"
            fontWeight="900"
            color="textColors.lightHeader"
            sx={{
              ml: 1,
              mt: 1,
            }}
          >
            View/Manage Your Templates
          </Typography>
          <TemplateListView />
          <Divider sx={{ my: 1, bgcolor: "borderColors.primary" }} />
          <Typography
            fontSize={"1.1rem"}
            fontStyle="normal"
            fontWeight="900"
            color="textColors.lightHeader"
            sx={{
              ml: 1,
            }}
          >
            Create New Template
          </Typography>
          {!addingTemplate && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "15vh",
                m: 1,
                gap: 2,
              }}
            >
              <Button
                variant="outlined"
                onClick={StartFromScratch}
                sx={{
                  flex: 1,
                  borderRadius: 2,
                  borderColor: "textColors.lightHeader",
                }}
              >
                <Typography
                  fontSize={"0.9rem"}
                  fontStyle="normal"
                  fontWeight="700"
                  color="textColors.lightHeader"
                >
                  Start from Scratch
                </Typography>
              </Button>
              <Button
                variant="outlined"
                onClick={() => {
                  setHeaderText("Browse JotPsych Templates");
                  setDescription(
                    "Choose a template from JotPsych's library. You can use the template as-is or customize it to meet your needs."
                  );
                  setAddingTemplate(true);
                  setAddContact(false);
                }}
                sx={{
                  flex: 1,
                  borderRadius: 2,
                  borderColor: "textColors.lightHeader",
                }}
              >
                <Typography
                  fontSize={".9rem"}
                  fontStyle="normal"
                  fontWeight="700"
                  color="textColors.lightHeader"
                >
                  Browse JotPsych Library
                </Typography>
              </Button>
            </Box>
          )}
        </Box>
      </BoxColumn>
      <BoxColumn flex={3}>
        {addingTemplate ? <TemplatePickerView /> : <TemplateEditorView />}
        {!selectedTemplate && !addingTemplate && <TemplateExplanationView />}
      </BoxColumn>
    </TemplateHeaderBox>
  );
};

export default TemplatesView;

interface TemplateSelectorProps {
  availableTemplates: AdminPublicTemplate[];
  excludedTemplates: string[];
  onAddTemplate: (publicTemplateId: string) => void;
  onClose: () => void;
  handleCopy?: (template: AdminPublicTemplate) => void;
  availableModules?: AvailableModule[];
}

const TemplateSelector = ({
  availableTemplates,
  excludedTemplates,
  onAddTemplate,
  onClose,
  handleCopy,
  availableModules,
}: TemplateSelectorProps) => {
  const [categories, setCategories] = useState<string[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<string>();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filteredTemplates, setFilteredTemplates] = useState<
    AdminPublicTemplate[]
  >([]);

  useEffect(() => {
    // Extract unique categories
    const uniqueCategories = new Set<string>();
    availableTemplates.forEach((template) => {
      if (template.public_categories) {
        template.public_categories.forEach((category) =>
          uniqueCategories.add(category)
        );
      }
    });
    setCategories(Array.from(uniqueCategories));
  }, [availableTemplates]);

  useEffect(() => {
    // Filter templates by selected category, search term, and exclude excludedTemplates
    const filtered = availableTemplates.filter(
      (template) =>
        !excludedTemplates.includes(template.template_id) &&
        template.public_name &&
        template.public_name.length>0 &&
        (template.public_name
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
          template.public_description
            ?.toLowerCase()
            .includes(searchTerm.toLowerCase()))
    );
    setFilteredTemplates(filtered);
  }, [availableTemplates, excludedTemplates, searchTerm]);

  return (
    <Box
      sx={{
        width: {
          xs: "90%",
          sm: "95%",
          md: "100%",
        },
        height: "75vh",
        borderRadius: "2vh",
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box sx={{ p: "1vh" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mb: "2vh",
          }}
        >
          <Typography variant="h6" fontWeight={600}>
            SELECT A TEMPLATE
          </Typography>
          <IconButton
            onClick={onClose}
            size="small"
            sx={{ position: "absolute", right: "10%" }}
          >
            <Close />
          </IconButton>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            bgcolor: "background.paper",
            borderRadius: "2vh",
            padding: "0.5vh 1.5vh",
            boxShadow: "0 0.3vh 0.5vh rgba(0,0,0,0.1)",
          }}
        >
          <InputBase
            fullWidth
            placeholder="Search by template name or description"
            inputProps={{ "aria-label": "search templates" }}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <InputAdornment position="end">
            <IconButton
              type="button"
              sx={{ padding: "0.5vh" }}
              aria-label="search"
            >
              <Search />
            </IconButton>
          </InputAdornment>
        </Box>
      </Box>
      <Box sx={{ flexGrow: 1, overflowY: "auto", p: "2vh" }}>
        {filteredTemplates.map((template) => (
          <NewTemplateBlock
            key={template.template_id}
            template={template}
            handleClick={() => {
              onAddTemplate(template.template_id);
              onClose();
            }}
            handleCopy={() => {
              if (handleCopy) {
                handleCopy(template);
                onClose();
              }
            }}
            availableModules={availableModules}
          />
        ))}
        {filteredTemplates.length === 0 && (
          <Typography variant="subtitle1" align="center" color="textSecondary">
            No templates found matching search criteria.
          </Typography>
        )}
      </Box>
    </Box>
  );
};

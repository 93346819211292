import { v4 as uuidv4 } from "uuid";
import { openDB } from "idb";

export type LogEntry = {
  timestamp: number;
  event: string;
  details?: any;
};

export type RecordingChunk = {
  id: string;
  position?: number;
  start_timestamp_seconds: number;
  end_timestamp_seconds: number;
  upload_status: "pending" | "error" | "success";
  chunk_data?: {
    chunk_size: number;
    chunk_type: string;
  };
  s3_object_id?: string;
};

export type EndSessionStatus = {
  status: "success" | "retrying" | "failed";
  failedChunks?: RecordingChunk[];
};

export class RecordingSession {
  id: string;
  start_timestamp?: number;
  end_timestamp?: number;
  transcription_session_id?: string;
  successfully_uploaded?: boolean;
  recording_ended?: boolean;
  recording_title?: string;
  chunks: RecordingChunk[];
  logs: LogEntry[] = [];

  constructor({ recording_title }: { recording_title?: string }) {
    this.id = String(uuidv4());
    this.recording_title = recording_title;
    this.chunks = [];
    this.saveToStorage();
  }

  static async cleanupCurrentSession() {
    // remove currentRecordingSession from storage
    await this.clearAllObjectStores();
  }

  static async clearAllObjectStores() {
    const db = await openDB("SmartScribeAudioChunks", 1);
    const tx = db.transaction(db.objectStoreNames, "readwrite");

    Array.from(db.objectStoreNames).forEach((storeName) => {
      const store = tx.objectStore(storeName);
      store.clear();
    });

    await tx.done;
  }

  addMediaStreamInfo(mediaStreamInfo: any) {
    this.addLog("MediaStreamInfo", mediaStreamInfo);
    this.saveToStorage();
  }

  // Method to add logs
  addLog(event: string, details?: any): void {
    const logEntry: LogEntry = {
      timestamp: Date.now(),
      event,
      details,
    };
    this.logs.push(logEntry);
  }

  async saveToStorage(): Promise<void> {
    try {
      const db = await openDB("SmartScribeRecordingSessions", 1, {
        upgrade(db) {
          db.createObjectStore("recordingSessions");
        },
      });
      const tx = db.transaction("recordingSessions", "readwrite");
      const store = tx.objectStore("recordingSessions");
      await store.put(JSON.stringify(this), `recordingSession:${this.id}`);
      await tx.done;
    } catch (e) {
      console.log("Failed to save recording session to storage", e);
    }
  }

  static async loadFromStorage(id: string): Promise<RecordingSession | null> {
    try {
      const db = await openDB("SmartScribeRecordingSessions", 1);
      const tx = db.transaction("recordingSessions", "readonly");
      const store = tx.objectStore("recordingSessions");
      const jsonValue: string | undefined = await store.get(
        `recordingSession:${id}`
      );

      if (jsonValue !== undefined) {
        return Object.assign(new RecordingSession({}), JSON.parse(jsonValue));
      }
    } catch (e) {
      console.log("Failed to load recording session from storage", e);
    }
    return null;
  }

  async startSession(): Promise<void> {
    console.log(`RecordingSession:${this.id}: \n\t\tStarting RecordingSession`);
    this.addLog("Session Started", { recording_session_id: this.id });
    this.start_timestamp = Date.now();

    try {
      // Save the current recording session ID
      const db = await openDB("SmartScribeRecordingSessions", 1, {
        upgrade(db) {
          db.createObjectStore("recordingSessions");
        },
      });
      const tx = db.transaction("recordingSessions", "readwrite");
      const store = tx.objectStore("recordingSessions");
      await store.put(this.id, "currentRecordingSession");
      await tx.done;

      // Save the whole session object to storage
      await this.saveToStorage();
    } catch (e) {
      console.log("Failed to start recording session", e);
    }
  }

  async endSession(): Promise<EndSessionStatus> {
    console.log(`RecordingSession:${this.id}: \n\t\tEnding RecordingSession`);

    // set end timestamp
    this.end_timestamp = Date.now();
    this.recording_ended = true;

    // this.printChunkStatus();

    // check if all chunks have been uploaded
    const failedChunks = this.getFailedChunks();
    if (failedChunks.length > 0) {
      // Add a log that the session ended
      this.addLog("Session Ended", { status: "failed" });
      this.saveToStorage();
      return { status: "failed", failedChunks };
    } else {
      this.addLog("Session Ended", { status: "success" });
      this.saveToStorage();
      return { status: "success", failedChunks };
    }
  }

  associateTranscriptionSession(transcriptionSessionId: string) {
    this.transcription_session_id = transcriptionSessionId;
    this.addLog("Associated Transcription Session", { transcriptionSessionId });
    this.saveToStorage();
  }

  updateRecordingTitle(recording_title: string) {
    this.recording_title = recording_title;
    this.addLog("Updated Recording Title", { recording_title });
    this.saveToStorage();
  }

  addChunkToRecordingSession(chunk: RecordingChunk) {
    try {
      console.log(
        `RecordingSession:${this.id}: \n\t\tAdding Chunk ${chunk.id}`
      );
      this.addLog("Added Chunk", { chunk });
      chunk.position = this.chunks.length;
      this.chunks.push(chunk);
      this.saveToStorage();
      return chunk;
    } catch (error) {
      throw error;
    }
  }

  updateChunkUploadStatus(
    chunkId: string,
    s3_object_id: string | undefined,
    successfully_uploaded: boolean
  ) {
    console.log(
      `RecordingSession:${this.id}: \n\t\tUpdating UploadStatus of Chunk ${chunkId}`
    );
    this.addLog("Updated Chunk Upload Status", {
      chunk_id: chunkId,
      upload_status: successfully_uploaded,
      s3_object_id,
    });
    const chunkIndex = this.chunks.findIndex((chunk) => chunk.id === chunkId);

    if (chunkIndex === -1) {
      console.error(
        `Chunk ${chunkId} not found in RecordingSession ${this.id}`
      );
      return;
    }

    this.chunks[chunkIndex].s3_object_id = s3_object_id;
    this.chunks[chunkIndex].upload_status = successfully_uploaded
      ? "success"
      : "error";
    this.saveToStorage();
  }

  getFailedChunks() {
    const failedChunks = this.chunks.filter(
      (chunk) => chunk.upload_status === "error"
    );

    if (failedChunks.length > 0) {
      this.successfully_uploaded = false;
      this.addLog("Got Failed Chunks", {
        failed_chunks: failedChunks.length,
      });
      console.log(
        `RecordingSession:${this.id}: \n\t\tFailed chunks:`,
        failedChunks
      );
    } else {
      this.successfully_uploaded = true;
      console.log(
        `RecordingSession:${this.id}: \n\t\tAll chunks uploaded successfully`
      );
    }

    this.saveToStorage();

    return failedChunks;
  }

  getPendingChunks() {
    const pendingChunks = this.chunks.filter(
      (chunk) => chunk.upload_status === "pending"
    );

    return pendingChunks;
  }

  getEndTimestampOfLastChunk() {
    // Check if there are any chunks
    if (this.chunks.length === 0) {
      return null;
    }

    // Get the last chunk
    const lastChunk = this.chunks[this.chunks.length - 1];

    // Return the end timestamp of the last chunk
    return Math.round(lastChunk.end_timestamp_seconds);
  }

  printChunkStatus() {
    // DEBUG print all chunks
    console.log(`\n\n\n\n\nRecordingSession:${this.id}:\n\t\t`);
    console.log(`\t\t`, this);
    for (const chunk of this.chunks) {
      console.log(`\t\t\t\t`, chunk.id, chunk.upload_status, chunk.chunk_data);
    }
  }

  toHumanReadableDate(timestamp: number): string {
    const date = new Date(timestamp);
    return date.toLocaleString();
  }

  generateLogsString() {
    let logString = "";
    for (const log of this.logs) {
      logString += `${this.toHumanReadableDate(log.timestamp)} - ${
        log.event
      }: ${JSON.stringify(log.details)}\n`;
    }
    return logString;
  }

  public printLogs() {
    console.log(this.logs);
    for (const log of this.logs) {
      console.log(log);
    }
  }
}

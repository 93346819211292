import { Box, useTheme } from "@mui/material";
import { ReactNode } from "react";

interface BoxColumnProps {
  flex?: number;
  children: ReactNode;
}

export const BoxColumn = ({ flex = 1, children }: BoxColumnProps) => {
  const theme = useTheme();
  return (
    <Box
      className="box-column"
      sx={{
        bgcolor: "backgroundColors.primary",
        borderColor: "borderColors.primary",
        borderBottomLeftRadius: "0.75rem",
        display: "flex",
        flexGrow: flex,
        flexShrink: 1,
        flexBasis: 0,
        "&:not(:last-child)": {
          [theme.breakpoints.up("md")]: {
            borderRight: 1,
            borderColor: "borderColors.primary",
          },
        },
        [theme.breakpoints.down("sm")]: {
          "&:not(:last-child)": {
            borderRight: "none",
          },
        },
      }}
    >
      {children}
    </Box>
  );
};

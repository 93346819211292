// src/components/Modal.tsx
import React, { MouseEvent, ReactNode } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import CustomButton, { RoundedButton } from "../styles/CustomButtons";

export interface DeleteModalProps {
  isOpen: boolean;
  children: ReactNode;
  continueText: string;
  cancelText: string;
  onCancel: () => void;
  onContinue: (arg?: any) => void;
  title?: string;
}

export const DeleteModal: React.FC<DeleteModalProps> = ({
  isOpen,
  children,
  continueText,
  cancelText,
  onCancel,
  onContinue,
  title,
}) => {
  const theme = useTheme();

  if (!isOpen) {
    return null;
  }

  const handleOverlayClick = (event: MouseEvent) => {
    if (event.target === event.currentTarget) {
      onCancel();
    }
  };

  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 50,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgba(0, 0, 0, 0.4)",
      }}
      onClick={handleOverlayClick}
    >
      <Box
        sx={{
          bgcolor: "backgroundColors.primary",
          borderRadius: "12px",
          p: 4,
          width: {
            xs: "90%",
            md: "50%",
          },
          fontWeight: "400",
          color: "textColors.darkText",
        }}
      >
        <Typography
          color="textColors.lightHeader"
          fontSize="1.25rem"
          fontWeight="700"
        >
          {title ? title : "Delete?"}
        </Typography>
        {children}
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            mt: 2,
          }}
        >
          <CustomButton
            variant="text"
            sx={{
              color: theme.palette.primary.light,
              fontSize: "1rem",
              fontWeight: "600",
            }}
            onClick={onCancel}
          >
            {cancelText}
          </CustomButton>
          <RoundedButton
            variant="contained"
            sx={{ backgroundColor: "backgroundColors.warningDark" }}
            onClick={onContinue}
          >
            {continueText}
          </RoundedButton>
        </Box>
      </Box>
    </Box>
  );
};

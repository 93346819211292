import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { BoxColumn } from "../../components/layout/BoxColumn";
import MultiColumnCenterBox from "../../layouts/MultiColumnCenterBox";
import ModulePicker from "../../components/Admin/pickers/ModulePicker";
import TemplatePicker from "../../components/Admin/pickers/TemplatePicker";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import UserPicker from "../../components/Admin/pickers/UserPicker";
import NotePicker from "../../components/Admin/pickers/NotePicker";
import APIService from "../../services/APIService";
import { textToParagraph } from "../../utils/utils";

export const GenerationSessionView: React.FC = () => {
  const [selectedUserId, setSelectedUserId] = useState<string>();
  const [selectedModuleId, setSelectedModuleId] = useState<string>();
  const [selectedTemplateId, setSelectedTemplateId] = useState<string>();
  const [selectedNoteId, setSelectedNoteId] = useState<string>();

  const [pronouns, setPronouns] = useState<string>();
  const [age, setAge] = useState<string>();
  const [subject, setSubject] = useState<string>();
  const [verbosity, setVerbosity] = useState<string>();
  const [style, setStyle] = useState<string>();
  const [additionalInfo, setAdditionalInfo] = useState<string>("");

  const [awaitingTaskId, setAwaitingTaskId] = useState<string>();
  const [taskResult, setTaskResult] = useState<
    { text: string; promptPackage: any } | undefined
  >();

  const pollingIntervalId = useRef<number | null>(null);

  const canSubmit = selectedModuleId && selectedTemplateId && selectedNoteId;

  // Split the text by newlines and map each non-empty line to a <p> element
  const paragraphs = taskResult?.text?.split("\n").map((line, i) => {
    if (line) {
      return textToParagraph({ text: line, className: "py-2" });
    }
  });

  const handlePronounsChange = (event: SelectChangeEvent) => {
    setPronouns(event.target.value);
  };

  const handleAgeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setAge(event.target.value);
  };

  const handleSubjectChange = (event: SelectChangeEvent) => {
    setSubject(event.target.value);
  };

  const handleVerbosityChange = (event: SelectChangeEvent) => {
    setVerbosity(event.target.value);
  };

  const handleStyleChange = (event: SelectChangeEvent) => {
    setStyle(event.target.value);
  };

  const handleAdditionalInfoChange = (
    event: ChangeEvent<HTMLTextAreaElement>
  ) => {
    setAdditionalInfo(event.target.value);
  };

  const handleSubmit = async () => {
    if (!canSubmit) {
      alert("All fields must be filled out before submitting.");
      return;
    }

    const accessToken = localStorage.getItem("accessToken");

    if (!accessToken) {
      return;
    }

    const url = "/testsuite/simulateSectionGeneration";
    const body = {
      note_id: selectedNoteId,
      template_id: selectedTemplateId,
      module_id: selectedModuleId,
      target_user_id: selectedUserId,
      demo_info: {
        age: age,
        pronouns: pronouns,
      },
      formatting_info: {
        subject: subject,
        verbosity: verbosity,
        style: style,
      },
      provided_context_string: additionalInfo,
    };

    try {
      const response = await APIService.makeAPIPostRequest({
        requestString: url,
        accessToken: accessToken,
        body: body,
      });

      if (response.ok) {
        setAwaitingTaskId(response.value.task_id);
        setTaskResult(undefined);
      }
    } catch (error) {
      console.error("Failed to submit:", error);
    }
  };

  const checkTaskStatus = async (taskId: string) => {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      return;
    }
    try {
      const response = await APIService.makeAPIGetRequest({
        requestString: `/testsuite/getSimulateSectionGenerationTaskStatus?task_id=${taskId}`,
        accessToken: accessToken,
      });
      if (response.ok && response.value.status !== 202) {
        console.log(response.value.task_result);
        if (pollingIntervalId.current) {
          clearInterval(pollingIntervalId.current);
        }
        setAwaitingTaskId(undefined); // Clear the taskId
        setTaskResult({
          text: response.value.task_result.text_result,
          promptPackage: response.value.task_result.prompt_package,
        }); // Store the result
      }
    } catch (error) {
      console.error("Failed to check task status:", error);
      if (pollingIntervalId.current) {
        clearInterval(pollingIntervalId.current);
      }
    }
  };

  useEffect(() => {
    if (awaitingTaskId) {
      pollingIntervalId.current = setInterval(() => {
        checkTaskStatus(awaitingTaskId);
      }, 3000) as unknown as number;
    }
    return () => {
      if (pollingIntervalId.current) {
        clearInterval(pollingIntervalId.current);
      }
    };
  }, [awaitingTaskId]);

  return (
    <MultiColumnCenterBox headingText="Your Templates">
      <BoxColumn flex={1}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            overflow: "scroll",
          }}
        >
          <UserPicker
            onUserSelect={(selectedUser) => {
              setSelectedUserId(selectedUser);
            }}
          />
          <NotePicker
            onNoteSelect={(selectedNote) => {
              setSelectedNoteId(selectedNote);
            }}
            userId={selectedUserId}
          />

          <TemplatePicker
            onTemplateSelect={(selectedTemplate) => {
              setSelectedTemplateId(selectedTemplate);
            }}
            userId={selectedUserId}
          />
          <ModulePicker
            onModuleSelect={(selectedModule) => {
              setSelectedModuleId(selectedModule);
            }}
          />

          <Box sx={{ display: "flex", gap: 2, alignItems: "center", mt: 4 }}>
            <FormControl fullWidth>
              <InputLabel id="pronouns-select-label">Pronouns</InputLabel>
              <Select
                labelId="pronouns-select-label"
                id="pronouns-select"
                value={pronouns}
                label="Pronouns"
                onChange={handlePronounsChange}
              >
                <MenuItem value="he/him">He/Him</MenuItem>
                <MenuItem value="she/her">She/Her</MenuItem>
                <MenuItem value="they/them">They/Them</MenuItem>
              </Select>
            </FormControl>
            <TextField
              label="Age"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              value={age}
              onChange={handleAgeChange}
            />
          </Box>

          <Box sx={{ display: "flex", flexDirection: "column", gap: 2, mt: 4 }}>
            <FormControl fullWidth>
              <InputLabel id="subject-select-label">Subject</InputLabel>
              <Select
                labelId="subject-select-label"
                id="subject-select"
                value={subject}
                label="Subject"
                onChange={handleSubjectChange}
              >
                <MenuItem value="Patient">Patient</MenuItem>
                <MenuItem value="Client">Client</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="verbosity-select-label">Verbosity</InputLabel>
              <Select
                labelId="verbosity-select-label"
                id="verbosity-select"
                value={verbosity}
                label="Verbosity"
                onChange={handleVerbosityChange}
              >
                <MenuItem value="terse">Terse</MenuItem>
                <MenuItem value="verbose">Verbose</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="style-select-label">Style</InputLabel>
              <Select
                labelId="style-select-label"
                id="style-select"
                value={style}
                label="Style"
                onChange={handleStyleChange}
              >
                <MenuItem value="paragraphs">Paragraphs</MenuItem>
                <MenuItem value="bullet points">Bullet Points</MenuItem>
              </Select>
            </FormControl>

            <TextField
              label="Additional Info"
              variant="outlined"
              multiline
              rows={4}
              value={additionalInfo}
              onChange={handleAdditionalInfoChange}
              inputProps={{
                maxLength: 500, // Set maximum length to 500 characters
              }}
              helperText={`${additionalInfo?.length}/500`}
            />
          </Box>

          <Button variant="contained" onClick={handleSubmit}>
            Submit
          </Button>
        </Box>
      </BoxColumn>
      <BoxColumn flex={2}>
        <Box
          sx={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            overflow: "scroll",
          }}
        >
          {awaitingTaskId ? (
            <CircularProgress /> // Show spinner while awaitingTaskId is set
          ) : taskResult ? (
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Box
                sx={{
                  m: 4,
                  p: 4,
                  border: 1,
                  borderRadius: "0.75rem",
                  color: "textColors.darkText",
                  borderColor: "borderColors.primary",
                  bgcolor: "backgroundColors.secondary",
                }}
              >
                <Typography variant="body1">
                  {paragraphs?.map((graph) => graph)}
                </Typography>
              </Box>
              <Box
                sx={{
                  m: 4,
                  p: 4,
                  border: 1,
                  borderRadius: "0.75rem",
                  color: "textColors.darkText",
                  borderColor: "borderColors.primary",
                  bgcolor: "backgroundColors.tertiary",
                }}
              >
                {taskResult && taskResult.promptPackage && (
                  <>
                    <Box sx={{ mb: 4, border: "1px dashed", p: 4 }}>
                      Model: {taskResult.promptPackage.model}
                      <br />
                      Temperature: {taskResult.promptPackage.temperature}
                    </Box>
                    <Box sx={{ mb: 4, border: "1px dashed", p: 4 }}>
                      System Prompt: <br />
                      <br />{" "}
                      {taskResult.promptPackage?.system_prompt
                        .split("\n")
                        .map((message: string, index: number) => (
                          <p>{message}</p>
                        ))}
                    </Box>
                    <Box sx={{ mb: 4, border: "1px dashed", p: 4 }}>
                      {taskResult.promptPackage?.total_context_string
                        .split("\n\n")
                        .map((paragraph: string, p_index: number) => (
                          <Box key={p_index} mb={2}>
                            {" "}
                            {paragraph.split("\n").map((line, lineIndex) => (
                              <Typography key={lineIndex}>{line}</Typography>
                            ))}
                          </Box>
                        ))}
                    </Box>
                    <Box sx={{ mb: 4, border: "1px dashed", p: 4 }}>
                      {taskResult.promptPackage?.total_generation_string
                        .split("\n")
                        .map((message: string, index: number) => (
                          <p>{message}</p>
                        ))}
                    </Box>
                    <Box sx={{ mb: 4, border: "1px dashed", p: 4 }}>
                      Formatting Instructions: <br />
                      <br />{" "}
                      {taskResult.promptPackage?.formatting_instructions
                        .split("\n")
                        .map((message: string, index: number) => (
                          <p>{message}</p>
                        ))}
                    </Box>
                  </>
                )}
              </Box>
            </Box>
          ) : (
            <Typography variant="body1">
              Submit to start the simulation.
            </Typography> // Placeholder text when idle
          )}
        </Box>
      </BoxColumn>
    </MultiColumnCenterBox>
  );
};

import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  CardActionArea,
  useTheme,
  CircularProgress,
} from "@mui/material";
import { useUser } from "../context/user";
import APIService from "../services/APIService";

interface ModalityOrDiagnosis {
  id: number;
  public_name: string;
  slug: string;
  type: "MODALITY" | "DIAGNOSIS";
}

interface ModalitiesDiagnosesFormProps {
  onSuccessfulFormSubmission: () => void;
  onNext: () => void;
}

const ModalitiesDiagnosesForm: React.FC<ModalitiesDiagnosesFormProps> = ({
  onSuccessfulFormSubmission,
  onNext,
}) => {
  const theme = useTheme();
  const { getAccessToken } = useUser();
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [items, setItems] = useState<ModalityOrDiagnosis[]>([]);
  const [fetchError, setFetchError] = useState<string | null>(null);

  useEffect(() => {
    const fetchItems = async () => {
      setLoading(true);
      try {
        const accessToken = await getAccessToken();
        if (!accessToken) {
          throw new Error("No access token available");
        }

        const result = await APIService.makeAPIGetRequest({
          requestString: "/user/getModalitiesAndDiagnoses",
          accessToken: accessToken,
        });

        if (result.ok) {
          console.log(result.value);
          setItems(result.value);
        } else {
          throw new Error("Failed to fetch items");
        }
      } catch (error) {
        setFetchError(
          "Failed to load modalities and diagnoses. Please try again later."
        );
        console.error("Error fetching modalities and diagnoses:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchItems();
  }, []);

  const handleItemToggle = (slug: string): void => {
    setSelectedItems((prev) =>
      prev.includes(slug)
        ? prev.filter((item) => item !== slug)
        : [...prev, slug]
    );
  };

  const handleSubmit = async (): Promise<void> => {
    setLoading(true);
    try {
      const accessToken = await getAccessToken();
      if (!accessToken) {
        throw new Error("No access token available");
      }

      const result = await APIService.makeAPIPostRequest({
        requestString: "/user/addModalitiesAndDiagnosesToUser",
        accessToken: accessToken,
        body: {
          items: selectedItems,
        },
      });

      if (result.ok) {
        onSuccessfulFormSubmission();
      } else {
        throw new Error("Failed to submit additional factors");
      }
    } catch (error) {
      console.error("Error submitting additional factors:", error);
      // Handle error (e.g., show error message to user)
    } finally {
      setLoading(false);
    }
  };

  if (fetchError) {
    return <Typography color="error">{fetchError}</Typography>;
  }

  if (loading && items.length === 0) {
    return <CircularProgress />;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "70vh",
        p: 4,
      }}
    >
      <Typography variant="h6" gutterBottom sx={{ mb: 4 }}>
        Select any conditions or modalities you regularly work with:
      </Typography>
      <Box
        sx={{
          flexGrow: 1,
          overflowY: "auto",
          mb: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: 2,
            justifyContent: "center",
            pb: 2,
          }}
        >
          {items.map((item) => (
            <Box
              key={item.slug}
              sx={{
                width: {
                  xs: "calc(50% - 8px)",
                  sm: "calc(33.33% - 10.67px)",
                  md: "calc(25% - 12px)",
                },
                maxWidth: "150px",
              }}
            >
              <Card
                raised={selectedItems.includes(item.slug)}
                sx={{
                  height: 0,
                  paddingTop: "100%",
                  position: "relative",
                  backgroundColor: selectedItems.includes(item.slug)
                    ? theme.palette.backgroundColors?.secondary
                    : theme.palette.background.default,
                }}
              >
                <CardActionArea
                  onClick={() => handleItemToggle(item.slug)}
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <CardContent>
                    <Typography align="center" fontWeight="bold">
                      {item.public_name}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Box>
          ))}
        </Box>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
        <Button onClick={onNext}>Skip</Button>
      </Box>
      <Button
        variant="contained"
        sx={{ mt: 3 }}
        disabled={loading || selectedItems.length === 0}
        onClick={handleSubmit}
      >
        {loading ? <CircularProgress size={24} /> : "Submit"}
      </Button>
    </Box>
  );
};

export default ModalitiesDiagnosesForm;

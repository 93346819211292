import { useContext, useEffect, useRef, useState } from "react";
import {
  json,
  Params,
  redirect,
  useLoaderData,
  useNavigate,
  useParams,
  useRouteError,
} from "react-router-dom";
import {
  Box,
  ListItem,
  Typography,
  Switch,
  Tooltip,
  Button,
  useTheme,
  Modal,
} from "@mui/material";
import { NoteSection } from "../components/NoteSection";
import { Sidebar } from "../components/layout/Sidebar";
import { SVGIcon } from "../components/SVGIcon";
import { MainContainer } from "../components/layout/MainContainer";
import {
  formatDate,
  formatDuration,
  stripTags,
  textToParagraph,
} from "../utils/utils";
import { DebugContext } from "../main";
import { Transcript, TranscriptType } from "../components/Transcript";
import { useAuth0 } from "@auth0/auth0-react";
import { useUIState } from "../context/uiState";
import { DeleteModal } from "../components/DeleteModal";
import EmailNoteModal from "../components/EmailNoteModal";
import { NoteFeedbackBox } from "../components/NoteFeedbackBox";
import { AdminEmails, AdminTemplate, DemoNoteDataType } from "../types/types";
import AssociateNoteWithPatientBox from "../components/AssociateNoteWithPatientBox";
import { RoundedButton } from "../styles/CustomButtons";
import DeleteIcon from "@mui/icons-material/Delete";
import EmailIcon from "@mui/icons-material/Email";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckIcon from "@mui/icons-material/Check";
import { getUserInfoFromLocalStorage } from "../utils/LocalStorageUtils";
import React from "react";
import { ConditionalTooltip } from "../components/ConditionalTooltip";
import { useUser } from "../context/user";
import { NoteOnboardingModal } from "../components/Modals/NoteOnboardingModal";

import { Alarm, Assignment, Edit } from "@mui/icons-material";
import APIService, { API_BASE_URL } from "../services/APIService";
import { useLocation } from "react-router-dom";
import { useThemeContext } from "../context/theme/context";
import SpellModal from "../components/Modals/SpellModal";
import { clarity } from "../services/ClarityService";
import CloseButton from "../components/Buttons/CloseButton";
import TemplatePicker from "../components/Pickers/TemplatePicker";
import { SimpleModal } from "../components/Modals/SimpleModal";
import { ModuleCustomizationView } from "./ModuleCustomizationView";
import { NoteMetadata } from "../components/NoteMetadata";
import SpellcheckIcon from "@mui/icons-material/Spellcheck";

export type NoteSectionType = {
  name: string;
  section_id: string;
  text: string;
  context?: string;
  edited_text?: string;
  edits: any[];
  error?: string;
  is_rated: boolean;
  module_id: string;
  model?: string;
  priority?: number;
};

export type NoteDataType = {
  note_id: string;
  note_title: string;
  internal_title?: string;
  issuing_user: string;
  issuing_user_name?: string;
  time_uploaded: number;
  auth_users: string[];
  transcript: TranscriptType;
  transcription_length_seconds?: number;
  content: {
    summary: string;
    sections: NoteSectionType[];
    edited_sections?: { name: string; text: string; context?: string }[];
  };
  config: any;
  is_rated: boolean;
  is_copied?: boolean;
  has_feedback: boolean;
  patient_id?: string;
  template: AdminTemplate;
  transcription_start_timestamp: number;
  transcription_end_timestamp: number;
};

interface Section {
  id: string;
}

export const NoteLoader = async ({
  params,
}: {
  params: Params;
}): Promise<NoteDataType | null> => {
  const accessToken = localStorage.getItem("accessToken");
  const note = await fetch(`${API_BASE_URL}/notes/${params.noteId}`, {
    method: "get",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    }),
  })
    .then((res) => {
      if (res.status === 401) {
        throw json(
          {
            message: "You are not authorized to access this resource.",
            cta: "Login",
          },
          { status: 401 }
        );
      }

      return res.json();
    })
    .then((data: NoteDataType) => {
      return data;
    })
    .catch(() => {
      throw json(
        {
          message: "There has been an error. Please login again.",
          cta: "Login",
        },
        { status: 500 }
      );
    });

  return note;
};

export const DemoNoteLoader = async ({
  params,
}: {
  params: Params;
}): Promise<DemoNoteDataType | null> => {
  const note = await fetch(`${API_BASE_URL}/demoNote/${params.noteId}`, {
    method: "get",
    headers: new Headers({
      "Content-Type": "application/json",
    }),
  })
    .then((res) => {
      if (res.status === 401) {
        throw json(
          {
            message: "You are not authorized to access this resource.",
            cta: "Login",
          },
          { status: 401 }
        );
      }

      return res.json();
    })
    .then((data: DemoNoteDataType) => {
      return data;
    })
    .catch(() => {
      throw json(
        {
          message: "There has been an error. Please login again.",
          cta: "Login",
        },
        { status: 500 }
      );
    });

  return note;
};

interface NoteViewProps {
  demoMode?: boolean;
}

interface ModuleCustomizationModalInfo {
  showModal: boolean;
  module_id: string;
  note_id: string;
  initialComparisonText: string;
  section_name: string;
  sectionIndex: number;
}

function NoteView({ demoMode = false }: NoteViewProps) {
  const [showOnlyMissedSection, setShowOnlyMissedSection] = useState(false);

  const theme = useTheme();
  const debug = useContext(DebugContext);
  const { noteId } = useParams();
  const note = useLoaderData() as Awaited<ReturnType<typeof NoteLoader>>;
  const demoNote = useLoaderData() as Awaited<
    ReturnType<typeof DemoNoteLoader>
  >;
  const userInfo = getUserInfoFromLocalStorage();
  const navigate = useNavigate();
  const [noteData, setNoteData] = useState(
    demoMode ? (demoNote as NoteDataType) : (note as NoteDataType)
  );
  const [added, setAdded] = useState<number[]>([]);
  const [saving, setSaving] = useState<number[]>([]);
  const [viewMode, setViewMode] = useState<{ mode: "note" | "transcript" }>({
    mode: "note",
  });
  const {
    state: uiState,
    setState: setUIState,
    showAlertBanner,
  } = useUIState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [pdfGenerating, setPDFGenerating] = useState(false);

  const [activeSection, setActiveSection] = useState<Section>();
  const scrollRef = useRef<HTMLDivElement>(null);

  const [emailList, setEmailList] = useState<AdminEmails[]>([]);
  const [emailModalOpen, setEmailModalOpen] = useState<boolean>(false);
  const [sendPDFEmailList, setSendPDFEmailList] = useState<string[]>([]);
  const [loadingText, setLoadingText] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const [copiedInSession, setCopiedInSession] = useState(false);
  const [loadingSections, setLoadingSections] = useState<string[]>();
  const [editInstructions, setEditInstructions] = useState<string>("");

  const intervalRef = useRef<number | undefined>();

  const [showTooltips, setShowTooltips] = useState<boolean>(demoMode);
  const [showNoteOnboarding, setShowNoteOnboarding] = useState<boolean>(false);
  const [selectedSections, setSelectedSections] = useState<number[]>([]);

  const [moduleCustomizationModalInfo, setModuleCustomizationModalInfo] =
    useState<ModuleCustomizationModalInfo>({
      showModal: false,
      module_id: "",
      initialComparisonText: "",
      note_id: noteId || "",
      section_name: "",
      sectionIndex: 0,
    });

  const location = useLocation();
  const origin = location.state?.origin; // Access the 'origin' property from state
  const [misspelledWords, setMisspelledWords] = useState<string[]>([]);

  const [matchedWords, setMatchedWords] = useState<string[]>([]);
  const [matchedMisspelled, setMatchedMisspelled] = useState<string[]>([]);
  const { troubleWords, addTroubleWords } = useUser();

  const { userState, updateOnboardingStep, getAccessToken } = useUser();
  const { brandLanguage } = useThemeContext();
  const [openSpellingModal, setOpenSpellingModal] = useState(false);
  const [openTemplateRegenerateModal, setOpenTemplateRegenerateModal] =
    useState(false);
  const [templateIdToRegenerate, setTemplateIdToRegenerate] =
    useState<string>();

  const handleAdd = (sectionIndex: number) => {
    if (added.includes(sectionIndex)) {
      setAdded((current) =>
        current.filter((section) => section !== sectionIndex)
      );
    } else {
      setAdded((current) => [...current, sectionIndex].sort((a, b) => a - b)); // Add new section index and sort the array
    }
  };

  const handleOpenSpellingModal = () => {
    setShowOnlyMissedSection(matchedMisspelled.length === 0);
    setOpenSpellingModal(true);
  };
  const handleSaveEdits = async (sectionIndex: number, editedText: string) => {
    // update data locally
    setNoteData((prevNoteData) => {
      const newNoteData = { ...prevNoteData }; // create a copy
      newNoteData.content.sections = [...newNoteData.content.sections]; // create a new reference for the sections array
      newNoteData.content.sections[sectionIndex].edited_text = editedText;
      return newNoteData;
    });

    if (demoMode) {
      return;
    }

    // update data on server
    const accessToken = await getAccessToken();

    setSaving([...saving, sectionIndex]);

    const response = await APIService.makeAPIPostRequest({
      requestString: "/notes/editSection",
      accessToken: accessToken,
      body: {
        note_id: noteId,
        section_index: sectionIndex,
        edited_text: editedText,
      },
    });

    if (response.ok) {
      const data = response.value;
      setSaving((current) =>
        current.filter((section) => section !== sectionIndex)
      );
    }
  };

  const recheckForMisspellings = (sections: NoteSectionType[]) => {
    const misspelledMatches: string[] = [];
    const correctSpellingMatches: string[] = [];

    if (
      !troubleWords ||
      typeof troubleWords !== "object" ||
      troubleWords === null
    ) {
      console.warn(
        "troubleWords is not properly defined or is not a valid object"
      );
      return;
    }

    // Convert troubleWords to an array if it's an object
    const troubleWordsArray = Array.isArray(troubleWords)
      ? troubleWords
      : Object.entries(troubleWords).map(
          ([correct_spelling, misspellings]) => ({
            correct_spelling,
            misspellings: Array.isArray(misspellings)
              ? misspellings
              : [misspellings],
          })
        );

    sections.forEach((section) => {
      const lowerCaseText = section.text.toLowerCase();

      troubleWordsArray.forEach((troubleWord) => {
        const correctSpelling = troubleWord.correct_spelling;
        troubleWord.misspellings.forEach((misspelling) => {
          if (typeof misspelling !== "string") {
            console.error(
              `Non-string misspelling for ${correctSpelling}:`,
              misspelling
            );
            return;
          }
          const lowerCaseMisspelling = misspelling.toLowerCase();
          if (
            lowerCaseText.includes(lowerCaseMisspelling) &&
            !misspelledMatches.includes(lowerCaseMisspelling)
          ) {
            misspelledMatches.push(lowerCaseMisspelling);
            if (!correctSpellingMatches.includes(correctSpelling)) {
              correctSpellingMatches.push(correctSpelling);
            }
          }
        });
      });
    });

    setMatchedMisspelled(misspelledMatches);
    setMatchedWords(correctSpellingMatches);
  };

  const handleSpellingModalSubmit = (
    updates: { original: string; corrected: string }[]
  ) => {
    const updatedSections = noteData.content.sections.map((section) => {
      let updatedText = section.edited_text
        ? section.edited_text
        : section.text;
      updates.forEach(({ original, corrected }) => {
        const regex = new RegExp(`\\b${original}\\b`, "gi");
        updatedText = updatedText.replace(regex, corrected);
        // console.log(corrected);
      });

      if (updatedText !== section.text) {
        handleSaveEdits(
          noteData.content.sections.indexOf(section),
          updatedText
        );
        return {
          ...section,
          edited_text: updatedText,
        };
      }
      return section;
    });

    // console.log(updates, matchedWords, matchedMisspelled);

    setNoteData((prevData) => ({
      ...prevData,
      content: {
        ...prevData.content,
        sections: updatedSections,
      },
    }));

    // Update matchedWords and matchedMisspelled
    const newMatchedWords = matchedWords.filter(
      (word) =>
        !updates.some(
          (update) => update.original.toLowerCase() === word.toLowerCase()
        )
    );
    const newMatchedMisspelled = matchedMisspelled.filter(
      (word) =>
        !updates.some(
          (update) => update.original.toLowerCase() === word.toLowerCase()
        )
    );

    setMatchedWords(newMatchedWords);
    setMatchedMisspelled(newMatchedMisspelled);
    console.log(updates, newMatchedWords, newMatchedMisspelled);

    // Recheck for misspellings
    recheckForMisspellings(updatedSections);

    setOpenSpellingModal(false);
    setSelectedWord(null);
    // console.log(selectedWord);
  };

  const handleRevertEdits = async (sectionIndex: number) => {
    // Revert edits locally
    setNoteData((prevNoteData) => {
      const newNoteData = { ...prevNoteData };
      newNoteData.content.sections = [...newNoteData.content.sections];
      newNoteData.content.sections[sectionIndex].edited_text = "";

      return newNoteData;
    });

    if (demoMode) {
      return;
    }

    // Revert data on server
    const accessToken = await getAccessToken();

    setSaving([...saving, sectionIndex]);

    const response = await APIService.makeAPIPostRequest({
      requestString: "/notes/revertSection",
      accessToken: accessToken,
      body: {
        note_id: noteId,
        section_index: sectionIndex,
      },
    });

    if (response.ok) {
      const data = response.value;
      setSaving((current) =>
        current.filter((section) => section !== sectionIndex)
      );
    }
  };

  const handleSetCopyStatus = async () => {
    if (demoMode) {
      return;
    }

    if (!copiedInSession && !note?.is_copied) {
      // only update is_copied to true if not already set
      const accessToken = await getAccessToken();

      const response = await APIService.makeAPIPostRequest({
        requestString: "/notes/setCopyStatus",
        accessToken: accessToken,
        body: { note_id: noteId },
      });

      if (response.ok) {
        setCopiedInSession(true);
      }
    }
  };

  const handleSendCopyEvents = async (
    module_ids: string[],
    copied_all: boolean
  ) => {
    if (module_ids.length === 0) {
      // exit the function if no module_ids are present
      return;
    }

    const accessToken = await getAccessToken();

    const response = await APIService.makeAPIPostRequest({
      requestString: "/notes/recordCopyEvent",
      accessToken: accessToken,
      body: {
        note_id: noteId,
        module_ids: module_ids,
        copied_all: copied_all,
      },
    });
  };

  const handleSectionClick = (sectionIndex: number) => {
    setActiveSection({ id: String(sectionIndex) });
    setUIState({
      ...uiState,
      sidebar: { isHidden: true },
    });
  };

  const handleSidebarIconClick = (
    event: React.MouseEvent,
    sectionIndex: number
  ) => {
    // Prevent event propagation
    event.stopPropagation();

    handleAddSection(sectionIndex);
  };

  const handleAddSection = (sectionIndex: number) => {
    setSelectedSections((prevSelectedSections) => {
      if (prevSelectedSections.includes(sectionIndex)) {
        return prevSelectedSections.filter((index) => index !== sectionIndex);
      } else {
        return [...prevSelectedSections, sectionIndex];
      }
    });
  };

  const titleCase = (sentence: string) => {
    let trimmedSentence = sentence.trim();

    const words = trimmedSentence.split(" ");

    for (let i = 0; i < words.length; i++) {
      words[i] = words[i][0].toUpperCase() + words[i].substr(1);
    }

    const newSentence = words.join(" ");

    return newSentence;
  };

  const handleCopySections = (copyAll = false) => {
    let copyString = "";
    let moduleIds: string[] = [];

    // // Format header information
    const headerString =
      `Note Title: ${noteData.note_title}\n` +
      `Provider: ${noteData.issuing_user_name}\n` +
      `Date: ${formatDate(noteData.time_uploaded, "datelong")}\n` +
      `Time: ${
        noteData.transcription_start_timestamp
          ? `${formatDate(noteData.transcription_start_timestamp, "time")} – ` +
            `${formatDate(noteData.transcription_end_timestamp, "time")}`
          : formatDate(noteData.time_uploaded, "time")
      }\n` +
      `Duration: ${
        noteData.transcription_length_seconds
          ? formatDuration(noteData.transcription_length_seconds)
          : "N/A"
      }\n\n`;

    if (copyAll) {
      copyString += headerString; // Add header information to the copy string only if you copy All
    }
    // Iterate over all sections in the note
    (noteData?.content?.sections || []).forEach((section, sectionIndex) => {
      // Check if the section index is in the `selectedSections` array or if copyAll is true
      if (selectedSections.includes(sectionIndex) || copyAll === true) {
        copyString += `===\n${titleCase(section.name as string)}\n===\n\n`;
        copyString += `${
          section.edited_text ? section.edited_text : section.text
        }\n\n`;

        if (section.module_id) {
          moduleIds.push(section.module_id);
        }
      }
    });

    copyString = stripTags(copyString);

    navigator.clipboard.writeText(copyString);
    showAlertBanner("Sections copied to clipboard!", "success", true);

    if (demoMode) {
      return;
    }

    handleSendCopyEvents(moduleIds, copyAll);
    handleSetCopyStatus();
  };

  const handleGeneratePDF = async () => {
    if (demoMode) {
      return;
    }

    setPDFGenerating(true);
    const accessToken = await getAccessToken();

    const response = await APIService.makeAPIPostRequest({
      requestString: "/notes/generatePDF",
      accessToken: accessToken,
      body: {
        note_id: noteId,
      },
    });

    if (response.ok) {
      const data = response.value;

      // start polling
      const polling = setInterval(async () => {
        const statusResponse = await APIService.makeAPIGetRequest({
          requestString: `/notes/checkPDFStatus/${data.pdf_generation_session_id}`,
          accessToken: accessToken,
        });

        if (statusResponse.ok) {
          const statusData = statusResponse.value;
          if (statusData.status === "completed") {
            clearInterval(polling);
            setPDFGenerating(false);
            // Open the PDF in a new tab
            window.open(statusData.download_url, "_blank");
          }
        }
      }, 3000); // polling every 3 seconds
    }
  };

  const handleRegenerateSection = async (
    moduleId: string,
    setAwaitingRegenerationTaskId: (taskId: string) => void
  ) => {
    if (demoMode) {
      return;
    }

    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      return;
    }

    const response = await APIService.makeAPIPostRequest({
      requestString: "/notes/regenerateSection",
      accessToken: accessToken,
      body: {
        note_id: noteId,
        module_id: moduleId,
      },
    });

    if (response.ok) {
      setAwaitingRegenerationTaskId(response.value.task_id);
    }
  };

  const handleRegenerateNoteWithNewTemplate = async () => {
    if (!templateIdToRegenerate) {
      return;
    }

    if (demoMode) {
      return;
    }

    const accessToken = await getAccessToken();

    if (!accessToken) {
      return;
    }

    const response = await APIService.makeAPIPostRequest({
      requestString:
        "/noteProcessingSession/retryNoteProcessingWithNewTemplate",
      accessToken: accessToken,
      body: {
        note_id: noteId,
        template_id: templateIdToRegenerate,
      },
    });

    if (response.ok) {
      navigate(`/status/${response.value.note_id}`);
    }
  };

  const handleRequestAIEdit = async (
    sectionText: string,
    editInstructions: string,
    moduleId: string,
    setAwaitingEditTaskId: (taskId: string) => void
  ) => {
    const accessToken = await getAccessToken();

    if (!accessToken) {
      return;
    }

    const url = "/notes/editSectionWithAI";
    const body = {
      section_content: sectionText,
      edit_instructions: editInstructions,
      module_id: moduleId,
      note_id: noteData.note_id,
    };

    try {
      const response = await APIService.makeAPIPostRequest({
        requestString: url,
        accessToken: accessToken,
        body: body,
      });

      if (response.ok) {
        setAwaitingEditTaskId(response.value.task_id);
      }
    } catch (error) {
      console.error("Failed to submit:", error);
    }

    clarity.setTag("sessionType", "aiedit");
  };

  const fetchAdminEmails = async () => {
    if (demoMode) {
      return;
    }

    const accessToken = await getAccessToken();

    const response = await APIService.makeAPIGetRequest({
      requestString: "/user/getAdminEmails",
      accessToken: accessToken,
    });

    if (response.ok) {
      const data = response.value;
      setEmailList(data.admin_emails);
    }
  };

  const handleOpenModal = () => {
    fetchAdminEmails();
    setEmailModalOpen(true);
  };

  const sendPDFAsEmail = async () => {
    if (sendPDFEmailList.length > 0) {
      setLoadingText("Sending");
      const accessToken = await getAccessToken();

      try {
        const response = await APIService.makeAPIPostRequest({
          requestString: "/email/sendPDFAsEmail",
          accessToken: accessToken,
          body: {
            note_id: noteId,
            email_list: sendPDFEmailList,
          },
        });

        if (response.ok) {
          const data = response.value;
          setSendPDFEmailList([]);
          setLoadingText("Sent");
          setTimeout(() => setLoadingText(""), 1000);
          setTimeout(() => setEmailModalOpen(false), 1000);
        } else {
          throw new Error("Failed to send PDF email.");
        }
      } catch (error) {
        console.error(error);
        setLoadingText("Sorry, could not send.");
        setTimeout(() => setLoadingText(""), 3000);
        setTimeout(() => setEmailModalOpen(false), 1000);
      }
    } else {
      setError("Please select at least one email.");
    }
  };

  const handleDeleteNote = async () => {
    if (demoMode) {
      setShowDeleteModal(false);
      return;
    }

    const accessToken = await getAccessToken();

    const response = await APIService.makeAPIPostRequest({
      requestString: "/notes/delete",
      accessToken: accessToken,
      body: { note_id: noteId },
    });

    if (response.ok) {
      const data = response.value;
      if (data.status === 200) {
        const win: Window = window;
        win.location = `/notes`;
      }
    }

    setShowDeleteModal(false);
  };

  const handleArchiveNote = async () => {
    if (demoMode) {
      return;
    }

    const accessToken = await getAccessToken();

    const response = await APIService.makeAPIPostRequest({
      requestString: "/notes/updateNoteReviewStatus",
      accessToken: accessToken,
      body: {
        note_id: noteId,
        status: "archived",
      },
    });

    if (response.ok) {
      const data = response.value;
      if (data.status === 200) {
        const win: Window = window;

        if (origin === "group") {
          win.location = `/group`;
        } else {
          win.location = `/notes`;
        }
      }
    }
  };

  const handleSubmitSectionRatingFeedback = async (
    section_id: string,
    rating: number,
    module_id: string
  ) => {
    if (!section_id) {
      return;
    }
    if (demoMode) {
      return;
    }

    const accessToken = await getAccessToken();

    const response = await APIService.makeAPIPostRequest({
      requestString: "/feedback/addNoteSectionFeedbackEvent",
      accessToken: accessToken,
      body: {
        note_id: noteId,
        section_id: section_id,
        section_rating: rating,
        module_id: module_id,
      },
    });

    if (response.ok) {
      const data = response.value;
    }
  };

  const handleSubmitSectionCommentFeedback = async (
    section_id: string,
    comment: string,
    module_id: string
  ) => {
    if (!section_id) {
      return;
    }
    if (demoMode) {
      return;
    }

    const accessToken = await getAccessToken();

    const response = await APIService.makeAPIPostRequest({
      requestString: "/feedback/addNoteSectionFeedbackEvent",
      accessToken: accessToken,
      body: {
        note_id: noteId,
        section_id: section_id,
        section_comment: comment,
        module_id: module_id,
      },
    });

    if (response.ok) {
      const data = response.value;
    }
  };

  const handleSubmitNoteCommentFeedback = async (comment: string) => {
    if (demoMode) {
      return;
    }

    const accessToken = await getAccessToken();

    const response = await APIService.makeAPIPostRequest({
      requestString: "/feedback/addNoteFeedbackEvent",
      accessToken: accessToken,
      body: {
        note_id: noteId,
        note_comment: comment,
      },
    });

    if (response.ok) {
      const data = response.value;
    }
  };

  const handleSubmitNoteRatingFeedback = async (rating: number) => {
    if (demoMode) {
      return;
    }

    const accessToken = await getAccessToken();

    const response = await APIService.makeAPIPostRequest({
      requestString: "/feedback/addNoteFeedbackEvent",
      accessToken: accessToken,
      body: {
        note_id: noteId,
        note_rating: rating,
      },
    });

    if (response.ok) {
      const data = response.value;
    }
  };

  const handleCloseNoteOnboarding = () => {
    setShowNoteOnboarding(false);
  };

  const updateNoteDataSections = (updatedSections: NoteSectionType[]) => {
    setNoteData((prevNoteData) => {
      // Create a copy of the current noteData
      let newNoteData = { ...prevNoteData };

      // Iterate over the updated sections and update the corresponding sections in noteData
      updatedSections.forEach((updatedSection) => {
        const sectionIndex = newNoteData.content.sections.findIndex(
          (section) => section.section_id === updatedSection.section_id
        );

        if (sectionIndex !== -1) {
          newNoteData.content.sections[sectionIndex] = updatedSection;
        }
      });

      return newNoteData;
    });
  };

  const getLoadingNoteSections = async (sectionIds: string[]) => {
    if (!sectionIds) {
      return;
    }
    if (sectionIds.length <= 0) {
      return;
    }

    const accessToken = await getAccessToken();

    const response = await APIService.makeAPIPostRequest({
      requestString: "/notes/getNoteSectionsBySectionIds",
      accessToken: accessToken,
      body: {
        note_id: noteId,
        section_ids: sectionIds,
      },
    });

    if (response.ok) {
      const data = response.value as { data: NoteSectionType[] };
      if (data.data && data.data.length > 0) {
        updateNoteDataSections(data.data);
      }
    }
  };

  const [selectedWord, setSelectedWord] = useState<string | null>(null);

  const handleWordClick = (word: string) => {
    if (!troubleWords) {
      console.error("troubleWords is not defined");
      return;
    }

    const correctSpelling = troubleWords.find(
      (entry) =>
        entry.correct_spelling.toLowerCase() === word.toLowerCase() ||
        entry.misspellings.some(
          (misspelling) => misspelling.toLowerCase() === word.toLowerCase()
        )
    )?.correct_spelling;

    setSelectedWord(word);
    setOpenSpellingModal(true);
    clarity.setTag("sessionType", "spellingmodal");
  };

  const formatMatchedWords = (words: string[]) => {
    return words.map((word, index) => (
      <React.Fragment key={index}>
        {index > 0 && (index === words.length - 1 ? " and " : ", ")}
        <span
          style={{ color: "#36229E", cursor: "pointer" }}
          onClick={() => handleWordClick(word)}
        >
          {word}
        </span>
      </React.Fragment>
    ));
  };

  const handleNewWordsSubmit = (
    correctWord: string,
    newMisspellings: string[]
  ) => {
    if (!troubleWords) {
      console.error("troubleWords is not defined");
      return;
    }

    const newTroubleWords = [...troubleWords];
    const existingEntryIndex = newTroubleWords.findIndex(
      (entry) => entry.correct_spelling === correctWord
    );

    if (existingEntryIndex === -1) {
      // If the correct word doesn't exist, add a new entry
      newTroubleWords.push({
        correct_spelling: correctWord,
        misspellings: newMisspellings,
      });
    } else {
      // If the correct word exists, update its misspellings
      newTroubleWords[existingEntryIndex] = {
        ...newTroubleWords[existingEntryIndex],
        misspellings: [
          ...new Set([
            ...newTroubleWords[existingEntryIndex].misspellings,
            ...newMisspellings,
          ]),
        ],
      };
    }

    // Update the troubleWords state
    //setTroubleWords(newTroubleWords);
  };

  const handleModuleCustomizationSession = (sectionIndex: number) => {
    const section = noteData.content.sections[sectionIndex];

    if (!section.module_id) {
      return;
    }

    setModuleCustomizationModalInfo((prevState) => ({
      ...prevState,
      showModal: true,
      module_id: section.module_id,
      note_id: noteId || "",
      initialComparisonText: section.edited_text
        ? section.edited_text
        : section.text,
      section_name: section.name,
      sectionIndex: sectionIndex,
    }));
  };

  const getRelevantMisspellings = (words: string[]) => {
    if (!troubleWords) {
      console.error("troubleWords is not defined");
      return {};
    }

    const relevantMisspellings: { [key: string]: string[] } = {};
    words.forEach((word) => {
      const lowerCaseWord = word.toLowerCase();
      const matchingEntry = troubleWords.find(
        (entry) =>
          entry.correct_spelling.toLowerCase() === lowerCaseWord ||
          entry.misspellings.some(
            (misspelling) => misspelling.toLowerCase() === lowerCaseWord
          )
      );

      if (matchingEntry) {
        relevantMisspellings[matchingEntry.correct_spelling] =
          matchingEntry.misspellings;
      }
    });

    return relevantMisspellings;
  };

  const highlightMisspelledWords = (
    text: string,
    misspelledWords: string[],
    onClick: (word: string) => void
  ) => {
    let highlightedText = text;

    misspelledWords.forEach((word) => {
      const regex = new RegExp(`\\b${word}\\b`, "gi");
      highlightedText = highlightedText.replace(regex, (match) => {
        return `<span class="highlight" onclick="openSpellModal('${match}')">${match}</span>`;
      });
    });

    return highlightedText;
  };

  const handleHighlightClick = (word: string) => {
    setSelectedWord(word);
    setOpenSpellingModal(true);
  };

  useEffect(() => {
    if (scrollRef.current && activeSection) {
      // Find the associated content section in the main container
      const contentSection = scrollRef.current.querySelector(
        `#section-${activeSection.id}`
      );

      // Scroll the main container to the content section
      contentSection?.scrollIntoView({ behavior: "smooth", inline: "start" });
    }
  }, [activeSection]);

  // Helper function to escape special regex characters
  function escapeRegExp(string: string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  }

  useEffect(() => {
    const emptyTextSectionIds: string[] = [];
    const misspelledMatches: string[] = [];
    const correctSpellingMatches: string[] = [];

    if (!troubleWords) {
      console.warn("troubleWords is not defined");
      return;
    }

    noteData.content.sections.forEach((section) => {
      if (section.text === "") {
        emptyTextSectionIds.push(section.section_id);
      }

      const text = section.text;

      troubleWords.forEach((troubleWord) => {
        const correctSpelling = troubleWord.correct_spelling;
        troubleWord.misspellings.forEach((misspelling: string) => {
          // Use lookahead and lookbehind to match words bounded by spaces, punctuation, or start/end of string
          // Updated regex to include single and double quotes
          const regex = new RegExp(
            `(?<=^|\\s|[.,!?;:()\\[\\]\\-/'"])(${escapeRegExp(
              misspelling
            )})(?=\\s|[.,!?;:()\\[\\]\\-/'"]|$)`,
            "gi"
          );
          const matches = text.match(regex);
          if (
            matches &&
            !misspelledMatches.includes(misspelling.toLowerCase())
          ) {
            misspelledMatches.push(misspelling.toLowerCase());
            if (!correctSpellingMatches.includes(correctSpelling)) {
              correctSpellingMatches.push(correctSpelling);
            }
          }
        });
      });
    });

    setMatchedMisspelled(misspelledMatches);
    setMatchedWords(correctSpellingMatches);

    if (emptyTextSectionIds.length > 0) {
      if (!intervalRef.current) {
        intervalRef.current = setInterval(async () => {
          await getLoadingNoteSections(emptyTextSectionIds);
        }, 5000) as unknown as number;
      }
    } else {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = undefined;
      }
    }
  }, [noteData, troubleWords]);

  // useEffect(() => {
  //   const emptyTextSectionIds: string[] = [];
  //   const misspelledMatches: string[] = [];
  //   const correctSpellingMatches: string[] = [];

  //   if (!troubleWords) {
  //     console.warn('troubleWords is not defined');
  //     return;
  //   }

  //   noteData.content.sections.forEach((section) => {
  //     if (section.text === "") {
  //       emptyTextSectionIds.push(section.section_id);
  //     }

  //     const lowerCaseText = section.text.toLowerCase();

  //     troubleWords.forEach((troubleWord) => {
  //       const correctSpelling = troubleWord.correct_spelling;
  //       troubleWord.misspellings.forEach((misspelling: string) => {
  //         const lowerCaseMisspelling = misspelling.toLowerCase();
  //         if (lowerCaseText.includes(lowerCaseMisspelling) && !misspelledMatches.includes(lowerCaseMisspelling)) {
  //           misspelledMatches.push(lowerCaseMisspelling);
  //           if (!correctSpellingMatches.includes(correctSpelling)) {
  //             correctSpellingMatches.push(correctSpelling);
  //           }
  //         }
  //       });
  //     });
  //   });

  //   setMatchedMisspelled(misspelledMatches);
  //   setMatchedWords(correctSpellingMatches);

  //   if (emptyTextSectionIds.length > 0) {
  //     if (!intervalRef.current) {
  //       intervalRef.current = setInterval(async () => {
  //         await getLoadingNoteSections(emptyTextSectionIds);
  //       }, 5000) as unknown as number;
  //     }
  //   } else {
  //     if (intervalRef.current) {
  //       clearInterval(intervalRef.current);
  //       intervalRef.current = undefined;
  //     }
  //   }
  // }, [noteData, troubleWords]);

  useEffect(() => {
    if (userState?.onboardingSteps && !demoMode) {
      if (!userState.onboardingSteps.viewed_first_note) {
        setShowTooltips(true);
        updateOnboardingStep("viewed_first_note", true);
      }
    }
  }, [userState]);

  const buttonStyle = {
    backgroundColor: "#4e54c8",
    color: "white",
    padding: "5px 5px",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    outline: "none",
    boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
    fontSize: "10px",
    top: "70%",
  };

  return (
    <Box className="flex-1">
      {/* <NoteOnboardingModal
        isOpen={showNoteOnboarding}
        onClose={handleCloseNoteOnboarding}
        title={demoMode ? "Demo Note" : ""}
      /> */}
      <EmailNoteModal
        emailModalOpen={emailModalOpen}
        setEmailModalOpen={setEmailModalOpen}
        sendPDFAsEmail={sendPDFAsEmail}
        setSendPDFEmailList={setSendPDFEmailList}
        loadingText={loadingText}
        error={error}
        setError={setError}
      />
      <DeleteModal
        isOpen={showDeleteModal}
        continueText="Delete"
        cancelText="Cancel"
        onCancel={() => {
          setShowDeleteModal(false);
        }}
        onContinue={handleDeleteNote}
      >
        Are you sure you want to delete this note? This action cannot be undone.
      </DeleteModal>

      {noteData.content && (
        <Box>
          <Sidebar
            setSelectedSections={setSelectedSections}
            selectedSections={selectedSections}
          >
            <Box
              className="flex-grow overflow-y-scroll"
              sx={{
                background:
                  "linear-gradient(white 30%, rgba(255, 255, 255, 0)) center top, linear-gradient(rgba(255, 255, 255, 0), white 70%) center bottom, radial-gradient(farthest-side at 50% 0,rgba(0, 0, 0, 0.2),rgba(0, 0, 0, 0)) center top, radial-gradient(farthest-side at 50% 100%,rgba(0, 0, 0, 0.2),rgba(0, 0, 0, 0)) center bottom;",
                backgroundRepeat: "no-repeat",
                backgroundSize: "100% 40px, 100% 40px, 100% 20px, 100% 20px;",
                backgroundAttachment: "local, local, scroll, scroll",
              }}
            >
              <ul>
                {noteData.content &&
                  noteData.content.sections.map((section, sectionIndex) => {
                    return (
                      <ListItem key={sectionIndex} sx={{ width: "100%" }}>
                        <ConditionalTooltip
                          title="An overview of all of the sections of the note. Click this to automatically scroll to that section."
                          condition={showTooltips}
                          placement={"right"}
                          arrow
                        >
                          <Box
                            onClick={() => handleSectionClick(sectionIndex)}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                              px: 1,
                              fontSize: "text-base",
                              color: "textColors.darkHeader",
                              borderRadius: "lg",
                              "&:hover": {
                                backgroundColor: "backgroundColors.tertiary",
                              },
                            }}
                          >
                            <Box
                              onClick={(event) =>
                                handleSidebarIconClick(event, sectionIndex)
                              }
                            >
                              <SVGIcon
                                name={
                                  selectedSections.includes(sectionIndex)
                                    ? "check-filled"
                                    : "check-unfilled"
                                }
                              />
                            </Box>
                            <Typography
                              variant="body1"
                              component="span"
                              sx={{ ml: 3, textTransform: "capitalize" }}
                            >
                              {section.name}
                            </Typography>
                          </Box>
                        </ConditionalTooltip>
                      </ListItem>
                    );
                  })}
              </ul>
            </Box>
            <Box className="pt-4 mt-4 space-y-2 border-t border-gray-200  text-center flex-shrink-0">
              <Box
                sx={{
                  width: "85%",
                  margin: "auto",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {selectedSections.length > 0 && (
                  <RoundedButton
                    variant="outlined"
                    sx={{
                      width: "100%",
                      fontSize: "0.85rem",
                      fontWeight: "700",
                      px: 2,
                      py: 1,
                      mr: 1,
                      mb: 1,
                    }}
                    onClick={() => setSelectedSections([])}
                  >
                    Deselect All
                  </RoundedButton>
                )}

                <RoundedButton
                  variant="outlined"
                  sx={{
                    width: "100%",
                    fontSize: "0.85rem",
                    fontWeight: "700",
                    whiteSpace: "nowrap",
                    px: 2,
                    py: 1,
                    mr: 1,
                    mb: 1,
                  }}
                  onClick={handleOpenSpellingModal}
                >
                  <SpellcheckIcon
                    sx={{
                      color: theme.palette.primary.light,
                      fontSize: "1rem",
                      paddingRight: 0.25,
                    }}
                  />
                  Check Spellings
                </RoundedButton>

                <ConditionalTooltip
                  title="This button copies all of the 'selected' sections to your clipboard. Press the + icon next to a section to copy it."
                  condition={showTooltips}
                  placement={"right"}
                  arrow
                >
                  <RoundedButton
                    variant="outlined"
                    sx={{
                      width: "100%",
                      fontSize: "0.85rem",
                      fontWeight: "700",
                      whiteSpace: "nowrap",
                      px: 2,
                      py: 1,
                      mr: 1,
                      mb: 1,
                    }}
                    onClick={() => handleCopySections(false)}
                  >
                    <ContentCopyIcon
                      sx={{
                        color: theme.palette.primary.light,
                        fontSize: "1rem",
                        paddingRight: 0.25,
                      }}
                    />
                    Copy Selected Sections
                  </RoundedButton>
                </ConditionalTooltip>
                <SpellModal
                  open={openSpellingModal}
                  onClose={() => {
                    setOpenSpellingModal(false);
                    setSelectedWord(null);
                  }}
                  matchedWords={matchedWords}
                  misspelledWords={matchedMisspelled}
                  selectedWord={selectedWord}
                  onSubmit={handleSpellingModalSubmit}
                  onNewWordsSubmit={handleNewWordsSubmit}
                  troubleWords={troubleWords}
                  addTroubleWords={addTroubleWords}
                />
                <ConditionalTooltip
                  title="This button automatically copies all sections of the note to your clipboard."
                  condition={showTooltips}
                  placement={"right"}
                  arrow
                >
                  <RoundedButton
                    variant="outlined"
                    sx={{
                      width: "100%",
                      fontSize: "0.85rem",
                      fontWeight: "700",
                      whiteSpace: "nowrap",
                      px: 2,
                      py: 1,
                      mr: 1,
                      mb: 1,
                    }}
                    onClick={() => handleCopySections(true)}
                  >
                    <ContentCopyIcon
                      sx={{
                        color: theme.palette.primary.light,
                        fontSize: "1rem",
                        paddingRight: 0.25,
                      }}
                    />
                    Copy All Sections
                  </RoundedButton>
                </ConditionalTooltip>
                {userInfo?.featureFlags?.emailNote && (
                  <RoundedButton
                    variant="outlined"
                    sx={{
                      width: "100%",
                      fontSize: "0.85rem",
                      fontWeight: "700",
                      px: 2,
                      py: 1,
                      mr: 1,
                      mb: 1,
                    }}
                    onClick={handleOpenModal}
                  >
                    <EmailIcon
                      sx={{
                        color: theme.palette.primary.light,
                        fontSize: "1rem",
                        paddingRight: 0.25,
                      }}
                    />
                    Email Note
                  </RoundedButton>
                )}
                <ConditionalTooltip
                  title="When you're done with a note, you can archive it for later and we'll keep it around."
                  condition={showTooltips}
                  placement={"right"}
                  arrow
                >
                  <RoundedButton
                    variant="outlined"
                    sx={{
                      width: "100%",
                      fontSize: "0.85rem",
                      fontWeight: "700",
                      whiteSpace: "nowrap",
                      px: 2,
                      py: 1,
                      mr: 1,
                      mb: 1,
                    }}
                    onClick={handleArchiveNote}
                  >
                    <CheckIcon
                      sx={{
                        color: theme.palette.primary.light,
                        fontSize: "1rem",
                      }}
                    />
                    Archive Note
                  </RoundedButton>
                </ConditionalTooltip>
                <ConditionalTooltip
                  title={`When you delete a note, ${brandLanguage.brandName} permanently deletes it and all associated data.`}
                  condition={showTooltips}
                  placement={"right"}
                  arrow
                >
                  <div>
                    <RoundedButton
                      variant="outlined"
                      sx={{
                        width: "100%",
                        fontSize: "0.85rem",
                        fontWeight: "700",
                        px: 2,
                        py: 1,
                        mr: 1,
                        mb: 1,
                        color: "textColors.warning",
                        borderColor: "borderColors.warning",
                        whiteSpace: "nowrap",
                        "&:hover": {
                          bgcolor: "backgroundColors.warning",
                          borderColor: "borderColors.warning",
                        },
                      }}
                      onClick={() => setShowDeleteModal(true)}
                    >
                      <DeleteIcon
                        sx={{
                          color: "textColors.warning",
                          fontSize: "1rem",
                          paddingRight: 0.25,
                        }}
                      />
                      Delete Note
                    </RoundedButton>
                  </div>
                </ConditionalTooltip>
              </Box>
            </Box>
          </Sidebar>
          <MainContainer scrollRef={scrollRef}>
            <Box className="mb-4 flex flex-col md:flex-row">
              <Box className="w-full md:w-12 lg:w-20 mb-4 md:mb-0"></Box>
              <Box className="flex-auto">
                <Box className="flex flex-col md:flex-row justify-between items-start md:items-center">
                  <Typography
                    variant="h3"
                    sx={{
                      color: "textColors.darkHeader",
                      py: 1,
                      fontWeight: "600",
                      fontSize: {
                        xs: "1.5rem",
                        sm: "2rem",
                        md: "2.5rem",
                        lg: "3rem",
                      },
                    }}
                  >
                    {noteData.note_title}
                  </Typography>
                  <ConditionalTooltip
                    title="Switch between the 'note' and the 'transcript' with this button."
                    condition={showTooltips}
                    placement={"top"}
                    arrow
                  >
                    <Box sx={{ display: "flex", py: 1, alignItems: "center" }}>
                      <Typography
                        variant="body1"
                        sx={{
                          fontWeight: "500",
                          paddingLeft: 2,
                          paddingRight: 1,
                        }}
                        color="textColors.primaryText"
                      >
                        Note
                      </Typography>
                      <Switch
                        checked={viewMode.mode === "transcript"}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setViewMode({ mode: "transcript" });
                          } else {
                            setViewMode({ mode: "note" });
                          }
                        }}
                      />
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: "500", px: 1 }}
                        color="textColors.primaryText"
                      >
                        Transcript
                      </Typography>
                    </Box>
                  </ConditionalTooltip>
                </Box>
                <NoteMetadata
                  noteData={noteData}
                  setOpenTemplateRegenerateModal={
                    setOpenTemplateRegenerateModal
                  }
                />

                {matchedWords.length > 0 && (
                  <ConditionalTooltip
                    title="Boxes like this will show up if there is something in the note's content that we would like to draw your attention to."
                    condition={showTooltips}
                    placement={"left"}
                    arrow
                  >
                    <Box
                      sx={{
                        bgcolor: "#FFDBDA",
                        border: 1,
                        borderColor: "#E66260",
                        borderRadius: "0.75rem",
                        padding: "1rem 1.5rem",
                        width: "100%",
                        marginTop: "1.5rem",
                        position: "relative",
                      }}
                    >
                      {/* Bell emoji with absolute positioning */}
                      <span
                        style={{
                          position: "absolute",
                          top: "-10px",
                          left: "-10px",
                          fontSize: "24px",
                          // backgroundColor: '#EC8583',
                          backgroundColor: "white",
                          borderRadius: "50%",
                          padding: "5px",
                          paddingRight: "6px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "30px",
                          height: "30px",
                          boxShadow: "0px 0px 5px rgba(0,0,0,0.2)",
                        }}
                      >
                        👋
                      </span>
                      <Box>
                        {" "}
                        We've noticed {formatMatchedWords([...matchedWords])} in
                        your note. Our software might confuse{" "}
                        {matchedWords.length === 1 ? "this" : "these"} with
                        similar medication names, so please take a moment to
                        double-check {matchedWords.length === 1 ? "it" : "them"}{" "}
                        for accuracy. As always, leave feedback if you find any
                        mistakes. Thank you!
                      </Box>
                      <Box sx={{ mt: 1 }}>
                        <Button
                          style={buttonStyle}
                          onClick={() => {
                            setOpenSpellingModal(true);
                            clarity.setTag("sessionType", "spellingmodal");
                          }}
                        >
                          Review Medications
                        </Button>
                        <SpellModal
                          open={openSpellingModal}
                          onClose={() => {
                            setOpenSpellingModal(false);
                            setSelectedWord(null);
                          }}
                          matchedWords={matchedWords}
                          misspelledWords={matchedMisspelled}
                          selectedWord={selectedWord}
                          onSubmit={handleSpellingModalSubmit}
                          onNewWordsSubmit={handleNewWordsSubmit}
                          troubleWords={troubleWords}
                          // getTroubleWords={getTroubleWords}
                          addTroubleWords={addTroubleWords}
                        />
                      </Box>
                    </Box>
                  </ConditionalTooltip>
                )}
                {/* If summary exists, to be phased out in future versions of app */}
                {noteData.content.summary && (
                  <Typography
                    variant="body1"
                    sx={{ color: "textColors.primaryText" }}
                  >
                    {textToParagraph({ text: noteData.content.summary })}
                  </Typography>
                )}
              </Box>
            </Box>
            {viewMode.mode === "note" && (
              <Box>
                {noteData.content &&
                  noteData.content.sections.map((section, sectionIndex) => {
                    return (
                      <Box
                        key={sectionIndex}
                        id={`section-${sectionIndex}`}
                        style={{ scrollMarginTop: "4em" }}
                      >
                        <NoteSection
                          onWordClick={handleWordClick}
                          showTooltips={showTooltips}
                          heading={section.name}
                          text={
                            section.edited_text
                              ? section.edited_text
                              : section.text
                          }
                          sectionId={section.section_id}
                          moduleId={section.module_id}
                          isEdited={section.edited_text ? true : false}
                          isAdded={selectedSections.includes(sectionIndex)}
                          handleAdd={() => handleAddSection(sectionIndex)}
                          isRated={section.is_rated ? true : false}
                          isErrored={section.error ? true : false}
                          context={section.context}
                          handleSaveEdits={(editedText) => {
                            handleSaveEdits(sectionIndex, editedText);
                          }}
                          handleRevertEdits={() => {
                            handleRevertEdits(sectionIndex);
                          }}
                          isSaving={saving.includes(sectionIndex)}
                          handleRegenerateSection={handleRegenerateSection}
                          handleRequestAIEdit={handleRequestAIEdit}
                          handleSectionRatingFeedback={(rating: number) =>
                            handleSubmitSectionRatingFeedback(
                              section.section_id,
                              rating,
                              section.module_id
                            )
                          }
                          handleSectionCommentFeedback={(comment: string) => {
                            handleSubmitSectionCommentFeedback(
                              section.section_id,
                              comment,
                              section.module_id
                            );
                          }}
                          handleSectionSetCopyStatus={() =>
                            handleSetCopyStatus()
                          }
                          handleSectionSendCopyEvents={(module_id: string) =>
                            handleSendCopyEvents([module_id], false)
                          }
                          handleStartModuleCustomizationSession={() => {
                            handleModuleCustomizationSession(sectionIndex);
                          }}
        
                        />
                      </Box>
                    );
                  })}
              </Box>
            )}
            {viewMode.mode === "transcript" && noteData.transcript && (
              <Transcript transcript={noteData.transcript} />
            )}
            {!note?.is_rated && (
              <NoteFeedbackBox
                handleNoteCommentFeedback={(comment: string) => {
                  handleSubmitNoteCommentFeedback(comment);
                }}
                handleNoteRatingFeedback={(rating: number) => {
                  handleSubmitNoteRatingFeedback(rating);
                }}
              />
            )}
            {debug && noteData && (
              <pre className="text-black/40">
                {JSON.stringify(noteData.config, null, 4)}
              </pre>
            )}
          </MainContainer>
        </Box>
      )}
      <Modal
        open={moduleCustomizationModalInfo.showModal}
        onClose={() => {
          setModuleCustomizationModalInfo((prevState) => ({
            ...prevState,
            showModal: false,
          }));
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            outline: "none",
            borderRadius: 8,
            width: "100%",
            maxWidth: "90vw",
            height: "100%",
            maxHeight: "90vh",
            overflow: "scroll",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ModuleCustomizationView
              note_id={moduleCustomizationModalInfo.note_id}
              module_id={moduleCustomizationModalInfo.module_id}
              initialComparisonText={
                moduleCustomizationModalInfo.initialComparisonText
              }
              section_name={moduleCustomizationModalInfo.section_name}
              onClose={() => {
                setModuleCustomizationModalInfo((prevState) => ({
                  ...prevState,
                  showModal: false,
                }));
              }}
              onAccept={(generation_output: string) => {
                handleSaveEdits(
                  moduleCustomizationModalInfo.sectionIndex,
                  generation_output
                );
              }}
            />
            <CloseButton
              onClick={() => {
                setModuleCustomizationModalInfo((prevState) => ({
                  ...prevState,
                  showModal: false,
                }));
              }}
            />
          </Box>
        </Box>
      </Modal>
      <SimpleModal
        isOpen={openTemplateRegenerateModal}
        onClose={() => {
          setOpenTemplateRegenerateModal(false);
        }}
      >
        <Box sx={{ width: "80%" }}>
          <Typography variant="h5" sx={{ mb: 2 }}>
            Would you like to regenerate this note with a different template?
          </Typography>
          <TemplatePicker
            onTemplateSelect={(template_id) => {
              setTemplateIdToRegenerate(template_id);
            }}
          />
          <Box display="flex" justifyContent="flex-end" mt={2} mb={2}>
            <Button
              variant="contained"
              sx={{ backgroundColor: "grey", marginRight: 1 }}
              onClick={() => {
                setOpenTemplateRegenerateModal(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                handleRegenerateNoteWithNewTemplate();
              }}
              disabled={!templateIdToRegenerate}
            >
              Regenerate
            </Button>
          </Box>
          <Typography
            variant="caption"
            color={templateIdToRegenerate ? "red" : "textSecondary"}
            sx={{ mt: 4, alignSelf: "flex-end" }}
          >
            Regenerating is permanent, all content and edits made to this note
            will be lost.
          </Typography>
        </Box>
      </SimpleModal>
    </Box>
  );
}

export default NoteView;
import React, { useEffect, useState } from "react";
import {
  AdminModule,
  AdminSection,
  AdminSystemPrompt,
  AdminTemplate,
  ExtractionPrompts,
} from "../../types/types";
import { AdminNotesLoader } from "../../loaders/AdminNotesLoader";
import { formatDate } from "../../utils/utils";
import { useUser } from "../../context/user";
import APIService from "../../services/APIService";

interface ExtractionTestSectionProps {
  module: AdminModule;
}

export const ExtractionTestSection: React.FC<ExtractionTestSectionProps> = ({
  module,
}) => {
  const [secondQuery, setSecondQuery] = useState<ExtractionPrompts>({
    semantic_prompt: "",
    keyword_prompt: "",
  });
  const { getAccessToken } = useUser();
  const [extractionLoading, setExtractionLoading] = useState(false);
  const [results, setResults] = useState<any>();
  const [selectedNote, setSelectedNote] = useState<string>();
  const [notes, setNotes] = useState<any>();
  const [noteSearchString, setNoteSearchString] = useState<string>("");
  const [filteredNotes, setFilteredNotes] = useState<any>([]);

  const fetchNotes = async () => {
    const response = await AdminNotesLoader(0, 20);
    if (response) {
      setNotes(response);
    }
  };

  const handleGenerate = async () => {
    setExtractionLoading(true);
    const accessToken = await getAccessToken();

    const response = await APIService.makeAPIPostRequest({
      requestString: "/testsuite/compareExtractions",
      accessToken: accessToken,
      body: {
        note_id_for_transcript: selectedNote,
        num_of_tokens: module.max_tokens,
        query: module.extraction_prompts,
        query2: secondQuery,
      },
    });

    if (!response.ok) {
      console.error("there was a problem with the network request");
    } else {
      const data = response.value;
      console.log(data);
      setResults(data);
    }

    setExtractionLoading(false);
  };

  const handleSemanticChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSecondQuery({ ...secondQuery, semantic_prompt: event.target.value });
  };

  const handleKeywordChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSecondQuery({ ...secondQuery, keyword_prompt: event.target.value });
  };

  const handleNoteSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedNote(event.target.value);
  };

  const handleNoteSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value.toLowerCase();
    setNoteSearchString(searchValue);
    const filtered = notes.filter((note: any) =>
      note.internal_title?.toLowerCase().includes(searchValue)
    );
    setFilteredNotes(filtered);
  };

  useEffect(() => {
    fetchNotes();
  }, []);

  // Initialize filteredNotes with all notes
  useEffect(() => {
    setFilteredNotes(notes);
  }, [notes]);

  return (
    <div className="my-4 bg-white p-2 rounded-md shadow">
      <h3>Extraction Test</h3>
      <div className="mb-2">
        <label
          className="block text-sm text-gray-500"
          htmlFor="second_semantic_query"
        >
          Comparative Semantic Query
        </label>
        <textarea
          name="second_semantic_query"
          id="second_semantic_query"
          value={secondQuery.semantic_prompt}
          onChange={handleSemanticChange}
          className="block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        />
        <label
          className="block text-sm text-gray-500"
          htmlFor="second_keyword_query"
        >
          Comparative Keyword Query
        </label>
        <textarea
          name="second_keyword_query"
          id="second_keyword_query"
          value={secondQuery.keyword_prompt}
          onChange={handleKeywordChange}
          className="block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        />
      </div>
      <div className="flex justify-between">
        <div>
          <label className="block text-sm text-gray-500" htmlFor="note_select">
            Note Transcript
          </label>
          <input
            onChange={handleNoteSearchChange}
            value={noteSearchString}
            className="mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
          <select
            name="note_select"
            id="note_select"
            value={selectedNote}
            onChange={handleNoteSelect}
            className="block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          >
            {filteredNotes?.map((note: any, index: number) => (
              <option key={index} value={note.note_id}>
                {note.internal_title} - {formatDate(note.time_uploaded)}
              </option>
            ))}
          </select>
        </div>
        <div>
          <button
            onClick={handleGenerate}
            className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
          >
            {extractionLoading ? "Loading..." : "Generate"}
          </button>
        </div>
      </div>
      <div className="">
        {results && (
          <div className="flex justify-between">
            <div className="w-1/2 p-2">
              <h2 className="font-bold mb-2">Module Query</h2>
              <p>Token count: {results.new_tokens}</p>
              {results.results_new.map((node: any) => {
                return (
                  <div
                    key={node.node.id_}
                    className="mb-4 border-dashed border-4"
                  >
                    <div className="mb-2 font-semibold">
                      Score: {node.score} - Node index:{" "}
                      {node.node.metadata.index} - Tags:{" "}
                      {node.node.metadata.topics}
                    </div>
                    {node.node.text}
                  </div>
                );
              })}
            </div>
            <div className="w-1/2 p-2">
              <h2 className="font-bold mb-2">Comparative Query</h2>
              <p>Token count: {results.old_tokens}</p>
              {results.results_old.map((node: any) => {
                return (
                  <div
                    key={node.node.id_}
                    className="mb-4 border-dashed border-4"
                  >
                    <div className="mb-2 font-semibold">
                      Score: {node.score} - Node index:{" "}
                      {node.node.metadata.index} - Tags:{" "}
                      {node.node.metadata.topics}
                    </div>
                    {node.node.text}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

// SentryService.ts
import * as Sentry from "@sentry/react";
import { appVersion } from "./APIService";

interface SentryConfig {
  dsn: string;
  environment: "debug" | "production";
}

class SentryService {
  static initialize(config: SentryConfig) {
    Sentry.init({
      dsn: config.dsn,
      release: appVersion,
      environment: config.environment,
      integrations: [
        Sentry.breadcrumbsIntegration({ console: true }),
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
      ],
      tracesSampleRate: 1.0, // Adjust tracing sample rate as needed
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
  }

  static logEvent(
    description: string,
    {
      level = "info", // Default level set to 'info'
      tags = {}, // Default to an empty object
      extra = {}, // Default to an empty object
    }: {
      level?: Sentry.SeverityLevel;
      tags?: Record<string, string>;
      extra?: Record<string, any>;
    } = {} // Default context object to ensure destructuring works when no second argument is provided
  ) {
    Sentry.captureEvent({
      message: description,
      level,
      tags,
      extra,
    });
  }

  static captureException(
    exception: any,
    {
      level = "error", // Default level set to 'info'
      tags = {}, // Default to an empty object
      extra = {}, // Default to an empty object
    }: {
      level?: Sentry.SeverityLevel;
      tags?: Record<string, string>;
      extra?: Record<string, any>;
    } = {} // Default context object to ensure destructuring works when no second argument is provided
  ) {
    Sentry.captureException(exception, { level, tags, extra });
  }

  static captureMessage(message: string, level: Sentry.SeverityLevel = "info") {
    Sentry.captureMessage(message, level);
  }

  static setUserContext(context: {
    id: string;
    email?: string;
    username?: string;
  }) {
    Sentry.setUser(context);
  }

  static clearUserContext() {
    Sentry.setUser(null);
  }

  static setExtra(key: string, value: any) {
    Sentry.setExtra(key, value);
  }

  static setTag(key: string, value: any) {
    Sentry.setTag(key, value);
  }
}

export default SentryService;

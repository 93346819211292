import { useAuth0 } from "@auth0/auth0-react";
import { ReactNode, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const AdminViewOnly = ({ children }: { children: ReactNode }) => {

  const { user, isAuthenticated } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      const isAdmin = user && user["https://api.smartscribe.health/roles"]?.includes("Admin");
      if (!isAdmin) {
        navigate("/");
      }
    }
  }, [user, isAuthenticated, navigate]);

  return isAuthenticated ? <>{children}</> : null;

};

import { useEffect, useState, useCallback } from "react";
import React from "react";
import {
  Box,
  Typography,
  Skeleton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
} from "@mui/material";
import MultiColumnCenterBox from "../layouts/MultiColumnCenterBox";
import { BoxColumn } from "../components/layout/BoxColumn";
import { CreateGroupForm } from "../components/CreateGroupForm";
import { useUser } from "../context/user";
import { AdminGroup, GroupPendingInvitation } from "../types/types";
import { GroupDetailsLoader } from "../loaders/GroupDetailsLoader";
import { UserGroupDetailsRow } from "../components/Admin/UserGroupDetailsRow";
import { getUserInfoFromLocalStorage } from "../utils/LocalStorageUtils";
import APIService from "../services/APIService";
import { ErrorPage } from "./Shared/ErrorPage";

export const PendingInvitationRow: React.FC<{
  invitation: GroupPendingInvitation;
  onRemove: (email: string) => void;
}> = ({ invitation, onRemove }) => {
  return (
    <tr>
      <td style={{ padding: "10px 15px", border: "none", textAlign: "left" }}>
        <Button
          onClick={() => onRemove(invitation.email)}
          color="secondary"
          style={{
            minWidth: "auto",
            padding: "0",
            color: "black",
            textDecoration: "none",
            cursor: "pointer",
            fontWeight: "bold",
          }}
          onMouseOver={(e) =>
            (e.currentTarget.style.textDecoration = "underline")
          }
          onMouseOut={(e) => (e.currentTarget.style.textDecoration = "none")}
        >
          Remove
        </Button>
      </td>
      <td style={{ padding: "10px 15px", border: "none" }}></td>
      <td style={{ padding: "10px 15px", border: "none" }}>
        <i>{invitation.email}</i>
      </td>
      <td style={{ padding: "10px 15px", border: "none" }}></td>
      <td style={{ padding: "10px 15px", border: "none" }}>
        <i>Invitation Pending</i>
      </td>
    </tr>
  );
};

const InviteUserModal: React.FC<{
  isInviteModalOpen: boolean;
  setInviteModalOpen: (open: boolean) => void;
  inviteEmail: string;
  setInviteEmail: (email: string) => void;
  inviteRole: string;
  setInviteRole: (role: string) => void;
  handleInvite: () => Promise<void>;
}> = ({
  isInviteModalOpen,
  setInviteModalOpen,
  inviteEmail,
  setInviteEmail,
  inviteRole,
  setInviteRole,
  handleInvite,
}) => {
  const { getAccessToken } = useUser();
  const handleClose = () => {
    setInviteModalOpen(false);
  };

  return (
    <Dialog
      open={isInviteModalOpen}
      onClose={handleClose}
      maxWidth="sm"
      PaperProps={{
        style: { minWidth: "500px", maxWidth: "80%" },
      }}
    >
      <DialogTitle>Invite New User</DialogTitle>
      <DialogContent>
        <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
          <TextField
            autoFocus
            margin="dense"
            id="email"
            label="Email Address"
            type="email"
            fullWidth
            variant="standard"
            value={inviteEmail}
            onChange={(e) => setInviteEmail(e.target.value)}
            style={{ flex: 2 }}
          />
          <TextField
            margin="dense"
            id="role"
            select
            label="Role"
            fullWidth
            variant="standard"
            value={inviteRole}
            onChange={(e) => setInviteRole(e.target.value)}
            SelectProps={{
              native: true,
            }}
            style={{ flex: 1 }}
          >
            <option value="member">Provider</option>
            <option value="admin">View Only</option>
          </TextField>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleInvite}>Send Invite</Button>
      </DialogActions>
    </Dialog>
  );
};

export const UserGroupAdministration: React.FC = () => {
  const { getAccessToken } = useUser();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [groupDetails, setGroupDetails] = useState<AdminGroup | null>(null);
  const [groupId, setGroupId] = useState("");
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const [inviteEmail, setInviteEmail] = useState("");
  const [inviteRole, setInviteRole] = useState("member");
  const [isInviteModalOpen, setInviteModalOpen] = useState(false);
  const { userState } = useUser();
  const userInfo = getUserInfoFromLocalStorage();

  const loadGroupDetails = useCallback(async (groupId: string) => {
    try {
      const details = await GroupDetailsLoader({ params: { groupId } });
      setGroupDetails(details[0]);
      setIsLoading(false);
    } catch (e: any) {
      setError(e.message);
      console.error("Failed to load group details:", e);
    }
  }, []);

  useEffect(() => {
    const groupIds = userState?.group_ids ?? [];
    setGroupId(groupIds[0]);
    if (groupIds.length > 0) {
      loadGroupDetails(groupIds[0]);
    }
  }, [userState, loadGroupDetails]);

  useEffect(() => {
    if (groupId) {
      loadGroupDetails(groupId);
    }
  }, [groupId, loadGroupDetails]);

  const handleUserSelect = (userId: string) => {
    setSelectedUserId((prevUserId) => (prevUserId === userId ? null : userId));
  };

  const handleInvite = async () => {
    setInviteEmail("");
    setInviteModalOpen(false);

    try {
      const accessToken = await getAccessToken();

      const response = await APIService.makeAPIPostRequest({
        requestString: "/group/addUserByEmail",
        accessToken: accessToken,
        body: {
          email: inviteEmail,
          group_id: groupId,
          role: inviteRole,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to add user to group");
      }

      await loadGroupDetails(groupId);
      setSelectedUserId(null);
    } catch (error) {
      console.error("Failed to add user:", error);
      setError("Failed to add user to group");
    }
  };

  const removeUserFromGroup = async () => {
    if (!selectedUserId || !groupId) return;

    try {
      const accessToken = await getAccessToken();

      const response = await APIService.makeAPIPostRequest({
        requestString: "/group/removeUserFromGroup",
        accessToken: accessToken,
        body: {
          user_id: selectedUserId,
          group_id: groupId,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to remove user from group");
      }

      await loadGroupDetails(groupId);
      setSelectedUserId(null);
    } catch (error) {
      console.error("Failed to remove user:", error);
      setError("Failed to remove user from group");
    }
  };

  const removePendingInvitation = async (email: string) => {
    try {
      const accessToken = await getAccessToken();

      const response = await APIService.makeAPIPostRequest({
        requestString: "/group/removePendingInvitation",
        accessToken: accessToken,
        body: {
          email: email,
          group_id: groupId,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to remove pending invitation");
      }

      await loadGroupDetails(groupId);
    } catch (error) {
      console.error("Failed to remove pending invitation:", error);
      setError("Failed to remove pending invitation");
    }
  };

  const changeUserRole = async (userId: string, newRole: string) => {
    const backendRole = newRole === "Provider" ? "member" : "admin";
    try {
      const accessToken = await getAccessToken();

      const response = await APIService.makeAPIPostRequest({
        requestString: "/group/changeUserRole",
        accessToken: accessToken,
        body: {
          user_id: userId,
          group_id: groupId,
          new_role: backendRole,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to change user role");
      }

      await loadGroupDetails(groupId);
    } catch (error) {
      console.error("Failed to change user role:", error);
      setError("Failed to change user role");
    }
  };

  const userIsBillingAdmin =
    userState?.group_user_roles?.includes("billing_admin");
  const userIsInGroup = (userState?.group_ids ?? []).length > 0;

  const handleGroupCreated = async (groupId: string) => {
    setGroupId(groupId);
    await loadGroupDetails(groupId);
    window.location.reload();
  };

  if (isLoading)
    return <Skeleton variant="rectangular" width="100%" height="100%" />;
  if (error) return <ErrorPage error_message={error} />

  return (
    <MultiColumnCenterBox headingText="Group Administration">
      <BoxColumn flex={1}>
        <InviteUserModal
          isInviteModalOpen={isInviteModalOpen}
          setInviteModalOpen={setInviteModalOpen}
          inviteEmail={inviteEmail}
          setInviteEmail={setInviteEmail}
          inviteRole={inviteRole}
          setInviteRole={setInviteRole}
          handleInvite={handleInvite}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "100%",
            height: "100%",
          }}
        >
          <div className="p-4 flex-grow">
            {userIsBillingAdmin ? (
              <>
                <div
                  style={{
                    textAlign: "left",
                    width: "100%",
                    fontSize: "24px",
                    fontWeight: "bold",
                    marginBottom: "5px",
                  }}
                >
                  {groupDetails?.group_info?.name}
                </div>

                <div className="flex justify-start w-full my-4 items-center space-x-4">
                  <div className="bg-gray-200 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center">
                    You have{" "}
                    {(groupDetails?.members?.length ?? 0) === 0
                      ? "0 active subscriptions"
                      : (groupDetails?.members?.length ?? 0) === 1
                      ? "1 active subscription"
                      : `${
                          groupDetails?.members?.length ?? 0
                        } active subscriptions`}
                  </div>
                  <button
                    onClick={() =>
                      window.open(
                        `https://billing.stripe.com/p/login/7sIdRo5NjdyN1QA8ww?prefilled_email=${encodeURIComponent(
                          userInfo?.email || ""
                        )}`,
                        "_blank"
                      )
                    }
                    className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                  >
                    Billing Portal
                  </button>
                  <div className="flex-1"></div>
                  <button
                    className={`font-bold py-2 px-4 rounded ${
                      selectedUserId
                        ? "bg-red-500 hover:bg-red-700 text-white"
                        : "bg-gray-300 text-gray-200 cursor-not-allowed"
                    }`}
                    onClick={removeUserFromGroup}
                    disabled={!selectedUserId}
                  >
                    Remove User from Group
                  </button>
                  <button
                    onClick={() => setInviteModalOpen(true)}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                  >
                    Invite New User
                  </button>
                </div>

                <div className="w-full mt-4">
                  <table
                    style={{
                      width: "100%",
                      borderCollapse: "collapse",
                    }}
                  >
                    <thead>
                      <tr
                        style={{
                          backgroundColor: "#E5E7EB",
                          color: "#2d3748",
                        }}
                      >
                        <th
                          style={{
                            padding: "10px 15px",
                            fontSize: "16px",
                            fontWeight: "600",
                            border: "none",
                          }}
                        ></th>
                        <th
                          style={{
                            padding: "10px 15px",
                            fontSize: "15px",
                            fontWeight: "600",
                            border: "none",
                            textAlign: "left",
                          }}
                        >
                          Name
                        </th>
                        <th
                          style={{
                            padding: "10px 15px",
                            fontSize: "15px",
                            fontWeight: "600",
                            border: "none",
                            textAlign: "left",
                          }}
                        >
                          Email
                        </th>
                        <th
                          style={{
                            padding: "10px 15px",
                            fontSize: "15px",
                            fontWeight: "600",
                            border: "none",
                            textAlign: "left",
                          }}
                        >
                          Total Notes Created
                        </th>
                        <th
                          style={{
                            padding: "10px 15px",
                            fontSize: "15px",
                            fontWeight: "600",
                            border: "none",
                            textAlign: "left",
                          }}
                        >
                          Role
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {groupDetails?.admins?.map((admin) => (
                        <UserGroupDetailsRow
                          key={admin.user_id}
                          member={admin}
                          isSelected={admin.user_id === selectedUserId}
                          onUserSelect={handleUserSelect}
                          onChangeUserRole={changeUserRole}
                        />
                      ))}
                      {groupDetails?.members?.map((member) => (
                        <UserGroupDetailsRow
                          key={member.user_id}
                          member={member}
                          isSelected={member.user_id === selectedUserId}
                          onUserSelect={handleUserSelect}
                          onChangeUserRole={changeUserRole}
                        />
                      ))}
                      {groupDetails?.pending_invitations?.map((invitation) => (
                        <PendingInvitationRow
                          key={invitation.email}
                          invitation={invitation}
                          onRemove={removePendingInvitation}
                        />
                      ))}
                    </tbody>
                  </table>
                </div>
              </>
            ) : userIsInGroup ? (
              <p>
                You are a member of a group. Talk to your group administrator to
                learn more.
              </p>
            ) : (
              <CreateGroupForm onGroupCreated={handleGroupCreated} />
            )}
          </div>
          <div
            className="mt-8 w-full"
            style={{ marginTop: "auto", padding: "0 16px 16px" }}
          >
            <hr className="border-t border-gray-300" />
            <div className="mt-2 p-2">
              <h4 className="text-xs font-semibold">View Only</h4>
              <p className="text-gray-600 text-xs">
                Users with the "View Only" role can view and edit all notes in
                the group but cannot generate notes of their own. Users with
                this role will not incur an additional charge on your
                subscription.
              </p>
            </div>
            <div className="mt-2 p-2">
              <h4 className="text-xs font-semibold">Provider</h4>
              <p className="text-gray-600 text-xs">
                Users with the "Provider" role can create, view, and edit their
                own notes but cannot access or modify the notes of others. Users
                with this role will incur an additional charge on your
                subscription.
              </p>
            </div>
          </div>
        </Box>
      </BoxColumn>
    </MultiColumnCenterBox>
  );
};

import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { json, Params, useNavigate } from "react-router-dom";
import { PrimaryButton } from "../../components/Buttons/Buttons";
import { NoteSection } from "../../components/NoteSection";
import { UserTemplateListLoader } from "./ConfigView";
import { TemplatesLoader } from "../../loaders/TemplatesLoader";
import { AdminTemplate, AdminUser } from "../../types/types";
import { UsersLoader } from "../../loaders/UsersLoader";
import { useUser } from "../../context/user";
import APIService from "../../services/APIService";

export const NoteProcessingViewTests = () => {
  const navigate = useNavigate();
  const { getAccessToken } = useUser();

  const [transcriptsList, setTranscriptsList] = useState<any[]>([]);
  const [selectedTranscript, setSelectedTranscript] = useState<any>();
  const [templateList, setTemplateList] = useState<AdminTemplate[]>();
  const [usersList, setUsersList] = useState<AdminUser[]>();
  const [selectedTemplate, setSelectedTemplate] = useState<string>();
  const [noteTitle, setNoteTitle] = useState("");
  const [maxTokens, setMaxTokens] = useState("1500");
  const [results, setResults] = useState<any>();
  const [spinning, setSpinning] = useState<boolean>(false);
  const [noteStatusLink, setNoteStatusLink] = useState("");
  const [intendedUserId, setIntendedUserId] = useState("");

  const fetchTemplatesData = async () => {
    const response = await TemplatesLoader();
    if (response) {
      setTemplateList(response);
    }
  };

  const handleSubmitNoteProcessing = async () => {
    setSpinning(true);
    const accessToken = await getAccessToken();

    const response = await APIService.makeAPIPostRequest({
      requestString: "/testsuite/createNoteProcessingSession",
      accessToken: accessToken,
      body: {
        note_id_for_transcript: selectedTranscript,
        note_title: noteTitle,
        template_id: selectedTemplate,
        intended_user_id: intendedUserId,
      },
    });

    if (response.ok) {
      const data = response.value;
      console.log(data);
      setNoteStatusLink(`/status/${data.note_id}`);
      setSpinning(false);
    }

    console.log(response);
  };

  const fetchTranscripts = async () => {
    const accessToken = await getAccessToken();

    const response = await APIService.makeAPIGetRequest({
      requestString: "/testsuite/getAllNotes",
      accessToken: accessToken,
    });

    if (response.ok) {
      const data = response.value;
      console.log(data.notes);
      setTranscriptsList(data.notes);
    }
  };

  const fetchUsers = async () => {
    const users = await UsersLoader();
    setUsersList(users);
  };

  const handleTranscriptSelection = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedTranscript(e.target.value);
  };

  const handleTemplateSelection = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedTemplateId = e.target.value;

    if (selectedTemplateId) {
      setSelectedTemplate(selectedTemplateId);
    }
  };

  const handleUserSelection = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const userId = e.target.value;

    if (userId) {
      setIntendedUserId(userId);
    }
  };

  useEffect(() => {
    fetchTranscripts();
    fetchTemplatesData();
    fetchUsers();
  }, []);

  return (
    <div>
      <div className="mb-4">
        <label
          htmlFor="transcriptSelect"
          className="block text-sm font-medium text-gray-700"
        >
          Select a transcript to process
        </label>
        <select
          id="transcriptSelect"
          value={selectedTranscript}
          onChange={handleTranscriptSelection}
          className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
        >
          <option value="">Select a transcript to process</option>
          {transcriptsList &&
            transcriptsList.map((obj, index) => (
              <option key={index} value={obj.note_id}>
                {obj.internal_title}
              </option>
            ))}
        </select>
      </div>
      <div className="mb-4">
        <select
          value={selectedTemplate}
          onChange={handleTemplateSelection}
          className="max-w-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        >
          <option value="">Select a template to use</option>
          {templateList?.map((template, index) => (
            <option key={index} value={template.template_id}>
              {template.template_name}
            </option>
          ))}
        </select>
      </div>
      <div className="mb-4">
        <select
          value={intendedUserId}
          onChange={handleUserSelection}
          className="max-w-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        >
          <option value="">
            Optionally select a user to generate note for (will use custom
            instructions and associate with their account)
          </option>
          {usersList?.map((user, index) => (
            <option key={index} value={user._id}>
              {user.user_info?.name ? user.user_info?.name : user._id}
            </option>
          ))}
        </select>
      </div>
      <div className="mb-4">
        <label
          htmlFor="titleInput"
          className="block text-sm font-medium text-gray-700"
        >
          Note Title
        </label>
        <textarea
          id="titleInput"
          value={noteTitle}
          onChange={(e) => setNoteTitle(e.target.value)}
          className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
        />
      </div>
      {spinning && <Spinner />}
      {!spinning && (
        <div className="mb-4">
          <PrimaryButton handleClick={() => handleSubmitNoteProcessing()}>
            Send
          </PrimaryButton>
        </div>
      )}
      {noteStatusLink && (
        <PrimaryButton
          handleClick={() => {
            navigate(noteStatusLink);
          }}
        >
          View Note Process
        </PrimaryButton>
      )}
    </div>
  );
};

function Spinner() {
  return (
    <div className="flex justify-center items-center">
      <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-gray-900"></div>
    </div>
  );
}

export default NoteProcessingViewTests;

import React, { useState, useEffect } from "react";
import {
  TextField,
  FormControl,
  Box,
  Autocomplete,
  FormGroup,
  FormControlLabel,
  Switch,
  IconButton,
} from "@mui/material";
import { Visibility } from "@mui/icons-material";
import APIService from "../../services/APIService";
import {
  AdminPublicTemplate,
  AdminUserTemplate,
  UserTemplateItem,
} from "../../types/types";
import { useUser } from "../../context/user";

interface TemplatePickerProps {
  onTemplateSelect: (template_id: string) => void;
}

const TemplatePicker: React.FC<TemplatePickerProps> = ({
  onTemplateSelect,
}) => {
  const { templatesList, getAccessToken, refreshTemplatesList } = useUser();
  const [selectedTemplateId, setSelectedTemplateId] = useState<string>();

  const handleTemplateChange = (event: any, value: UserTemplateItem | null) => {
    if (value) {
      setSelectedTemplateId(value.template_id);
      onTemplateSelect(value.template_id);
    } else {
      setSelectedTemplateId("");
      onTemplateSelect("");
    }
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
      <FormControl fullWidth>
        {templatesList && (
          <Autocomplete
            options={templatesList}
            getOptionLabel={(option) =>
              option.display_name ? option.display_name : ""
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Choose a template"
                variant="outlined"
              />
            )}
            onChange={handleTemplateChange}
          />
        )}
      </FormControl>
    </Box>
  );
};

export default TemplatePicker;

import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  createTheme,
  Theme,
  ThemeOptions,
  ThemeProvider,
} from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import { useUIState } from "../uiState";
import { jotpalTheme, jotpsychTheme } from "../../styles/themes";

type BrandLanguage = {
  brandName: string;
  brandSite: string;
  brandContact: string;
};

// Extend the context type to include the brand language
type ThemeContextType = {
  brand: string;
  brandLanguage: BrandLanguage;
  setBrand: Dispatch<SetStateAction<string>>;
};

// Provide an initial value matching the context type
const ThemeContext = createContext<ThemeContextType>({
  brand: "jotpsych",
  brandLanguage: {
    brandName: "JotPsych",
    brandSite: "jotpsych.com",
    brandContact: "contact@jotpsych.com",
  },
  setBrand: () => {},
});

export const useThemeContext = () => useContext(ThemeContext);

export const ThemeContextProvider = ({ children }: { children: ReactNode }) => {
  const [brand, setBrand] = useState<string>(
    localStorage.getItem("preferred_brand") || "jotpsych"
  );

  let theme: Theme;
  let brandLanguage: BrandLanguage;

  switch (brand) {
    case "jotpsych":
      theme = jotpsychTheme;
      brandLanguage = {
        brandName: "JotPsych",
        brandSite: "jotpsych.com",
        brandContact: "contact@jotpsych.com",
      };
      document.title = `${brandLanguage.brandName} by SmartScribe — AI-Powered Medical Charting`;
      break;
    case "jotpal":
      theme = jotpalTheme;
      brandLanguage = {
        brandName: "JotPal",
        brandSite: "jotpal.ai",
        brandContact: "contact@jotpal.ai",
      };
      document.title = `${brandLanguage.brandName} by SmartScribe — AI-Powered Medical Charting`;
      break;
    default:
      theme = jotpsychTheme;
      brandLanguage = {
        brandName: "JotPsych",
        brandSite: "jotpsych.com",
        brandContact: "contact@jotpsych.com",
      };
      document.title = `${brandLanguage.brandName} by SmartScribe — AI-Powered Medical Charting`;
  }

  // Now, we include the brandLanguage in the context's value
  const contextValue = useMemo(
    () => ({
      brand,
      brandLanguage,
      setBrand,
    }),
    [brand]
  );

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const brandParam = searchParams.get("brand");
    let preferredBrand = brandParam;

    // Load the brand from localStorage if not found in URL
    if (!preferredBrand) {
      preferredBrand = localStorage.getItem("preferred_brand");
    } else if (["jotpsych", "jotpal", "smartscribe"].includes(preferredBrand)) {
      // Save to localStorage if the brand is valid and found in URL
      localStorage.setItem("preferred_brand", preferredBrand);

      // Remove the 'brand' parameter from the URL
      searchParams.delete("brand");
      const newUrl = `${window.location.pathname}${
        searchParams.toString() ? `?${searchParams.toString()}` : ""
      }${window.location.hash}`;
      window.history.replaceState(null, "", newUrl);
    }

    // Update state if preferredBrand is valid
    if (
      preferredBrand &&
      ["jotpsych", "jotpal", "smartscribe"].includes(preferredBrand)
    ) {
      setBrand(preferredBrand);
    }
  }, []); // Empty dependency array means this effect runs once on mount

  return (
    <ThemeContext.Provider value={contextValue}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};

import { Params, json } from "react-router-dom";
import { AdminNote } from "../types/types";
import { API_BASE_URL } from "../services/APIService";

export const AdminNotesLoader = async (
  skip = 0,
  limit = 10,
  user_id = "",
  sort_field = "",
  sort_direction = -1
): Promise<AdminNote[]> => {
  const accessToken = localStorage.getItem("accessToken");

  const queryParams = new URLSearchParams({
    skip: String(skip),
    limit: String(limit),
    user_id,
    sort_field,
    sort_direction: String(sort_direction),
  });

  const notesList = await fetch(
    `${API_BASE_URL}/testsuite/getNotes?${queryParams.toString()}`,
    {
      method: "get",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      }),
    }
  )
    .then((res) => {
      if (res.status === 401) {
        throw json(
          {
            message: "You are not authorized to access this resource.",
            cta: "Login",
          },
          { status: 401 }
        );
      }

      return res.json();
    })
    .then((data: { notes: AdminNote[] }) => {
      return data.notes;
    })
    .catch(() => {
      throw json(
        {
          message: "There has been an error. Please login again.",
          cta: "Login",
        },
        { status: 500 }
      );
    });

  return notesList;
};

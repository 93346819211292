import { useEffect, useState } from "react";
import { useSelectedUser } from "../../context/selectedUserContext.tsx";
import { AdminUser } from "../../types/types.js";
import { useUser } from "../../context/user/context.js";
import APIService from "../../services/APIService.js";

interface UserInfoProps {
  usersList: AdminUser[];
}

export const UserInfo = ({ usersList }: UserInfoProps) => {
  const { getAccessToken } = useUser();
  const { selectedUser } = useSelectedUser();

  if (!selectedUser) {
    return <div className="m-4 p-4 bg-red-200 rounded-md">Select a user</div>;
  }

  const userToShow = usersList.find((obj) => obj._id === selectedUser);

  if (!userToShow) {
    return <div className="m-4 p-4 bg-red-200 rounded-md">Select a user</div>;
  }

  const [isBetaUser, setIsBetaUser] = useState<boolean>(false);

  const updateUserFeatureSet = async (featureSet: string | undefined) => {
    const accessToken = await getAccessToken();
    const response = await APIService.makeAPIPostRequest({
      requestString: "/user/updateUserFeatureSet",
      accessToken: accessToken,
      body: {
        user_id: selectedUser,
        feature_set: featureSet,
      },
    });
    if (response.ok) {
      window.location.reload();
    }
  };

  const updateUserBetaStatus = async (betaStatus: boolean) => {
    const accessToken = await getAccessToken();
    const response = await APIService.makeAPIPostRequest({
      requestString: "/user/updateUserBetaStatus",
      accessToken: accessToken,
      body: {
        user_id: selectedUser,
        beta_status: betaStatus,
      },
    });

    if (response.ok) {
      window.location.reload();
    }
  };

  const handleBetaToggle = async () => {
    const newBetaStatus = isBetaUser ? false : true;
    setIsBetaUser(!isBetaUser);

    updateUserBetaStatus(newBetaStatus);
  };

  useEffect(() => {
    if (userToShow && userToShow.featureSet === "next") {
      setIsBetaUser(true);
    } else {
      setIsBetaUser(false);
    }
  }, [selectedUser]);

  return (
    <div className="m-4 p-4 bg-slate-100 rounded-md">
      <h2 className="text-2xl font-bold mb-2">{userToShow.user_info?.name}</h2>
      <p>Id: {userToShow._id}</p>
      <p>Email: {userToShow.user_info?.email}</p>
      <p>Institution: {userToShow.user_info?.institution}</p>
      <p>Specialization: {userToShow.user_info?.specialization}</p>
      <div className="mt-4">
        <label>
          Beta user?
          <input
            type="checkbox"
            checked={isBetaUser}
            onChange={handleBetaToggle}
          />
        </label>
      </div>
    </div>
  );
};

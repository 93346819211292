import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { json, Params } from "react-router-dom";
import { PrimaryButton } from "../../components/Buttons/Buttons";
import { NoteSection } from "../../components/NoteSection";
import APIService, { API_BASE_URL } from "../../services/APIService";
import { useUser } from "../../context/user";

interface Config {
  model?: string;
  temperature?: number;
  system_prompt: string;
  tasks: Array<{
    section: string;
    extraction_prompt: string;
    generation_prompt: string;
    model?: string;
  }>;
  extraction_sections: number;
  extraction_model_name: string;
  summary: boolean;
  summary_sections: string[];
  icd: boolean;
  debug: boolean;
}

interface User {
  _id: string;
  logins?: number;
  featureSet?: string;
  notes?: any;
  user_info?: {
    email?: string;
    institution?: string;
    name?: string;
    specialization?: string;
  };
  user_templates?: { user_template_id: string; title: string }[];
}

interface SectionEditorProps {
  config: Config;
  setConfig: (config: Config) => void;
  testDataList: { object_id: string }[];
}

export const SectionEditor: React.FC<SectionEditorProps> = ({
  config,
  setConfig,
  testDataList,
}) => {
  const [newSection, setNewSection] = useState<{
    section: string;
    extraction_prompt: string;
    generation_prompt: string;
  }>({
    section: "",
    extraction_prompt: "",
    generation_prompt: "",
  });
  const { getAccessToken } = useUser();
  const [transcriptsList, setTranscriptsList] = useState<any[]>([]);
  const [selectedTranscript, setSelectedTranscript] = useState<any>();
  const [results, setResults] = useState<any>({ "0": "" });
  const [resultLoading, setResultLoading] = useState<any>({ "0": false });

  const handleRunTestGeneration = async (index: number) => {
    if (!selectedTranscript) {
      return;
    }

    setResultLoading({ ...resultLoading, [`${index}`]: true });
    const data = {
      note_id_for_transcript: selectedTranscript,
      test_config: { ...config, tasks: [config.tasks[index]] },
    };

    const accessToken = await getAccessToken();

    const response = await APIService.makeAPIPostRequest({
      requestString: "/testsuite/generateSection",
      accessToken: accessToken,
      body: JSON.stringify(data),
    });

    if (response.ok) {
      const data = response.value;
      console.log(data);
      setResultLoading({ ...resultLoading, [`${index}`]: false });
      setResults({ ...results, [`${index}`]: data });
    }

    console.log(response);
  };

  const fetchTranscripts = async () => {
    const accessToken = await getAccessToken();

    const response = await APIService.makeAPIGetRequest({
      requestString: "/testsuite/getAllNotes",
      accessToken: accessToken,
    });

    if (response.ok) {
      const data = response.value;
      console.log(data.notes);
      setTranscriptsList(data.notes);
    }
  };

  const handleTranscriptSelection = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedTranscript(e.target.value);
  };

  const updateModel = (model: string) => {
    setConfig({ ...config, model: model });
  };

  const updateTemperature = (temperature: number) => {
    setConfig({ ...config, temperature: temperature });
  };

  const updateSystemPrompt = (systemPrompt: string) => {
    setConfig({ ...config, system_prompt: systemPrompt });
  };

  const updateSection = (index: number, updatedSection: any) => {
    const updatedTasks = [...config.tasks];
    updatedTasks[index] = updatedSection;
    setConfig({ ...config, tasks: updatedTasks });
  };

  const deleteSection = (index: number) => {
    const updatedTasks = [...config.tasks];
    updatedTasks.splice(index, 1);
    setConfig({ ...config, tasks: updatedTasks });
  };

  const addSection = () => {
    setConfig({ ...config, tasks: [...config.tasks, newSection] });
    setNewSection({
      section: "",
      extraction_prompt: "",
      generation_prompt: "",
    });
  };

  useEffect(() => {
    fetchTranscripts();
  }, []);

  return (
    <div className="container mx-auto">
      <div className="p-4 border-2 border-gray-300 rounded-md my-2">
        <label className="block font-bold">Model and temperature</label>
        <input
          type="text"
          value={config.model}
          onChange={(e) => updateModel(e.target.value)}
          className="block w-full p-1 border-2 border-gray-300 rounded-md"
        />
        <input
          type="number"
          value={config.temperature}
          onChange={(e) => updateTemperature(Number(e.target.value))}
          className="block w-full p-1 border-2 border-gray-300 rounded-md"
        />
      </div>
      <div className="p-4 border-2 border-gray-300 rounded-md my-2">
        <label className="block font-bold">System prompt</label>
        <textarea
          value={config.system_prompt}
          onChange={(e) => updateSystemPrompt(e.target.value)}
          className="block w-full p-1 border-2 border-gray-300 rounded-md"
        />
      </div>
      {config.tasks.map((task, index) => (
        <div
          key={index}
          className="p-4 border-2 border-gray-300 rounded-md my-2"
        >
          <h2 className="font-bold mb-2">Section {index + 1}</h2>
          <div className="space-y-2">
            <div>
              <label className="block font-bold">Section:</label>
              <input
                type="text"
                value={task.section}
                onChange={(e) =>
                  updateSection(index, { ...task, section: e.target.value })
                }
                className="block w-full p-1 border-2 border-gray-300 rounded-md"
              />
            </div>
            <div>
              <label className="block font-bold">Extraction Prompt:</label>
              <textarea
                value={task.extraction_prompt}
                onChange={(e) =>
                  updateSection(index, {
                    ...task,
                    extraction_prompt: e.target.value,
                  })
                }
                className="block w-full p-1 border-2 border-gray-300 rounded-md"
              />
            </div>
            <div>
              <label className="block font-bold">Generation Prompt:</label>
              <textarea
                value={task.generation_prompt}
                onChange={(e) =>
                  updateSection(index, {
                    ...task,
                    generation_prompt: e.target.value,
                  })
                }
                className="block w-full p-1 border-2 border-gray-300 rounded-md"
              />
            </div>
            <div>
              <label className="block font-bold">Model Override:</label>
              <textarea
                value={task.model}
                onChange={(e) =>
                  updateSection(index, {
                    ...task,
                    model: e.target.value,
                  })
                }
                className="block w-full p-1 border-2 border-gray-300 rounded-md"
              />
            </div>
          </div>
          <div className="flex flex-row justify-between">
            <button
              onClick={() => deleteSection(index)}
              className="bg-red-500 text-white font-bold py-1 px-2 mt-2 rounded-md"
            >
              Delete Section
            </button>
            <select
              value={selectedTranscript}
              onChange={handleTranscriptSelection}
              className="max-w-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              <option value="">Select a test transcript</option>
              {transcriptsList &&
                transcriptsList.map((obj, index) => (
                  <option key={index} value={obj.note_id}>
                    {obj.note_title}
                  </option>
                ))}
            </select>
            {resultLoading[index] && <div>Loading...</div>}
            {!resultLoading[index] && (
              <button
                onClick={() => handleRunTestGeneration(index)}
                className="bg-blue-500 text-white font-bold py-1 px-2 mt-2 rounded-md"
              >
                Run test generation
              </button>
            )}
          </div>
          {results[index] && (
            // <div className="p-4 border-2 border-gray-200 border-dashed rounded-lg">
            //   {resultText[index]}
            // </div>
            <NoteSection
              text={results[index].text}
              isEdited={false}
              heading={results[index].name}
              context={results[index].context}
              isAdded={false}
              handleAdd={() => {}}
              forceDebug={true}
              handleSaveEdits={() => {}}
              handleRevertEdits={() => {}}
              isSaving={false}
            />
          )}
        </div>
      ))}
      <div className="p-4 border-2 border-gray-300 rounded-md my-2">
        <h2 className="font-bold mb-2">Add New Section</h2>
        <div className="space-y-2">
          <div>
            <label className="block font-bold">Section:</label>
            <input
              type="text"
              value={newSection.section}
              onChange={(e) =>
                setNewSection({ ...newSection, section: e.target.value })
              }
              className="block w-full p-1 border-2 border-gray-300 rounded-md"
            />
          </div>
          <div>
            <label className="block font-bold">Extraction Prompt:</label>
            <textarea
              value={newSection.extraction_prompt}
              onChange={(e) =>
                setNewSection({
                  ...newSection,
                  extraction_prompt: e.target.value,
                })
              }
              className="block w-full p-1 border-2 border-gray-300 rounded-md"
            />
          </div>
          <div>
            <label className="block font-bold">Generation Prompt:</label>
            <textarea
              value={newSection.generation_prompt}
              onChange={(e) =>
                setNewSection({
                  ...newSection,
                  generation_prompt: e.target.value,
                })
              }
              className="block w-full p-1 border-2 border-gray-300 rounded-md"
            />
          </div>
        </div>
        <button
          onClick={addSection}
          className="bg-green-500 text-white font-bold py-1 px-2 mt-2 rounded-md"
        >
          Add Section
        </button>
      </div>
    </div>
  );
};

export const UsersLoader = async (): Promise<User[] | null> => {
  const accessToken = localStorage.getItem("accessToken");
  const user = await fetch(`${API_BASE_URL}/user/getUsers`, {
    method: "get",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    }),
  })
    .then((res) => {
      if (res.status === 401) {
        throw json(
          {
            message: "You are not authorized to access this resource.",
            cta: "Login",
          },
          { status: 401 }
        );
      }

      return res.json();
    })
    .then((data: { users: User[] }) => {
      return data.users;
    })
    .catch(() => {
      throw json(
        {
          message: "There has been an error. Please login again.",
          cta: "Login",
        },
        { status: 500 }
      );
    });

  return user;
};

export const ConfigLoader = async ({
  params,
}: {
  params: Params;
}): Promise<Config | null> => {
  const accessToken = localStorage.getItem("accessToken");
  const config = await fetch(
    `${API_BASE_URL}/testsuite/getUserTemplate/${params.configName}`,
    {
      method: "get",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      }),
    }
  )
    .then((res) => {
      if (res.status === 401) {
        throw json(
          {
            message: "You are not authorized to access this resource.",
            cta: "Login",
          },
          { status: 401 }
        );
      }

      return res.json();
    })
    .then((data: Config) => {
      return data;
    })
    .catch(() => {
      throw json(
        {
          message: "There has been an error. Please login again.",
          cta: "Login",
        },
        { status: 500 }
      );
    });

  return config;
};

export const UserTemplateListLoader = async (): Promise<
  { user_template_id: string; title: string }[]
> => {
  const accessToken = localStorage.getItem("accessToken");
  const userTemplateList = await fetch(
    `${API_BASE_URL}/testsuite/getUserTemplates`,
    {
      method: "get",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      }),
    }
  )
    .then((res) => {
      return res.json();
    })
    .then((data: any) => {
      console.log(data);
      return data;
    })
    .catch(() => {
      throw json(
        {
          message: "There has been an error. Please login again.",
          cta: "Login",
        },
        { status: 500 }
      );
    });

  return userTemplateList;
};

export const ConfigView = () => {
  const { getAccessToken } = useUser();
  const [configList, setConfigList] = useState<
    { user_template_id: string; title: string }[]
  >([]);
  const [usersList, setUsersList] = useState<User[]>();
  const [selectedUser, setSelectedUSer] = useState<User>();
  const [testDataList, setTestDataList] = useState<{ object_id: string }[]>([]);
  const [selectedConfig, setSelectedConfig] = useState<{
    user_template_id: string;
    title: string;
  }>();
  const [configName, setConfigName] = useState<string>("");
  const [config, setConfig] = useState<Config | null>(null);

  const [templateSaving, setTemplateSaving] = useState<boolean>(false);
  const [userSaving, setUserSaving] = useState<boolean>(false);

  const fetchConfig = async (name: string) => {
    const config = await ConfigLoader({ params: { configName: name } });
    if (config) {
      setConfig(config);
    }
  };

  const listUsers = async () => {
    const usersList = await UsersLoader();
    console.log(usersList);
    if (usersList) {
      setUsersList(usersList);
    }
  };

  const listConfigs = async () => {
    const configList = await UserTemplateListLoader();
    if (configList) {
      setConfigList(configList);
    }
  };

  const handleUserSelection = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedUserId = e.target.value;
    const selectedObj = usersList?.find((obj) => obj._id === selectedUserId);

    if (selectedObj) {
      setSelectedUSer(selectedObj);
      console.log(selectedObj);
    }
  };

  const handleSelection = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedTemplateId = e.target.value;
    const selectedObj = configList.find(
      (obj) => obj.user_template_id === selectedTemplateId
    );

    if (selectedObj) {
      setSelectedConfig(selectedObj);
      setConfigName(selectedObj.title);
    }
  };

  const handleAssignUser = async () => {
    setUserSaving(true);
    const accessToken = await getAccessToken();

    const response = await APIService.makeAPIPostRequest({
      requestString: "/testsuite/assignUserTemplate",
      accessToken: accessToken,
      body: {
        user_template_id: selectedConfig?.user_template_id,
        user_template_title: configName,
        assigned_user_id: selectedUser?._id,
      },
    });

    if (response.ok) {
      const data = response.value;
      setUserSaving(false);
      console.log(data);
    }
  };

  const handleSaveConfig = async () => {
    setTemplateSaving(true);
    const accessToken = await getAccessToken();

    const response = await APIService.makeAPIPostRequest({
      requestString: "/testsuite/updateUserTemplate",
      accessToken: accessToken,
      body: {
        user_template_id: selectedConfig?.user_template_id,
        user_template_title: configName,
        config_json: config,
      },
    });

    if (response.ok) {
      const data = response.value;
      setTemplateSaving(false);
    }
  };

  const handleDeleteConfig = async () => {
    const accessToken = await getAccessToken();

    const response = await APIService.makeAPIPostRequest({
      requestString: "/deleteConfig",
      accessToken: accessToken,
      body: { config_name: configName },
    });

    if (response.ok) {
      const data = response.value;
      const win: Window = window;
      win.location = `/config`;
    }
  };

  useEffect(() => {
    listConfigs();
    listUsers();
  }, []);

  useEffect(() => {
    if (selectedConfig) {
      fetchConfig(selectedConfig.user_template_id);
    }
  }, [selectedConfig]);

  return (
    <div>
      <div>
        <select
          value={selectedConfig?.user_template_id}
          onChange={handleSelection}
          className="max-w-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        >
          <option value="">Select a config to edit</option>
          {configList &&
            configList.map((obj, index) => (
              <option key={index} value={obj.user_template_id}>
                {obj.title}
              </option>
            ))}
        </select>
        {selectedConfig && <p>Selected config: {selectedConfig.title}</p>}
        {selectedConfig && (
          <input
            type="text"
            id="configName"
            className="bg-gray-50 max-w-sm border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder={selectedConfig.title}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setConfigName(e.target.value);
            }}
          />
        )}
      </div>
      {config && (
        <div>
          <SectionEditor
            config={config}
            setConfig={setConfig}
            testDataList={testDataList}
          />
          {!templateSaving && (
            <PrimaryButton handleClick={handleSaveConfig}>Save</PrimaryButton>
          )}
          {templateSaving && "Saving..."}
          <PrimaryButton handleClick={handleDeleteConfig}>Delete</PrimaryButton>
          <div>
            <select
              value={selectedUser?._id}
              onChange={handleUserSelection}
              className="max-w-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              <option value="">Select a user to assign template to</option>
              {usersList &&
                usersList.length > 0 &&
                usersList.map((obj, index) => (
                  <option key={index} value={obj._id}>
                    {obj.user_info?.name ? obj.user_info?.name : obj._id} |{" "}
                    {obj.user_info?.institution}
                  </option>
                ))}
            </select>
            {!userSaving && (
              <PrimaryButton handleClick={handleAssignUser}>
                Assign User
              </PrimaryButton>
            )}
            {userSaving && "Assigning..."}
            {selectedUser && selectedUser.user_templates && (
              <div>
                <p>User's current templates:</p>
                <ul>
                  {selectedUser.user_templates.map((obj, index) => (
                    <li>{obj.title}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ConfigView;

import { AdminNote } from "../../types/types";
import { NotesList } from "../../components/Admin/NotesList";
import { AdminNotesLoader } from "../../loaders/AdminNotesLoader";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const NotesOverview: React.FC = () => {
  const { user_id } = useParams<{ user_id?: string }>();
  const [isLoading, setIsLoading] = useState(true);
  const [notes, setNotes] = useState<AdminNote[] | null>();
  const [page, setPage] = useState(0);
  const [perPage] = useState(10);
  const [sortField, setSortField] = useState("time_uploaded");
  const [sortDirection, setSortDirection] = useState(-1);

  const fetchNotesData = async () => {
    setIsLoading(true);
    const response = await AdminNotesLoader(
      page,
      perPage,
      user_id,
      sortField,
      sortDirection
    );
    if (response) {
      setIsLoading(false);
      console.log(response);
      setNotes(response);
    }
  };

  const handlePageChange = (newPage: number) => {
    setPage(Math.max(0, newPage));
  };

  const handleSortChange = (field: string) => {
    if (field === sortField) {
      // Toggle sort direction if the field is the same
      setSortDirection(sortDirection === -1 ? 1 : -1);
    } else {
      // Default to ascending sort for a new field
      setSortField(field);
      setSortDirection(sortDirection === -1 ? 1 : -1);
    }
  };

  useEffect(() => {
    fetchNotesData();
  }, [page, perPage, sortField, sortDirection]);

  if (isLoading) return <div>Loading...</div>;

  return (
    <div className="flex">
      <div className="w-3/4 overflow-x-auto h-screen">
        <div>
          Notes {page} – {page + perPage}
        </div>
        <NotesList notesList={notes!} handleSortChange={handleSortChange} />
        <div className="flex justify-between">
          <button onClick={() => handlePageChange(page - 10)}>
            Previous Page
          </button>
          <button onClick={() => handlePageChange(page + 10)}>Next Page</button>
        </div>
      </div>
    </div>
  );
};

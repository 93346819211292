import { AdminNote } from "../../types/types";
import { NotesList } from "../../components/Admin/NotesList";
import { AdminNotesLoader } from "../../loaders/AdminNotesLoader";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { NoteProcessingView } from "../NoteProcessingView";
import NoteProcessingViewTests from "./NoteProcessingTestsView";
import { CustomPromptEditor } from "./CustomPromptsEditor";

export const AdminToolsView: React.FC = () => {
  return (
    <div className="flex">
      {/* <div className="border p-4">
        <div className="text-xl pb-4">Regenerate Note</div>
        <NoteProcessingViewTests />
      </div> */}
      <div className="border p-4">
        <div className="text-xl pb-4">Edit Custom Prompts</div>
        <CustomPromptEditor />
      </div>
    </div>
  );
};

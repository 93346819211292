import { useNavigate, useNavigation } from "react-router-dom";
import { textToParagraph } from "../utils/utils";

export type DemoNoteType = {
  title: string;
  link: string;
  summary: string;
};

export const DemoNote = ({ title, summary, link }: DemoNoteType) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(link);
  };

  return (
    <div
      className="p-4 m-4 border-2 border-gray-200 rounded-lg hover:border-purple-400 hover:cursor-pointer transition-colors"
      onClick={handleClick}
    >
      <div className="flex justify-between mb-4">
        <div className="text-4xl font-bold">{title}</div>
      </div>
      <div>{textToParagraph({ text: summary })}</div>
    </div>
  );
};

import styled from "@emotion/styled";
import { Button } from "@mui/material";

const CustomButton = styled(Button)({
  // your custom styles go here
  textTransform: "none",
}) as typeof Button;

export const CircleButton = styled(CustomButton)(() => ({
  borderRadius: "100px",
})) as typeof CustomButton;

export const RoundedButton = styled(CustomButton)(() => ({
  borderRadius: "0.5rem",
  margin: ".25rem",
})) as typeof CustomButton;

export default CustomButton;

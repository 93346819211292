import React from "react";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../utils/utils";
import { SVGIcon } from "./SVGIcon";
import { Patient } from "../types/types";

export interface PatientsListItemProps {
  patient: Patient;
}

export const PatientsListItem = ({ patient }: PatientsListItemProps) => {
  const navigate = useNavigate();

  const handleClick = () => {
    // Navigate to patient details page
    navigate(`/patient/${patient.patient_id}`);
  };

  return (
    <div
      className="relative p-4 m-4 border-2 border-gray-200 rounded-lg hover:border-purple-400 hover:cursor-pointer transition-colors"
      onClick={handleClick}
    >
      <div className="flex justify-between">
        <div className="text-4xl font-bold">{patient.patient_info.name}</div>
        <div className="text-black/20">{patient.patient_id}</div>
      </div>
      {patient.patient_info.dob && (
        <div className="text-l font-bold">DOB: {patient.patient_info.dob}</div>
      )}
      {patient.last_visit && (
        <div className="text-l font-bold">
          Last Visit: {formatDate(patient.last_visit)}
        </div>
      )}
      {patient.notes && patient.notes.length > 0 && (
        <div className="text-l font-bold">
          Total Notes: {patient.notes.length}
        </div>
      )}
    </div>
  );
};

import React from "react";
import {
  Box,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";

interface TestNoteTranscriptViewProps {
  transcript: { speaker: string; text: string }[];
}

export const TestNoteTranscriptView: React.FC<TestNoteTranscriptViewProps> = ({
  transcript,
}) => {
  return (
    <Paper
      elevation={3}
      sx={{
        maxWidth: 600,
        maxHeight: 400,
        overflow: "auto",
        margin: "auto",
        padding: 2,
        backgroundColor: "background.paper",
      }}
    >
      <List>
        {transcript.map((line, index) => (
          <ListItem
            key={index}
            sx={{
              "&:nth-of-type(odd)": {
                backgroundColor: "action.hover",
              },
              borderRadius: 1,
              my: 1,
            }}
          >
            <ListItemText
              primary={
                <React.Fragment>
                  <Typography component="span" fontWeight="bold">
                    {line.speaker}:
                  </Typography>{" "}
                  {line.text}
                </React.Fragment>
              }
              sx={{
                "& .MuiListItemText-primary": {
                  fontFamily: "monospace",
                  fontSize: "0.9rem",
                },
              }}
            />
          </ListItem>
        ))}
      </List>
    </Paper>
  );
};

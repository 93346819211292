import React from "react";
import { formatDate } from "../../utils/utils"; // Import or define formatDate function
import { Box, Typography } from "@mui/material";

interface EditDiffComponentProps {
  diffs: any[];
  timestamp: number;
}

const EditDiffComponent = ({ diffs, timestamp }: EditDiffComponentProps) => {
  return (
    <Box className="bg-white shadow-lg rounded-lg p-6 mt-4 outline-slate-200">
      <Box className="flex justify-between items-start mb-4">
        <h3 className="text-xl font-bold">Edit Diff</h3>
        <span className="text-gray-400 text-sm">{formatDate(timestamp)}</span>
      </Box>
      <Box className="text-base font-mono">
        {diffs.map((diff, index) => {
          // diff[0] is the operation (insert, delete, equal)
          // diff[1] is the text of the operation
          switch (diff[0]) {
            case 1:
              return (
                <span key={index} className="font-bold">
                  {diff[1]}
                </span>
              );
            case -1:
              return (
                <span key={index} className="text-gray-400 line-through">
                  {diff[1]}
                </span>
              );
            case 0:
              return <span key={index}>{diff[1]}</span>;
            default:
              return null;
          }
        })}
      </Box>
    </Box>
  );
};

export default EditDiffComponent;

import { API_BASE_URL } from "../services/APIService";
import { AdminSpecialty, AdminUser } from "../types/types";

export const SpecialtiesLoader = async (): Promise<AdminSpecialty[]> => {
  const accessToken = localStorage.getItem("accessToken");
  const specialtiesList = await fetch(
    `${API_BASE_URL}/specialties/getAllSpecialties`,
    {
      method: "get",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      }),
    }
  )
    .then((res) => {
      if (res.status === 401) {
        throw new Error(
          JSON.stringify({
            message: "You are not authorized to access this resource.",
            cta: "Login",
          })
        );
      }

      return res.json();
    })
    .then((data: { specialties: AdminSpecialty[] }) => {
      return data.specialties;
    })
    .catch(() => {
      throw new Error(
        JSON.stringify({
          message: "There has been an error. Please login again.",
          cta: "Login",
        })
      );
    });

  return specialtiesList;
};

export const AvailableSpecialtiesLoader = async (): Promise<
  AdminSpecialty[]
> => {
  const accessToken = localStorage.getItem("accessToken");
  const specialtiesList = await fetch(
    `${API_BASE_URL}/specialties/getAvailableSpecialties`,
    {
      method: "get",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      }),
    }
  )
    .then((res) => {
      if (res.status === 401) {
        throw new Error(
          JSON.stringify({
            message: "You are not authorized to access this resource.",
            cta: "Login",
          })
        );
      }

      return res.json();
    })
    .then((data: { specialties: AdminSpecialty[] }) => {
      return data.specialties;
    })
    .catch(() => {
      throw new Error(
        JSON.stringify({
          message: "There has been an error. Please login again.",
          cta: "Login",
        })
      );
    });

  return specialtiesList;
};

import React, { useState, useEffect } from "react";
import {
  TextField,
  FormControl,
  Box,
  Autocomplete,
  IconButton,
} from "@mui/material";
import { AdminNote } from "../../../types/types";
import APIService from "../../../services/APIService";
import { formatDate } from "../../../utils/utils";
import { Visibility } from "@mui/icons-material";

interface NotePickerProps {
  onNoteSelect: (user_id: string) => void;
  userId?: string; // Optional userId prop
}

const NotePicker: React.FC<NotePickerProps> = ({ onNoteSelect, userId }) => {
  const [availableNotes, setAvailableNotes] = useState<AdminNote[]>([]);
  const [selectedNoteId, setSelectedNoteId] = useState<string>();

  useEffect(() => {
    fetchNotes();
  }, [userId]);

  useEffect(() => {
    fetchNotes();
  }, []);

  const fetchNotes = async (search: string = "") => {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) return;

    let url = `/testsuite/getNotes`;

    if (userId) {
      url = `/testsuite/getNotes?user_id=${userId}&limit=50`;
    }

    const response = await APIService.makeAPIGetRequest({
      requestString: url,
      accessToken: accessToken,
    });

    if (response.ok) {
      setAvailableNotes(response.value.notes);
    }
  };

  const handleNoteChange = (event: any, value: AdminNote | null) => {
    if (value) {
      setSelectedNoteId(value.note_id);
      onNoteSelect(value.note_id);
    } else {
      setSelectedNoteId("");
      onNoteSelect("");
    }
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
      <FormControl fullWidth>
        <Autocomplete
          options={availableNotes}
          getOptionLabel={(option) =>
            option.internal_title
              ? `${option.internal_title} - ${formatDate(option.time_uploaded)}`
              : `${option.note_id} - ${formatDate(option.time_uploaded)}`
          }
          renderInput={(params) => (
            <TextField {...params} label="Choose a note" variant="outlined" />
          )}
          onChange={handleNoteChange}
        />
      </FormControl>
      {selectedNoteId && (
        <IconButton
          aria-label="preview"
          onClick={() => {
            window.open(
              `${window.location.origin}/admin/note/${selectedNoteId}`,
              "_blank"
            );
          }}
        >
          <Visibility />
        </IconButton>
      )}
    </Box>
  );
};

export default NotePicker;

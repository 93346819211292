import { createTheme } from "@mui/material/styles";

declare module "@mui/material/styles/createPalette" {
  interface PaletteOptions {
    customColorMain?: PaletteColorOptions;
    backgroundColors?: {
      primary: string;
      secondary: string;
      tertiary: string;
      brand: string;
      warning: string;
      warningDark: string;
    };
    borderColors?: {
      primary: string;
      lightprimary: string;
      darkprimary: string;
      brand: string;
      warning: string;
    };
    textColors?: {
      lightHeader: string;
      darkHeader: string;
      accent: string;
      brand: string;
      brandContrast: string;
      lightText: string;
      primaryText: string;
      darkText: string;
      warning: string;
      disabled: string;
    };
    linkColors: {
      link: string;
      linkHover: string;
    };
  }
}

//Colors object was created to that colors can be uniform for both inline styling and for the theme provider

export const Colors = {
  brand_main: "#36229e", //Eggplant[700]
  brand_dark: "#1f0862", // also button hovers
  jotpal_main: "#61BD97",
  jotpal_dark: "#307357",
  brand_white: "#ffffff", // Pure White
  brand_black: "#000000", // Pure Black
  //Primary Palette is - Eggplant:
  primary: "#ffffff", //Eggplant[700]
  primary900: "#2a2348",
  primary800: "#291d69",
  primary700: "#36229e",
  primary600: "#4d32d7",
  primary500: "#644ce0",
  primary400: "#836eed",
  primary300: "#ada1f3",
  primary200: "#e6e1ff",
  primary100: "#f5f4fc",
  //All greys below are eggplant-neutrals. ~Do not use regular greys!~
  grey900: "#030115",
  grey800: "#2a2533",
  grey700: "#4d4757",
  grey600: "#857f93",
  grey500: "#a39eae",
  grey400: "#bcbac9",
  grey300: "#d5d3e0",
  grey200: "#ebeaf1",
  grey100: "#f5f4f8",
  grey50: "#faf9fc",
  sucess: "",
  danger: "#eb4a26",
  dangerLight: "#faf6f5",
  body_bg: "",
  border: "",
  inverse: "",
  link: "#2196F3",
  linkHover: "#1976D2",
};

export const theme = createTheme({
  palette: {
    primary: {
      main: Colors.jotpal_main,
      dark: Colors.jotpal_dark,
      // light: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    grey: {
      50: Colors.grey50,
      100: Colors.grey100,
      200: Colors.grey200,
      300: Colors.grey300,
      400: Colors.grey400,
      500: Colors.grey500,
      600: Colors.grey600,
      700: Colors.grey700,
      800: Colors.grey800,
      900: Colors.grey900,
    },
    error: {
      main: Colors.danger,
    },
    customColorMain: {
      100: Colors.primary100,
      200: Colors.primary200,
      300: Colors.primary300,
      400: Colors.primary400,
      500: Colors.primary500,
      600: Colors.primary600,
      700: Colors.primary700,
      800: Colors.primary800,
      900: Colors.primary900,
    },
    backgroundColors: {
      primary: Colors.brand_white,
      secondary: Colors.primary100,
      tertiary: Colors.grey100,
      brand: Colors.primary700,
      warning: Colors.dangerLight,
      warningDark: Colors.danger,
    },
    borderColors: {
      primary: Colors.primary200,
      lightprimary: Colors.grey200,
      darkprimary: Colors.grey300,
      brand: Colors.primary700,
      warning: Colors.danger,
    },
    textColors: {
      lightHeader: Colors.primary900,
      darkHeader: Colors.grey900,
      accent: Colors.primary800,
      brand: Colors.primary700,
      brandContrast: Colors.brand_white,
      lightText: Colors.grey600,
      primaryText: Colors.grey700,
      darkText: Colors.grey900,
      warning: Colors.danger,
      disabled: Colors.grey500,
    },
    linkColors: {
      link: Colors.link,
      linkHover: Colors.linkHover,
    },
    //common key has black and white colors
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
});

import { useEffect, useState } from "react";
import { useSelectedUser } from "../../context/selectedUserContext.tsx";
import { AdminUser } from "../../types/types.js";
import { useUser } from "../../context/user/context.js";
import APIService from "../../services/APIService.js";

interface UserFeaturesProps {
  usersList: AdminUser[];
  featuresList: string[];
}

export const UserFeatures = ({
  usersList,
  featuresList,
}: UserFeaturesProps) => {
  const { getAccessToken } = useUser();
  const { selectedUser } = useSelectedUser();

  if (!selectedUser) {
    return <div className="m-4 p-4 bg-red-200 rounded-md">Select a user</div>;
  }

  const userToShow = usersList.find((obj) => obj._id === selectedUser);

  if (!userToShow) {
    return <div className="m-4 p-4 bg-red-200 rounded-md">Select a user</div>;
  }

  // Initialize feature flags state based on the user's current feature_flags
  const [featureFlags, setFeatureFlags] = useState<{
    [key: string]: boolean;
  }>({});

  // Function to initialize feature flags
  const initializeFeatureFlags = (user: AdminUser | undefined) => {
    if (user) {
      const flags = featuresList.reduce((acc, feature) => {
        acc[feature] = user.feature_flags ? user.feature_flags[feature] : false;
        return acc;
      }, {} as { [key: string]: boolean });
      setFeatureFlags(flags);
    }
  };

  // Handle feature flag toggle
  const handleFeatureToggle = async (feature: string) => {
    const currentValue = featureFlags[feature];
    const newValue = !currentValue;

    // Optimistically update UI
    setFeatureFlags((prev) => ({ ...prev, [feature]: newValue }));

    // Update feature flag on the server
    try {
      await updateUserFeatureFlags(feature, newValue);
    } catch (error) {
      // Revert UI on failure
      console.error("Failed to update feature flag", error);
      setFeatureFlags((prev) => ({ ...prev, [feature]: currentValue }));
    }
  };

  const updateUserFeatureFlags = async (
    featureFlag: string,
    value: boolean
  ) => {
    const accessToken = await getAccessToken();
    const response = await APIService.makeAPIPostRequest({
      requestString: "/user/updateFeatureFlagOnUser",
      accessToken: accessToken,
      body: {
        target_user_id: selectedUser,
        feature_name: featureFlag,
        feature_value: value,
      },
    });

    // Implementation of this function would call your backend API
    console.log(`Updating feature ${featureFlag} to ${value}`);
  };

  // Effect for updating featureFlags when selectedUser changes
  useEffect(() => {
    const userToShow = usersList.find((obj) => obj._id === selectedUser);
    initializeFeatureFlags(userToShow);
  }, [selectedUser, usersList, featuresList]);

  return (
    <div className="m-4 p-4 bg-slate-100 rounded-md">
      <div className="mt-4">
        {featuresList.map((feature) => (
          <div key={feature}>
            <label>
              {feature}{" "}
              <input
                type="checkbox"
                checked={featureFlags[feature]}
                onChange={() => handleFeatureToggle(feature)}
              />
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

import { useEffect, useState } from "react";
import { BorderBox } from "./layout/BorderBox";
import { UserNote } from "./UserNote";
import { UserNoteTypeII } from "../types/types";
import { Box, Typography, Link, useTheme } from "@mui/material";
import CustomButton, { RoundedButton } from "../styles/CustomButtons";
import Pagination from "./Pagination";
import { FilterDropdown } from "./FilterDropDown";
import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
import React from "react";
import { Height } from "@mui/icons-material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { EmptyNoteList } from "./EmptyNoteList";
import { DeleteModal } from "./DeleteModal";
import { useUser } from "../context/user";
import APIService from "../services/APIService";

export const GroupNotesList = ({
  forReviewNotes,
  archivedNotes,
}: {
  forReviewNotes: UserNoteTypeII[] | undefined | null;
  archivedNotes: UserNoteTypeII[] | undefined | null;
}) => {
  const { getAccessToken } = useUser();
  const theme = useTheme();

  const [sortedNotes, setSortedNotes] = useState<UserNoteTypeII[]>([]);
  const [notesToDisplay, setNotesToDisplay] = useState("ready_for_review");
  const [itemsPerPage, setItemsPerPage] = useState<number | string>(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [checkedNoteIds, setCheckedNoteIds] = useState<string[]>([]);
  const [displayNotes, setDisplayNotes] = useState<UserNoteTypeII[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const filterItems = ["a-z", "Z-A", "recent", "oldest"];

  const sortObjectsByTimeDescending = (notes: UserNoteTypeII[]) => {
    const sorted = notes.sort((a, b) => b.time_uploaded - a.time_uploaded); // Sort by descending recency
    return sorted;
  };
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    // clear checked notes
    setCheckedNoteIds([]);

    if (notesToDisplay === "ready_for_review") {
      if (forReviewNotes && forReviewNotes.length > 0) {
        const sorted = sortObjectsByTimeDescending(forReviewNotes);
        setIsLoading(false);
        setSortedNotes(sorted);
        setDisplayNotes(sorted);
      } else if (forReviewNotes === null) {
        // no notes exist
        setIsLoading(false);
        setSortedNotes([]);
        setDisplayNotes([]);
      } else {
        // undefined
      }
    } else if (notesToDisplay === "archived") {
      if (archivedNotes && archivedNotes.length > 0) {
        const sorted = sortObjectsByTimeDescending(archivedNotes);
        setIsLoading(false);
        setSortedNotes(sorted);
        setDisplayNotes(sorted);
      } else if (archivedNotes == null) {
        // no notes exist
        setIsLoading(false);
        setSortedNotes([]);
        setDisplayNotes([]);
      } else {
        // undefined
      }
    }
  }, [notesToDisplay, forReviewNotes, archivedNotes]);

  const handleDeleteNotes = () => {
    if (checkedNoteIds.length > 0) {
      checkedNoteIds.forEach((noteId) => {
        const deleteNote = async () => {
          const accessToken = await getAccessToken();
          const response = await APIService.makeAPIPostRequest({
            requestString: "/notes/delete",
            accessToken: accessToken,
            body: { note_id: noteId },
          });

          if (response.ok) {
            const data = response.value;
            if (data.status === 200) {
              const win: Window = window;
              win.location = `/group`;
            }
          }
        };
        deleteNote();
      });
      setShowDeleteModal(false);
    }
  };

  const handleArchiveNotesClick = () => {
    if (checkedNoteIds.length > 0) {
      checkedNoteIds.forEach((noteId) => {
        const archiveNote = async () => {
          const accessToken = await getAccessToken();

          const response = await APIService.makeAPIPostRequest({
            requestString: "/notes/updateNoteReviewStatus",
            accessToken: accessToken,
            body: {
              note_id: noteId,
              status: "archived",
            },
          });

          if (response.ok) {
            const data = response.value;
            if (data.status === 200) {
              const win: Window = window;
              win.location = `/group`;
            }
          }
        };
        archiveNote();
      });
    }
  };

  const handleDeleteNotesClick = () => {
    setShowDeleteModal(true);
  };

  const handleItemsPerPageChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setCurrentPage(1);
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const paginatedNotes =
    itemsPerPage === "all" || typeof itemsPerPage !== "number"
      ? sortedNotes
      : sortedNotes.slice(
          (currentPage - 1) * itemsPerPage,
          currentPage * itemsPerPage
        );

  let allUnderline = itemsPerPage === "all" ? "underline" : "none";
  let fiftyUnderline = itemsPerPage === 50 ? "underline" : "none";
  let twentyUnderline = itemsPerPage === 20 ? "underline" : "none";

  let reviewButtonText =
    notesToDisplay === "ready_for_review"
      ? theme.palette.primary.light
      : "textColors.darkHeader";
  let reviewButtonBackground =
    notesToDisplay === "ready_for_review"
      ? "backgroundColors.secondary"
      : "backgroundColors.primary";
  let reviewButtonWeight =
    notesToDisplay === "ready_for_review" ? "600" : "500";
  let completedButtonText =
    notesToDisplay === "archived"
      ? theme.palette.primary.light
      : "textColors.darkHeader";
  let completedButtonBackground =
    notesToDisplay === "archived"
      ? "backgroundColors.secondary"
      : "backgroundColors.primary";
  let completedButtonWeight = notesToDisplay === "archived" ? "600" : "500";

  let paginationLayout = checkedNoteIds.length ? "space-between" : "flex-end";
  let paginationPadding = checkedNoteIds.length ? "0" : "1rem";

  let title =
    notesToDisplay === "ready_for_review"
      ? "Notes to Review"
      : "Archived Notes";

  let noNotesMessage =
    notesToDisplay === "ready_for_review"
      ? "notes to review"
      : "archived notes";

  return (
    <Box className="NotesList-Container" sx={{ width: "100vw" }}>
      <DeleteModal
        isOpen={showDeleteModal}
        continueText="Delete"
        cancelText="Cancel"
        onCancel={() => {
          setShowDeleteModal(false);
        }}
        onContinue={handleDeleteNotes}
      >
        Are you sure you want to delete this note? This action cannot be undone.
      </DeleteModal>
      <Box
        className="NotesListNav-Container"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignContent: "center",
          width: "100%",
          paddingLeft: "4rem",
          paddingRight: "4rem",
        }}
      >
        <Box sx={{ paddingTop: 2.5, paddingBottom: 1 }}>
          <RoundedButton
            variant="outlined"
            sx={{
              borderColor: "borderColors.darkprimary",
              color: reviewButtonText,
              backgroundColor: reviewButtonBackground,
              whiteSpace: "nowrap",
              fontWeight: reviewButtonWeight,
              my: 4,
            }}
            onClick={() => {
              setNotesToDisplay("ready_for_review");
            }}
          >
            Awaiting Review
          </RoundedButton>
          <RoundedButton
            variant="outlined"
            sx={{
              borderColor: "borderColors.darkprimary",
              color: completedButtonText,
              backgroundColor: completedButtonBackground,
              whiteSpace: "nowrap",
              fontWeight: completedButtonWeight,
              my: 4,
            }}
            onClick={() => {
              setNotesToDisplay("archived");
            }}
          >
            Archived Notes
          </RoundedButton>
        </Box>
        <Box
          onClick={() => {
            window.location.reload();
          }}
          sx={{
            marginTop: 4.5,
            marginBottom: 1.25,
            marginRight: "2.5rem",
            "&:hover": {
              cursor: "pointer",
              borderRadius: "0.25rem",
              backgroundColor: "backgroundColors.secondary",
            },
          }}
        >
          <RefreshIcon style={{ fontSize: 30 }} />
        </Box>
      </Box>
      <Box
        className="notesListMain-container"
        sx={{ padding: "4.5rem", paddingTop: "1rem" }}
      >
        <Box
          className="title-buttons-search-pagination-container"
          sx={{ position: "relative" }}
        >
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: {
                  xs: "column",
                  md: "row",
                },
                justifyContent: "flex-start",
                alignItems: { xs: "flex-start", md: "center" },
              }}
            >
              <Typography
                color={"textColors.darkHeader"}
                fontSize={"1.4rem"}
                fontStyle={"normal"}
                fontWeight={"700"}
                lineHeight={"1.5rem"}
                noWrap
              >
                {title}
              </Typography>
              {checkedNoteIds.length > 0 && (
                <Box
                  className="mark-delete-buttons-container"
                  sx={{
                    position: "absolute", // <-- set position to absolute
                    top: -10,
                    left: 175,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignContent: "center",
                    paddingLeft: {
                      xs: "1rem",
                      md: "2rem",
                    },
                  }}
                >
                  {notesToDisplay === "ready_for_review" && (
                    <RoundedButton
                      variant="outlined"
                      onClick={handleArchiveNotesClick}
                      sx={{
                        margin: 0,
                        whiteSpace: "nowrap",
                        flex: 1,
                        marginLeft: {
                          xs: "0",
                        },
                      }}
                    >
                      <CheckIcon
                        sx={{
                          color: theme.palette.primary.light,
                          fontSize: "1rem",
                        }}
                      />
                      Archive
                    </RoundedButton>
                  )}

                  <RoundedButton
                    variant="outlined"
                    onClick={handleDeleteNotesClick}
                    sx={{
                      margin: 0,
                      color: "textColors.warning",
                      borderColor: "borderColors.warning",
                      whiteSpace: "nowrap",
                      flex: 1,
                      "&:hover": {
                        bgcolor: "backgroundColors.warning",
                        borderColor: "borderColors.warning",
                      },
                    }}
                  >
                    <DeleteIcon
                      sx={{
                        color: "textColors.warning",
                        fontSize: "1rem",
                      }}
                    />
                    Delete
                  </RoundedButton>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        <Box className="notes-container" sx={{ paddingTop: "1rem" }}>
          {isLoading && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                alignContent: "center",
                height: "25vh",
              }}
            >
              <Typography variant="h4" color="textColors.lightHeader">
                Loading Notes...
              </Typography>
            </Box>
          )}
          {!isLoading &&
            paginatedNotes.map((note: UserNoteTypeII, index: number) => {
              return (
                <UserNote
                  key={note.note_id}
                  index={index}
                  note_id={note.note_id}
                  note_title={note.note_title}
                  status={note.status}
                  review_status={note.review_status}
                  time={note.time_uploaded}
                  summary={note.summary}
                  issuing_user_name={note.issuing_user_name}
                  link={
                    note.status === "completed"
                      ? `/note/${note.note_id}`
                      : `/status/${note.note_id}`
                  }
                  checkedNoteIds={checkedNoteIds}
                  setCheckedNoteIds={setCheckedNoteIds}
                  notesToDisplay={notesToDisplay}
                  origin="group"
                />
              );
            })}
          {!isLoading && paginatedNotes.length !== 0 && (
            <Box
              className="pagination-buttons"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                marginTop: "1rem",
              }}
            >
              <CustomButton
                variant="text"
                sx={{
                  textDecoration: twentyUnderline,
                  "&:hover": { textDecoration: twentyUnderline },
                }}
                onClick={() => setItemsPerPage(20)}
              >
                20
              </CustomButton>
              <CustomButton
                variant="text"
                sx={{
                  textDecoration: fiftyUnderline,
                  "&:hover": { textDecoration: fiftyUnderline },
                }}
                onClick={() => setItemsPerPage(50)}
              >
                50
              </CustomButton>
              <CustomButton
                variant="text"
                sx={{
                  whiteSpace: "nowrap",
                  textDecoration: allUnderline,
                  "&:hover": { textDecoration: allUnderline },
                }}
                onClick={() => setItemsPerPage("all")}
              >
                All
              </CustomButton>
            </Box>
          )}
          {itemsPerPage !== "all" && (
            <Pagination
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              totalItems={sortedNotes.length}
              onPageChange={handlePageChange}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

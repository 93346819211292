import { Box, Typography, useTheme } from "@mui/material";

import { useUIState } from "../../context/uiState";
import { useNavigate } from "react-router-dom";
import { UIStateType } from "../../context/uiState/context";

interface DashboardNumsProps {
  num: number;
  text: string;
  link?: string;
}

const DashboardNums: React.FC<DashboardNumsProps> = ({ num, text, link }) => {
  const { state, setState } = useUIState();
  const currentPage = state.navigation.currentPage;
  const navigate = useNavigate();
  const theme = useTheme();

  const handleClick = () => {
    if (link) {
      setState((prevState: UIStateType) => ({
        ...prevState,
        navigation: { currentPage: "note" },
      }));
      navigate(link);
    }
  };

  return (
    <Box
      onClick={handleClick}
      sx={{
        bgcolor: "backgroundColors.secondary",
        border: 1,
        borderColor: "borderColors.primary",
        borderRadius: "0.75rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        padding: { xs: "0.75rem 1rem", sm: "1rem 1.5rem" },
        margin: { xs: "0.25rem", sm: "0.5rem" },
        width: "100%",
      }}
    >
      <Typography
        variant="h3"
        fontSize="2.5rem"
        fontStyle="normal"
        fontWeight="700"
        lineHeight="3rem"
        color={theme.palette.primary.light}
      >
        {num}
      </Typography>
      <Typography
        variant="body1"
        fontSize="1.0625rem"
        fontStyle="normal"
        fontWeight="700"
        lineHeight="1.59375rem"
        color="textColors.accent"
        whiteSpace="nowrap"
      >
        {text}
      </Typography>
    </Box>
  );
};

export default DashboardNums;

import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate, useLocation } from "react-router-dom";
import { useUIState } from "../context/uiState";
import { getUserInfoFromLocalStorage } from "../utils/LocalStorageUtils";
import { SVGLogo } from "./SVGLogo";
import CustomButton from "../styles/CustomButtons";
import { LoginButton } from "./Buttons/LoginButton";
import UserMenuButton from "./Buttons/UserMenuButton";
import {
  Box,
  AppBar,
  Toolbar,
  Badge,
  Typography,
  Chip,
  Avatar,
  useTheme,
  IconButton,
  List,
  Drawer,
  ListItem,
  ListItemText,
  Switch,
  Tooltip,
  Stack,
} from "@mui/material";
import { Menu, MenuItem, Button } from "@mui/material";
import HelpButton from "./Buttons/HelpButton";
import { UIStateType } from "../context/uiState/context";
import { useEffect, useState } from "react";
import React from "react";
import { useUser } from "../context/user";
import { JotPalWordmarkLogo, JotPsychWordmarkLogo } from "./Icons/Logo";
import { compareVersions, unixTimestampToDaysRemaining } from "../utils/utils";
import { ConditionalTooltip } from "./ConditionalTooltip";
import { AlertBanner } from "./AlertBanner";
import { useThemeContext } from "../context/theme/context";
import { FRONTEND_APP_VERSION } from "../main";
import { NewFeatureModal } from "./Modals/NewFeatureModal";
import { Menu as MenuIcon } from "@mui/icons-material";
import { useFrontChatBoot } from "../lib/hooks/use-front-chat-boot";
import { PatientMatchModal } from "./Modals/PatientMatchModal";
import { useAudioDataContext } from "../context/AudioDataContext";
import { useRecordingContext } from "../context/RecordingContext";
import APIService from "../services/APIService";
import { ShowNotifications } from "../Views/Shared/ShowNotifications"

export const Nav = ({}: {}) => {
  const { userState, newFeature, getAccessToken } = useUser();
  const location = useLocation();
  const navigate = useNavigate();
  const [anchorElement, setAnchorElement] = React.useState<
    (EventTarget & HTMLButtonElement) | null
  >(null);

  const { state: UIState, setState, showAlertBanner } = useUIState();
  const { loginWithRedirect, logout } = useAuth0();
  const { brandLanguage } = useThemeContext();
  const theme = useTheme();

  const [showPatientMatchModal, setShowPatientMatchModal] =
    useState<boolean>(false);

  const { audioData } = useAudioDataContext();
  const { recordingStatus, elapsedTime } = useRecordingContext();

  const [drawerOpen, setDrawerOpen] = useState(false);

  const currentPage = UIState.navigation.currentPage;

  const [showNewFeatureModal, setShowNewFeatureModal] =
    useState<boolean>(false);

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setAnchorElement(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElement(null);
  };

  let trial_remaining_days = undefined;

  if (userState?.stripeInfo?.stripe_current_period_end) {
    trial_remaining_days = unixTimestampToDaysRemaining(
      userState.stripeInfo?.stripe_current_period_end
    );
  }

  const formatTime = (totalSeconds: number): string => {
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes}:${seconds.toString().padStart(2, "0")}`;
  };

  const isRecorderPage = location.pathname.includes("/record"); // Adjust the path according to your app's URL structure

  // Front ChatBot Initialization

  const [userChatBotHash, setUserChatBotHash] = useState("");

  useEffect(() => {
    const fetchUserHash = async () => {
      const accessToken = await getAccessToken();

      if (!accessToken) {
        setUserChatBotHash(""); // Set a default hash if no access token
        return;
      }

      try {
        const response = await APIService.makeAPIGetRequest({
          requestString: "/user/getChatBotHash",
          accessToken: accessToken,
        });

        if (!response.ok) {
          throw new Error("Failed to fetch user hash");
        }

        const data = response.value;
        setUserChatBotHash(data.chatbot_hash);
      } catch (error) {
        console.error("Error fetching user hash:", error);
        setUserChatBotHash(""); // Fallback hash in case of error
      }
    };

    fetchUserHash();
  }, []);

  const chatId = "ee1815dea718524ed6bebe059556b58b";
  const { frontChat, initialize, isInitialized } = useFrontChatBoot(
    document.body
  );
  const [isWindowVisible, setIsWindowVisible] = useState(false);

  const handleToggleSidebar = () => {
    if (location.pathname.startsWith("/note/")) {
      setState({
        ...UIState,
        sidebar: { isHidden: !UIState.sidebar.isHidden },
      });
    } else {
      setDrawerOpen(!drawerOpen);
    }
  };

  if (import.meta.env.VITE_BUILD_ENV !== "dev") {
    useEffect(() => {
      if (!initialize || isInitialized) {
        return;
      }

      if (
        userState?.userInfo?.email == null ||
        userState?.userInfo?.email === "" ||
        userState?.userInfo?.name == null ||
        userState?.userInfo?.name === "" ||
        userChatBotHash == null ||
        userChatBotHash === ""
      ) {
        initialize({ chatId });
      } else {
        initialize({
          chatId,
          email: userState?.userInfo?.email ?? "",
          name: userState?.userInfo?.name,
          userHash: userChatBotHash,
        });
      }
    }, [isInitialized, initialize]);

    useEffect(() => {
      if (!frontChat || !isInitialized || isWindowVisible) {
        return;
      }

      frontChat("hide");
      setIsWindowVisible(false);
    }, [frontChat, isInitialized, isWindowVisible]);
  }

  // End Front ChatBox Initialization

  // Set font weight based on current page
  let homeButtonWeight = currentPage === "App" ? "bold" : "normal";
  let notesButtonWeight = currentPage === "UserNotesView" ? "bold" : "normal";
  let groupButtonWeight = currentPage === "GroupNotesView" ? "bold" : "normal";
  let patientsButtonWeight = currentPage === "PatientsView" ? "bold" : "normal";
  let templatesButtonWeight =
    currentPage === "TemplatesView" ? "bold" : "normal";
  let recordButtonWeight = currentPage === "RecorderView" ? "bold" : "normal";

  // Set text color based on current page
  let homeButtonColor =
    currentPage === "App"
      ? theme.palette.primary.light
      : "textColors.lightHeader";
  let notesButtonColor =
    currentPage === "UserNotesView"
      ? theme.palette.primary.light
      : "textColors.lightHeader";
  let groupButtonColor =
    currentPage === "GroupNotesView"
      ? theme.palette.primary.light
      : "textColors.lightHeader";
  let templatesButtonColor =
    currentPage === "TemplatesView"
      ? theme.palette.primary.light
      : "textColors.lightHeader";
  let recordButtonColor =
    currentPage === "RecorderView"
      ? theme.palette.primary.light
      : "textColors.lightHeader";
  let patientsButtonColor =
    currentPage === "PatientsView"
      ? theme.palette.primary.light
      : "textColors.lightHeader";

  // Update currentPage state when URL changes- currentPage is the global state that allows the dev to know where the user is at any url path. This allows for correct help messages for the page that the user is on and also for styling the nav buttons based on where the user is at in the application.
  useEffect(() => {
    if (location.pathname === "/") {
      setState((prevState: UIStateType) => ({
        ...prevState,
        navigation: { currentPage: "App" },
      }));
    } else if (location.pathname === "/notes") {
      setState((prevState: UIStateType) => ({
        ...prevState,
        navigation: { currentPage: "UserNotesView" },
      }));
    } else if (location.pathname === "/user") {
      setState((prevState: UIStateType) => ({
        ...prevState,
        navigation: { currentPage: "UserView" },
      }));
    } else if (location.pathname === "/callback") {
      setState((prevState: UIStateType) => ({
        ...prevState,
        navigation: { currentPage: "AuthenticationCallback" },
      }));
    } else if (location.pathname === "/note/:noteId") {
      setState((prevState: UIStateType) => ({
        ...prevState,
        navigation: { currentPage: "NoteView" },
      }));
    } else if (location.pathname === "/status/:noteId") {
      setState((prevState: UIStateType) => ({
        ...prevState,
        navigation: { currentPage: "NoteProcessingView" },
      }));
    } else if (location.pathname === "/transcribe") {
      setState((prevState: UIStateType) => ({
        ...prevState,
        navigation: { currentPage: "TranscribeView" },
      }));
    } else if (location.pathname === "/admin") {
      setState((prevState: UIStateType) => ({
        ...prevState,
        navigation: { currentPage: "AdminAuth0ProviderWithNavigate" },
      }));
    } else if (location.pathname === "/patients") {
      setState((prevState: UIStateType) => ({
        ...prevState,
        navigation: { currentPage: "PatientsView" },
      }));
    } else if (location.pathname === "/group") {
      setState((prevState: UIStateType) => ({
        ...prevState,
        navigation: { currentPage: "GroupNotesView" },
      }));
    } else if (location.pathname === "/templates") {
      setState((prevState: UIStateType) => ({
        ...prevState,
        navigation: { currentPage: "TemplatesView" },
      }));
    } else if (location.pathname === "/record") {
      setState((prevState: UIStateType) => ({
        ...prevState,
        navigation: { currentPage: "RecorderView" },
      }));
    } else if (location.pathname === "/group_administration") {
      setState((prevState: UIStateType) => ({
        ...prevState,
        navigation: { currentPage: "UserGroupAdministration" },
      }));
    }
    //This is where you can add more views as they are created (all paths were found in main.tsx). The currentPage names are the components that the path is associated with.
  }, [location, setState]);

  useEffect(() => {
    if (
      userState?.onboardingSteps?.generated_first_note === false &&
      !localStorage.getItem("onboardingBannerShown")
    ) {
      // Set a timeout to delay the alert banner display
      const timerId = setTimeout(() => {
        localStorage.setItem("onboardingBannerShown", "true");
        showAlertBanner(
          "Need help getting started? Click here to schedule 15 minutes with our product team.",
          "warning",
          false,
          () => {
            window.open(
              `https://${brandLanguage.brandSite}/onboarding-support`,
              "_blank"
            );
          }
        );
      }, 20000); // Delay set to 20 seconds

      return () => clearTimeout(timerId);
    }
  }, [userState]);

  return (
    <AppBar position="fixed" color="primary" sx={{ bgcolor: "grey.100" }}>
      <Toolbar>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "space-between",
            flex: 1,
          }}
        >
          <Box
            color="primary"
            sx={{
              flex: 1,
              display: "flex",
              alignItems: "center",
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleToggleSidebar}
              sx={{ display: { xs: "block", md: "none" }, color: "black" }}
            >
              <MenuIcon />
            </IconButton>
            <CustomButton
              onClick={() => {
                setState((prevState: UIStateType) => ({
                  ...prevState,
                  navigation: { currentPage: "home" },
                }));
                navigate("/");
              }}
              size="large"
              aria-label="logo-home-button"
            >
              {brandLanguage.brandName === "JotPsych" && (
                <JotPsychWordmarkLogo size={100} />
              )}
              {brandLanguage.brandName === "JotPal" && (
                <JotPalWordmarkLogo size={100} />
              )}
              {/* {brandLanguage.brandName} */}
            </CustomButton>
            {location.pathname.startsWith("/note/") && (
              <CustomButton
                sx={{ display: { xs: "inherit", md: "none" } }}
                onClick={() => {
                  setState((prevState: UIStateType) => ({
                    ...prevState,
                    navigation: { currentPage: "home" },
                  }));
                  navigate("/");
                }}
                size="large"
                aria-label="logo-home-button"
              >
                Home
              </CustomButton>
            )}

            <Box
              className="left-nav-buttons-container"
              sx={{ display: { xs: "none", md: "flex" } }}
            >
              <CustomButton
                sx={{ fontWeight: homeButtonWeight, color: homeButtonColor }}
                variant="text"
                onClick={() => navigate("/")}
              >
                Home
              </CustomButton>
              <CustomButton
                sx={{
                  fontWeight: notesButtonWeight,
                  color: notesButtonColor,
                }}
                variant="text"
                onClick={() => navigate("/notes")}
              >
                Notes
              </CustomButton>
              {userState?.group_user_roles?.includes("admin") && (
                <CustomButton
                  sx={{
                    fontWeight: groupButtonWeight,
                    color: groupButtonColor,
                  }}
                  variant="text"
                  onClick={() => navigate("/group")}
                >
                  Group Notes
                </CustomButton>
              )}
              {userState?.group_user_roles?.includes("billing_admin") && (
                <CustomButton
                  sx={{
                    fontWeight: groupButtonWeight,
                    color: groupButtonColor,
                  }}
                  variant="text"
                  onClick={() => navigate("/group_administration")}
                >
                  Group Administration
                </CustomButton>
              )}
              <CustomButton
                sx={{
                  fontWeight: templatesButtonWeight,
                  color: templatesButtonColor,
                }}
                variant="text"
                onClick={() => {
                  navigate("/templates");
                }}
              >
                Templates
              </CustomButton>
              {!localStorage.getItem("recordClicked") ? (
                <Badge
                  badgeContent={"New!"}
                  color={"secondary"}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                >
                  <CustomButton
                    sx={{
                      fontWeight: recordButtonWeight,
                      color: recordButtonColor,
                    }}
                    variant="text"
                    onClick={() => {
                      localStorage.setItem(
                        "recordClicked",
                        JSON.stringify(true)
                      );
                      navigate("/record");
                    }}
                  >
                    Record
                  </CustomButton>
                </Badge>
              ) : (
                <CustomButton
                  sx={{
                    fontWeight: recordButtonWeight,
                    color: recordButtonColor,
                  }}
                  variant="text"
                  onClick={() => {
                    navigate("/record");
                  }}
                >
                  Record
                </CustomButton>
              )}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Stack
            direction={"row"}
            gap={4}
            sx={{ display: { xs: "none", md: "flex" }, alignItems: "center" }}
          >
            {/* Patient Match Enrollment */}
            {!userState?.patientMatchEnrolled && (
              <Tooltip
                title="Click to learn more about MindFind, our client-provider matching service!"
                placement="bottom"
                arrow
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    maxWidth: "200px",
                  }}
                  onClick={() => setShowPatientMatchModal(true)}
                >
                  <Typography
                    sx={{ color: "textColors.lightHeader", marginRight: 1 }}
                  >
                    Looking for new clients?
                  </Typography>
                  <Switch
                    checked={userState?.patientMatchEnrolled}
                    onChange={() => setShowPatientMatchModal(true)}
                  />
                </Box>
              </Tooltip>
            )}

            {/* Recording Status */}
            {!isRecorderPage && recordingStatus.isRecording && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 1,
                  }}
                >
                  <Box
                    sx={{
                      width: 15,
                      height: 15,
                      borderRadius: "50%",
                      backgroundColor: "red",
                      animation: "pulse 1s infinite",
                      marginRight: 1,
                    }}
                  />
                  <Typography variant="body2" sx={{ color: "black" }}>
                    {formatTime(elapsedTime)}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    height: 10,
                    backgroundColor: "grey.500",
                    borderRadius: 1,
                  }}
                >
                  <Box
                    sx={{
                      width: `${audioData}%`,
                      height: "100%",
                      backgroundColor:
                        audioData > 50 ? "success.main" : "warning.main",
                      borderRadius: 1,
                    }}
                  />
                </Box>
              </Box>
            )}

            {/* Trial Days Remaining */}
            {userState?.paymentStatus === "trialing" &&
              trial_remaining_days && (
                <ConditionalTooltip
                  condition={trial_remaining_days <= 3}
                  title="Your trial is almost up! Click here to access your billing portal so you don't lose access to your notes."
                  placement="bottom"
                  arrow
                >
                  <Chip
                    label={`${trial_remaining_days} ${
                      trial_remaining_days > 1 ? "days" : "day"
                    } left in trial`}
                    color={trial_remaining_days > 3 ? "info" : "warning"}
                    avatar={
                      <Avatar sx={{ fontWeight: 700 }}>
                        {trial_remaining_days}
                      </Avatar>
                    }
                    onClick={() =>
                      window.open(
                        `https://billing.stripe.com/p/login/7sIdRo5NjdyN1QA8ww?prefilled_email=${encodeURIComponent(
                          userState.userInfo?.email || ""
                        )}`,
                        "_blank"
                      )
                    }
                  />
                </ConditionalTooltip>
              )}

            {/* Help Menu */}
            <Box>
              <CustomButton
                sx={{ color: "textColors.lightHeader" }}
                variant="text"
                onClick={handleClick}
              >
                Help
              </CustomButton>
              <Menu
                id="support-menu"
                anchorEl={anchorElement}
                keepMounted
                open={Boolean(anchorElement)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose}>
                  <Box
                    component="a"
                    href={`https://${brandLanguage.brandSite}/how-it-works`}
                    target="_blank"
                    sx={{ color: "textColors.lightHeader" }}
                  >
                    Instructions
                  </Box>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <Box
                    component="a"
                    href={`https://${brandLanguage.brandSite}/onboarding-support`}
                    target="_blank"
                    sx={{ color: "textColors.lightHeader" }}
                  >
                    Onboarding
                  </Box>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setShowNewFeatureModal(true);
                    handleClose();
                  }}
                >
                  <Box sx={{ color: "textColors.lightHeader" }}>
                    What's New?
                  </Box>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <Box
                    component="a"
                    href={`https://${brandLanguage.brandSite}/contact`}
                    target="_blank"
                    sx={{ color: "textColors.lightHeader" }}
                  >
                    Contact Us
                  </Box>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleClose();
                    localStorage.removeItem("accessToken");
                    localStorage.removeItem("userInfo");
                    logout({
                      logoutParams: { returnTo: window.location.origin },
                    });
                  }}
                >
                  <Box sx={{ color: "textColors.lightHeader" }}>Logout</Box>
                </MenuItem>
              </Menu>
            </Box>
          </Stack>
          <ShowNotifications />
          {/* User Menu Button */}
          <UserMenuButton />
        </Box>
      </Toolbar>
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        sx={{ display: { xs: "block", md: "none" } }}
      >
        <List>
          <ListItem
            button
            onClick={() => {
              setDrawerOpen(false);
              navigate("/");
            }}
            sx={{ fontWeight: homeButtonWeight, color: homeButtonColor }}
          >
            <ListItemText primary="Home" />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              setDrawerOpen(false);
              navigate("/record");
            }}
            sx={{ fontWeight: notesButtonWeight, color: notesButtonColor }}
          >
            <ListItemText primary="Record" />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              setDrawerOpen(false);
              navigate("/notes");
            }}
            sx={{ fontWeight: notesButtonWeight, color: notesButtonColor }}
          >
            <ListItemText primary="Notes" />
          </ListItem>
          {userState?.group_user_roles?.includes("billing_admin") &&
            <ListItem
              button
              onClick={() => {
                setDrawerOpen(false);
                navigate("/group_administration");
              }}
              sx={{ fontWeight: notesButtonWeight, color: notesButtonColor }}
            >
              <ListItemText primary="Group Administration" />
            </ListItem>
          }
          <ListItem
            button
            component="a"
            href={`https://${brandLanguage.brandSite}/onboarding-support`}
            target="_blank"
            onClick={() => {
              setDrawerOpen(false);
            }}
            sx={{ fontWeight: notesButtonWeight, color: notesButtonColor }}
          >
            <ListItemText primary="Onboarding" />
          </ListItem>
          <ListItem
            button
            component="a"
            href={`https://${brandLanguage.brandSite}/contact`}
            target="_blank"
            onClick={() => {
              setDrawerOpen(false);
            }}
            sx={{ fontWeight: notesButtonWeight, color: notesButtonColor }}
          >
            <ListItemText primary="Contact Us" />
          </ListItem>

          <ListItem
            button
            onClick={() => {
              localStorage.removeItem("accessToken");
              localStorage.removeItem("userInfo");
              logout({
                logoutParams: {
                  returnTo: window.location.origin,
                },
              });
              setDrawerOpen(false);
            }}
            sx={{ fontWeight: notesButtonWeight, color: notesButtonColor }}
          >
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </Drawer>
      {UIState.navbar.showCopyNotification && (
        <div
          className={`tooltip-animation absolute z-40 top-0 left-0 inline-block p-4 mt-16 w-full font-medium text-black duration-300 transition-opacity bg-green-400 shadow-sm tooltip`}
        >
          Copied
        </div>
      )}
      <AlertBanner />
      <NewFeatureModal
        isOpen={showNewFeatureModal}
        onClose={() => {
          setShowNewFeatureModal(false);
        }}
      />
      <PatientMatchModal
        isOpen={showPatientMatchModal}
        onClose={() => {
          setShowPatientMatchModal(false);
        }}
      />
    </AppBar>
  );
};

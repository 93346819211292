import { useSelectedUser } from "../../context/selectedUserContext.tsx/index.js";
import { AdminUser } from "../../types/types.js";

interface UserTemplatesProps {
  usersList: AdminUser[];
  addNotificationToUser: () => void;
}

export const UserNotifications = ({
  usersList,
  addNotificationToUser,
}: UserTemplatesProps) => {
  const { selectedUser } = useSelectedUser();

  if (!selectedUser) {
    return <></>;
  }

  const userToShow = usersList.find((obj) => obj._id === selectedUser);

  if (!userToShow) {
    return <></>;
  }

  return (
    <div className="m-4 p-4 bg-slate-100 rounded-md">
      <h2 className="text-2xl font-bold mb-2">Add Notifiations to the User</h2>
      {userToShow.user_templates?.map((template) => (
        <div
          key={template.template_id}
          className="mb-2 bg-white p-2 rounded-md shadow"
        >
          <div className="mb-2">
            <span className="font-semibold">{template.display_name}</span>
            <span className="ml-4 text-sm text-gray-500">
              {template.template_id}
            </span>
          </div>
        </div>
      ))}
      <button
        className="px-4 py-2 mt-2 bg-green-500 text-white rounded hover:bg-green-700"
        onClick={addNotificationToUser}
      >
        Add Notifications
      </button>
    </div>
  );
};

import React, { useContext, useMemo } from "react";
import { DebugContext } from "../main";
import { Typography, Box } from "@mui/material";

export type TranscriptType = {
  confidence?: number;
  start: number;
  end: number;
  speaker: string;
  role?: string;
  text: string;
  words?: WordType[];
}[];

export type WordType = {
  confidence: number;
  end: number;
  speaker: string;
  start: number;
  text: string;
};

const LABELED_SPEAKERS = ["PROVIDER", "PATIENT", "CLIENT"];

export const Transcript = ({ transcript }: { transcript: TranscriptType }) => {
  const debug = useContext(DebugContext);

  const groupedTranscript = useMemo(() => {
    return transcript.reduce((acc, current) => {
      if (acc.length === 0 || acc[acc.length - 1].speaker !== current.speaker) {
        acc.push({ speaker: current.speaker, text: [current.text] });
      } else {
        acc[acc.length - 1].text.push(current.text);
      }
      return acc;
    }, [] as { speaker: string; text: string[] }[]);
  }, [transcript]);

  return (
    <Box>
      <Typography
        variant="h5"
        fontWeight="600"
        color="textColors.lightHeader"
        className="pb-2"
      >
        Transcript
      </Typography>
      <Box>
        {groupedTranscript.map((section, index) => {
          const backgroundColor = index % 2 === 0 ? "#f5f5f5" : "#ffffff";
          const showLabel = LABELED_SPEAKERS.includes(section.speaker);

          return (
            <Box
              key={index}
              sx={{
                backgroundColor,
                padding: 1,
              }}
            >
              <Typography variant="body2" color="textColors.primaryText">
                {showLabel && (
                  <span
                    style={{ fontWeight: "bold" }}
                  >{`${section.speaker}: `}</span>
                )}
                {section.text.map((text, textIndex) => (
                  <p
                    key={textIndex}
                    style={{
                      margin: 0,
                      paddingTop: textIndex > 0 ? "0.5em" : 0,
                    }}
                  >
                    {text}
                  </p>
                ))}
              </Typography>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default Transcript;

import React, { useState, useEffect } from "react";
import {
  TextField,
  FormControl,
  Box,
  Autocomplete,
  FormGroup,
  FormControlLabel,
  Switch,
  IconButton,
} from "@mui/material";
import {
  AdminPublicTemplate,
  AdminTemplate,
  AdminUserTemplate,
  UserTemplateItem,
} from "../../../types/types";
import APIService from "../../../services/APIService";
import { Visibility } from "@mui/icons-material";

interface TemplatePickerProps {
  onTemplateSelect: (template_id: string) => void;
  userId?: string; // Optional userId prop
}

const TemplatePicker: React.FC<TemplatePickerProps> = ({
  onTemplateSelect,
  userId, // Accepting userId as a prop
}) => {
  const [availableTemplates, setAvailableTemplates] = useState<
    AdminPublicTemplate[] | AdminUserTemplate[]
  >([]);
  const [availableUserTemplates, setAvailableUserTemplates] = useState<
    UserTemplateItem[]
  >([]);
  const [userTemplatesMode, setUserTemplatesMode] = useState(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState<string>();

  useEffect(() => {
    fetchTemplates();
  }, [userTemplatesMode, userId]); // React to changes in userTemplatesMode and userId

  const fetchTemplates = async (search: string = "") => {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) return;

    if (!userTemplatesMode) {
      const response = await APIService.makeAPIGetRequest({
        requestString: `/template/getAllPublicTemplates?search=${search}`,
        accessToken: accessToken,
      });

      if (response.ok) {
        setAvailableTemplates(response.value.templates);
      }
    } else if (userTemplatesMode) {
      const response = await APIService.makeAPIGetRequest({
        requestString: `/user/getTemplatesForUser?target_user_id=${userId}`,
        accessToken: accessToken,
      });

      if (response.ok) {
        setAvailableUserTemplates(response.value.templates);
      }
    }
  };

  const handleTemplateChange = (
    event: any,
    value: AdminPublicTemplate | AdminUserTemplate | null
  ) => {
    if (value) {
      setSelectedTemplateId(value.template_id);
      onTemplateSelect(value.template_id);
    } else {
      setSelectedTemplateId("");
      onTemplateSelect("");
    }
  };

  const handleUserTemplateChange = (
    event: any,
    value: UserTemplateItem | null
  ) => {
    if (value) {
      onTemplateSelect(value.template_id);
    }
  };

  const handleModeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserTemplatesMode(event.target.checked);
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
      <FormControl fullWidth>
        {userTemplatesMode ? (
          <Autocomplete
            options={availableUserTemplates}
            getOptionLabel={(option) =>
              option.display_name ? option.display_name : ""
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Choose a template"
                variant="outlined"
              />
            )}
            onChange={handleUserTemplateChange}
          />
        ) : (
          <Autocomplete
            options={availableTemplates}
            getOptionLabel={(option) =>
              option.public_name ? option.public_name : option.template_name
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Choose a template"
                variant="outlined"
              />
            )}
            onChange={handleTemplateChange}
          />
        )}

        {userId && (
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={userTemplatesMode}
                  onChange={handleModeChange}
                />
              }
              label="User Templates Mode"
            />
          </FormGroup>
        )}
      </FormControl>
      {selectedTemplateId && (
        <IconButton
          aria-label="preview"
          onClick={() => {
            window.open(
              `${window.location.origin}/admin/publictemplate/${selectedTemplateId}`,
              "_blank"
            );
          }}
        >
          <Visibility />
        </IconButton>
      )}
    </Box>
  );
};

export default TemplatePicker;

import DashboardNote from "../components/Dashboard/DashboardNote";
import { UserNoteTypeII } from "../types/types";
import { Box, Typography, Link, useTheme } from "@mui/material";
import DashboardNums from "../components/Dashboard/DashboardNums";

import RefreshIcon from "@mui/icons-material/Refresh";
import { ReactNode } from "react";

interface MultiColumnCenterBoxProps {
  loading?: boolean;
  headingText?: string;
  headingComponent?: ReactNode;
  children?: ReactNode;
  showHeader?: boolean;
}

const MultiColumnCenterBox = ({
  loading,
  headingText,
  headingComponent,
  showHeader = true,
  children,
}: MultiColumnCenterBoxProps) => {
  const theme = useTheme();

  return (
    <Box
      className="container"
      sx={{
        display: "flex",
        flexDirection: "column",
        bgcolor: "backgroundColors.primary",
        border: 1,
        borderColor: "borderColors.primary",
        borderRadius: "0.75rem",
        marginY: { xs: "2rem", sm: "2rem" },
        marginX: { xs: ".5rem", sm: "2rem" },
      }}
    >
      {showHeader && (
        <Box
          className="header-container"
          sx={{
            backgroundColor: "backgroundColors.primary",
            borderBottom: 1,
            borderColor: "borderColors.primary",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h2"
            fontSize="1.75rem"
            fontStyle="normal"
            fontWeight="700"
            lineHeight="1.5rem"
            padding="2rem 2.5rem"
            color="textColors.lightHeader"
          >
            {headingText}
          </Typography>
          {headingComponent}
        </Box>
      )}

      <Box
        sx={{
          display: "flex",
          height: { xs: "auto", minHeight: "60vh" },
          flexDirection: { xs: "column-reverse", md: "row" },
        }}
      >
        {loading ? (
          <Typography
            variant="h2"
            fontSize="1.75rem"
            fontStyle="normal"
            fontWeight="700"
            lineHeight="1.5rem"
            padding="2rem 2.5rem"
            color="textColors.lightHeader"
          >
            Loading...
          </Typography>
        ) : (
          children
        )}
      </Box>
    </Box>
  );
};

export default MultiColumnCenterBox;

import { useSelectedUser } from "../../context/selectedUserContext.tsx";
import { AdminUser } from "../../types/types.js";

interface UserGroupsProps {
  usersList: AdminUser[];
  removeUserFromGroup: (group_id: string, user_id: string) => void;
  addUserToGroup: () => void;
}

export const UserGroups = ({
  usersList,
  removeUserFromGroup,
  addUserToGroup,
}: UserGroupsProps) => {
  const { selectedUser } = useSelectedUser();

  if (!selectedUser) {
    return <></>;
  }

  const userToShow = usersList.find((obj) => obj._id === selectedUser);

  if (!userToShow) {
    return <></>;
  }

  return (
    <div className="m-4 p-4 bg-slate-100 rounded-md">
      <h2 className="text-2xl font-bold mb-2">Associated Groups</h2>
      {userToShow.group_info?.map((group) => (
        <div
          key={group.group_id}
          className="mb-2 bg-white p-2 rounded-md shadow"
        >
          <div className="mb-2">
            <span className="font-semibold">
              {group.group_info?.name}
            </span>
            <span className="ml-4 text-sm text-gray-500">{group.group_id}</span>
            <span className="ml-4 text-sm text-black-800">role: {userToShow.user_role}</span>
          </div>
          <div className="flex justify-between">
            <button className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700">
              Edit Group
            </button>
            <button
              className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-700"
              onClick={() => removeUserFromGroup(group.group_id, selectedUser)}
            >
              Remove User From Group
            </button>
          </div>
        </div>
      ))}
      <button
        className="px-4 py-2 mt-2 bg-green-500 text-white rounded hover:bg-green-700"
        onClick={addUserToGroup}
      >
        Add User to Group
      </button>
    </div>
  );
};

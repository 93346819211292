import { useEffect, useRef, useState } from "react";
import { useNavigate, useNavigation } from "react-router-dom";
import { textToParagraph, formatDate, truncateText } from "../utils/utils";
import { PrimaryButton } from "./Buttons/Buttons";
import { SVGIcon } from "./SVGIcon";
import { UserNoteType } from "../types/types";
import {
  Typography,
  Box,
  CircularProgress,
  LinearProgress,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { useUIState } from "../context/uiState";
import { UIStateType } from "../context/uiState/context";
import { ComponentProps } from "react";
import React from "react";
import FullScreenLoader from "./FullScreenLoader";
import { getUserInfoFromLocalStorage } from "../utils/LocalStorageUtils";
import { getErrorMessage } from "../utils/errorUtils";

export const UserNote: React.FC<UserNoteType> = ({
  note_id,
  index,
  note_title,
  status,
  review_status,
  time,
  summary,
  issuing_user_name,
  link,
  checkedNoteIds,
  setCheckedNoteIds,
  origin,
  error_message = "",
}) => {
  const userInfo = getUserInfoFromLocalStorage();
  const navigate = useNavigate();
  const { state, setState } = useUIState();
  const truncateRef = useRef<HTMLElement>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const handleClick = () => {
    if (status !== "errored") {
      setLoading(true);

      setState((prevState: UIStateType) => ({
        ...prevState,
        navigation: { currentPage: "note" },
      }));
      navigate(link, { state: { origin: origin } });
    }
  };

  let haveTop = 0;
  if (index === 0) {
    haveTop = 1;
  }

  useEffect(() => {
    if (truncateRef.current) {
      truncateText(truncateRef.current, 3);
    }
  }, []);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let note = note_id;
    if (event.target.checked && setCheckedNoteIds) {
      // If the checkbox is checked, add the note to the checkedNoteIds array
      setCheckedNoteIds((prevcheckedNoteIds: string[]) => [
        ...prevcheckedNoteIds,
        note,
      ]);
    } else if (setCheckedNoteIds) {
      // // If the checkbox is unchecked, remove the note from the checkedNoteIds array
      setCheckedNoteIds((prevcheckedNoteIds: string[]) =>
        prevcheckedNoteIds.filter((checkedNote) => checkedNote !== note)
      );
    }
  };

  return (
    <Box
      className="note-container"
      sx={{
        display: "flex",
        alignItems: "flex-start",
        borderTop: haveTop,
        borderBottom: 1,
        paddingTop: "1.5rem",
        paddingBottom: "2rem",
        borderColor: "borderColors.primary",
        "&:hover": {
          cursor: "pointer",
          bgcolor: "backgroundColors.secondary",
        },
      }}
    >
      <Checkbox
        checked={checkedNoteIds && checkedNoteIds.includes(note_id)}
        onChange={handleCheckboxChange}
        sx={{
          color: "borderColors.darkprimary",
          "& .MuiSvgIcon-root": {
            fontSize: "1.5rem",
            padding: "0px",
          },
        }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          paddingTop: "0.5rem",
          px: "1rem",
          width: "100%",
          "&:hover": {
            cursor: "pointer",
            bgcolor: "backgroundColors.secondary",
          },
        }}
        onClick={handleClick}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              color="textColors.darkHeader"
              fontSize="1rem"
              fontStyle="normal"
              fontWeight="700"
              lineHeight="1.5rem"
              alignItems="center"
              sx={{ paddingBottom: "1rem" }}
            >
              {note_title}
            </Typography>
          </Box>
          <Box>
            {issuing_user_name && (
              <Typography
                color="textColors.darkText"
                fontSize="0.8rem"
                fontStyle="normal"
                fontWeight="600"
                lineHeight="1rem"
                alignItems="center"
                sx={{ paddingBottom: "0.5rem" }}
              >
                {issuing_user_name}
              </Typography>
            )}
            <Typography
              color="textColors.darkText"
              fontSize="0.8rem"
              fontStyle="normal"
              fontWeight="600"
              lineHeight="1rem"
              letterSpacing="0.125rem"
              textTransform="uppercase"
              textAlign="right"
              sx={{ paddingBottom: "0.5rem" }}
            >
              {formatDate(time)}
            </Typography>
            {status === "completed" && review_status === "archived" && (
              <Box sx={{ paddingBottom: "0.5rem" }}>
                <LinearProgress
                  variant="determinate"
                  value={100}
                  color="info"
                />
              </Box>
            )}
            {status === "completed" && review_status === "ready_for_review" && (
              <Box sx={{ paddingBottom: "0.5rem" }}>
                <LinearProgress
                  variant="determinate"
                  value={100}
                  color="success"
                />
              </Box>
            )}
            {status === "processing" && (
              <Box sx={{ paddingBottom: "0.5rem" }}>
                <LinearProgress value={100} color={"warning"} />
              </Box>
            )}
            {status === "errored" && (
              <Box sx={{ paddingBottom: "0.5rem" }}>
                <LinearProgress
                  value={100}
                  variant="determinate"
                  color={"error"}
                />
              </Box>
            )}
          </Box>
        </Box>
        <Typography
          ref={truncateRef}
          paddingRight="1rem"
          color="textColors.primaryText"
          fontWeight="400"
          lineHeight="1.5rem"
        >
          {status === "errored" && (
            <div className="text-gray-400">
              There was an error generating this note.
            </div>
          )}
          {summary}
        </Typography>
        {status === "errored" && error_message && getErrorMessage(error_message, note_id)}
      </Box>
      {loading && <FullScreenLoader />}
    </Box>
  );
};

import React, { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import {
  Box,
  Button,
  Typography,
  Paper,
  Stack,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  CircularProgress,
  TextField,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
} from "@mui/material";
import { styled } from "@mui/system";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import PictureAsPdfRoundedIcon from "@mui/icons-material/PictureAsPdfRounded";
import TextSnippetRoundedIcon from "@mui/icons-material/TextSnippetRounded";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import CloseIcon from "@mui/icons-material/Close";
import APIService from "../services/APIService";
import { useUser } from "../context/user";

const StyledDropzone = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  border: "2px dashed white",
  padding: theme.spacing(3),
  textAlign: "center",
  cursor: "pointer",
  transition: "background-color 0.3s ease-in-out",
}));

const UploadModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const [uploadState, setUploadState] = useState({
    files: [] as File[],
    text: "",
    status: "idle" as "idle" | "uploading" | "success" | "error",
    error: null as string | null,
  });
  const { getAccessToken } = useUser();

  const handleDrop = useCallback((acceptedFiles: File[]) => {
    setUploadState((prev) => ({
      ...prev,
      files: [...prev.files, ...acceptedFiles],
    }));
  }, []);

  const handleRemoveFile = useCallback((index: number) => {
    setUploadState((prev) => ({
      ...prev,
      files: prev.files.filter((_, i) => i !== index),
      error: "",
    }));
  }, []);

  const uploadToS3 = async (file: File, upload_url: string) => {
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 30000); // 30 second timeout

    try {
      // Convert the file to a Blob
      const blob = new Blob([file], { type: file.type });

      const response = await fetch(upload_url, {
        method: "PUT",
        body: blob,
        headers: {
          "Content-Type": blob.type,
        },
        signal: controller.signal,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      return { success: true };
    } catch (error) {
      // console.error('Upload failed:', error);
      return {
        success: false,
        error: error instanceof Error ? error.message : String(error),
      };
    } finally {
      clearTimeout(timeoutId);
    }
  };

  const handleUpload = useCallback(async () => {
    setUploadState((prev) => ({ ...prev, status: "uploading", error: null }));

    try {
      const pdfFiles = uploadState.files;
      const textContent = uploadState.text.trim();

      // console.log("Starting upload process...");
      if (pdfFiles.length > 0 && textContent) {
        // console.log("Uploading both PDF(s) and text");
      } else if (pdfFiles.length > 0) {
        // console.log(`Uploading ${pdfFiles.length} PDF file(s) only`);
      } else if (textContent) {
        // console.log("Uploading text only");
      } else {
        // console.log("No content to upload");
        throw new Error("No content to upload");
      }

      const generateUniqueFileName = ({
        prefix,
        extension,
      }: {
        prefix: string;
        extension: string;
      }) => {
        const timestamp = new Date()
          .toISOString()
          .replace(/[-:]/g, "")
          .split(".")[0];
        const randomString = Math.random().toString(36).substring(2, 8);
        return `${prefix}_${timestamp}_${randomString}.${extension}`;
      };

      const filesToUpload = [
        ...pdfFiles,
        ...(textContent
          ? [
              new File(
                [textContent],
                generateUniqueFileName({
                  prefix: "pasted_content",
                  extension: "txt",
                }),
                { type: "text/plain" }
              ),
            ]
          : []),
      ];

      await Promise.all(
        filesToUpload.map(async (file) => {
          // console.log(`Processing file: ${file.name}`);
          const getUploadURLResponse = await APIService.makeAPIPostRequest({
            requestString: "/pdfUploads/upload_pdf",
            accessToken: await getAccessToken(),
            body: { fileName: file.name, fileType: file.type },
            retries: 3,
          });

          if (!getUploadURLResponse.ok) {
            // console.error(`Failed to get upload URL for ${file.name}`);
            throw new Error(
              `Please upload the file with the type pdf or text, not ${file.type}`
            );
          }

          const { pdf_generation_session_id, s3_file_name, upload_url } =
            getUploadURLResponse.value;
          // console.log(`Received: session ID: ${pdf_generation_session_id}, upload URL: ${upload_url}`);

          const newFileName = `${s3_file_name}`;
          const newFile = new File([file], newFileName, { type: file.type });

          // console.log(`Uploading ${file.name} to S3 as ${newFileName}`);

          try {
            const uploadResult = await uploadToS3(newFile, upload_url);

            if (!uploadResult.success) {
              // console.error(`Uploading file to S3 failed for ${newFileName}: ${uploadResult.error}`);
              throw new Error(
                "Sorry, the upload failed. Please try again later."
              );
            }
            // console.log(`Successfully uploaded ${file.name} as ${newFileName}`);

            // Send POST request for successful upload
            await APIService.makeAPIPostRequest({
              requestString: "/pdfUploads/confirmUpload",
              accessToken: await getAccessToken(),
              body: {
                sessionId: pdf_generation_session_id,
                status: "completed",
                s3_file_name: s3_file_name,
              },
              retries: 3,
            });
          } catch (error) {
            // Send POST request for failed upload
            await APIService.makeAPIPostRequest({
              requestString: "/pdfUploads/confirmUpload",
              accessToken: await getAccessToken(),
              body: {
                sessionId: pdf_generation_session_id,
                status: "aborted",
                s3_file_name: s3_file_name,
              },
              retries: 3,
            });
            throw error;
          }
        })
      );

      // console.log("Upload process completed successfully");
      setUploadState((prev) => ({ ...prev, status: "success" }));
    } catch (error) {
      // console.error("Upload failed:", error);
      setUploadState((prev) => ({
        ...prev,
        status: "error",
        text: "",
        error: error instanceof Error ? error.message : String(error),
      }));
    }
  }, [uploadState.files, uploadState.text, getAccessToken]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop,
    accept: { "application/pdf": [".pdf"] },
    multiple: true,
  });

  const handleClose = () => {
    setUploadState({
      files: [],
      text: "",
      status: "idle",
      error: null,
    });
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Stack direction="row" spacing={-1} justifyContent="center">
          <TextSnippetRoundedIcon
            sx={{
              color: "primary.main",
              fontSize: 40,
              mx: 1,
              transform: "rotate(-15deg)",
              position: "relative",
              top: "2px",
            }}
          />
          <PictureAsPdfRoundedIcon
            sx={{
              color: "primary.main",
              fontSize: 40,
              mx: 1,
              position: "relative",
              top: "2px",
            }}
          />
          <TextSnippetRoundedIcon
            sx={{
              color: "primary.main",
              fontSize: 40,
              mx: 1,
              transform: "rotate(15deg)",
              position: "relative",
              top: "2px",
            }}
          />
        </Stack>
        {uploadState.status !== "success" && (
          <Box sx={{ display: "flex", justifyContent: "center", my: "2rem" }}>
            <Typography>
              Upload a document or paste a template that you normally use and
              we'll inspect it and get back to you soon about converting it into
              a JotPsych template.
            </Typography>
          </Box>
        )}
      </DialogTitle>
      <DialogContent>
        {uploadState.status !== "success" ? (
          <>
            <StyledDropzone {...getRootProps()} sx={{ mb: 2 }}>
              <input {...getInputProps()} />
              <Box sx={{ py: 2 }}>
                <FileUploadIcon sx={{ fontSize: 40, color: "white" }} />
                <Typography variant="h6" color="secondary.contrastText">
                  {uploadState.files.length > 0
                    ? "Drag more files or click to select"
                    : "Drag PDF files here or click to select"}
                </Typography>
              </Box>
            </StyledDropzone>
            {uploadState.files.length > 0 && (
              <Box sx={{ width: "100%", mb: 2 }}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Selected Files:
                </Typography>
                <List dense>
                  {uploadState.files.map((file, index) => (
                    <ListItem key={index}>
                      <ListItemIcon>
                        <InsertDriveFileIcon />
                      </ListItemIcon>
                      <ListItemText primary={file.name} />
                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => handleRemoveFile(index)}
                        >
                          <CloseIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              </Box>
            )}
            <Divider sx={{ my: 3 }}>
              <Typography variant="body2" color="text.secondary">
                or
              </Typography>
            </Divider>
            <TextField
              id="text-field"
              label="Paste your text here"
              multiline
              rows={4}
              variant="outlined"
              value={uploadState.text}
              onChange={(e) =>
                setUploadState((prev) => ({ ...prev, text: e.target.value }))
              }
              sx={{
                width: "100%",
                mb: 2,
              }}
            />
            {uploadState.error && (
              <Typography color="error" sx={{ mt: 2 }}>
                {uploadState.error}
              </Typography>
            )}
          </>
        ) : (
          <Box sx={{ textAlign: "center" }}>
            <Typography variant="h6">
              Thanks! We'll email you once your custom template is completed.
            </Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
        {(uploadState.files.length > 0 || uploadState.text.trim()) &&
          uploadState.status !== "success" && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleUpload}
              disabled={uploadState.status === "uploading"}
            >
              {uploadState.status === "uploading" ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "UPLOAD"
              )}
            </Button>
          )}
      </DialogActions>
    </Dialog>
  );
};

export default UploadModal;

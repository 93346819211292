import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../utils/utils";
import { AdminModule } from "../../types/types";
import { ModulesLoader } from "../../loaders/ModulesLoader";
import APIService from "../../services/APIService";
import { useUser } from "../../context/user";

interface ModulesOverviewProps {}

export const ModulesOverview: React.FC<ModulesOverviewProps> = () => {
  const { getAccessToken } = useUser();
  const [isLoading, setIsLoading] = useState(true);
  const [modules, setModules] = useState<AdminModule[] | null>();
  const navigate = useNavigate();

  const fetchModulesData = async () => {
    setIsLoading(true);
    const response = await ModulesLoader();
    if (response) {
      setModules(response);
      setIsLoading(false);
    }
  };

  const onUpdateSuccess = async () => {
    fetchModulesData();
  };

  const createNewModule = async () => {
    const accessToken = await getAccessToken();

    const response = await APIService.makeAPIPostRequest({
      requestString: "/template/createModule",
      accessToken: accessToken,
      body: {
        module_name: "New Module",
        extraction_prompt: "",
        generation_prompt: "",
        placeholders: ["{% NA_DEFAULT %}"],
      },
    });

    if (response.ok) {
      const data = response.value;
      navigate(`/admin/module/${data.module_id}`);
    }
  };

  const handleDeleteModule = async (module_id: string) => {
    const accessToken = await getAccessToken();

    const response = await APIService.makeAPIPostRequest({
      requestString: "/template/deleteModule",
      accessToken: accessToken,
      body: {
        module_id,
      },
    });

    if (!response.ok) {
      console.error("Problem deleting module");
    } else {
      onUpdateSuccess();
    }
  };

  useEffect(() => {
    fetchModulesData();
  }, []);

  if (isLoading || !modules) return <div>Loading...</div>;

  return (
    <div className="flex">
      <div className="h-screen">
        <table className="table-auto w-full border-collapse border-2 border-gray-500">
          <thead className="sticky top-0">
            <tr className="bg-blue-200">
              <th className="px-4 py-2 border-2 border-gray-400">id</th>
              <th className="px-4 py-2 border-2 border-gray-400">Name</th>
              <th className="px-4 py-2 border-2 border-gray-400">Created On</th>
              <th className="px-4 py-2 border-2 border-gray-400">
                Last Modified
              </th>
              <th className="px-4 py-2 border-2 border-gray-400">Public</th>
              <th className="px-4 py-2 border-2 border-gray-400">Edit</th>
              <th className="px-4 py-2 border-2 border-gray-400">Delete</th>
            </tr>
          </thead>
          <tbody>
            {modules.map((module, index) => (
              <tr className={`hover:bg-blue-100 cursor-pointer`} key={index}>
                <td className="px-4 py-2 border-2 border-gray-400">
                  {module.module_id}
                </td>
                <td className="px-4 py-2 border-2 border-gray-400">
                  {module.module_name}
                </td>
                <td className="px-4 py-2 border-2 border-gray-400">
                  {formatDate(module.created_on)}
                </td>
                <td className="px-4 py-2 border-2 border-gray-400">
                  {formatDate(module.modified_on)}
                </td>
                <td className="px-4 py-2 border-2 border-gray-400">
                  {module.public ? "True" : ""}
                </td>
                <td className="px-4 py-2 border-2 border-gray-400">
                  <button
                    className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
                    onClick={() => {
                      navigate(`/admin/module/${module.module_id}`);
                    }}
                  >
                    Edit Module
                  </button>
                </td>
                <td className="px-4 py-2 border-2 border-gray-400">
                  <button
                    className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-700"
                    onClick={() => handleDeleteModule(module.module_id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="flex justify-center mt-4">
          <button
            onClick={createNewModule}
            className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
          >
            Create New Module
          </button>
        </div>
      </div>
    </div>
  );
};

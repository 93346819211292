import React, { useState } from "react";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (title: string, externalUrl: string) => void;
}

export const UserNotificationModal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
}) => {
    const [title, setTitle] = useState<string>("");
    const [externalUrl, setExternalUrl] = useState<string>("");
  const handleConfirm = () => {
    if(title == ""){
        alert("Enter title")
        return;
    }
    onConfirm(title, externalUrl);
  };

  const handleOverlayClick = (event: any) => {
    if (event.target === event.currentTarget) {
        setTitle("")
        setExternalUrl("")
      onClose();
    }
  };

  const handleTitleNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTitle(event.target.value);
  };

  const handleURLChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setExternalUrl(event.target.value);
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div
        className="flex items-center justify-center min-h-screen"
        onClick={handleOverlayClick}
      >
        <div className="bg-white p-4 rounded-lg text-left overflow-hidden shadow-xl">
          <h3 className="my-2">Notification Title</h3>
          <input
            type="text"
            value={title}
            onChange={handleTitleNameChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
          <br/>
          <h3 className="my-2">External URL</h3>
          <input
            type="text"
            value={externalUrl}
            onChange={handleURLChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
          <br/>
          <div className="mt-5 sm:mt-3 sm:flex sm:flex-row-reverse">
            <button
              type="button"
              className="ml-3 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={handleConfirm}
            >
              Confirm
            </button>
            <button
              type="button"
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
              onClick={onClose}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

import { useEffect, useRef, useState } from "react";
import { json, Params, useLoaderData, useParams } from "react-router-dom";
import { NoteSection } from "../components/NoteSection";
import { Sidebar } from "../components/layout/Sidebar";
import { SVGIcon } from "../components/SVGIcon";
import dummydata from "../dummydata/data.json";
import { UserNote } from "../components/UserNote";
import { MainContainer } from "../components/layout/MainContainer";
import { UserNotesList } from "../components/UserNotesList";
import { UserNoteTypeII } from "../types/types";
import {
  ArchivedNotesLoader,
  ForReviewNotesLoader,
} from "../loaders/NotesLoader";
import { UnauthorizedError } from "../components/errors/UnathorizedError";
import { getUserInfoFromLocalStorage } from "../utils/LocalStorageUtils";
import { UserInfoForm } from "../components/UserInfoForm";
import { Box } from "@mui/material";
import APIService from "../services/APIService";
import { useUser } from "../context/user";
import { GroupNotesList } from "../components/GroupNotesList";

export type UserNotesDataType = UserNoteTypeII[];

function GroupNotesView() {
  const [forReviewNotes, setForReviewNotes] = useState<
    UserNoteTypeII[] | undefined | null
  >();
  const [archivedNotes, setArchivedNotes] = useState<
    UserNoteTypeII[] | undefined | null
  >();

  const accessToken = localStorage.getItem("accessToken");
  const [loadError, setLoadError] = useState(false);
  const { userState } = useUser();

  useEffect(() => {
    const fetchNotes = async (status: string) => {
      if (!accessToken || !userState?.group_ids?.length) {
        return;
      }

      const firstGroupId = userState.group_ids[0];

      try {
        const result = await APIService.makeAPIGetRequest({
          requestString: `/group/getGroupNotes?group_id=${firstGroupId}&status=${status}`,
          accessToken: accessToken,
        });

        if (result.ok) {
          if (status === "ready_for_review") {
            setForReviewNotes(result.value.notes);
          } else if (status === "archived") {
            setArchivedNotes(result.value.notes);
          }
        } else {
          setLoadError(true);
        }
      } catch {
        console.log("setting load error");
        setLoadError(true);
      }
    };

    fetchNotes("ready_for_review");
    fetchNotes("archived");
  }, [userState, accessToken]);

  if (loadError) {
    return (
      <UnauthorizedError message="There has been an error. Please login again." />
    );
  }

  return (
    <GroupNotesList
      forReviewNotes={forReviewNotes}
      archivedNotes={archivedNotes}
    />
  );
}

export default GroupNotesView;

import { useState } from "react";
import { useLoaderData } from "react-router-dom";
import { PrimaryButton } from "../components/Buttons/Buttons";
import { Patient } from "../types/types";
import { PatientsLoader } from "../loaders/PatientsLoader";
import PatientsList from "../components/PatientsList";
import NewPatientForm, { NewPatientInfo } from "../components/NewPatientForm";
import APIService from "../services/APIService";
import { useUser } from "../context/user";

export const PatientManagerView = () => {
  const patients = useLoaderData() as Awaited<
    ReturnType<typeof PatientsLoader>
  >;
  const { getAccessToken } = useUser();
  const [patientsList, setPatientsList] = useState<Patient[]>(patients);
  const [showForm, setShowForm] = useState(false);
  const [formError, setFormError] = useState("");
  const [patientSearchString, setPatientSearchString] = useState<string>("");
  const [filteredPatients, setFilteredPatients] =
    useState<Patient[]>(patientsList);

  const handlePatientSearchChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const searchValue = e.target.value.toLowerCase();
    setPatientSearchString(searchValue);
    const filtered = patientsList.filter((patient) =>
      patient.patient_info.name.toLowerCase().includes(searchValue)
    );
    setFilteredPatients(filtered);
  };

  const handleAddNewPatient = async (info: NewPatientInfo) => {
    const accessToken = await getAccessToken();

    const response = await APIService.makeAPIPostRequest({
      requestString: "/user/createPatientOnUser",
      accessToken: accessToken,
      body: {
        patient_info: info,
      },
    });

    if (response.ok) {
      // Refresh the patient list or navigate as needed
      setShowForm(false);
      setPatientsList(await PatientsLoader());
    } else {
      // Handle error
      setFormError(`Invalid format.`);
      console.error("Failed to add new patient");
    }
  };

  return (
    <div className="bg-gray-100 h-screen flex-1">
      <div className="text-center py-4 text-2xl font-bold bg-blue-500 text-white">
        Patient Manager
      </div>
      <div className="p-4 flex flex-wrap justify-around">
        <div className="bg-white p-4 rounded-md shadow-md flex-none w-full md:w-1/3">
          <PrimaryButton handleClick={() => setShowForm(!showForm)}>
            Add New Patient
          </PrimaryButton>
          {showForm && (
            <NewPatientForm
              onAddNewPatient={handleAddNewPatient}
              formError={formError}
            />
          )}
        </div>

        <div className="bg-white p-4 rounded-md shadow-md flex flex-col md:w-2/3 max-h-[50vh]">
          <input
            onChange={handlePatientSearchChange}
            value={patientSearchString}
            className="mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            placeholder="Search for a patient's name..."
          />
          <div className="overflow-y-auto h-full">
            <PatientsList patientsList={filteredPatients} />
          </div>
        </div>
      </div>
    </div>
  );
};

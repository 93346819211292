import { useNavigate } from "react-router-dom";
import { AdminFeedbackSession } from "../../types/types";
import { formatDate } from "../../utils/utils";

interface FeedbackSessionRowProps {
  feedbackSession: AdminFeedbackSession;
}

export const FeedbackSessionRow = ({
  feedbackSession,
}: FeedbackSessionRowProps) => {
  const navigate = useNavigate();

  return (
    <tr
      className={`hover:bg-blue-100 cursor-pointer`}
      onClick={() => {
        navigate(`/admin/feedback/${feedbackSession.feedback_session_id}`);
      }}
    >
      <td className="px-4 py-2 border-2 border-gray-400">
        {feedbackSession.feedback_session_id}
      </td>
      <td className="px-4 py-2 border-2 border-gray-400">
        {feedbackSession.note_id}
      </td>
      <td className="px-4 py-2 border-2 border-gray-400">
        {feedbackSession.internal_title}
      </td>
      <td className="px-4 py-2 border-2 border-gray-400">
        {feedbackSession.user_id}
      </td>
      <td className="px-4 py-2 border-2 border-gray-400">
        {feedbackSession.feedback_events.length}
      </td>

      <td className="px-4 py-2 border-2 border-gray-400">
        {formatDate(feedbackSession.last_modified)}
      </td>
    </tr>
  );
};

import { createContext, useContext, useState } from "react";
import { AdminEmails, AdminUser } from "../../types/types";

interface SelectedUserContextType {
  selectedUser: string;
  selectUser: (user: string) => void;
}

const SelectedUserContext = createContext<SelectedUserContextType | undefined>(
  undefined
);

export const useSelectedUser = () => {
  return useContext(SelectedUserContext)!;
};

export const SelectedUserProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [selectedUser, setSelectedUser] = useState<string>("");

  const selectUser = (user: string) => {
    setSelectedUser(user);
  };

  return (
    <SelectedUserContext.Provider value={{ selectedUser, selectUser }}>
      {children}
    </SelectedUserContext.Provider>
  );
};

import React, { useEffect, useState } from "react";
import {
  AdminTemplate,
  AdminSection,
  AdminModule,
  AdminBaseTemplate,
} from "../../types/types";
import { SectionEditor } from "../../components/Admin/SectionEditor";
import { ModulesLoader } from "../../loaders/ModulesLoader";
import { useLoaderData, useNavigate } from "react-router-dom";
import {
  BaseTemplateLoader,
  TemplateLoader,
} from "../../loaders/TemplatesLoader";
import { TemplateCustomPromptSection } from "../../components/Admin/CustomPromptSections";
import APIService from "../../services/APIService";
import { useUser } from "../../context/user";

interface TemplateEditorProps {}

export const BaseTemplateEditor: React.FC<TemplateEditorProps> = () => {
  const { getAccessToken } = useUser();
  const template = useLoaderData() as Awaited<
    ReturnType<typeof BaseTemplateLoader>
  >;
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [modifiedTemplate, setModifiedTemplate] =
    useState<AdminBaseTemplate>(template);

  const navigate = useNavigate();

  const onTemplateChange = () => {
    console.log(modifiedTemplate);
  };

  const createNewPublicTemplate = async () => {
    const accessToken = await getAccessToken();

    const response = await APIService.makeAPIPostRequest({
      requestString: "/template/createPublicTemplate",
      accessToken: accessToken,
      body: {
        template_name: "New Public Template",
        inherits_from: template.base_template_id,
        summary_modules: [],
        sections: [],
      },
    });

    if (response.ok) {
      const data = response.value;
      navigate(`/admin/publictemplate/${data.template_id}`);
    }
  };

  const saveTemplate = async () => {
    setIsSaving(true);

    const accessToken = await getAccessToken();

    const response = await APIService.makeAPIPostRequest({
      requestString: "/template/updateBaseTemplate",
      accessToken: accessToken,
      body: {
        base_template_id: template.base_template_id,
        updated_fields: modifiedTemplate,
      },
    });

    if (!response.ok) {
      console.error("there was a problem with the network request");
    } else {
      console.log("Successfully saved!");
    }

    setIsSaving(false);
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value =
      event.target.name === "max_tokens"
        ? Number(event.target.value)
        : event.target.value;

    setModifiedTemplate({
      ...modifiedTemplate,
      [event.target.name]: value,
    });
    onTemplateChange();
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setModifiedTemplate({
      ...modifiedTemplate,
      [event.target.name]: event.target.checked,
    });
  };

  useEffect(() => {
    setIsLoading(false);
  }, []);

  if (isLoading) return <div>Loading...</div>;

  return (
    <div className="m-4 p-4 bg-slate-100 rounded-md">
      <div className="flex justify-center mt-4">
        <button
          onClick={saveTemplate}
          className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
        >
          Save Template
        </button>
      </div>
      <div className="flex justify-between mb-4">
        <h1 className="text-2xl font-bold">
          {modifiedTemplate.base_template_name}
        </h1>
        <div className="text-sm text-gray-500">{template.base_template_id}</div>
      </div>
      <div className="mb-2 bg-white p-2 rounded-md shadow">
        <div className="mb-2">
          <label
            className="block text-sm text-gray-500"
            htmlFor="template_name"
          >
            Template Name
          </label>
          <input
            type="text"
            name="base_template_name"
            id="base_template_name"
            value={modifiedTemplate.base_template_name}
            onChange={handleInputChange}
            className="mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
        <div className="flex justify-between space-x-4 mb-2">
          <div className="w-1/3">
            <label className="block text-sm text-gray-500" htmlFor="model">
              Model
            </label>
            <input
              type="text"
              name="model"
              id="model"
              value={modifiedTemplate.model || ""}
              onChange={handleInputChange}
              className="mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>

          <div className="w-1/3">
            <label
              className="block text-sm text-gray-500"
              htmlFor="temperature"
            >
              Temperature
            </label>
            <input
              type="text"
              name="temperature"
              id="temperature"
              value={modifiedTemplate.temperature || ""}
              onChange={handleInputChange}
              className="mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>

          <div className="w-1/3">
            <label className="block text-sm text-gray-500" htmlFor="max_tokens">
              Max Tokens
            </label>
            <input
              type="number"
              name="max_tokens"
              id="max_tokens"
              value={modifiedTemplate.max_tokens || 0}
              onChange={handleInputChange}
              className="mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
        </div>

        <div className="mb-2">
          <label
            className="block text-sm text-gray-500"
            htmlFor="system_prompt"
          >
            System Prompt
          </label>
          <textarea
            name="system_prompt"
            id="system_prompt"
            value={modifiedTemplate.system_prompt}
            onChange={handleInputChange}
            className="mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
        <div className="mb-2">
          <label
            className="block text-sm text-gray-500"
            htmlFor="anthropic_system_prompt"
          >
            Anthropic System Prompt
          </label>
          <textarea
            name="anthropic_system_prompt"
            id="anthropic_system_prompt"
            value={modifiedTemplate.anthropic_system_prompt}
            onChange={handleInputChange}
            className="mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
      </div>
      <div className="flex justify-center mt-4">
        <button
          onClick={saveTemplate}
          className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
        >
          Save Template
        </button>
      </div>
      {isSaving && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="p-6 bg-white rounded shadow-xl text-xl font-bold">
            Saving...
          </div>
        </div>
      )}
      <div className="flex justify-center mt-4">
        <button
          onClick={createNewPublicTemplate}
          className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
        >
          Create New Public Template Based on this Base Template
        </button>
      </div>
    </div>
  );
};

import { useEffect, useState } from "react";
import { useSelectedUser } from "../../context/selectedUserContext.tsx";
import { AdminNote, AdminUser } from "../../types/types.js";
import { AdminNotesLoader } from "../../loaders/AdminNotesLoader.js";
import { formatDate } from "../../utils/utils.js";
import { useNavigate } from "react-router-dom";
import { PrimaryButton } from "../Buttons/Buttons.js";

export const UserRecentNotes = () => {
  const { selectedUser } = useSelectedUser();
  const [isLoading, setIsLoading] = useState(false);
  const [notes, setNotes] = useState<AdminNote[] | null>();

  const navigate = useNavigate();

  const fetchNotesData = async () => {
    setIsLoading(true);
    const response = await AdminNotesLoader(0, 3, selectedUser);
    if (response) {
      setIsLoading(false);
      console.log(response);
      setNotes(response);
    }
  };

  useEffect(() => {
    fetchNotesData();
  }, [selectedUser]);

  if (isLoading)
    return <div className="m-4 p-4 bg-slate-100 rounded-md">Loading...</div>;

  return (
    <div className="m-4 p-4 bg-slate-100 rounded-md">
      <h2 className="text-2xl font-bold mb-2">Recent Notes</h2>
      {notes && notes.length > 0 ? (
        <div>
          {notes.map((note: AdminNote) => (
            <div
              className="m-2 p-2 bg-slate-200 rounded-md hover:bg-blue-100 cursor-pointer"
              onClick={() => {
                navigate(`/admin/note/${note.note_id}`);
              }}
            >
              <div className="font-bold">{note.internal_title}</div>
              <div className=" text-slate-500">
                {formatDate(note.time_uploaded)}
              </div>
            </div>
          ))}
          <div className="text-center">
            <PrimaryButton
              handleClick={() => navigate(`/admin/notes/${selectedUser}`)}
            >
              See All Notes for User
            </PrimaryButton>
          </div>
        </div>
      ) : (
        <p>No recent notes to display</p>
      )}
    </div>
  );
};

import { AdminBaseTemplate, AdminTemplate } from "../../types/types";
import React, { useEffect, useState } from "react";
import {
  BaseTemplatesLoader,
  TemplatesLoader,
} from "../../loaders/TemplatesLoader";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../utils/utils";
import APIService from "../../services/APIService";
import { useUser } from "../../context/user";

interface TemplatesOverviewProps {}

export const BaseTemplatesOverview: React.FC<TemplatesOverviewProps> = () => {
  const { getAccessToken } = useUser();
  const [isLoading, setIsLoading] = useState(true);
  const [templates, setTemplates] = useState<AdminBaseTemplate[] | null>();
  const navigate = useNavigate();

  const fetchTemplatesData = async () => {
    setIsLoading(true);
    const response = await BaseTemplatesLoader();
    if (response) {
      setTemplates(response);
      setIsLoading(false);
    }
  };

  const createNewBaseTemplate = async () => {
    const accessToken = await getAccessToken();

    const response = await APIService.makeAPIPostRequest({
      requestString: "/template/createBaseTemplate",
      accessToken: accessToken,
      body: {
        base_template_name: "New Base Template",
        model: "gpt-4",
        temperature: 0.1,
        max_tokens: 2500,
        system_prompt: "",
      },
    });

    if (response.ok) {
      const data = response.value;
      navigate(`/admin/basetemplate/${data.base_template_id}`);
    }
  };

  const handleDeleteTemplate = async (base_template_id: string) => {
    console.log(base_template_id);
    const accessToken = await getAccessToken();

    const response = await APIService.makeAPIPostRequest({
      requestString: "/template/deleteBaseTemplate",
      accessToken: accessToken,
      body: {
        test: "test",
        base_template_id: base_template_id,
      },
    });

    if (!response.ok) {
      console.error("Problem deleting template");
    } else {
      onUpdateSuccess();
    }
  };

  const onUpdateSuccess = async () => {
    fetchTemplatesData();
  };

  useEffect(() => {
    fetchTemplatesData();
  }, []);

  if (isLoading || !templates) return <div>Loading...</div>;

  return (
    <div className="flex">
      <div className="h-screen">
        <table className="table-auto w-full border-collapse border-2 border-gray-500">
          <thead className="sticky top-0">
            <tr className="bg-blue-200">
              <th className="px-4 py-2 border-2 border-gray-400">id</th>
              <th className="px-4 py-2 border-2 border-gray-400">Name</th>
              <th className="px-4 py-2 border-2 border-gray-400">Created On</th>
              <th className="px-4 py-2 border-2 border-gray-400">
                Last Modified
              </th>
              <th className="px-4 py-2 border-2 border-gray-400">Edit</th>
              <th className="px-4 py-2 border-2 border-gray-400">Delete</th>
            </tr>
          </thead>
          <tbody>
            {templates.map((template, index) => (
              <tr className={`hover:bg-blue-100 cursor-pointer`} key={index}>
                <td className="px-4 py-2 border-2 border-gray-400">
                  {template.base_template_id}
                </td>
                <td className="px-4 py-2 border-2 border-gray-400">
                  {template.base_template_name}
                </td>
                <td className="px-4 py-2 border-2 border-gray-400">
                  {formatDate(template.created_on)}
                </td>
                <td className="px-4 py-2 border-2 border-gray-400">
                  {formatDate(template.modified_on)}
                </td>
                <td className="px-4 py-2 border-2 border-gray-400">
                  <button
                    className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
                    onClick={() => {
                      navigate(
                        `/admin/basetemplate/${template.base_template_id}`
                      );
                    }}
                  >
                    Edit Template
                  </button>
                </td>
                <td className="px-4 py-2 border-2 border-gray-400">
                  <button
                    className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-700"
                    onClick={() =>
                      handleDeleteTemplate(template.base_template_id)
                    }
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="flex justify-center mt-4">
          <button
            onClick={createNewBaseTemplate}
            className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
          >
            Create New Base Template
          </button>
        </div>
      </div>
      {/* <div className="w-1/2 fixed right-0 border-2 border-gray-400">
        <UserInfo usersList={users!} />
        <UserTemplates
          usersList={users!}
          removeTemplateFromUser={handleRemoveTemplateFromUser}
          addTemplateToUser={() => {
            setTemplatesModalVisible(true);
          }}
        />
        <UserGroups
          usersList={users!}
          addUserToGroup={() => {
            setGroupsModalVisible(true);
          }}
          removeUserFromGroup={handleRemoveUserFromGroup}
        />
      </div> */}
    </div>
  );
};

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../utils/utils";
import { useUser } from "../../context/user";
import APIService from "../../services/APIService";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { AdminSpecialty } from "../../types/types";

interface BaseTemplate {
  base_template_id: string;
  base_template_name: string;
}

interface SpecialtiesOverviewProps {}

export const SpecialtiesOverview: React.FC<SpecialtiesOverviewProps> = () => {
  const { getAccessToken } = useUser();
  const [isLoading, setIsLoading] = useState(true);
  const [specialties, setSpecialties] = useState<AdminSpecialty[] | null>(null);
  const [baseTemplates, setBaseTemplates] = useState<BaseTemplate[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newSpecialtyName, setNewSpecialtyName] = useState("");
  const [selectedBaseTemplateId, setSelectedBaseTemplateId] = useState("");
  const navigate = useNavigate();

  const fetchSpecialties = async () => {
    setIsLoading(true);
    const accessToken = await getAccessToken();
    const response = await APIService.makeAPIGetRequest({
      requestString: "/specialties/getAllSpecialties",
      accessToken: accessToken,
    });

    if (response.ok) {
      const data = await response.value;
      console.log(data);
      setSpecialties(data.specialties);
    } else {
      console.error("Problem fetching specialties");
    }
    setIsLoading(false);
  };

  const fetchBaseTemplates = async () => {
    const accessToken = await getAccessToken();
    const response = await APIService.makeAPIGetRequest({
      requestString: "/template/getAllBaseTemplates",
      accessToken: accessToken,
    });

    if (response.ok) {
      const data = await response.value;
      console.log(data);
      setBaseTemplates(data.base_templates);
    } else {
      console.error("Problem fetching base templates");
    }
  };

  const handleDeleteSpecialty = async (specialty_id: string) => {
    const accessToken = await getAccessToken();

    const response = await APIService.makeAPIPostRequest({
      requestString: "/specialty/deleteSpecialty",
      accessToken: accessToken,
      body: {
        specialty_id,
      },
    });

    if (!response.ok) {
      console.error("Problem deleting specialty");
    } else {
      onUpdateSuccess();
    }
  };

  const handleAddSpecialty = async () => {
    const accessToken = await getAccessToken();

    const response = await APIService.makeAPIPostRequest({
      requestString: "/specialties/createSpecialty",
      accessToken: accessToken,
      body: {
        specialty_name: newSpecialtyName,
        base_template_id: selectedBaseTemplateId,
      },
    });

    if (response.ok) {
      setIsModalOpen(false);
      setNewSpecialtyName("");
      setSelectedBaseTemplateId("");
      onUpdateSuccess();
    } else {
      console.error("Problem creating specialty");
    }
  };

  const onUpdateSuccess = async () => {
    fetchSpecialties();
  };

  useEffect(() => {
    fetchSpecialties();
    fetchBaseTemplates();
  }, []);

  if (isLoading || !specialties) return <div>Loading...</div>;

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      <TableContainer
        component={Paper}
        style={{ flexGrow: 1, overflow: "auto" }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Edit</TableCell>
              <TableCell>Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {specialties.map((specialty) => (
              <TableRow key={specialty.specialty_id} hover>
                <TableCell>{specialty.specialty_id}</TableCell>
                <TableCell>{specialty.specialty_name}</TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      navigate(`/admin/specialty/${specialty.specialty_id}`)
                    }
                  >
                    Edit Specialty
                  </Button>
                </TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() =>
                      handleDeleteSpecialty(specialty.specialty_id)
                    }
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div
        style={{ display: "flex", justifyContent: "center", margin: "16px 0" }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => setIsModalOpen(true)}
        >
          Add Specialty
        </Button>
      </div>

      <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <DialogTitle>Add New Specialty</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Specialty Name"
            type="text"
            fullWidth
            value={newSpecialtyName}
            onChange={(e) => setNewSpecialtyName(e.target.value)}
          />
          <FormControl fullWidth margin="dense">
            <InputLabel id="base-template-label">Base Template</InputLabel>
            <Select
              labelId="base-template-label"
              value={selectedBaseTemplateId}
              onChange={(e) =>
                setSelectedBaseTemplateId(e.target.value as string)
              }
            >
              {baseTemplates.map((template) => (
                <MenuItem
                  key={template.base_template_id}
                  value={template.base_template_id}
                >
                  {template.base_template_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsModalOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddSpecialty} color="primary">
            Add Specialty
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

import React, { useState, useEffect, memo } from "react";
import { useAudioDataContext } from "../context/AudioDataContext";
import { Colors } from "@shared/Colors";
import { Box, Typography } from "@mui/material";
import { useRecordingContext } from "../context/RecordingContext";

interface VolumeBarProps {
  volume: number;
}

const VolumeBar: React.FC<VolumeBarProps> = ({ volume }) => {
  const barHeight = `${Math.min(volume, 100)}%`; // Ensure bar height does not exceed 100%

  return (
    <div
      style={{
        height: barHeight,
        width: "1%",
        marginLeft: "1%",
       backgroundColor: Colors.jotpsych_main,
      }}
    />
  );
};

interface AudioVisualizerProps {}

const AudioVisualizer: React.FC<AudioVisualizerProps> = memo(() => {
  const { audioData } = useAudioDataContext();
  const { recordingStatus} = useRecordingContext();
  const [volumes, setVolumes] = useState<number[]>(new Array(50).fill(1));
  const scalingFactor = 1; // Define a scaling factor to make waveforms more obvious

  useEffect(() => {
    if (recordingStatus.recordingPaused) {
      return
    }
    // Apply scaling factor and ensure volume is at least 1 and does not exceed 100
    const scaledVolume = Math.min(Math.max(1, audioData) * scalingFactor, 100);
    // Add new volume and remove the oldest one to simulate scrolling
    setVolumes((prevVolumes) => [
      ...prevVolumes.slice(1),
      Math.max(1, scaledVolume),
    ]);
  }, [audioData, recordingStatus.recordingPaused]);

  useEffect(() => {
    // If the audioData is reset to -1, reset the volumes array
    if (audioData === -1) {
      setVolumes(new Array(50).fill(1)); // Reset volumes to default bars
    }
  }, [audioData]); // Add audioData to the dependency array

  return (
    <div
      style={{
        display: "flex", // This will lay out the child divs in a row
        flexDirection: "row",
        alignItems: "center", // This will vertically center the child divs
        justifyContent: "center",
        height: "100%",
        width: "100%",
        overflow: "hidden",
      }}
    >
      {volumes.map((vol, index) => (
        <VolumeBar key={index} volume={vol} />
      ))}
    </div>
  );
});

export default AudioVisualizer;

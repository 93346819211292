import React, { useState, FormEvent, useEffect } from "react";
import { PrimaryButton } from "./Buttons/Buttons";
import SentimentSatisfiedIcon from "@mui/icons-material/SentimentSatisfiedOutlined";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfiedOutlined";
import SentimentNeutralIcon from "@mui/icons-material/SentimentNeutralOutlined";
import { Box, Typography, Divider, TextField, useTheme } from "@mui/material";
import CustomButton, { RoundedButton } from "../styles/CustomButtons";
interface NoteFeedbackBoxProps {
  handleNoteRatingFeedback: (rating: number) => void;
  handleNoteCommentFeedback: (comment: string) => void;
}

export const NoteFeedbackBox: React.FC<NoteFeedbackBoxProps> = ({
  handleNoteRatingFeedback,
  handleNoteCommentFeedback,
}) => {
  const [comment, setComment] = useState<string>("");
  const [markedRated, setMarkedRated] = useState<boolean>(false);
  const [commentSubmitted, setCommentSubmitted] = useState<boolean>(false);

  const theme = useTheme();

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setComment(event.target.value);
  };

  const handleComment = (event: FormEvent) => {
    event.preventDefault();
    setCommentSubmitted(true);
    handleNoteCommentFeedback(comment);
  };

  const handleRating = (rating: number) => {
    setMarkedRated(true);
    handleNoteRatingFeedback(rating);
  };

  return (
    <Box sx={{ mb: 2 }}>
      <Divider sx={{ my: 3 }} />
      {!markedRated && (
        <Box
          sx={{
            backgroundColor: "backgroundColors.secondary",
            padding: 2,
            borderRadius: "0.75rem",
            border: 1,
            borderColor: "borderColors.primary",
          }}
        >
          <Typography
            variant="body1"
            color={theme.palette.primary.light}
            fontWeight="700"
            sx={{ mb: 2, textAlign: "center" }}
          >
            Are you satisfied with this note?
          </Typography>
          <Box className="flex justify-center space-x-4">
            <CustomButton
              variant="outlined"
              onClick={() => handleRating(0)}
              sx={{
                px: 2,
                py: 1.5,
                border: 2,
                borderRadius: "0.5rem",
                borderColor: "borderColors.brand",
              }}
            >
              <SentimentSatisfiedIcon />
              <Box component="span" sx={{ ml: 0.5, fontWeight: "700" }}>
                Yes
              </Box>
            </CustomButton>
            <CustomButton
              variant="outlined"
              onClick={() => handleRating(1)}
              sx={{
                px: 2,
                py: 1.5,
                border: 2,
                borderRadius: "0.5rem",
                borderColor: "borderColors.brand",
              }}
            >
              <SentimentDissatisfiedIcon />
              <Box component="span" sx={{ ml: 0.5, fontWeight: "700" }}>
                No
              </Box>
            </CustomButton>
            <CustomButton
              variant="outlined"
              onClick={() => handleRating(2)}
              sx={{
                px: 2,
                py: 1.5,
                border: 2,
                borderRadius: "0.5rem",
                borderColor: "borderColors.brand",
              }}
            >
              <SentimentNeutralIcon />
              <Box component="span" sx={{ ml: 0.5, fontWeight: "700" }}>
                Somewhat
              </Box>
            </CustomButton>
          </Box>
        </Box>
      )}
      {markedRated && !commentSubmitted && (
        <Box
          component="form"
          onSubmit={handleComment}
          className="flex flex-col"
        >
          <TextField
            value={comment}
            onChange={handleInputChange}
            sx={{ flexGrow: 1 }}
            InputProps={{
              sx: {
                px: 1,
                color: "textColors.primaryText",
              },
            }}
            placeholder="Care to tell us more?"
          />
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <RoundedButton
              type="submit"
              variant="contained"
              sx={{ width: "25%" }}
            >
              Submit
            </RoundedButton>
          </Box>
        </Box>
      )}
      {markedRated && commentSubmitted && (
        <Typography className="text-center">
          Thank you for your feedback. Your scribe is getting smarter.
        </Typography>
      )}
    </Box>
  );
};

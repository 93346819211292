import { getUserInfoFromLocalStorage } from "../../utils/LocalStorageUtils";
import { useNavigate } from "react-router-dom";
import { Avatar } from "@mui/material";
import { CircleButton } from "../../styles/CustomButtons";
import { useUIState } from "../../context/uiState";
import { UIStateType } from "../../context/uiState/context";

const UserMenuButton = () => {
  const userInfo = getUserInfoFromLocalStorage();
  const navigate = useNavigate();
  const { state, setState } = useUIState();
  const currentPage = state.navigation.currentPage;

  return (
    <CircleButton
      onClick={() => {
        setState((prevState: UIStateType) => ({
          ...prevState,
          navigation: { currentPage: "user" }
        }));
        navigate("/user");
      }}
    >
      <Avatar variant="circular" src={userInfo?.img} alt="user photo"></Avatar>
    </CircleButton>
  );
};

export default UserMenuButton;

import { useEffect, useState } from "react";
import { BorderBox } from "./layout/BorderBox";
import { UserNote } from "./UserNote";
import { DemoNote } from "./DemoNote";
import { DemoNoteDataType } from "../types/types";

export const DemoNotesList = ({
  notes,
}: {
  notes: DemoNoteDataType[] | null;
}) => {
  return (
    <div className="max-w-screen-lg mx-auto">
      {notes && notes.length > 0 && (
        <div className="text-4xl font-bold py-2">Demo Notes:</div>
      )}
      {notes &&
        notes.map((note: DemoNoteDataType) => {
          return (
            <DemoNote
              key={note.note_id}
              title={note.note_title}
              summary={note.content.sections[0].text}
              link={`/demo/${note.note_id}`}
            />
          );
        })}
    </div>
  );
};

import { useEffect, useState } from "react";
import { useSelectedUser } from "../../context/selectedUserContext.tsx/index.js";
import { AdminUser, UserTemplateItem } from "../../types/types.js";
import { TemplatesOnUserLoader } from "../../loaders/TemplatesLoader.js";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../context/user/context.js";
import APIService from "../../services/APIService.js";

interface UserTemplatesProps {}

export const UserTemplates = ({}: UserTemplatesProps) => {
  const { getAccessToken } = useUser();
  const { selectedUser } = useSelectedUser();
  const [isLoading, setIsLoading] = useState(false);
  const [templates, setTemplates] = useState<UserTemplateItem[] | null>();

  const navigate = useNavigate();

  const fetchTemplates = async () => {
    setIsLoading(true);
    const response = await TemplatesOnUserLoader({
      params: { user_id: selectedUser },
    });
    if (response) {
      console.log(response);
      setIsLoading(false);
      setTemplates(response);
    }
  };

  const handleRemoveTemplate = async (template_id: string) => {
    const accessToken = await getAccessToken();

    const response = await APIService.makeAPIPostRequest({
      requestString: "/template/removeTemplateFromUser",
      accessToken: accessToken,
      body: {
        target_user_id: selectedUser,
        template_id: template_id,
      },
    });

    if (response.ok) {
      console.log("successfully removed");
      fetchTemplates();
    }
  };

  useEffect(() => {
    if (selectedUser) {
      fetchTemplates();
    }
  }, [selectedUser]);

  if (isLoading)
    return <div className="m-4 p-4 bg-slate-100 rounded-md">Loading...</div>;

  return (
    <div className="m-4 p-4 bg-slate-100 rounded-md">
      <h2 className="text-2xl font-bold mb-2">User Templates</h2>
      {templates &&
        templates.map((template) => (
          <div
            key={template.template_id}
            className="mb-2 bg-white p-2 rounded-md shadow"
          >
            <div className="mb-2">
              <span className="font-semibold">{template.display_name}</span>
              <span className="ml-4 text-sm text-gray-500">
                {template.template_id}
              </span>
            </div>
            <div className="flex justify-between">
              <a
                href={
                  template.type === "public"
                    ? `/admin/publictemplate/${template.template_id}`
                    : `/admin/usertemplate/${template.template_id}`
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700">
                  Edit Template
                </button>
              </a>
              <button
                className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-700"
                onClick={() => {
                  handleRemoveTemplate(template.template_id);
                }}
              >
                Remove Template
              </button>
            </div>
          </div>
        ))}
      <div>To add a template to a user, go to that template's editor</div>
    </div>
  );
};

// export const UserTemplates = ({
//   user_id,
//   removeTemplateFromUser,
//   addTemplateToUser,
// }: UserTemplatesProps) => {
//   const { selectedUser } = useSelectedUser();

//   if (!selectedUser) {
//     return <></>;
//   }

//   const userToShow = usersList.find((obj) => obj._id === selectedUser);

//   if (!userToShow) {
//     return <></>;
//   }
// };

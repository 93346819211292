import React from "react";
import { CircularProgress, Box } from "@mui/material";

const FullScreenLoader: React.FC = () => {
  return (
    <Box
      sx={{
        position: "fixed", // Use fixed to cover the entire screen
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent grey
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 1500, // Ensure it covers other elements
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default FullScreenLoader;

import React, { useEffect, useState } from "react";
import {
  AdminPublicTemplate,
  AdminTemplate,
  AdminUser,
  AdminUserTemplate,
} from "../../types/types";
import { UsersLoader } from "../../loaders/UsersLoader";
import APIService from "../../services/APIService";
import { useUser } from "../../context/user";

interface TemplateToUserModalProps {
  isOpen: boolean;
  onClose: () => void;
  template: AdminPublicTemplate | AdminUserTemplate;
  type: "public" | "user";
}

export const TemplateToUserModal: React.FC<TemplateToUserModalProps> = ({
  isOpen,
  onClose,
  template,
  type,
}) => {
  const { getAccessToken } = useUser();
  const [userSearchString, setUserSearchString] = useState<string>("");
  const [displayName, setDisplayName] = useState<string>(
    template.template_name
  );
  const [users, setUsers] = useState<AdminUser[]>();
  const [filteredUsers, setFilteredUsers] = useState<AdminUser[]>([]);
  const [selectedUserId, setSelectedUserId] = useState<string>();

  const handleUserSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (users) {
      const searchValue = e.target.value.toLowerCase();
      setUserSearchString(searchValue);
      const filtered = users.filter((user: AdminUser) =>
        user.user_info?.name.toLowerCase().includes(searchValue)
      );
      setFilteredUsers(filtered);
      setSelectedUserId(filtered[0]._id);
    }
  };

  const fetchUsers = async () => {
    const response = await UsersLoader();
    if (response) {
      setUsers(response);
    }
  };

  const handleDisplayNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDisplayName(event.target.value);
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedUserId(event.target.value);
  };

  const handleSubmit = async () => {
    const accessToken = await getAccessToken();

    const response = await APIService.makeAPIPostRequest({
      requestString: "/template/assignTemplateToUser",
      accessToken: accessToken,
      body: {
        target_user_id: selectedUserId,
        template_id: template.template_id,
        display_name: displayName,
        template_type: type,
      },
    });

    if (response.ok) {
      console.log("successfully added");
      onClose();
    }
  };

  const handleOverlayClick = (event: any) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    if (users) {
      setFilteredUsers(users);
    }
  }, [users]);

  if (!isOpen) {
    return null;
  }

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto w-full">
      <div
        className="flex items-center justify-center min-h-screen"
        onClick={handleOverlayClick}
      >
        <div className="bg-white p-4 rounded-lg text-left overflow-hidden shadow-xl w-[80%]">
          <div className="mb-2 w-1/2">
            <label className="block text-sm text-gray-500" htmlFor="module_id">
              Select a User to assign template to
            </label>
            <div>
              {selectedUserId && <div>Selected: {selectedUserId} </div>}
            </div>
            <input
              onChange={handleUserSearchChange}
              value={userSearchString}
              className="mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
            <select
              name="user"
              id="user"
              value={selectedUserId}
              onChange={handleSelectChange}
              className="mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            >
              {filteredUsers.map((user: AdminUser) => (
                <option key={user._id} value={user._id}>
                  {user.user_info?.name} - {user._id}
                </option>
              ))}
            </select>
          </div>
          <h3 className="my-2">Name of this template for this user?</h3>
          <input
            type="text"
            value={displayName}
            onChange={handleDisplayNameChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
          <div className="mt-5 sm:mt-3 sm:flex sm:flex-row-reverse">
            <button
              type="button"
              className="ml-3 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={handleSubmit}
            >
              Confirm
            </button>
            <button
              type="button"
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
              onClick={onClose}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { BoxColumn } from "../../components/layout/BoxColumn";
import MultiColumnCenterBox from "../../layouts/MultiColumnCenterBox";
import ModulePicker from "../../components/Admin/pickers/ModulePicker";
import TemplatePicker from "../../components/Admin/pickers/TemplatePicker";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import UserPicker from "../../components/Admin/pickers/UserPicker";
import NotePicker from "../../components/Admin/pickers/NotePicker";
import APIService from "../../services/APIService";
import { textToParagraph } from "../../utils/utils";

export const GenerationSessionModelComparisonView: React.FC = () => {
  const [selectedUserId, setSelectedUserId] = useState<string>();
  const [selectedModuleId, setSelectedModuleId] = useState<string>();
  const [selectedTemplateId, setSelectedTemplateId] = useState<string>();
  const [selectedNoteId, setSelectedNoteId] = useState<string>();
  const [selectedSystemPrompt, setSelectedSystemPrompt] = useState<string>();
  const [selectedModels, setSelectedModels] = useState<string[]>([]);
  const [generationPrompt, setGenerationPrompt] = useState<string>();

  const [pronouns, setPronouns] = useState<string>();
  const [age, setAge] = useState<string>();
  const [subject, setSubject] = useState<string>();
  const [verbosity, setVerbosity] = useState<string>();
  const [style, setStyle] = useState<string>();
  const [additionalInfo, setAdditionalInfo] = useState<string>("");

  const [awaitingTaskId, setAwaitingTaskId] = useState<string>();
  const [taskResult, setTaskResult] = useState<
    { model: string; text: string; time: number }[]
  >([]);

  const modelNames = {
    "gpt-4o": "GPT-4 Omni",
    "gpt-4": "GPT-4",
    "gpt-3.5-turbo": "GPT-3.5-Turbo",
    "gpt-4-turbo-preview": "GPT-4-Turbo (OpenAI)",
    "azure-gpt-4-turbo": "GPT-4-Turbo (Azure)",
    "claude-3-opus-20240229": "Claude 3 Opus",
    "claude-3-sonnet-20240229": "Claude 3 Sonnet",
  };

  const pollingIntervalId = useRef<number | null>(null);

  const canSubmit = selectedModuleId && selectedTemplateId && selectedNoteId;

  const handlePronounsChange = (event: SelectChangeEvent) => {
    setPronouns(event.target.value);
  };

  const handleAgeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setAge(event.target.value);
  };

  const handleSubjectChange = (event: SelectChangeEvent) => {
    setSubject(event.target.value);
  };

  const handleVerbosityChange = (event: SelectChangeEvent) => {
    setVerbosity(event.target.value);
  };

  const handleStyleChange = (event: SelectChangeEvent) => {
    setStyle(event.target.value);
  };

  const handleModelsChange = (event: SelectChangeEvent<string[]>) => {
    const value = event.target.value;
    setSelectedModels(typeof value === "string" ? value.split(",") : value);
  };

  const handleAdditionalInfoChange = (
    event: ChangeEvent<HTMLTextAreaElement>
  ) => {
    setAdditionalInfo(event.target.value);
  };

  const handleSubmit = async () => {
    if (!canSubmit) {
      alert("All fields must be filled out before submitting.");
      return;
    }

    const accessToken = localStorage.getItem("accessToken");

    if (!accessToken) {
      return;
    }

    const url = "/testsuite/simulateSectionGenerationModelComparison";
    const body = {
      system_prompt: selectedSystemPrompt,
      generation_prompt: generationPrompt,
      note_id: selectedNoteId,
      template_id: selectedTemplateId,
      module_id: selectedModuleId,
      target_user_id: selectedUserId,
      demo_info: {
        age: age,
        pronouns: pronouns,
      },
      formatting_info: {
        subject: subject,
        verbosity: verbosity,
        style: style,
      },
      provided_context_string: additionalInfo,
      models: selectedModels.join(","),
    };

    try {
      const response = await APIService.makeAPIPostRequest({
        requestString: url,
        accessToken: accessToken,
        body: body,
      });

      if (response.ok) {
        setAwaitingTaskId(response.value.task_id);
        setTaskResult([]);
      }
    } catch (error) {
      console.error("Failed to submit:", error);
    }
  };

  const checkTaskStatus = async (taskId: string) => {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      return;
    }
    try {
      const response = await APIService.makeAPIGetRequest({
        requestString: `/testsuite/getSimulateSectionGenerationTaskStatus?task_id=${taskId}`,
        accessToken: accessToken,
      });
      if (response.ok && response.value.status !== 202) {
        console.log(response.value.task_result);
        if (pollingIntervalId.current) {
          clearInterval(pollingIntervalId.current);
        }
        setAwaitingTaskId(undefined); // Clear the taskId
        setTaskResult(response.value.task_result); // Store the result
      }
    } catch (error) {
      console.error("Failed to check task status:", error);
      if (pollingIntervalId.current) {
        clearInterval(pollingIntervalId.current);
      }
    }
  };

  useEffect(() => {
    if (awaitingTaskId) {
      pollingIntervalId.current = setInterval(() => {
        checkTaskStatus(awaitingTaskId);
      }, 3000) as unknown as number;
    }
    return () => {
      if (pollingIntervalId.current) {
        clearInterval(pollingIntervalId.current);
      }
    };
  }, [awaitingTaskId]);

  return (
    <MultiColumnCenterBox headingText="Your Templates">
      <BoxColumn flex={1}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            overflow: "scroll",
            maxWidth: "500px",
          }}
        >
          <FormControl fullWidth margin="normal">
            <InputLabel id="language-select-label">Models</InputLabel>
            <Select
              labelId="language-select-label"
              id="language-select"
              multiple
              value={selectedModels}
              onChange={handleModelsChange}
              renderValue={(selected) =>
                selected
                  .map((code) => modelNames[code as keyof typeof modelNames])
                  .join(", ")
              }
            >
              {Object.entries(modelNames).map(([code, name]) => (
                <MenuItem key={code} value={code}>
                  <Checkbox checked={selectedModels.indexOf(code) > -1} />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {/* <TextField
            label="Models"
            type="text"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            value={selectedModels}
            onChange={(event) => {
              setSelectedModels(event.target.value);
            }}
          /> */}
          <TextField
            label="System Prompt"
            variant="outlined"
            multiline
            rows={4}
            value={selectedSystemPrompt}
            onChange={(event) => {
              setSelectedSystemPrompt(event.target.value);
            }}
          />
          <UserPicker
            onUserSelect={(selectedUser) => {
              setSelectedUserId(selectedUser);
            }}
          />
          <NotePicker
            onNoteSelect={(selectedNote) => {
              setSelectedNoteId(selectedNote);
            }}
            userId={selectedUserId}
          />

          <TemplatePicker
            onTemplateSelect={(selectedTemplate) => {
              setSelectedTemplateId(selectedTemplate);
            }}
            userId={selectedUserId}
          />
          <ModulePicker
            onModuleSelect={(selectedModule) => {
              setSelectedModuleId(selectedModule);
            }}
          />
          <TextField
            label="Generation Prompt"
            variant="outlined"
            multiline
            rows={4}
            value={generationPrompt}
            onChange={(event) => {
              setGenerationPrompt(event.target.value);
            }}
          />

          <Box sx={{ display: "flex", gap: 2, alignItems: "center", mt: 4 }}>
            <FormControl fullWidth>
              <InputLabel id="pronouns-select-label">Pronouns</InputLabel>
              <Select
                labelId="pronouns-select-label"
                id="pronouns-select"
                value={pronouns}
                label="Pronouns"
                onChange={handlePronounsChange}
              >
                <MenuItem value="he/him">He/Him</MenuItem>
                <MenuItem value="she/her">She/Her</MenuItem>
                <MenuItem value="they/them">They/Them</MenuItem>
              </Select>
            </FormControl>
            <TextField
              label="Age"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              value={age}
              onChange={handleAgeChange}
            />
          </Box>

          <Box sx={{ display: "flex", flexDirection: "column", gap: 2, mt: 4 }}>
            <FormControl fullWidth>
              <InputLabel id="subject-select-label">Subject</InputLabel>
              <Select
                labelId="subject-select-label"
                id="subject-select"
                value={subject}
                label="Subject"
                onChange={handleSubjectChange}
              >
                <MenuItem value="Patient">Patient</MenuItem>
                <MenuItem value="Client">Client</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="verbosity-select-label">Verbosity</InputLabel>
              <Select
                labelId="verbosity-select-label"
                id="verbosity-select"
                value={verbosity}
                label="Verbosity"
                onChange={handleVerbosityChange}
              >
                <MenuItem value="terse">Terse</MenuItem>
                <MenuItem value="verbose">Verbose</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="style-select-label">Style</InputLabel>
              <Select
                labelId="style-select-label"
                id="style-select"
                value={style}
                label="Style"
                onChange={handleStyleChange}
              >
                <MenuItem value="paragraphs">Paragraphs</MenuItem>
                <MenuItem value="bullet points">Bullet Points</MenuItem>
              </Select>
            </FormControl>

            <TextField
              label="Additional Info"
              variant="outlined"
              multiline
              rows={4}
              value={additionalInfo}
              onChange={handleAdditionalInfoChange}
              inputProps={{
                maxLength: 500, // Set maximum length to 500 characters
              }}
              helperText={`${additionalInfo?.length}/500`}
            />
          </Box>

          <Button variant="contained" onClick={handleSubmit}>
            Submit
          </Button>
        </Box>
      </BoxColumn>
      <BoxColumn flex={2}>
        <Box
          sx={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            overflow: "scroll",
          }}
        >
          {awaitingTaskId ? (
            <CircularProgress /> // Show spinner while awaitingTaskId is set
          ) : taskResult ? (
            <Box>
              {taskResult &&
                taskResult.length > 0 &&
                taskResult.map((result) => {
                  const paragraphs = result.text.split("\n").map((line, i) => {
                    if (line) {
                      return textToParagraph({ text: line, className: "py-2" });
                    }
                  });
                  return (
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      {result.model} - {result.time}
                      <Box
                        sx={{
                          m: 4,
                          p: 4,
                          border: 1,
                          borderRadius: "0.75rem",
                          color: "textColors.darkText",
                          borderColor: "borderColors.primary",
                          bgcolor: "backgroundColors.secondary",
                        }}
                      >
                        <Typography variant="body1">
                          {paragraphs?.map((graph: any) => graph)}
                        </Typography>
                      </Box>
                    </Box>
                  );
                })}
            </Box>
          ) : (
            <Typography variant="body1">
              Submit to start the simulation.
            </Typography> // Placeholder text when idle
          )}
        </Box>
      </BoxColumn>
    </MultiColumnCenterBox>
  );
};

export class LowDataError extends Error {
  constructor(message?: string) {
    super(message); // Pass the message to the Error constructor
    this.name = "LowDataError";

    // This line is needed to restore the correct prototype chain.
    // It's a workaround for a limitation in TypeScript when extending built-in JavaScript types.
    Object.setPrototypeOf(this, LowDataError.prototype);
  }
}

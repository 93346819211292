import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  SelectChangeEvent,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { useUser } from "../../context/user";
import APIService from "../../services/APIService";
import { PublicTemplatesLoader } from "../../loaders/TemplatesLoader";
import {
  AdminBaseTemplate,
  AdminPublicTemplate,
  AdminSpecialty,
} from "../../types/types";

export const SpecialtyEditor: React.FC = () => {
  const { specialtyId } = useParams<{ specialtyId: string }>();
  const { getAccessToken } = useUser();
  const [specialty, setSpecialty] = useState<AdminSpecialty | null>(null);
  const [baseTemplates, setBaseTemplates] = useState<AdminBaseTemplate[]>([]);
  const [publicTemplates, setPublicTemplates] = useState<AdminPublicTemplate[]>(
    []
  );
  const [isLoading, setIsLoading] = useState(true);
  const [isAddTemplateDialogOpen, setIsAddTemplateDialogOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await Promise.all([
        fetchSpecialty(),
        fetchBaseTemplates(),
        fetchPublicTemplates(),
      ]);
      setIsLoading(false);
    };
    fetchData();
  }, [specialtyId]);

  const fetchSpecialty = async () => {
    const accessToken = await getAccessToken();
    const response = await APIService.makeAPIGetRequest({
      requestString: `/specialties/getSpecialty/${specialtyId}`,
      accessToken,
    });
    if (response.ok) {
      const data = await response.value;
      console.log(data);
      setSpecialty(data.specialty);
    } else {
      console.error("Failed to fetch specialty");
    }
  };

  const fetchBaseTemplates = async () => {
    const accessToken = await getAccessToken();
    const response = await APIService.makeAPIGetRequest({
      requestString: "/template/getAllBaseTemplates",
      accessToken,
    });
    if (response.ok) {
      const data = await response.value;
      setBaseTemplates(data.base_templates);
    } else {
      console.error("Failed to fetch base templates");
    }
  };

  const fetchPublicTemplates = async () => {
    const templates = await PublicTemplatesLoader();
    setPublicTemplates(templates);
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSpecialty((prev) =>
      prev ? { ...prev, specialty_name: event.target.value } : null
    );
  };

  const handleBaseTemplateChange = (event: SelectChangeEvent) => {
    setSpecialty((prev) =>
      prev
        ? { ...prev, default_base_template: event.target.value as string }
        : null
    );
  };

  const handleRemoveTemplate = async (templateId: string) => {
    const accessToken = await getAccessToken();
    const response = await APIService.makeAPIPostRequest({
      requestString: "/specialties/removeTemplateFromSpecialty",
      accessToken,
      body: { specialty_id: specialtyId, template_id_to_remove: templateId },
    });
    if (response.ok) {
      fetchSpecialty(); // Refresh the specialty data
    } else {
      console.error("Failed to remove template from specialty");
    }
  };

  const handleAddTemplate = async (templateId: string) => {
    const accessToken = await getAccessToken();
    const templateToAdd = publicTemplates.find(
      (t) => t.template_id === templateId
    );
    if (!templateToAdd) return;

    const response = await APIService.makeAPIPostRequest({
      requestString: "/specialties/addPublicTemplateToSpeciality",
      accessToken,
      body: {
        specialty_id: specialtyId,
        template_id: templateId,
        template_name: templateToAdd.template_name,
      },
    });
    if (response.ok) {
      fetchSpecialty(); // Refresh the specialty data
      setIsAddTemplateDialogOpen(false);
    } else {
      console.error("Failed to add template to specialty");
    }
  };

  const handleSave = async () => {
    if (!specialty) return;
    const accessToken = await getAccessToken();
    const response = await APIService.makeAPIPostRequest({
      requestString: `/specialties/updateSpecialty/${specialtyId}`,
      accessToken,
      body: {
        specialty_name: specialty.specialty_name,
        default_base_template: specialty.default_base_template,
      },
    });
    if (response.ok) {
      console.log("Specialty updated successfully");
    } else {
      console.error("Failed to update specialty");
    }
  };

  if (isLoading || !specialty) return <div>Loading...</div>;

  return (
    <div>
      <TextField
        label="Specialty Name"
        value={specialty.specialty_name}
        onChange={handleNameChange}
        fullWidth
        margin="normal"
      />
      <FormControl fullWidth margin="normal">
        <InputLabel>Base Template</InputLabel>
        <Select
          value={specialty.default_base_template}
          onChange={handleBaseTemplateChange}
        >
          {baseTemplates.map((template) => (
            <MenuItem
              key={template.base_template_id}
              value={template.base_template_id}
            >
              {template.base_template_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <h3>Public Templates</h3>
      <List>
        {specialty.public_templates?.map((template) => (
          <ListItem key={template.template_id}>
            <ListItemText primary={template.template_name} />
            <ListItemSecondaryAction>
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => handleRemoveTemplate(template.template_id)}
              >
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
      <Button
        startIcon={<AddIcon />}
        onClick={() => setIsAddTemplateDialogOpen(true)}
      >
        Add Public Template
      </Button>
      <Button
        onClick={handleSave}
        variant="contained"
        color="primary"
        style={{ marginTop: "20px" }}
      >
        Save Changes
      </Button>

      <Dialog
        open={isAddTemplateDialogOpen}
        onClose={() => setIsAddTemplateDialogOpen(false)}
      >
        <DialogTitle>Add Public Template</DialogTitle>
        <DialogContent>
          <List>
            {publicTemplates
              .filter(
                (t) =>
                  !specialty.public_templates?.some(
                    (pt) => pt.template_id === t.template_id
                  )
              )
              .map((template) => (
                <ListItem
                  key={template.template_id}
                  button
                  onClick={() => handleAddTemplate(template.template_id)}
                >
                  <ListItemText primary={template.template_name} />
                </ListItem>
              ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsAddTemplateDialogOpen(false)}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

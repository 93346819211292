import { useAuth0 } from "@auth0/auth0-react";
import { ChangeEvent, useEffect, useState } from "react";
import { UserTemplateListLoader } from "./ConfigView";
import { useUser } from "../../context/user";
import APIService from "../../services/APIService";

export const TranscribeView = () => {
  const { getAccessToken } = useUser();
  const [objectID, setObjectID] = useState<string>("patient_test.mp3");
  const [configType, setConfigType] = useState<string>("base");
  const [configList, setConfigList] = useState<
    { user_template_id: string; title: string }[]
  >([]);
  const [noteName, setNoteName] = useState<string>("");

  const listConfigs = async () => {
    const configList = await UserTemplateListLoader();
    if (configList) {
      setConfigList(configList);
    }
  };

  const getUserConfigOverride = async () => {
    const accessToken = await getAccessToken();

    const response = await APIService.makeAPIGetRequest({
      requestString: "/testUserConfig",
      accessToken: accessToken,
    });

    if (response.ok) {
      const data = response.value;
      console.log(data);
    }
  };

  const handleSubmit = async () => {
    const accessToken = await getAccessToken();

    const response = await APIService.makeAPIGetRequest({
      requestString: `/transcribe?object_id=${objectID}&config_type=${configType}&note_name=${noteName}`,
      accessToken: accessToken,
    });

    if (response.ok) {
      const data = response.value;
      const win: Window = window;
      win.location = `/note/${data.process_id}`;
    }
  };

  useEffect(() => {
    listConfigs();
    getUserConfigOverride();
  }, []);

  return (
    <div>
      <div>
        <label
          htmlFor="note_name"
          className="block mb-2 text-sm font-medium text-gray-900"
        >
          Note Name:
        </label>
        <input
          type="text"
          id="note_name"
          className="bg-gray-50 max-w-sm border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Patient X"
          defaultValue="Patient X"
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setNoteName(e.target.value);
          }}
        />
        <label
          htmlFor="object_id"
          className="block mb-2 text-sm font-medium text-gray-900"
        >
          S3 Object ID:
        </label>
        <div className="flex">
          <input
            type="text"
            id="object_id"
            className="bg-gray-50 max-w-sm border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="patient_test.mp3"
            defaultValue="patient_test.mp3"
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setObjectID(e.target.value);
            }}
          />
          <button
            type="button"
            className="ml-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
        <label
          htmlFor="config_type"
          className="block mb-2 text-sm font-medium text-gray-900"
        >
          Select a config type
        </label>
        <select
          id="config_type"
          className="max-w-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          onChange={(e: ChangeEvent<HTMLSelectElement>) => {
            setConfigType(e.target.value);
          }}
          defaultValue="base"
        >
          {configList &&
            configList.map((obj, index) => (
              <option key={index} value={obj.user_template_id}>
                {obj.title}
              </option>
            ))}
        </select>
      </div>
    </div>
  );
};

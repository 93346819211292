import React from 'react';
import { Dialog, DialogTitle, DialogContent, IconButton,Box, Typography, Button, Tooltip, } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {SectionBlock} from './SectionBlock'; // Assuming SectionBlock is in the same directory
import {
    AdminSection,
    AdminTemplate,
    AvailableModule,
    ListTemplate,
  } from "../../types/types";
  
import { ThumbUp, ThumbDown } from '@mui/icons-material';
import { NoteSectionDisplayBox } from '../Note/NoteSectionDisplayBox';
  
interface NotePreviewModalProps {
    open: boolean;
    onClose: () => void;
    templateSections: AdminSection[];
    availableModules?: AvailableModule[];
}
const NotePreviewModal = ({ open, onClose, templateSections, availableModules }: NotePreviewModalProps) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', typography: 'h6' }}>
        Note Preview
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {templateSections.map((section, index) => (
          <PreviewBlock
            key={index}
            section={section}
            availableModules={availableModules} 
          />
        ))}
      </DialogContent>
    </Dialog>
  );
};

export default NotePreviewModal;

interface PreviewBlockProps {
    section: AdminSection;
    handleClick?: () => void;
    handleRevert?: () => void;
    availableModules?: AvailableModule[];
    templateModuleMap?: { [key: string]: string };
  }
  
  export const PreviewBlock = ({
    section,
    handleClick,
    handleRevert,
    availableModules,
    templateModuleMap,
  }: PreviewBlockProps) => {
    const moduleInfo = availableModules?.find(
      (module) => module.module_id === section.module_id
    );
  
    return (
      <Box
        sx={{
          padding: '1vh',
          marginBottom: '1vh',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              fontSize: '2.5vh',
              fontWeight: 'bold',
              marginBottom: '1vh',
            }}
          >
            {section.display_name}
          </Typography>
        </Box>
            <NoteSectionDisplayBox>{moduleInfo?.public_example || 'The patient is alert and oriented, cooperative, and able to provide a clear medical history. They appear to be in no acute distress and are able to communicate effectively. Their mental status is normal, with no evidence of confusion or disorientation.'}</NoteSectionDisplayBox>

      </Box>
    );
  };


import {
  Box,
  Breadcrumbs,
  IconButton,
  SxProps,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import { CustomTransitionModal } from "./CustomModal";
import { ChevronLeft, ChevronRight, Close } from "@mui/icons-material";
import { Colors } from "../../styles/styles";
import { useState } from "react";
import { RoundedButton } from "../../styles/CustomButtons";
import { IOSQRCode } from "../SVGIcon";

interface IOSQRCodeModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const modalStyle = (theme: Theme): SxProps<Theme> => ({
  position: "absolute",
  display: "flex",
  flexDirection: "column",
  top: "50%",
  left: "50%",
  width: "50vw",
  height: "65vh",
  transform: "translate(-50%, -50%)",
  borderRadius: 2,
  overflow: "hidden",
  bgcolor: theme.palette.grey[200],
  boxShadow: theme.shadows[5],
  "& .MuiTypography-root": {
    color: theme.palette.text.primary,
  },
});

const closeButtonStyle: SxProps<Theme> = {
  position: "absolute",
  right: 8,
  top: 8,
};

export const IOSQRCodeModal = ({ isOpen, onClose }: IOSQRCodeModalProps) => {
  const theme = useTheme();

  return (
    <CustomTransitionModal isOpen={isOpen} onClose={onClose}>
      <Box sx={modalStyle(theme)}>
        {/* Close button in the corner of the modal */}
        <IconButton onClick={onClose} sx={closeButtonStyle}>
          <Close />
        </IconButton>
        <Box
          id={"container"}
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            id={"heading"}
            sx={{
              backgroundColor: Colors.primary200,
              p: theme.spacing(2, 4, 3),
              height: 60,
            }}
          >
            <Typography
              id="transition-modal-title"
              variant="h6"
              component="h2"
              sx={{ fontWeight: 700, textAlign: "center" }}
            >
              Scan on your phone to download
            </Typography>
          </Box>
          <Box
            id={"page-container"}
            sx={{
              height: "100%",
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                marginTop: 2,
                width: "80%", // or specific size
                height: "80%", // or specific size
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                overflow: "hidden", // Ensures anything outside this box is not shown
              }}
            >
              <IOSQRCode />
            </Box>
          </Box>
        </Box>
      </Box>
    </CustomTransitionModal>
  );
};

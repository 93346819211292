import { useAuth0 } from "@auth0/auth0-react";
import { ReactNode, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const ModeratorView = ({ children }: { children: ReactNode }) => {
  
  const { user, isAuthenticated } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {

      // Check if the user has the moderator role
      const isModerator =
        user && user["https://api.smartscribe.health/roles"].includes("Moderator");

      // Check if the user has the admin role
      const isAdmin =
        user && user["https://api.smartscribe.health/roles"].includes("Admin");

      if (!isModerator) {
        if (!isAdmin) {
          // Redirect the user to a page indicating they don't have permission
          navigate("/");
        }
      }
        
    }
  }, [user, isAuthenticated, navigate]);

  return isAuthenticated ? <>{children}</> : null;

};


import { openDB, DBSchema, IDBPDatabase } from "idb";

interface MyDB extends DBSchema {
  audioChunks: {
    key: string;
    value: Blob;
  };
}

class IndexedDBManager {
  private static instance: IndexedDBManager;
  private db: IDBPDatabase<MyDB> | null = null;

  private constructor() {}

  public static getInstance(): IndexedDBManager {
    if (!IndexedDBManager.instance) {
      IndexedDBManager.instance = new IndexedDBManager();
    }
    return IndexedDBManager.instance;
  }

  public async initDB(): Promise<void> {
    this.db = await openDB<MyDB>("SmartScribeAudioChunks", 1, {
      upgrade(db) {
        db.createObjectStore("audioChunks");
      },
    });
  }

  public async writeBlob(blob: Blob, id: string): Promise<void> {
    if (!this.db) throw new Error("DB not initialized");
    try {
      const tx = this.db.transaction("audioChunks", "readwrite");
      await tx.store.put(blob, id);
    } catch (error) {
      if (
        error instanceof DOMException &&
        error.name === "QuotaExceededError"
      ) {
        console.error("Storage quota exceeded.");
      } else {
        throw error;
      }
    }
  }

  public async readBlob(id: string): Promise<Blob | undefined> {
    if (!this.db) throw new Error("DB not initialized");
    return await this.db.get("audioChunks", id);
  }

  public async getAllKeys(): Promise<string[]> {
    if (!this.db) throw new Error("DB not initialized");
    return await this.db.getAllKeys("audioChunks");
  }

  public async clearAll(): Promise<void> {
    if (!this.db) throw new Error("DB not initialized");
    const tx = this.db.transaction("audioChunks", "readwrite");
    await tx.store.clear();
  }
}

export const indexedDBManager = IndexedDBManager.getInstance();

import { Box, Button, Typography, Divider, Grid, Link } from "@mui/material";
import { clarity } from "../services/ClarityService";
import { useUIState } from "../context/uiState";
import { SimpleRecorder } from "../components/SimpleRecorder";
import { useEffect, useRef, useState } from "react";
import { TestNoteTranscriptView } from "../components/TestNoteTranscriptView";
import { useRecordingContext } from "../context/RecordingContext";
import { useUser } from "../context/user";
import { useNavigate } from "react-router-dom";
import APIService from "../services/APIService";

interface TestNoteRecordingViewProps {
  onNext?: () => void;
  subjectLanguage?: string;
}

export const TestNoteRecordingView = ({
  onNext,
  subjectLanguage,
}: TestNoteRecordingViewProps) => {
  const { state } = useUIState();
  const { platform } = state;
  const navigate = useNavigate();
  const { getAccessToken } = useUser();

  const { setNoteInformation, cleanUpSession } = useRecordingContext();
  const {
    refreshTemplatesList,
    templatesList,
    userState,
    updateOnboardingStep,
  } = useUser();

  const [microphonePermissionGranted, setMicrophonePermissionGranted] =
    useState<boolean>(false);
  const [recordingStopped, setRecordingStopped] = useState<boolean>(false);
  const [noteSubmitted, setNoteSubmitted] = useState<boolean>(false);
  const [submittedNoteId, setSubmittedNoteId] = useState<string>();

  const containerRef = useRef<HTMLDivElement>(null);

  const showAdditionaInfo = () => {
    if (recordingStopped) {
      return true;
    } else {
      return false;
    }
  };

  const handleSkip = () => {
    cleanUpSession();
    // Clear the noteTitle and additionalInfo fields
    setNoteInformation((prev) => ({
      ...prev,
      noteTitle: "",
      additionalInfo: "",
    }));
    if (onNext) onNext();
  };

  const handleSuccessfulNoteSubmission = async (noteId: string) => {
    await handleCheckFirstExampleNote();
    await APIService.makeAPIPostRequest({
      requestString: "/user/logExampleNoteCreated",
      accessToken: await getAccessToken(),
      body: {
        note_id: noteId,
      },
    });
    setNoteSubmitted(true);
    setSubmittedNoteId(noteId);
    cleanUpSession();
  };

  const handleStopRecording = () => {
    setRecordingStopped(true);
    setNoteInformation((prev) => ({
      ...prev,
      templateId: templatesList ? templatesList[0].template_id : "full",
    }));
  };

  const handleMicrophonePermission = () => {
    setMicrophonePermissionGranted(true);
  };

  const handleDeleteRecording = () => {
    setRecordingStopped(false);
    setNoteSubmitted(false);
  };

  const handleComplete = () => {
    if (submittedNoteId) {
      if (onNext) onNext();
      navigate(`/status/${submittedNoteId}`);
    } else {
      if (onNext) onNext();
    }
  };

  const handleCheckFirstExampleNote = async () => {
    if (userState?.onboardingSteps?.generated_example_note !== true) {
      updateOnboardingStep("generated_example_note", true);
    }
  };

  const getInstructionText = (): string[] => {
    if (!microphonePermissionGranted) {
      return ["First, please grant microphone permission."];
    } else if (!recordingStopped) {
      return [
        "Record your first JotPsych note by reading the script below and adding creative details along the way.",
        "When you are done, press the stop recording button.",
      ];
    } else if (!noteSubmitted) {
      return [
        "Additional info to add? Just modify the box below and then click the submit recording button.",
      ];
    } else {
      const finalParagraphs = [
        "Congratulations, you're now just seconds away from viewing your first JotPsych note.",
        "During your 10-day free trial, you can record unlimited notes and use all of our premium customization features. Use the button below to view your first note!",
      ];

      if (platform !== "desktop") {
        finalParagraphs.push(
          "To begin building custom templates, create custom note sections, and further train your AI scribe, please visit our website on your desktop computer."
        );
      }
      return finalParagraphs;
    }
  };

  const patientScript = [
    {
      speaker: "Provider",
      text: "Good morning, John. How are you feeling today?",
    },
    {
      speaker: "Patient",
      text: "Morning, Doctor. I'm feeling a bit overwhelmed, honestly. I'm having trouble focusing.",
    },
    {
      speaker: "Provider",
      text: "I understand. Let's talk about what's been going on. Just to start, can you tell me about the medications you've tried in the past and how they've worked for you?",
    },
    {
      speaker: "Patient",
      text: "Sure. I've tried a few different medications over the years. I was on Adderall for a while, but it made me really jittery and anxious. Then, I tried Ritalin, but it didn't seem to help much with my focus. Recently, I was prescribed Vyvanse, but I haven't noticed any significant improvements.",
    },
    {
      speaker: "Provider",
      text: "It sounds like you've had a tough time finding the right medication. I appreciate you sharing that with me. I have a few more questions to get a better understanding of your situation. Can you tell me a bit about your background and job?",
    },
    {
      speaker: "Patient",
      text: "Sure. I'm a software developer. I work mostly from home with my wife, which is nice, but it also makes it hard to stay focused. We live together with her brother right now, and her parents are always visiting, which creates a bit of a hectic home environment. Anyways, I've always struggled with keeping track of tasks and managing my time.",
    },
    {
      speaker: "Provider",
      text: "Thank you for that. And how about your sleep? How many hours do you usually get, and do you feel rested when you wake up?",
    },
    {
      speaker: "Patient",
      text: "I usually get around 5 to 6 hours of sleep. I have trouble falling asleep, and even when I do sleep, I often wake up feeling tired. My wife also tells me that I snore!",
    },
    {
      speaker: "Provider",
      text: "Sleep is crucial, and it sounds like that's an area we need to address as well. Based on what you've told me, I think we should try a different approach. I'd like to suggest a non-stimulant medication called Strattera. It works differently from the stimulant medications you've tried and might be a better fit for you.",
    },
    {
      speaker: "Patient",
      text: "I've heard of Strattera, but I don't know much about it. How does it work?",
    },
    {
      speaker: "Provider",
      text: "Strattera, or atomoxetine, works by increasing the levels of norepinephrine in the brain, which can help with attention and focus. It's not a stimulant, so it doesn't have the same risk of causing jitteriness or anxiety. We'll start with a low dose of 40mg per day and see how you respond.",
    },
    {
      speaker: "Patient",
      text: "That sounds promising. I'm willing to give it a try.",
    },
    {
      speaker: "Provider",
      text: "Great. We'll monitor your progress closely and make any necessary adjustments. Also, I'd recommend working on a consistent sleep schedule and perhaps some behavioral strategies, like mindfulness practice, to help with your focus. Let's set up a follow-up appointment in a few weeks to see how you're doing.",
    },
    {
      speaker: "Patient",
      text: "Oh, I just remembered, I was also on Concerta for a while, but it didn't seem to do much for me either.",
    },
    {
      speaker: "Provider",
      text: "Thank you for mentioning that. It's important to have all the details. We'll still go ahead with Strattera and see how it works for you. Do you want to meet two weeks from now? How's Thursday?",
    },
    { speaker: "Patient", text: "Thursday works for me, thank you!" },
    {
      speaker: "Provider",
      text: "You're welcome. We'll find a solution that works for you. Take care, and I'll see you at our next appointment.",
    },
    { speaker: "Patient", text: "Thanks, I'll see you then." },
  ];

  const clientScript = [
    {
      speaker: "Provider",
      text: "Good afternoon, John. How are you doing today?",
    },
    {
      speaker: "Client",
      text: "Hi there. I've been really anxious lately, and it's starting to affect my daily life.",
    },
    {
      speaker: "Provider",
      text: "I'm sorry to hear that. Let's talk more about your anxiety. Can you describe some of the specific situations where you feel most anxious?",
    },
    {
      speaker: "Client",
      text: "It mostly happens at work. I have a lot of deadlines, and I constantly feel like I'm not going to meet them. Sometimes, even small tasks seem overwhelming. I also get really anxious in social situations, like meetings or gatherings, even with people I know well.",
    },
    {
      speaker: "Provider",
      text: "It sounds like you're dealing with both performance anxiety and social anxiety. Have you noticed any physical symptoms that accompany your anxiety?",
    },
    {
      speaker: "Client",
      text: "Yes, I often feel my heart racing, I start sweating, and I sometimes get headaches. It's really hard to focus on anything when I'm feeling that way.",
    },
    {
      speaker: "Provider",
      text: "Those physical symptoms are common with anxiety. It's important to address both the physical and mental aspects. Have you tried any methods to manage your anxiety so far?",
    },
    {
      speaker: "Client",
      text: "I've tried deep breathing exercises, but they don't always help. I also tried meditation, but I find it hard to stick with it.",
    },
    {
      speaker: "Provider",
      text: "It's good that you've tried those techniques. They can be very effective, but they do require practice. Along with those, we can also consider pharmacological intervention. One medication that might help is Sertraline, which is commonly used to treat anxiety disorders. However, I'd like to focus on some home exercises that might help you manage your anxiety better.",
    },
    {
      speaker: "Client",
      text: "Okay, what kind of exercises?",
    },
    {
      speaker: "Provider",
      text: "Let's start with cognitive-behavioral techniques. One effective method is journaling. Writing down your thoughts and feelings can help you identify patterns and triggers for your anxiety. You can start by noting when you feel anxious and what was happening at the time.",
    },
    {
      speaker: "Client",
      text: "That sounds doable. I know that my sister did something like that when she was feeling depressed a few years ago. We used to live together and I saw that it was really hard for her. Is there anything else?",
    },
    {
      speaker: "Provider",
      text: "We could try another technique called progressive muscle relaxation. This involves tensing and then slowly relaxing different muscle groups in your body. It's a way to release physical tension and can be very calming. Additionally, regular physical exercise, like walking or yoga, can significantly reduce anxiety levels. Are you regularly active?",
    },
    {
      speaker: "Client",
      text: "I'm not very active right now, not since moving out on my own. But that sounds like a good idea, I'll give those a shot.",
    },
    {
      speaker: "Provider",
      text: "Great. Consistency is key with these practices. We can also explore mindfulness techniques. There are many apps that offer guided mindfulness sessions which can help you stay present and reduce anxiety, but let's stick to the first two for now and not get ahead of ourselves.",
    },
    {
      speaker: "Client",
      text: "I've heard about mindfulness apps, but I agree, I want to just start slowly.",
    },
    {
      speaker: "Provider",
      text: "Excellent. Let's plan to meet again in a few weeks to see how you're doing and adjust our approach as needed. In the meantime, keep track of your anxiety levels and try the techniques we've discussed.",
    },
    {
      speaker: "Client",
      text: "Thanks again, I appreciate your help.",
    },
    {
      speaker: "Provider",
      text: "You're welcome, John. We'll work through this together. Take care, and I'll see you at our next appointment. Next week same time?",
    },
    {
      speaker: "Client",
      text: "That sounds good. Thanks, I'll see you then.",
    },
  ];

  const setSimpleDefaults = () => {
    if (subjectLanguage === "client") {
      setNoteInformation((prev) => ({
        ...prev,
        noteTitle: "John Doe, Anxiety Intake",
        additionalInfo: "Intake Appt, Dx: Moderate Anxiety, GAD-7: 14",
      }));
    } else {
      setNoteInformation((prev) => ({
        ...prev,
        noteTitle: "John Doe, ADHD Intake",
        additionalInfo: "Intake Appt, Dx: ADHD, CAARS T-score: 75",
      }));
    }
  };

  useEffect(() => {
    setSimpleDefaults();
    refreshTemplatesList();
  }, []);

  // Effect for scrolling to top when status changes
  useEffect(() => {
    if (containerRef.current) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [microphonePermissionGranted, recordingStopped, noteSubmitted]);

  return (
    <Box ref={containerRef} sx={{ textAlign: "center", p: 4 }}>
      {!microphonePermissionGranted && (
        <Typography variant="h5" gutterBottom>
          Let's record our first mock encounter!
        </Typography>
      )}

      {getInstructionText().map((paragraph, index) => (
        <Typography key={index} variant="body1" sx={{ mt: 1 }}>
          {paragraph}
        </Typography>
      ))}

      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12} md={6}>
          {noteSubmitted ? (
            <></>
          ) : (
            <Box
              sx={{
                border: "1px dashed",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <SimpleRecorder
                onSuccessfulSubmit={handleSuccessfulNoteSubmission}
                onStopRecording={handleStopRecording}
                onReceiveAudioPermission={handleMicrophonePermission}
                onDeleteRecording={handleDeleteRecording}
                showNoteTitle={showAdditionaInfo()}
                showAdditionalInfo={showAdditionaInfo()}
                showAudioStream={microphonePermissionGranted}
              />
            </Box>
          )}
        </Grid>

        {microphonePermissionGranted && !recordingStopped && (
          <Grid item xs={12} md={6}>
            <Box sx={{ height: { md: "100%" }, overflowY: "auto" }}>
              <TestNoteTranscriptView
                transcript={
                  subjectLanguage === "client" ? clientScript : patientScript
                }
              />
            </Box>
          </Grid>
        )}
      </Grid>

      <Box sx={{ mt: 4 }}>
        {noteSubmitted && (
          <Box sx={{ mt: 4 }}>
            <Button variant="contained" onClick={handleComplete}>
              Let's get started!
            </Button>
          </Box>
        )}

        {!noteSubmitted && onNext && (
          <Box sx={{ mt: 2 }}>
            <Link onClick={handleSkip} underline="hover">
              I'll do this later
            </Link>
          </Box>
        )}
      </Box>
    </Box>
  );
};

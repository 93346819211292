import React, { useState, FormEvent, useEffect } from "react";
import { TextField } from "@mui/material";
import { RoundedButton } from "../styles/CustomButtons";

interface InlineCommentBoxProps {
  onSubmit: (comment: string) => void;
}

export const InlineCommentBox: React.FC<InlineCommentBoxProps> = ({
  onSubmit
}) => {
  const [comment, setComment] = useState<string>("");

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setComment(event.target.value);
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    onSubmit(comment);
  };

  return (
    <form onSubmit={handleSubmit} className="flex items-center">
      <TextField
        value={comment}
        onChange={handleInputChange}
        sx={{ flexGrow: 1 }}
        InputProps={{
          sx: {
            px: 1,
            color: "textColors.primaryText"
          }
        }}
        placeholder="Care to tell us more?"
      />
      <RoundedButton
        type="submit"
        variant="contained"
        sx={{
          px: 2,
          py: 1
        }}
      >
        Submit
      </RoundedButton>
    </form>
  );
};

import { useState } from "react";

export const AdminNav = ({}: {}) => {
  const [showTemplatesDropdown, setShowTemplatesDropdown] = useState(false);

  const handleTemplatesHover = () => {
    setShowTemplatesDropdown(true);
  };

  const handleTemplatesLeave = () => {
    setShowTemplatesDropdown(false);
  };

  const TemplatesDropdown = () => (
    <div className="absolute mt-1 bg-white border border-gray-200">
      <a
        href="/admin/templates"
        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
      >
        Legacy Templates
      </a>
      <a
        href="/admin/basetemplates"
        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
      >
        Base Templates
      </a>
      <a
        href="/admin/publictemplates"
        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
      >
        Public Templates
      </a>
      <a
        href="/admin/usertemplates"
        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
      >
        User Templates
      </a>
    </div>
  );

  return (
    <nav className="fixed top-14 z-50 w-full bg-white border-b border-gray-200 ">
      <div className="px-3 py-3 lg:px-5 lg:pl-3">
        <div className="flex items-center justify-between">
          <div className="flex items-center justify-start">
            <a href="/admin/users" className="flex ml-2 md:mr-24">
              <span className="self-center text-xl font-semibold sm:text-2xl whitespace-nowrap ">
                Users
              </span>
            </a>
          </div>
          <div className="flex items-center justify-start">
            <a href="/admin/notes" className="flex ml-2 md:mr-24">
              <span className="self-center text-xl font-semibold sm:text-2xl whitespace-nowrap ">
                Notes
              </span>
            </a>
          </div>
          <div className="flex items-center justify-start">
            <a href="/admin/feedbacks" className="flex ml-2 md:mr-24">
              <span className="self-center text-xl font-semibold sm:text-2xl whitespace-nowrap ">
                Feedback
              </span>
            </a>
          </div>
          <div
            className="flex items-center justify-start"
            onMouseEnter={handleTemplatesHover}
            onMouseLeave={handleTemplatesLeave}
          >
            <a href="/admin/templates" className="flex ml-2 md:mr-24">
              <span className="self-center text-xl font-semibold sm:text-2xl whitespace-nowrap ">
                Templates
              </span>
              {showTemplatesDropdown && <TemplatesDropdown />}
            </a>
          </div>
          <div className="flex items-center justify-start">
            <a href="/admin/modules" className="flex ml-2 md:mr-24">
              <span className="self-center text-xl font-semibold sm:text-2xl whitespace-nowrap ">
                Modules
              </span>
            </a>
          </div>
          <div className="flex items-center justify-start">
            <a href="/admin/specialties" className="flex ml-2 md:mr-24">
              <span className="self-center text-xl font-semibold sm:text-2xl whitespace-nowrap ">
                Specialties
              </span>
            </a>
          </div>
          <div className="flex items-center justify-start">
            <a href="/admin/groups" className="flex ml-2 md:mr-24">
              <span className="self-center text-xl font-semibold sm:text-2xl whitespace-nowrap ">
                Groups
              </span>
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
};

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Button,
  Grid,
  Card,
  CardContent,
  CircularProgress,
  CardActions
} from '@mui/material';
import { API_BASE_URL } from '../../services/APIService'; // Assuming you have an API base URL defined
import { JotPalWordmarkLogo, JotPsychWordmarkLogo } from "../../components/Icons/Logo";
import { useThemeContext } from "../../context/theme/context";
import CustomButton from "../../styles/CustomButtons";

interface Module {
  display_name: string;
  module_id: string;
}

interface DetailedInfo {
  created_by: string;
  created_on: number;
  modified_on: number;
  public_description: string;
  template_name: string;
  public_name: string;
  template_version: number;
  sections: Module[];
}

interface Template {
  template_id: string;
  display_name: string;
  type: string;
  detailed_info: DetailedInfo;
  created_by_user: string;
}

const ViewUserTemplates: React.FC = () => {
  const { userId } = useParams<{ userId: string }>();
  const [templates, setTemplates] = useState<Template[]>([]);
  const [userName, setUserName] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const { brandLanguage } = useThemeContext();

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        if (!userId) return;
        setLoading(true);
        const response = await fetch(`${API_BASE_URL}/template/getUserTemplates/${encodeURIComponent(userId)}`);
        
        if (response.ok) {
          const data = await response.json();
          setTemplates(data.templates);
          // Assuming the user's name is part of the response, for example: data.user_name
          setUserName(data.requester_name || 'User');
        } else {
          console.error("Failed to fetch templates, status code:", response.status);
        }
      } catch (error) {
        console.error('Error fetching templates:', error);
      } finally {
        setLoading(false);
      }
    };

    if (userId) {
      fetchTemplates();
    }
  }, [userId]);

  const handleTemplateClick = (templateId: string) => {
    navigate(`/share/templates/${templateId}`);
  };

  return (
    <>
        <Box sx={{ height: '100vh', width: '100vw', boxSizing: 'border-box' }}>
            <CustomButton
                onClick={() => {
                navigate("/");
                }}
                size="large"
                aria-label="logo-home-button"
                sx={{height: "5%", px: 2, pt: 2}}
            >
                {brandLanguage.brandName === "JotPsych" && (
                <JotPsychWordmarkLogo size={150} />
                )}
                {brandLanguage.brandName === "JotPal" && (
                <JotPalWordmarkLogo size={150} />
                )}
            </CustomButton>
            <Box sx={{ padding: 2, height: '95%' }}>
                
                {userName && 
                    <Typography variant="h3" gutterBottom sx={{ fontWeight: 'bold' }}>
                        Templates Used by {userName}
                    </Typography>
                }

                {loading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <CircularProgress />
                    </Box>
                ) : templates.length === 0 ? (
                    <Typography variant="body1">No templates found for this user.</Typography>
                ) : (
                    <Grid container spacing={4}>
                    {templates.map((template) => (
                        <Grid item xs={12} md={6} lg={4} key={template.template_id}>
                        <Card elevation={4} sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                            <CardContent>
                            <Typography variant="h5" sx={{ fontWeight: 'bold', pb: 1 }}>
                                {template.detailed_info.public_name ? template.detailed_info.public_name : template.detailed_info.template_name}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                {template.detailed_info.public_description}
                            </Typography>
                            <Typography variant="caption" color="textSecondary" sx={{ fontWeight: 'bold', py: 0 }}>
                                - {template.created_by_user}
                            </Typography>
                            </CardContent>
                            <CardActions sx={{ justifyContent: 'space-between' }}>
                            <Button size="small" color="primary" onClick={() => handleTemplateClick(template.template_id)}>
                                View Details
                            </Button>
                            </CardActions>
                        </Card>
                        </Grid>
                    ))}
                    
                    </Grid>
                )}
            </Box>
        </Box>
    </>
  );
};

export default ViewUserTemplates;

import { useEffect, useRef, useState } from "react";
import { json, Params, useLoaderData, useParams } from "react-router-dom";
import { NoteSection } from "../components/NoteSection";
import { Sidebar } from "../components/layout/Sidebar";
import { SVGIcon } from "../components/SVGIcon";
import dummydata from "../dummydata/data.json";
import { MainContainer } from "../components/layout/MainContainer";
import { UserNotesList } from "../components/UserNotesList";
import { DemoNotesList } from "../components/DemoNotesList";
import { DemoNoteDataType } from "../types/types";
import { API_BASE_URL } from "../services/APIService";

export type DemoNotesDataType = DemoNoteDataType[];

export const DemoNotesLoader = async (): Promise<DemoNoteDataType[] | null> => {
  const notes = await fetch(`${API_BASE_URL}/getDemoNotes`, {
    method: "get",
    headers: new Headers({
      "Content-Type": "application/json",
    }),
  })
    .then((res) => {
      if (res.status === 401) {
        throw json(
          {
            message: "You are not authorized to access this resource.",
            cta: "Login",
          },
          { status: 401 }
        );
      }

      return res.json();
    })
    .then((data: any) => {
      return data.demo_notes;
    })
    .catch(() => {
      throw json(
        {
          message: "There has been an error. Please login again.",
          cta: "Login",
        },
        { status: 500 }
      );
    });
  return notes;
};

function DemoNotesView() {
  const notes = useLoaderData() as Awaited<ReturnType<typeof DemoNotesLoader>>;

  return (
    <div>
      <DemoNotesList notes={notes} />
    </div>
  );
}

export default DemoNotesView;

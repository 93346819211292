import { access } from "fs";
import APIService from "../../services/APIService";

export const startTranscriptionSession = async (
  recordingSessionId: string,
  accessToken?: string | null,
  onNetworkDelay?: () => void,
  onNetworkAbsoluteTimeout?: () => void
): Promise<string | null> => {
  const transcriptionSessionResponse = await APIService.makeAPIPostRequest({
    requestString: "/transcriptionSession/startTranscriptionSession",
    accessToken,
    body: {
      recording_session_id: recordingSessionId,
    },
    onNetworkDelay: onNetworkDelay,
    onNetworkAbsoluteTimeout: onNetworkAbsoluteTimeout,
  });
  if (transcriptionSessionResponse.ok) {
    console.log(
      `Started TranscriptionSession: ${transcriptionSessionResponse.value.transcription_session_id}`
    );
    return transcriptionSessionResponse.value.transcription_session_id;
  } else {
    // SentryService.captureMessage(
    //   "Failed to start TranscriptionSession.",
    //   "error"
    // );
    console.error("Failed to start TranscriptionSession.");
    return null;
  }
};

export const endTranscriptionSession = async (
  transcriptionSessionId: string,
  recordingSessionLogs: string,
  accessToken?: string | null
): Promise<boolean> => {
  const transcriptionSessionResponse = await APIService.makeAPIPostRequest({
    requestString: "/transcriptionSession/endTranscriptionSession",
    accessToken,
    body: {
      transcription_session_id: transcriptionSessionId,
      recording_session_logs: recordingSessionLogs,
    },
  });
  if (transcriptionSessionResponse.ok) {
    console.log("Recording ended.");
    return true;
  } else {
    console.error("Failed to end transcription session.");
    return false;
  }
};

// src/components/EmailModal.tsx
import React, { ChangeEvent, MouseEvent, useState } from "react";
import { useSelectedUser } from "../context/selectedUserContext.tsx";
import deleteIcon from "../assets/delete-icon.svg";
import { SVGIcon } from "./SVGIcon";
import { AdminEmails } from "../types/types";
import { RoundedButton } from "../styles/CustomButtons";
import { Box } from "@mui/material";
import APIService from "../services/APIService.js";
import { useUser } from "../context/user/index.js";

interface EmailModalProps {
  emailList: AdminEmails[];
  emailModalOpen: boolean;
  setEmailModalOpen: (value: boolean) => void;
  fetchAdminEmails: () => void;
}

const EmailModal: React.FC<EmailModalProps> = ({
  emailList,
  emailModalOpen,
  setEmailModalOpen,
  fetchAdminEmails,
}) => {
  const { getAccessToken } = useUser();

  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState<string | null>(null);
  const [nameError, setNameError] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [name, setName] = useState<string>("");
  const [loadingText, setLoadingText] = useState<string>("");

  if (!emailModalOpen) {
    return null;
  }

  const removeAdminEmail = async (email: string) => {
    // remove selected AdminEmail from server
    setLoadingText("Deleting");
    const accessToken = await getAccessToken();
    const response = await APIService.makeAPIPostRequest({
      requestString: "/user/removeAdminEmail",
      accessToken: accessToken,
      body: {
        email: email,
      },
    });

    if (response.ok) {
      const data = response.value;
      console.log(data);
      fetchAdminEmails();
      setLoadingText("");
    }
  };

  const handleOverlayClick = (event: MouseEvent) => {
    //close modal by clicking anywhere but the modal
    if (event.target === event.currentTarget) {
      setEmailModalOpen(false);
    }
  };

  function isValidEmail(email: string): boolean {
    return /\S+@\S+\.\S+/.test(email);
  }

  function isValidName(name: string): boolean {
    return /^\S[A-Za-z0-9\s\.\-\(\)]*\S$/.test(name);
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { id, value } = event.target;
    setError(null);

    if (id === "email") {
      setEmail(value);
      setEmailError(isValidEmail(value) ? null : "Email is invalid");
    } else if (id === "name") {
      setName(value);
      setNameError(isValidName(value) ? null : "Name is invalid");
    }
  };

  const addAdminEmail = async () => {
    // add AdminEmail to server
    setTimeout(() => {
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur();
      }
    }, 50);

    if (!email || !name) {
      setError("Name and Email fields cannot be empty");
      return;
    }

    if (emailError || nameError) {
      return;
    }

    setLoadingText("Adding");
    const accessToken = await getAccessToken();
    try {
      const response = await APIService.makeAPIPostRequest({
        requestString: "/user/addAdminEmail",
        accessToken: accessToken,
        body: {
          email: email,
          name: name,
        },
      });

      if (response.ok) {
        const data = response.value;
        console.log(data);
        fetchAdminEmails();
        setEmail("");
        setName("");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingText("");
    }
  };

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-40"
      onClick={handleOverlayClick}
    >
      <div className="bg-white rounded-lg p-6 mx-10 inline-block text-center justify-center">
        <h1 className="text-3xl font-bold">Email Address Manager</h1>
        {loadingText && (
          <div className="fixed inset-0 z-100 flex items-center justify-center bg-black bg-opacity-40">
            <div className="bg-white rounded-lg p-6 w-6/12 md:w-1/2 flex items-center justify-center">
              <h1>{loadingText}</h1>
            </div>
          </div>
        )}
        {emailList && emailList.length > 0 && (
          <ul className="mb-10 mt-5 overflow-y-scroll inline-block">
            {emailList.map((email) => (
              <li
                className="text-xs grid grid-cols-12 items-center sm:text-sm md:text-base lg:text-lg"
                key={email.email_id}
              >
                <p className="text-start col-span-3">{email.name}</p>
                <p className="text-end col-span-4">{email.email_address}</p>
                {email.verified_status ? (
                  <p className="text-end col-span-3">Verified</p>
                ) : (
                  <p className="text-end col-span-3">Not Verified</p>
                )}
                <div
                  className="m-5 w-7 cursor-pointer border-2 hover:border-red-400 col-span-2"
                  onClick={() => removeAdminEmail(email.email_address)}
                >
                  <SVGIcon name="delete" />
                </div>
              </li>
            ))}
          </ul>
        )}
        <form className="flex justify-center mt-4 align-center">
          <label className="py-2 px-3">
            Name:
            <input
              className="appearance-none border rounded-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="name"
              name="name"
              type="text"
              value={name}
              onChange={handleChange}
            />
            {nameError && <h2 style={{ color: "red" }}>{nameError}</h2>}
          </label>
          <label className="py-2 px-3">
            Email:
            <input
              className="appearance-none border rounded-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="email"
              name="email"
              type="text"
              value={email}
              onChange={handleChange}
            />
            {emailError && <h2 style={{ color: "red" }}>{emailError}</h2>}
          </label>
          <Box sx={{ marginTop: "1rem" }}>
            <RoundedButton variant="contained" onClick={() => addAdminEmail()}>
              Add Email
            </RoundedButton>
          </Box>
        </form>
        {error && <h2 style={{ color: "red" }}>{error}</h2>}
      </div>
    </div>
  );
};

export default EmailModal;

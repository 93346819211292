import { Box } from "@mui/material";
import { BorderBox } from "../components/layout/BorderBox";
import { LoginButton } from "../components/Buttons/LoginButton";
import { useThemeContext } from "../context/theme/context";

const LoginView = () => {
  const { brandLanguage } = useThemeContext();
  return (
    <Box sx={{ margin: 4 }}>
      <BorderBox>
        <div className="text-center">
          <div className="text-4xl font-bold py-2">
            Welcome to {brandLanguage.brandName}!
          </div>
          <div className="text-xl py-4">
            To begin experiencing the future of automated charting, please login
            or create an account by clicking the button below.
            <br />
          </div>
          <LoginButton />
        </div>
      </BorderBox>
    </Box>
  );
};

export default LoginView;

import React, { useEffect, useState } from "react";
import { PrimaryButton } from "../components/Buttons/Buttons";

export interface NewPatientInfo {
  name: string;
  dob?: string;
}

type NewPatientFormProps = {
  onAddNewPatient: (info: NewPatientInfo) => void;
  formError?: string;
};

export const NewPatientForm = ({
  onAddNewPatient,
  formError,
}: NewPatientFormProps) => {
  const [name, setName] = useState("");
  const [dob, setDob] = useState("");

  const handleSubmit = () => {
    onAddNewPatient({ name, dob });
  };

  // Reset form fields when component mounts
  useEffect(() => {
    setName("");
    setDob("");
  }, []);

  return (
    <div className="relative p-4 m-4 border-2 border-gray-200 rounded-lg hover:border-purple-400 hover:cursor-pointer transition-colors">
      {formError && <div className="text-red-500 mb-2">{formError}</div>}
      <div className="flex justify-between">
        <div className="text-4xl font-bold">New Patient</div>
      </div>
      <div>
        <input
          type="text"
          className="p-1 border-2 border-gray-200 rounded"
          placeholder="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      <div>
        <input
          type="text"
          className="p-1 mt-2 border-2 border-gray-200 rounded"
          placeholder="YYYY-MM-DD (optional)"
          value={dob}
          onChange={(e) => setDob(e.target.value)}
        />
      </div>
      <div className="mt-4">
        <PrimaryButton handleClick={handleSubmit}>Submit</PrimaryButton>
      </div>
    </div>
  );
};

export default NewPatientForm;

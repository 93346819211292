import { AdminGroup } from "../../types/types";
import { GroupsLoader } from "../../loaders/GroupsLoader";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { GroupsList } from "../../components/Admin/GroupsList";
import { CreateGroupForm } from "../../components/CreateGroupForm";
import APIService from "../../services/APIService";
import { useUser } from "../../context/user";

import React from "react";

export const AdminGroupsView: React.FC = () => {
  const { getAccessToken } = useUser();
  const [isLoading, setIsLoading] = useState(true);
  const [groups, setGroups] = useState<AdminGroup[] | null>();
  const [groupName, setGroupName] = useState("");
  const [billingAdminUserID, setBillingAdminUserID] = useState("");

  const fetchGroupsData = async () => {
    const response = await GroupsLoader();
    if (response) {
      setIsLoading(false);
      setGroups(response);
    }
  };

  const addGroup = async () => {
    if (!groupName || !billingAdminUserID) {
      alert(
        "Please fill in both the group name and the billing admin user id."
      );
      return;
    }

    const newGroup = {
      group_info: {
        name: groupName,
      },
      billing_admin: billingAdminUserID,
    };

    try {
      const accessToken = await getAccessToken();
      const response = await APIService.makeAPIPostRequest({
        requestString: "/group/createGroup",
        accessToken: accessToken,
        body: newGroup,
      });

      if (!response.ok) {
        const message = `An error has occurred.`;
        throw new Error(message);
      }

      const result = response.value;
      await fetchGroupsData();
    } catch (error) {
      console.error("Failed to assign user to group:", error);
    }

    setGroupName("");
    setBillingAdminUserID("");
  };

  useEffect(() => {
    fetchGroupsData();
  }, []);

  if (isLoading) return <div>Loading...</div>;

  return (
    <div className="flex flex-col w-3/4 overflow-x-auto h-screen">
      <GroupsList groupsList={groups!} />
      <div className="p-4 shadow-lg rounded-lg bg-white mt-4">
        <h2 className="text-lg font-semibold mb-4">Add New Group</h2>
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="group-name"
            >
              Group Name
            </label>
            <input
              className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
              id="group-name"
              type="text"
              placeholder="Enter group name"
              value={groupName}
              onChange={(e) => setGroupName(e.target.value)}
            />
          </div>
          <div className="w-full md:w-1/2 px-3">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="billing-admin-user-id"
            >
              Billing Admin User ID
            </label>
            <input
              className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
              id="billing-admin-user-id"
              type="text"
              placeholder="Enter Billing Admin User ID"
              value={billingAdminUserID}
              onChange={(e) => setBillingAdminUserID(e.target.value)}
            />
          </div>
        </div>
        <div className="flex justify-center">
          <button
            onClick={addGroup}
            className="shadow bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Add Group
          </button>
        </div>
      </div>
    </div>
  );
};

import { ReactNode } from "react";
import { RoundedButton } from "../../styles/CustomButtons";

export const PrimaryButton = ({
  handleClick,
  children
}: {
  handleClick: () => void;
  children: ReactNode;
}) => {
  return (
    <RoundedButton onClick={handleClick} variant="contained">
      {children}
    </RoundedButton>
  );
};

import { Params, json } from "react-router-dom";
import { AdminFeedbackSession, AdminNote } from "../types/types";
import { API_BASE_URL } from "../services/APIService";

export const AdminFeedbackLoader = async (
  note_id: string
): Promise<AdminFeedbackSession> => {
  const accessToken = localStorage.getItem("accessToken");

  const feedbackSession = await fetch(
    `${API_BASE_URL}/feedback/getNoteFeedback?note_id=${note_id}`,
    {
      method: "get",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      }),
    }
  )
    .then((res) => {
      if (res.status === 401) {
        throw json(
          {
            message: "You are not authorized to access this resource.",
            cta: "Login",
          },
          { status: 401 }
        );
      }

      return res.json();
    })
    .then((data: { note_feedback_session: AdminFeedbackSession }) => {
      return data.note_feedback_session;
    })
    .catch(() => {
      throw json(
        {
          message: "There has been an error. Please login again.",
          cta: "Login",
        },
        { status: 500 }
      );
    });

  return feedbackSession;
};

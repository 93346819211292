import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Box,
  Button,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  Tooltip,
  CircularProgress,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { useRecordingContext } from "../context/RecordingContext";
import { useUser } from "../context/user";
import { useUIState } from "../context/uiState";
import AudioVisualizer from "../components/AudioVisualizer";
import { UserTemplateItem } from "../types/types";
import { useNavigate } from "react-router-dom";
import { DeleteModal } from "../components/DeleteModal";
import SentryService from "../services/SentryService";

const SPINNER_TIMEOUT = 45000; // 45 seconds

export const RecorderView: React.FC = () => {
  const {
    recordingStatus,
    noteInformation,
    setNoteInformation,
    setUseSharedMedia,
    useSharedMedia,
    microphonePermissionGranted,
    checkMicrophonePermission,
    selectedMicrophone,
    setNoAudioStatus,
    noAudioStatus,
    initiateRecording,
    initiateEndRecording,
    sendRecording,
    pauseRecording,
    resumeRecording,
    setSelectedMicrophone,
    getAvailableMicrophones,
    availableMicrophones,
    deleteRecordingHelper,
    elapsedTime,
    cleanUpSession,
    releaseMicrophone,
    initiateReupload,
    lowDataMode,
  } = useRecordingContext();

  const { userState, templatesList, updateOnboardingStep } = useUser();
  const { state, showAlertBanner } = useUIState();
  const { platform, browser } = state;
  const navigate = useNavigate();

  const [noteTitleError, setNoteTitleError] = useState(false);
  const [ageError, setAgeError] = useState("");
  const [spinnerError, setSpinnerError] = useState(false);
  const [timerDisplay, setTimerDisplay] = useState("0:00");
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const isRecording = useRef<boolean>(false);

  useEffect(() => {
    localStorage.setItem("elapsedTime", elapsedTime.toString());
    const minutes = Math.floor(elapsedTime / 60);
    const seconds = elapsedTime % 60;
    const formattedTime = `${minutes}:${String(seconds).padStart(2, "0")}`;
    setTimerDisplay(formattedTime);
  }, [elapsedTime]);

  useEffect(() => {
    isRecording.current = recordingStatus.isRecording;
  }, [recordingStatus.isRecording]);

  useEffect(() => {
    if (microphonePermissionGranted === undefined) {
      getAvailableMicrophones();
    }

    // This will only run when the component unmounts
    return () => {
      if (!isRecording) {
        releaseMicrophone();
      }
    };
  }, []);

  useEffect(() => {
    let spinnerTimeout: NodeJS.Timeout;
    const baseTimeout = SPINNER_TIMEOUT;
    let timeoutDuration = baseTimeout;

    if (recordingStatus.isReuploading) {
      timeoutDuration = baseTimeout * 5; // 5x timeout for reuploading
    }

    if (
      recordingStatus.isReuploading ||
      recordingStatus.isStarting ||
      recordingStatus.isStopping
    ) {
      spinnerTimeout = setTimeout(() => {
        setSpinnerError(true);
        showAlertBanner(
          "An error occurred. Please refresh the page and try again.",
          "error"
        );
        SentryService.logEvent("Spinner timeout.", {
          level: "error",
          extra: { recordingStatus, timeoutDuration },
        });
      }, timeoutDuration);
    }

    return () => {
      if (spinnerTimeout) {
        clearTimeout(spinnerTimeout);
      }
    };
  }, [
    recordingStatus.isReuploading,
    recordingStatus.isStarting,
    recordingStatus.isStopping,
  ]);

  const handleNoteTitleChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newNoteTitle = event.target.value;
    setNoteInformation((prevInfo) => ({
      ...prevInfo,
      noteTitle: newNoteTitle,
    }));
    setNoteTitleError(newNoteTitle.trim() === "");
  };

  const handleTemplateChange = (event: SelectChangeEvent) => {
    const newTemplateId = event.target.value as string;
    setNoteInformation((prev) => ({ ...prev, templateId: newTemplateId }));
  };

  const handlePronounsChange = (event: SelectChangeEvent) => {
    const newPronouns = event.target.value;
    setNoteInformation((prevInfo) => ({
      ...prevInfo,
      pronouns: newPronouns,
    }));
  };

  const handleAgeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newAge = Number(event.target.value);
    if (newAge >= 0 && newAge <= 120) {
      setNoteInformation((prevInfo) => ({
        ...prevInfo,
        age: newAge,
      }));
      setAgeError("");
    } else {
      setAgeError("Age must be between 0 and 120.");
    }
  };

  const handleAdditionalInfoChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newAdditionalInfo = event.target.value;
    setNoteInformation((prevInfo) => ({
      ...prevInfo,
      additionalInfo: newAdditionalInfo,
    }));
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUseSharedMedia(event.target.checked);
  };

  const handleCheckFirstNote = async () => {
    if (userState?.onboardingSteps?.generated_first_note !== true) {
      updateOnboardingStep("generated_first_note", true);
    }
  };

  const startRecording = async () => {
    const startRecordingStatus = await initiateRecording();
    if (startRecordingStatus) {
    } else {
      console.error("Failed to start recording.");
    }
  };

  const handleResumeRecording = async () => {
    const resumeRecordingStatus = await resumeRecording();
    if (!resumeRecordingStatus) {
      console.error("Failed to resume recording.");
    }
  };

  const stopRecording = async () => {
    const stopRecordingStatus = await initiateEndRecording();
  };

  const deleteRecording = async () => {
    setShowDeleteModal(false);
    const deleteRecordingResponse = await deleteRecordingHelper();
    if (deleteRecordingResponse) {
      showAlertBanner("Recording deleted successfully.", "success");
    } else {
      showAlertBanner("Failed to delete recording.", "error");
    }
  };

  const submitRecording = async () => {
    if (elapsedTime < 20) {
      showAlertBanner("Recording must be at least 20 seconds long.", "error");
      return;
    }

    if (!noteInformation.noteTitle) {
      setNoteTitleError(true);
      return;
    }

    setNoteTitleError(false);
    try {
      const submitRecordingResponse = await sendRecording();
      if (submitRecordingResponse) {
        await handleCheckFirstNote();
        navigate(`/status/${submitRecordingResponse}`);
      } else {
        showAlertBanner("Failed to submit recording.", "error");
      }
    } catch (error) {
      showAlertBanner("Error occurred while submitting recording.", "error");
    }
  };

  const handleInitiateReupload = async () => {
    await initiateReupload();
  };

  const RecordingControls = useMemo(() => {
    if (
      recordingStatus.isReuploading ||
      recordingStatus.isStarting ||
      recordingStatus.isStopping
    ) {
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 100,
          }}
        >
          {spinnerError ? (
            <>
              <Typography color="error">
                An error occurred. Please reset the recorder:
              </Typography>
              <Button
                variant="contained"
                onClick={() => {
                  localStorage.removeItem("recordingStatus");
                  window.location.reload();
                }}
              >
                Reset Recorder
              </Button>
            </>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <CircularProgress />
              {lowDataMode && (
                <Typography sx={{ mt: 2, color: "lightgrey" }}>
                  Slow network detected, improve your network connection if this
                  takes too long.
                </Typography>
              )}
            </Box>
          )}
        </Box>
      );
    }

    if (recordingStatus.readyToRecord) {
      switch (microphonePermissionGranted) {
        case true:
          return (
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              {platform === "desktop" && (
                <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                  <FormControl fullWidth>
                    <InputLabel id="microphone-select-label">
                      Microphone
                    </InputLabel>
                    <Select
                      labelId="microphone-select-label"
                      value={selectedMicrophone?.deviceId || ""}
                      label="Microphone"
                      onChange={(event) => {
                        const selectedMic = availableMicrophones?.find(
                          (mic) => mic.deviceId === event.target.value
                        );
                        if (selectedMic) {
                          setSelectedMicrophone(selectedMic);
                        }
                      }}
                    >
                      {availableMicrophones?.map((mic) => (
                        <MenuItem key={mic.deviceId} value={mic.deviceId}>
                          {mic.label || `Microphone ${mic.deviceId}`}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {browser === "chrome" && (
                    <Tooltip title="Wearing headphones while recording a telehealth visit? Check this box to share audio from the browser tab with your telehealth platform so that we can record it.">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={useSharedMedia}
                            onChange={handleCheckboxChange}
                            color="primary"
                          />
                        }
                        label="Headphones?"
                      />
                    </Tooltip>
                  )}
                </Box>
              )}
              <Button
                variant="contained"
                color="primary"
                onClick={startRecording}
                fullWidth
              >
                Start Recording
              </Button>
            </Box>
          );
        case false:
          return (
            <Typography>
              Microphone access is denied. You'll need to enable it to use the
              recorder.
            </Typography>
          );
        case undefined:
          return (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                alignItems: "center",
              }}
            >
              <Typography>
                The recorder needs microphone access to record.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={checkMicrophonePermission}
              >
                Request Microphone Access
              </Button>
            </Box>
          );
      }
    }

    if (recordingStatus.isRecording) {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 2,
            width: "100%",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Box
              sx={{
                width: 20,
                height: 20,
                borderRadius: "50%",
                backgroundColor: "red",
                animation: "pulse 1s infinite",
              }}
            />
            <Typography variant="body2">{timerDisplay}</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              width: "100%",
            }}
          >
            <Button
              sx={{ width: "180px" }}
              variant="contained"
              color="primary"
              onClick={stopRecording}
            >
              Stop Recording
            </Button>
            <Button
              sx={{ flex: 1 }}
              variant="contained"
              color="inherit"
              onClick={
                recordingStatus.recordingPaused
                  ? handleResumeRecording
                  : pauseRecording
              }
            >
              {recordingStatus.recordingPaused ? "Resume" : "Pause"}
            </Button>
          </Box>
        </Box>
      );
    }

    if (recordingStatus.chunksToBeUploaded) {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 2,
            width: "100%",
          }}
        >
          <Typography variant="body1" sx={{ textAlign: "center" }}>
            Timer: {timerDisplay}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "100%",
              gap: 2,
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={handleInitiateReupload}
              sx={{ flex: 3 }}
            >
              Retry Upload
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => setShowDeleteModal(true)}
              sx={{ flex: 1 }}
            >
              Delete
            </Button>
          </Box>
        </Box>
      );
    }

    if (recordingStatus.readyToSubmit) {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 2,
            width: "100%",
          }}
        >
          <Typography variant="body1" sx={{ textAlign: "center" }}>
            Timer: {timerDisplay}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "100%",
              gap: 2,
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={submitRecording}
              sx={{ flex: 3 }}
            >
              Submit Recording
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => setShowDeleteModal(true)}
              sx={{ flex: 1 }}
            >
              Delete
            </Button>
          </Box>
        </Box>
      );
    }

    return null;
  }, [
    recordingStatus,
    microphonePermissionGranted,
    spinnerError,
    timerDisplay,
    useSharedMedia,
    noteInformation,
  ]);

  return (
    <Box
      sx={{
        width: { xs: "95%", md: "80%" },
        maxWidth: 500,
        margin: "auto",
        padding: 2,
      }}
    >
      <Typography variant="h6" textAlign="center" marginBottom={2}>
        Record an Encounter
      </Typography>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <TextField
          label="Note Title"
          value={noteInformation.noteTitle || ""}
          onChange={handleNoteTitleChange}
          required
          error={noteTitleError}
          helperText={noteTitleError ? "Please enter a note title" : ""}
        />
        {templatesList && templatesList.length > 0 && (
          <FormControl fullWidth>
            <InputLabel id="template-label">Template</InputLabel>
            <Select
              labelId="template-label"
              id="template"
              value={
                noteInformation.templateId ? noteInformation.templateId : ""
              }
              label="Template"
              onChange={handleTemplateChange}
            >
              {templatesList.map((template: UserTemplateItem) => (
                <MenuItem
                  key={template.template_id}
                  value={template.template_id}
                >
                  {template.display_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <Box sx={{ display: "flex", gap: 2 }}>
          <FormControl fullWidth>
            <InputLabel id="pronouns-label">Pronouns</InputLabel>
            <Select
              labelId="pronouns-label"
              value={noteInformation.pronouns || ""}
              label="Pronouns"
              onChange={handlePronounsChange}
            >
              <MenuItem value="he/him">He/Him</MenuItem>
              <MenuItem value="she/her">She/Her</MenuItem>
              <MenuItem value="they/them">They/Them</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label="Age"
            type="number"
            InputLabelProps={{ shrink: true }}
            value={noteInformation.age || ""}
            onChange={handleAgeChange}
            error={!!ageError}
            helperText={ageError}
          />
        </Box>
        <TextField
          label="Additional Info"
          multiline
          rows={3}
          value={noteInformation.additionalInfo || ""}
          onChange={handleAdditionalInfoChange}
        />

        <Box sx={{ minHeight: "100px", height: "10vh", maxHeight: "300px" }}>
          <AudioVisualizer />
        </Box>
        {RecordingControls}
      </Box>
      <DeleteModal
        isOpen={showDeleteModal}
        continueText="Delete"
        cancelText="Cancel"
        onCancel={() => {
          setShowDeleteModal(false);
        }}
        onContinue={deleteRecording}
      >
        Are you sure you want to delete this recording? This action is permanent
        and cannot be undone.
      </DeleteModal>
    </Box>
  );
};

//  **APIService.ts**:
//     - Include functions to start a session, upload chunks, and end a session.

import { Result } from "../types/types";

//@ts-ignore
import { LowDataError } from "@shared/Errors";
const apiBaseUrl = import.meta.env.VITE_API_URL;

export const API_BASE_URL = apiBaseUrl;
export const FRONTEND_RECORDING_APP_VERSION = "1.0.8";

export const appVersion = `web-${FRONTEND_RECORDING_APP_VERSION}`;

export const DEBUG = true;
export const DEBUG_FAILURE_PERCENTAGE = 0.0;
export const NETWORK_FAILURE_PERCENTAGE = 0.0;
const DELAY_WARNING_THRESHOLD = 5000; // 5 seconds
const TIMEOUT_THRESHOLD = 12000; // 12 seconds

interface RequestOptions {
  method: "get" | "post";
  url: string;
  accessToken?: string | null;
  retries?: number;
  body?: any;
  onNetworkDelay?: () => void;
  onNetworkAbsoluteTimeout?: () => void;
}

interface GetRequestOptions {
  requestString: string;
  accessToken?: string | null;
  retries?: number;
  onNetworkDelay?: () => void;
  onNetworkAbsoluteTimeout?: () => void;
}

interface PostRequestOptions {
  requestString: string;
  accessToken?: string | null;
  retries?: number;
  body?: any;
  onNetworkDelay?: () => void;
  onNetworkAbsoluteTimeout?: () => void;
}

class APIService {
  private API_URL: string;

  constructor(apiURL: string) {
    this.API_URL = apiURL;
  }

  public changeAPIUrl(apiURL: string) {
    this.API_URL = apiURL;
  }

  private networkShouldFail() {
    if (DEBUG) {
      return Math.random() < NETWORK_FAILURE_PERCENTAGE;
    } else {
      return false;
    }
  }

  private async executeRequest(options: RequestOptions): Promise<Result<any>> {
    const {
      method,
      url,
      accessToken,
      retries = 1, // Default value if not provided
      body,
      onNetworkDelay,
      onNetworkAbsoluteTimeout,
    } = options;

    if (!accessToken) {
      return Promise.resolve({
        ok: false as const,
        error: new Error("Access token is undefined"),
      });
    }

    let networkWarningTimer: NodeJS.Timeout | null = null;

    if (onNetworkDelay) {
      networkWarningTimer = setTimeout(() => {
        onNetworkDelay();
      }, DELAY_WARNING_THRESHOLD);
    }

    const fetchPromise = fetch(url, {
      method: method,
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
        "App-Version": `${appVersion}`,
      }),
      body: JSON.stringify(body),
    })
      .then(async (response: Response) => {
        if (response.ok) {
          const results = await response.json();
          return { ok: true as const, value: results };
        } else {
          const { message } = await response.json();
          return { ok: false as const, error: new Error(message) };
        }
      })
      .catch((err: Error) => {
        return { ok: false as const, error: err };
      });

    const timeoutPromise = new Promise<Result<any>>((resolve, reject) => {
      if (this.networkShouldFail()) {
        reject(new LowDataError("Debug timeout simulated"));
      }
      setTimeout(() => {
        reject(new LowDataError("Request timed out after 12 seconds"));
      }, TIMEOUT_THRESHOLD);
    });

    return Promise.race([fetchPromise, timeoutPromise])
      .finally(() => {
        if (networkWarningTimer) clearTimeout(networkWarningTimer);
      })
      .catch((error) => {
        console.error(error);
        if (retries > 0) {
          console.warn(
            `${url} - Network request timed out. Retrying ${retries} more time(s)...`
          );

          return this.executeRequest({
            ...options,
            retries: retries - 1,
          });
        } else {
          console.error(
            `${url} - Network request timed out. Exceeded retry attempts.`
          );
          if (onNetworkAbsoluteTimeout) {
            onNetworkAbsoluteTimeout();
          }
          const timeoutError = new Error(
            "Network request timed out and exceeded retry limit."
          );
          timeoutError.name = "timeoutError";
          return {
            ok: false as const,
            error: timeoutError,
          };
        }
      });
  }

  public async makeAPIGetRequest(
    options: GetRequestOptions
  ): Promise<Result<any>> {
    const {
      requestString,
      accessToken,
      onNetworkDelay,
      onNetworkAbsoluteTimeout,
    } = options;

    const url = `${this.API_URL}${requestString}`;
    console.log(url);

    return this.executeRequest({
      method: "get",
      url,
      accessToken,
      onNetworkDelay,
      onNetworkAbsoluteTimeout,
    });
  }

  public async makeAPIPostRequest(
    options: PostRequestOptions
  ): Promise<Result<any>> {
    const {
      requestString,
      accessToken,
      body,
      onNetworkDelay,
      onNetworkAbsoluteTimeout,
    } = options;

    const url = `${this.API_URL}${requestString}`;
    console.log(url);

    return this.executeRequest({
      method: "post",
      url,
      accessToken,
      body,
      onNetworkDelay,
      onNetworkAbsoluteTimeout,
    });
  }
}

export default new APIService(apiBaseUrl);

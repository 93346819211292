import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";

interface CustomTransitionModalProps {
  isOpen: boolean;
  children: React.ReactNode;
  onClose: () => void;
}

export const CustomTransitionModal = ({
  isOpen,
  children,
  onClose,
}: CustomTransitionModalProps) => {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={isOpen}
      onClose={onClose}
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={isOpen}>
        <div>{children}</div>
      </Fade>
    </Modal>
  );
};

import React, { useState } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  Typography,
  Button,
  SelectChangeEvent,
  useTheme,
  CircularProgress,
  Checkbox,
  ListItemText,
} from "@mui/material";
import { useUser } from "../context/user";
import APIService from "../services/APIService";

export const assemblyLanguageOptions = {
  en: "English",
  es: "Spanish",
  fr: "French",
  de: "German",
  it: "Italian",
  pt: "Portuguese",
};

export const deepgramLanguageOptions = {
  en: "English",
  es: "Spanish",
  fr: "French",
  de: "German",
  it: "Italian",
  pt: "Portuguese",
  zh: "Chinese",
  hi: "Hindi",
  ja: "Japanese",
  ru: "Russian",
  uk: "Ukranian",
  sv: "Swedish",
  nl: "Dutch",
  tr: "Turkish",
  id: "Indonesian",
  ko: "Korean",
};

interface LanguageAndNotePreferencesFormProps {
  onSuccessfulFormSubmission: (subjectLanguage: string) => void;
}

const LanguageAndNotePreferencesForm = ({
  onSuccessfulFormSubmission,
}: LanguageAndNotePreferencesFormProps) => {
  const theme = useTheme(); // Access the theme
  const { getAccessToken, userState } = useUser();

  const [languages, setLanguages] = useState<string[]>(["en"]);
  const [subjectLanguage, setSubjectLanguage] = useState<string>("patient");
  const [loading, setLoading] = useState<boolean>(false);

  let languageNames = userState?.featureFlags?.deepgram
    ? deepgramLanguageOptions
    : assemblyLanguageOptions;

  const handleLanguageChange = (event: SelectChangeEvent<string[]>) => {
    const value = event.target.value;
    setLanguages(typeof value === "string" ? value.split(",") : value);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    setLoading(true);
    event.preventDefault();
    const accessToken = await getAccessToken();

    if (!accessToken) {
      setLoading(false);
      return;
    }

    const result = await APIService.makeAPIPostRequest({
      requestString: "/user/languageAndPreferencesForm",
      accessToken: accessToken,
      body: {
        languages: languages,
        subject_language: subjectLanguage,
      },
    });

    if (result.ok) {
      onSuccessfulFormSubmission(subjectLanguage);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ p: 4 }}>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <Typography sx={{ color: theme.palette.text.secondary }}>
          Which languages do you use regularly during encounters?
        </Typography>

        <FormControl fullWidth margin="normal">
          {/* <InputLabel id="language-select-label">
          Which languages do you use regularly during encounters?
        </InputLabel> */}
          <Select
            labelId="language-select-label"
            id="language-select"
            multiple
            value={languages}
            onChange={handleLanguageChange}
            renderValue={(selected) =>
              selected
                .map(
                  (code) => languageNames[code as keyof typeof languageNames]
                )
                .join(", ")
            }
          >
            {Object.entries(languageNames).map(([code, name]) => (
              <MenuItem key={code} value={code}>
                <Checkbox checked={languages.indexOf(code) > -1} />
                <ListItemText primary={name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl component="fieldset" margin="normal">
          <FormLabel component="legend">
            What word do you typically use to refer to the subject of your
            encounters?
          </FormLabel>
          <RadioGroup
            aria-label="style"
            name="style"
            value={subjectLanguage}
            onChange={(e) => setSubjectLanguage(e.target.value)}
            row
          >
            <FormControlLabel
              value="patient"
              control={<Radio />}
              label="Patient"
            />
            <FormControlLabel
              value="client"
              control={<Radio />}
              label="Client"
            />
          </RadioGroup>
        </FormControl>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          disabled={loading}
        >
          {loading ? <CircularProgress size={"24px"} /> : "Next"}
        </Button>
      </Box>
    </Box>
  );
};

export default LanguageAndNotePreferencesForm;

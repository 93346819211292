import { AlertColor } from "@mui/material";
import {
  createContext,
  useContext,
  ReactNode,
  useState,
  useEffect,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";

interface AlertBannerState {
  isVisible: boolean;
  message: string;
  color: AlertColor;
  autoHide: boolean;
  action?: () => void;
}

type Platform = "mobile" | "android" | "ios" | "desktop";

const initialState = {
  sidebar: { isHidden: true },
  navbar: { showCopyNotification: false },
  navigation: { currentPage: "home" },
  alertBanner: {
    isVisible: false,
    message: "",
    color: "info" as AlertColor,
    autoHide: true,
  },
  platform: "desktop" as Platform, // Default platform is set to "desktop"
  browser: "",
  loadedInChromeExtension: false,
};

export type UIStateType = {
  sidebar: { isHidden: boolean };
  navbar: { showCopyNotification: boolean };
  navigation: { currentPage: string };
  alertBanner: {
    isVisible: boolean;
    message: string;
    color: AlertColor;
    autoHide: boolean;
    action?: () => void;
  };
  platform: Platform; // Added platform property
  browser: string;
  loadedInChromeExtension: boolean;
};

const UIStateContext = createContext<{
  state: UIStateType;
  setState: React.Dispatch<React.SetStateAction<UIStateType>>;
  showAlertBanner: (
    message: string,
    color: AlertBannerState["color"],
    autoHide?: boolean,
    action?: () => void
  ) => void;
}>({
  state: initialState,
  setState: () => {},
  showAlertBanner: () => {},
});

export const useUIState = () => useContext(UIStateContext);

export const UIStateProvider = ({ children }: { children: ReactNode }) => {
  const [ uiState, setUIState] = useState<UIStateType>(initialState);

  const detectBrowser = () => {
    const ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf("safari") !== -1 && ua.indexOf("chrome") === -1) {
      return "safari";
    } else if (ua.indexOf("chrome") !== -1) {
      return "chrome";
    } else if (ua.indexOf("firefox") !== -1) {
      return "firefox";
    } else {
      return "other";
    }
  };

  const detectPlatform = () => {
    const userAgent = navigator.userAgent;
    console.log(userAgent);
    if (/Android/i.test(userAgent)) {
      return "android";
    } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
      return "ios";
    } else if (/Mobile/i.test(userAgent)) {
      return "mobile";
    } else {
      return "desktop";
    }
  };

  const registerWindowListeners = () => {
    const handleMessage = (event: MessageEvent) => {
      // Make sure the message is from the extension
      // if (event.origin !== "chrome-extension://your-extension-id") return;

      console.log(
        "Received message from chrome extension:",
        event.origin,
        event.data
      );

      if (event.data.type === "extensionLoaded") {
        console.log("Loaded in chrome extension!");
        setUIState((prev) => ({ ...prev, loadedInChromeExtension: true }));
      }
    };

    window.addEventListener("message", handleMessage);

    // Return a function to remove the listener
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  };

  useEffect(() => {
    const platform = detectPlatform();
    const browser = detectBrowser();
    setUIState((prev) => ({ ...prev, platform, browser }));
  }, []);

  useEffect(() => {
    const cleanup = registerWindowListeners();
    return cleanup;
  }, []);

  useEffect(() => {
    // Automatically hide the alert banner if autoHide is true
    if (uiState.alertBanner.isVisible && uiState.alertBanner.autoHide) {
      const timer = setTimeout(() => {
        setUIState((prev) => ({
          ...prev,
          alertBanner: { ...prev.alertBanner, isVisible: false },
        }));
      }, 5000); // Adjust time as needed
      return () => clearTimeout(timer);
    }
  }, [uiState.alertBanner]);

  // Example helper function to show the alert banner
  const showAlertBanner = (
    message: string,
    color: AlertBannerState["color"],
    autoHide: boolean = true,
    action?: () => void
  ) => {
    setUIState((prev) => ({
      ...prev,
      alertBanner: {
        isVisible: true,
        message,
        color,
        autoHide,
        action,
      },
    }));

    // Automatically hide the banner after a specified duration
    if (autoHide) {
      setTimeout(() => {
        setUIState((prev) => ({
          ...prev,
          alertBanner: {
            ...prev.alertBanner,
            isVisible: false,
          },
        }));
      }, 3000); // Adjust the duration as needed
    }
  };

  // Provide the showAlertBanner function through context
  const contextValue = {
    state: uiState,
    setState: setUIState,
    showAlertBanner,
  };

  return (
    <UIStateContext.Provider value={contextValue}>
      {children}
    </UIStateContext.Provider>
  );
};

import { json } from "react-router-dom";
import { AdminGroup, AdminUser } from "../types/types";
import { API_BASE_URL } from "../services/APIService";

export const GroupsLoader = async (): Promise<AdminGroup[]> => {
  const accessToken = localStorage.getItem("accessToken");
  const groupsList = await fetch(`${API_BASE_URL}/group/getAllGroups`, {
    method: "get",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    }),
  })
    .then((res) => {
      if (res.status === 401) {
        throw json(
          {
            message: "You are not authorized to access this resource.",
            cta: "Login",
          },
          { status: 401 }
        );
      }

      return res.json();
    })
    .then((data: { groups: AdminGroup[] }) => {
      return data.groups;
    })
    .catch(() => {
      throw json(
        {
          message: "There has been an error. Please login again.",
          cta: "Login",
        },
        { status: 500 }
      );
    });

  return groupsList;
};

// src/components/EmailNoteModal.tsx
import React, { ChangeEvent, MouseEvent, useEffect, useState } from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Checkbox,
} from "@mui/material";
import { useSelectedUser } from "../context/selectedUserContext.tsx";
import { AdminEmails } from "../types/types";
import { useNavigate } from "react-router-dom";
import { BorderColorSharp } from "@mui/icons-material";
import { RoundedButton } from "../styles/CustomButtons";
import { useUser } from "../context/user/index.js";
import APIService from "../services/APIService.js";

interface EmailNoteModalProps {
  emailModalOpen: boolean;
  setEmailModalOpen: (value: boolean) => void;
  sendPDFAsEmail: () => void;
  setSendPDFEmailList: (value: any) => void;
  loadingText: string;
  error: string | null;
  setError: (value: string | null) => void;
}

const EmailNoteModal: React.FC<EmailNoteModalProps> = ({
  emailModalOpen,
  setEmailModalOpen,
  sendPDFAsEmail,
  setSendPDFEmailList,
  loadingText,
  error,
  setError,
}) => {
  const { getAccessToken } = useUser();
  const [emailList, setEmailList] = useState<AdminEmails[]>([]);
  const navigate = useNavigate();

  const fetchAdminEmails = async () => {
    const accessToken = await getAccessToken();
    const response = await APIService.makeAPIGetRequest({
      requestString: "/user/getAdminEmails",
      accessToken: accessToken,
    });

    if (response.ok) {
      const data = response.value;
      setEmailList(data.admin_emails);
    }
  };

  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    email: { email_address: string }
  ) => {
    if (event.target.checked) {
      setError(null);
      setSendPDFEmailList((prevEmails: any) => [
        ...prevEmails,
        email.email_address,
      ]);
    } else {
      setSendPDFEmailList((prevEmails: any) =>
        prevEmails.filter((e: any) => e !== email.email_address)
      );
    }
  };

  // const emails = emailList
  //   .filter((email) => email.verified_status === true)
  //   .map((email) => {
  //     return (

  //     );
  //   });

  const handleOverlayClick = (event: MouseEvent) => {
    //close modal by clicking anywhere but the modal
    if (event.target === event.currentTarget) {
      setEmailModalOpen(false);
    }
  };

  useEffect(() => {
    fetchAdminEmails();
  }, []);

  if (!emailModalOpen) {
    return null;
  }

  return (
    <Box
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-40"
      onClick={handleOverlayClick}
    >
      <Box className="bg-white rounded-lg p-6 mx-10 inline-block text-center justify-center">
        <Typography
          variant="h3"
          fontWeight="600"
          color="textColors.lightHeader"
        >
          Select Email Addresses
        </Typography>
        {loadingText && (
          <Box className="fixed inset-0 z-100 flex items-center justify-center bg-black bg-opacity-40">
            <Box className="bg-white rounded-lg p-6 w-6/12 md:w-1/2 flex items-center justify-center">
              <Typography variant="h4" color="textColors.lightHeader">
                {loadingText}
              </Typography>
            </Box>
          </Box>
        )}
        {emailList && emailList.length > 0 && (
          <Box>
            <List
              sx={{
                mb: 10,
                mt: 5,
                overflowY: "scroll",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {emailList
                .filter((email) => email.verified_status === true)
                .map((email) => (
                  <ListItem
                    key={email.email_id}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      width: "100%",
                      fontSize: {
                        xs: "text-xs",
                        sm: "text-sm",
                        md: "text-base",
                        lg: "text-lg",
                      },
                    }}
                  >
                    <ListItemText
                      primary={email.name}
                      sx={{
                        textAlign: "start",
                        color: "textColors.primaryText",
                      }}
                    />
                    <ListItemText
                      primary={email.email_address}
                      sx={{
                        textAlign: "end",
                        paddingLeft: 1,
                        color: "textColors.primaryText",
                      }}
                    />
                    <Checkbox
                      id={email.email_id}
                      name={email.email_id}
                      sx={{
                        color: "borderColors.darkprimary",
                        "& .MuiSvgIcon-root": {
                          fontSize: "1.5rem",
                          padding: "0px",
                        },
                      }}
                      onChange={(event) => handleCheckboxChange(event, email)}
                    />
                  </ListItem>
                ))}
            </List>
            <RoundedButton variant="contained" onClick={() => sendPDFAsEmail()}>
              Send Note
            </RoundedButton>
          </Box>
        )}
        {(!emailList || emailList.length == 0) && (
          <Box className="my-4">
            <Typography
              variant="h6"
              color="textColors.primaryText"
              className="mb-2"
            >
              No administrative email addresses on file. Add one from your user
              page.
            </Typography>
            <RoundedButton
              variant="outlined"
              onClick={() => {
                navigate("/user");
              }}
            >
              Go to User Page
            </RoundedButton>
          </Box>
        )}

        {error && (
          <Typography variant="h4" color="textColors.warning">
            {error}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default EmailNoteModal;

import { Params, json } from "react-router-dom";
import { AdminFeedbackSession, AdminNote } from "../types/types";
import { API_BASE_URL } from "../services/APIService";

export const AdminFeedbackSessionLoader = async ({
  params,
}: {
  params: Params;
}): Promise<AdminFeedbackSession | null> => {
  const accessToken = localStorage.getItem("accessToken");
  const feedbackSession = await fetch(
    `${API_BASE_URL}/feedback/getFeedbackSession?feedback_session_id=${params.feedbackSessionId}`,
    {
      method: "get",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      }),
    }
  )
    .then((res) => {
      if (res.status === 401) {
        throw json(
          {
            message: "You are not authorized to access this resource.",
            cta: "Login",
          },
          { status: 401 }
        );
      }

      return res.json();
    })
    .then((data: { feedback_session: AdminFeedbackSession }) => {
      console.log(data.feedback_session);
      return data.feedback_session;
    })
    .catch(() => {
      throw json(
        {
          message: "There has been an error. Please login again.",
          cta: "Login",
        },
        { status: 500 }
      );
    });

  return feedbackSession;
};

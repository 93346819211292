import { AdminGroup } from "../../types/types";
import { UserInfo } from "./UserInfo";
import { GroupRow } from "./GroupRow";

interface GroupsListProps {
  groupsList: AdminGroup[];
}

export const GroupsList = ({ groupsList }: GroupsListProps) => {
  // const { data: users, isLoading } = useQuery("users", fetchUsers);

  // if (isLoading) {
  //   return <div>Loading...</div>;
  // }

  return (
    <div className="flex flex-col">
    <div className="flex flex-col">
        <table className="table-auto w-full border-collapse border-2 border-gray-500">
          <thead className="sticky top-0 bg-blue-200">
          <tr>
            <th className="px-4 py-2 border-2 border-gray-400">ID</th>
            <th className="px-4 py-2 border-2 border-gray-400">Name</th>
            <th className="px-4 py-2 border-2 border-gray-400">Institution</th>
            <th className="px-4 py-2 border-2 border-gray-400">Members</th>
            <th className="px-4 py-2 border-2 border-gray-400">Admins</th>
          </tr>
          </thead>
          <tbody>
          {groupsList.map((group) => (
          <GroupRow key={group.group_id} group={group} />
        ))}
          </tbody>
        </table>
    </div>
    </div>
  );
};

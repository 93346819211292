import {
  Box,
  Button,
  Card,
  CardContent,
  IconButton,
  LinearProgress,
  Tooltip,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { formatDate, truncateText } from "../../utils/utils";
import React, { useState, useRef, useEffect } from "react";
import { useUIState } from "../../context/uiState";
import { UIStateType } from "../../context/uiState/context";
import {
  AdminSection,
  AdminTemplate,
  AvailableModule,
  ListTemplate,
} from "../../types/types";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { PrimaryButton } from "../Buttons/Buttons";
import {
  Close,
  DragIndicator,
  DragIndicatorOutlined,
  DragIndicatorRounded,
  DragIndicatorTwoTone,
  Star,
} from "@mui/icons-material";
import { NoteSectionDisplayBox } from "../Note/NoteSectionDisplayBox";

interface SectionBlockProps {
  section: AdminSection;
  handleClick?: () => void;
  handleRevert?: () => void;
  availableModules?: AvailableModule[];
  templateModuleMap?: { [key: string]: string };
}

export const SectionBlock = ({
  section,
  handleClick,
  handleRevert,
  availableModules,
  templateModuleMap,
}: SectionBlockProps) => {
  const moduleInfo = availableModules?.find(
    (module) => module.module_id === section.module_id
  );
  return (
    <Card
      sx={{
        margin: 1,
        borderColor: "borderColors.primary",
        border: "1px solid",
        "&:hover": {
          boxShadow: 3, // Adjust shadow on hover
        },
      }}
      onClick={handleClick}
    >
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box
            id="module-text"
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              flexGrow: 1,
            }}
          >
            <Box>
              <Typography
                fontStyle="normal"
                fontWeight="700"
                sx={{
                  cursor: "pointer",
                  display: "inline-block",
                  "&:hover + .hover-content": {
                    cursor: "pointer",
                    display: "block",
                    opacity: 1,
                    visibility: "visible",
                    transform: "translateY(0%)",
                  },
                }}
              >
                {section.display_name}
              </Typography>
              {/* Hover content */}
              {moduleInfo && (
                <HoverContent
                  title={section.display_name}
                  content={moduleInfo?.public_example || ""}
                />
              )}
            </Box>

            <Box>
              <Typography fontSize=".8rem" fontWeight="300">
                {moduleInfo?.public_description}
              </Typography>
            </Box>
          </Box>
          {templateModuleMap && templateModuleMap[section.module_id] && (
            <Tooltip
              title={"This section has been customized."}
              placement="top"
              arrow
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  "&:hover .revert-button": {
                    display: "inherit",
                  },
                }}
              >
                <Star />
                <Typography fontSize={12}>Customized</Typography>
                {handleRevert && (
                  <Button
                    variant="text"
                    className="revert-button"
                    sx={{ display: "none" }}
                    onClick={handleRevert}
                  >
                    <Typography fontSize={10}>Revert?</Typography>
                  </Button>
                )}
              </Box>
            </Tooltip>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

interface DraggableSectionBlockProps {
  section: AdminSection;
  index: number;
  handleClick?: () => void;
  handleRemove?: () => void;
  handleRevert?: () => void;
  availableModules?: AvailableModule[];
  templateModuleMap?: { [key: string]: string };
}

export const DraggableSectionBlock = ({
  section,
  index,
  handleClick,
  handleRemove,
  handleRevert,
  availableModules,
  templateModuleMap,
}: DraggableSectionBlockProps) => {
  const moduleInfo = availableModules?.find(
    (module) => module.module_id === section.module_id
  );

  return (
    <Draggable
      key={section.module_id}
      draggableId={section.module_id}
      index={index}
    >
      {(provided) => (
        <Card
          ref={provided.innerRef}
          {...provided.draggableProps}
          sx={{
            margin: 1,
            borderColor: "borderColors.primary",
            border: "1px solid",
            "&:hover": {
              boxShadow: 3, // Adjust shadow on hover
            },
          }}
          onClick={handleClick}
        >
          <CardContent
            sx={{
              // position: "relative",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box
              id="drag-handle"
              {...provided.dragHandleProps}
              sx={{ marginLeft: -1, marginRight: 1 }}
            >
              <DragIndicator />
            </Box>
            <Box
              id="module-text"
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                flexGrow: 1,
              }}
            >
              <Box>
                <Typography
                  fontStyle="normal"
                  fontWeight="700"
                  sx={{
                    cursor: "pointer",
                    display: "inline-block",
                    "&:hover + .hover-content": {
                      opacity: 1,
                      visibility: "visible",
                    },
                  }}
                >
                  {section.display_name}
                </Typography>
                {/* Hover content */}
                {moduleInfo && (
                  <HoverContent
                    title={section.display_name}
                    content={moduleInfo?.public_example || ""}
                  />
                )}
              </Box>
              <Box>
                <Typography fontSize=".8rem" fontWeight="300">
                  {moduleInfo?.public_description}
                </Typography>
              </Box>
            </Box>
            <Box
              id="interactions"
              sx={{ display: "flex", justifyContent: "flex-start" }}
            >
              {templateModuleMap && templateModuleMap[section.module_id] && (
                <Tooltip
                  title={"This section has been customized."}
                  placement="top"
                  arrow
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      "&:hover .revert-button": {
                        display: "inherit",
                      },
                    }}
                  >
                    <Star />
                    <Typography fontSize={12}>Customized</Typography>
                    {handleRevert && (
                      <Button
                        variant="text"
                        className="revert-button"
                        sx={{ display: "none" }}
                        onClick={handleRevert}
                      >
                        <Typography fontSize={10}>Revert?</Typography>
                      </Button>
                    )}
                  </Box>
                </Tooltip>
              )}
              <IconButton
                onClick={handleRemove}
                sx={{ marginLeft: 2, height: 40 }}
              >
                <Close />
              </IconButton>
            </Box>
          </CardContent>
        </Card>
      )}
    </Draggable>
  );
};

// New HoverContent component
interface HoverContentProps {
  title: string;
  content: string;
}
export const HoverContent = ({ title, content }: HoverContentProps) => (
  <Box
    className="hover-content"
    sx={{
      position: "absolute",
      left: 0,
      width: "100%",
      opacity: 0,
      visibility: "hidden",
      transition: "opacity 0.2s, visibility 0.2s",
      zIndex: 1,
      backgroundColor: "white",
      boxShadow: "0 0.8vh 0.8vh rgba(0, 0, 0, 0.5)",
      borderRadius: "1.5vh",
      border: "0.15vh solid",
      borderColor: "divider",
      padding: "0.8vh",
      pointerEvents: "none",
    }}
  >
    <Typography variant="h6" gutterBottom>
      {title}
    </Typography>
    <NoteSectionDisplayBox fontSize={10}>{content}</NoteSectionDisplayBox>
    {/* <Box
      sx={{
        backgroundColor: "backgroundColors.secondary",
        borderRadius: "0.4vh",
        padding: "0.8vh",
      }}
    >
      <Typography variant="body2">{content}</Typography>
    </Box> */}
  </Box>
);

import { AdminFeedbackSession, AdminNote } from "../../types/types";
import { FeedbackSessionRow } from "./FeedbackSessionRow";
import { NoteRow } from "./NoteRow";

interface FeedbackSessionsListProps {
  feedbackSessionsList: AdminFeedbackSession[];
  handleSortChange?: (field: string) => void;
}

export const FeedbackSessionsList: React.FC<FeedbackSessionsListProps> = ({
  feedbackSessionsList,
  handleSortChange,
}) => {
  const handleSort = (field: string) => {
    if (handleSortChange) {
      handleSortChange(field);
    }
  };

  return (
    <table className="table-auto w-full border-collapse border-2 border-gray-500">
      <thead className="sticky top-0">
        <tr className="bg-blue-200">
          <th
            className="px-4 py-2 border-2 border-gray-400"
            onClick={() => handleSort("feedback_session_id")}
          >
            Feedback Session Id
          </th>
          <th
            className="px-4 py-2 border-2 border-gray-400"
            onClick={() => handleSort("note_id")}
          >
            Note ID
          </th>
          <th
            className="px-4 py-2 border-2 border-gray-400"
            onClick={() => handleSort("internal_title")}
          >
            Title
          </th>
          <th
            className="px-4 py-2 border-2 border-gray-400"
            onClick={() => handleSort("user_id")}
          >
            Issuing User
          </th>
          <th className="px-4 py-2 border-2 border-gray-400">
            Number of Events
          </th>
          <th
            className="px-4 py-2 border-2 border-gray-400"
            onClick={() => handleSort("last_modified")}
          >
            Last Modified
          </th>
        </tr>
      </thead>
      <tbody>
        {feedbackSessionsList.map((feedbackSession, index) => (
          <FeedbackSessionRow key={index} feedbackSession={feedbackSession} />
        ))}
      </tbody>
    </table>
  );
};

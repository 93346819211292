import { RoundedButton } from "../../styles/CustomButtons";
import { useAuth0 } from "@auth0/auth0-react";

export const LogoutButton = () => {
  const { logout } = useAuth0();

  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userInfo");
    logout({
      logoutParams: {
        returnTo: window.location.origin
      }
    });
  };

  return (
    <RoundedButton variant="outlined" onClick={handleLogout}>
      Logout
    </RoundedButton>
  );
};

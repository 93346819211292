import React, { useState } from "react";
import { AdminTemplate } from "../../types/types";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  templates: AdminTemplate[];
  onConfirm: (selectedTemplateId: string, displayName: string) => void;
}

export const TemplatesModal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  templates,
  onConfirm,
}) => {
  const [selectedTemplate, setSelectedTemplate] = useState<string>(
    templates[0].template_id
  );
  const [displayName, setDisplayName] = useState<string>(
    templates[0].template_name
  );

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const template = templates.find(
      (template) => template.template_id === event.target.value
    );
    if (!template) return;
    setSelectedTemplate(template.template_id);
    setDisplayName(template.template_name);
  };

  const handleDisplayNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDisplayName(event.target.value);
  };

  const handleConfirm = () => {
    onConfirm(selectedTemplate, displayName);
  };

  const handleOverlayClick = (event: any) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div
        className="flex items-center justify-center min-h-screen"
        onClick={handleOverlayClick}
      >
        <div className="bg-white p-4 rounded-lg text-left overflow-hidden shadow-xl">
          <h3 className="text-lg leading-6 font-medium text-gray-900 mb-3">
            Select a template
          </h3>
          <select
            value={selectedTemplate}
            onChange={handleChange}
            className="block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          >
            {templates.map((template) => (
              <option key={template.template_id} value={template.template_id}>
                {template.template_name}
              </option>
            ))}
          </select>
          <h3 className="my-2">Display Name for this User?</h3>
          <input
            type="text"
            value={displayName}
            onChange={handleDisplayNameChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
          <div className="mt-5 sm:mt-3 sm:flex sm:flex-row-reverse">
            <button
              type="button"
              className="ml-3 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={handleConfirm}
            >
              Confirm
            </button>
            <button
              type="button"
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
              onClick={onClose}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

import { useSelectedUser } from "../../context/selectedUserContext.tsx";
import { AdminSpecialty, AdminUser } from "../../types/types";

interface UserSpecialtiesProps {
  usersList: AdminUser[];
  specialties: AdminSpecialty[];
  removeUserFromSpecialty: (specialty_id: string, user_id: string) => void;
  addUserToSpecialty: () => void;
}

export const UserSpecialties = ({
  usersList,
  specialties,
  removeUserFromSpecialty,
  addUserToSpecialty,
}: UserSpecialtiesProps) => {
  const { selectedUser } = useSelectedUser();

  if (!selectedUser) {
    return <></>;
  }

  const userToShow = usersList.find((obj) => obj._id === selectedUser);

  if (!userToShow) {
    return <></>;
  }

  return (
    <div className="m-4 p-4 bg-slate-100 rounded-md">
      <h2 className="text-2xl font-bold mb-2">Associated Specialties</h2>
      {userToShow.associated_specialties?.map((specialty_id) => {
        const specialty = specialties.find(
          (s) => s.specialty_id === specialty_id
        );
        return (
          <div
            key={specialty_id}
            className="mb-2 bg-white p-2 rounded-md shadow"
          >
            <div className="mb-2">
              <span className="font-semibold">{specialty?.specialty_name}</span>
              <span className="ml-4 text-sm text-gray-500">{specialty_id}</span>
            </div>
            <div className="flex justify-between">
              <button className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700">
                Edit Specialty
              </button>
              <button
                className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-700"
                onClick={() =>
                  removeUserFromSpecialty(specialty_id, selectedUser)
                }
              >
                Remove User From Specialty
              </button>
            </div>
          </div>
        );
      })}
      <button
        className="px-4 py-2 mt-2 bg-green-500 text-white rounded hover:bg-green-700"
        onClick={addUserToSpecialty}
      >
        Add User to Specialty
      </button>
    </div>
  );
};

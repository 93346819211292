import React from "react";
import { Slide, Alert, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useUIState } from "../context/uiState";
import { UIStateType } from "../context/uiState/context";

export const AlertBanner: React.FC = () => {
  const { state, setState } = useUIState();

  const handleClose = () => {
    setState((prev: UIStateType) => ({
      ...prev,
      alertBanner: { ...prev.alertBanner, isVisible: false, action: undefined },
    }));
  };

  const handleBannerClick = () => {
    // Check if there's an action and call it
    if (state.alertBanner.action) {
      state.alertBanner.action();
    }
  };

  return (
    <Slide
      direction="down"
      in={state.alertBanner.isVisible}
      mountOnEnter
      unmountOnExit
      style={{ cursor: state.alertBanner.action ? "pointer" : "initial" }}
    >
      <Alert
        severity={state.alertBanner.color}
        onClick={handleBannerClick}
        sx={{
          fontSize: "1.25rem", // Increase font size
          "& .MuiAlert-message": {
            // Ensures message and potentially the title have increased font size
            fontSize: "1.25rem",
            marginLeft: 2.5,
          },
        }}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="medium"
            onClick={(e) => {
              e.stopPropagation(); // Prevents the banner's click action when closing
              handleClose();
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
        onClose={handleClose}
        icon={false}
      >
        {state.alertBanner.message}
      </Alert>
    </Slide>
  );
};
